import React from "react";
import {isNullable} from "../../providers/objectsAreEqual";
import "./MMInlineSelect.less";
import {toArray} from "../../providers/toArray";

export default class MMInlineSelect extends React.Component {
    onChange = (value) => {
        let new_value = value;
        if (this.props.mode === "multiple") {
            new_value = isNullable(this.props.value) ? [] : toArray(this.props.value);
            if (this.isSelected(value)) {
                for (const key_entry of toArray(value)) {
                    const index = new_value.indexOf(key_entry);
                    if (index >= 0) new_value.splice(index, 1);
                }
            } else {
                for (const key_entry of toArray(value)) {
                    const index = new_value.indexOf(key_entry);
                    if (index < 0) new_value.push(key_entry);
                }
            }
        } else if (this.isSelected(value)) {
            new_value = null;
        }
        if (this.props.onChange) this.props.onChange(new_value);
    };

    isSelected = (key) => {
        for (const key_entry of toArray(key)) {
            if (!toArray(this.props.value)
                .includes(key_entry)) {
                return false;
            }
        }
        return true;
    };

    render() {
        return (
            <div className={`MMInlineSelect ${this.props.className}`}>
                {this.props.options.map(option => (
                    <div
                        className={"option " + (this.isSelected(option.key) ? "selected" : "")}
                        onClick={() => this.onChange(option.key)}
                        key={option.key}
                    >
                        {option.value}
                    </div>
                ))}
            </div>);
    }
}

MMInlineSelect.defaultProps = {
    options: [],
    mode: "default",
    value: ""
};
