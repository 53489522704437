import {LoadingOutlined} from "@ant-design/icons";
import React from "react";
import {DatePicker} from 'antd';
import DatePickerLocale from 'antd/es/date-picker/locale/da_DK';
import _ from "lodash";
import moment from "moment";
import "./CalendarEntryEditor.less";
import MMModal from "../../elements/layout/MMModal";
import MMButton from "../../elements/buttons/MMButton";
import MMTriggersComponent from "../../elements/components/MMTriggersComponent";
import ModalController from "../../controllers/ModalController";
import MMSelect from "../../elements/inputs/MMSelect";
import {uuidv4} from "../../providers/data/dataMiddleware";
import MMInlineSelect from "../../elements/inputs/MMInlineSelect";
import {parseDate, parsedDateToDateEntry} from "../../providers/triggers/triggerToCalendarEntries";
import objectsAreEqual from "../../providers/objectsAreEqual";

class CalendarEntryEditor extends MMTriggersComponent {
    constructor(props) {
        super(props);
        this.modalController = new ModalController(this);
    }

    componentDidMount() {
        super.componentDidMount();
        const is_new = !this.props.timeline_uuid;
        const trigger_id = !is_new ? this.props.trigger_id : uuidv4();
        const inversetrigger_id = !is_new ? this.props.inversetrigger_id : uuidv4();
        const start = is_new ? moment(this.props.date) : null;
        this.setState({
            timeline_uuid: this.props.timeline_uuid,
            new: is_new,
            editable: true,
            trigger_id,
            inversetrigger_id,
            trigger_uuid: !is_new ? this.props.trigger_uuid : trigger_id,
            inversetrigger_uuid: !is_new ? this.props.inversetrigger_uuid : inversetrigger_id
        }, () => {
            if (start) {
                this.onStartChange(start);
            }
            if (!is_new) {
                this.parseExistingEntry();
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps);
        if (prevState.start !== this.state.start
            || prevState.end !== this.state.end
            || prevState.repeat !== this.state.repeat
            || prevState.repeat_until !== this.state.repeat_until) {
            this.onDateChange();
        }
    }

    triggerIsParsable = trigger => {
        if (!trigger || !trigger.time || !trigger.time.time) {
            return false;
        }
        for (const prop of ["time", "before", "after"]) {
            if (trigger.time[prop] && (trigger.time[prop].includes("sunset") || trigger.time[prop].includes("sunrise"))) {
                return false;
            }
        }
        return true;
    };

    parseExistingEntry = () => {
        const trigger = this.getElement(this.state.trigger_id, "trigger", "id");
        const inversetrigger = this.getElement(this.state.inversetrigger_id, "trigger", "id");

        if (!this.triggerIsParsable(trigger) || !this.triggerIsParsable(inversetrigger)
            || !objectsAreEqual(trigger.time.before, inversetrigger.time.before)
            || !objectsAreEqual(trigger.time.after, inversetrigger.time.after)) {
            this.setState({editable: false});
            return;
        }

        if (!trigger) {
            console.error("Failed to find trigger with id: " + this.state.trigger_id);
            return;
        }
        if (!inversetrigger) {
            console.error("Failed to find inverse trigger with id: " + this.state.inversetrigger_id);
            return;
        }

        const trigger_time_parsed = parseDate(trigger.time.time);
        const inversetrigger_time_parsed = parseDate(inversetrigger.time.time);
        let repeat = null;
        let repeat_until = null;
        let start;
        let end;
        if (trigger.time.after) {
            start = moment(trigger.time.after);
            end = moment(parsedDateToDateEntry(inversetrigger_time_parsed, start.toDate()));
            repeat_until = moment(trigger.time.before);

            const {
                day,
                weekDay,
                year,
                month
            } = trigger_time_parsed;
            repeat = "dag";
            if (weekDay) {
                repeat = "uge";
            }
            if (day) {
                repeat = "måned";
            }
            if (month) {
                repeat = "år";
            }
            if (year) {
                repeat = null;
            }

            /* if (!year) {
                repeat = "år";
                if (!month) {
                    repeat = "måned";
                    if (!day) {
                        repeat = "uge";
                        if (!weekDay) {
                            repeat = "dag";
                        }
                    }
                }
            } */
        } else {
            start = moment(trigger.time.time);
            end = moment(parsedDateToDateEntry(inversetrigger_time_parsed, start.toDate()));
        }

        // Replace triggers if they're tied to multiple timelines
        const other_timelines_are_using_trigger = this.getTimelinesUsingTrigger(trigger.id, ["trigger", "inversetrigger"])
            .some(t => t.uuid !== this.state.timeline_uuid);
        const other_timelines_are_using_inversetrigger = this.getTimelinesUsingTrigger(inversetrigger.id, ["trigger", "inversetrigger"])
            .some(t => t.uuid !== this.state.timeline_uuid);

        let p = Promise.resolve();
        if (other_timelines_are_using_trigger) {
            p = p.then(() => this.replaceTriggerOnTimeline(trigger));
        }
        if (other_timelines_are_using_inversetrigger) {
            p = p.then(() => this.replaceTriggerOnTimeline(inversetrigger, "inversetrigger"));
        }
        p.then(() => this.setState({
            start,
            end,
            repeat,
            repeat_until
        }));
    };

    deleteEntry = () => {
        const trigger_changes = [];

        if (this.state.trigger_id) {
            const timelines_using_trigger = this.getTimelinesUsingTrigger(this.state.trigger_id, ["trigger", "inversetrigger"]);
            if (timelines_using_trigger.length <= 1) {
                // This entry is the only one using this trigger, delete it
                trigger_changes.push({
                    uuid: this.state.trigger_uuid,
                    delete: true
                });
            }
        }
        if (this.state.inversetrigger_id) {
            const timelines_using_trigger = this.getTimelinesUsingTrigger(this.state.inversetrigger_id, ["trigger", "inversetrigger"]);
            if (timelines_using_trigger.length <= 1) {
                // This entry is the only one using this trigger, delete it
                trigger_changes.push({
                    uuid: this.state.inversetrigger_uuid,
                    delete: true
                });
            }
        }
        this.removeTimelineEntry(this.state.timeline_uuid)
            .then(() => this.setTypeChanges(trigger_changes))
            .then(() => this.saveChanges());
    };

    discardChanges = () => {
        this.modalController.close();
    };

    replaceTriggerOnTimeline = (trigger, type = "trigger") => {
        const id = uuidv4();
        const new_trigger = {
            ...trigger,
            id,
            uuid: id
        };
        return this.removeTriggerOnTimeline(trigger.id, this.state.timeline_uuid, [type])
            .then(() => this.setTypeChanges(new_trigger))
            .then(() => this.addTriggerOnTimeline(id, this.state.timeline_uuid, type))
            .then(() => new Promise(resolve => this.setState({
                [type + "_id"]: id,
                [type + "_uuid"]: id
            }, resolve)));
    };

    removeTimelineEntry = timeline_uuid => {
        if (!timeline_uuid) {
            return Promise.resolve();
        }

        let p = Promise.resolve();
        if (this.state.trigger_id) {
            p = p.then(() => this.removeTriggerOnTimeline(this.state.trigger_id, timeline_uuid));
        }
        if (this.state.inversetrigger_id) {
            p = p.then(() => this.removeTriggerOnTimeline(this.state.inversetrigger_id, timeline_uuid, ["inversetrigger"]));
        }
        return p;
    };

    addTimelineEntry = timeline_uuid => {
        const timeline = _.cloneDeep(this.getElement(timeline_uuid, "timeline"));
        if (!timeline) {
            console.error("Failed to find timeline", timeline_uuid);
            return;
        }
        if (this.state.trigger_id && !timeline.trigger.includes(this.state.trigger_id)) {
            timeline.trigger.push(this.state.trigger_id);
        }
        if (this.state.inversetrigger_id && !timeline.inversetrigger.includes(this.state.inversetrigger_id)) {
            timeline.inversetrigger.push(this.state.inversetrigger_id);
        }
        return this.setTypeChanges({
            uuid: timeline.uuid,
            trigger: timeline.trigger,
            inversetrigger: timeline.inversetrigger
        }, "timeline");
    };

    onChangeTimeline = timeline_uuid => {
        this.removeTimelineEntry(this.state.timeline_uuid) // Remove the triggers from the old timeline
            .then(() => this.addTimelineEntry(timeline_uuid)) // Add the triggers to the new timeline
            .then(() => this.setState({timeline_uuid})); // Set MMSelect
    };

    onStartChange = start => {
        this.setState({start});
    };

    onEndChange = end => {
        this.setState({end});
    };

    onDateChange = () => {
        const start = this.state.start;
        const end = this.state.end;
        const repeat = this.state.repeat;
        const repeat_until = this.state.repeat_until;
        let start_time;
        let end_time;
        let before;
        let after;
        if (!start || !end) {
            return;
        }
        if (!repeat) {
            start_time = start.format("YYYY-MM-DD HH:mm:ss");
            end_time = end.format("YYYY-MM-DD HH:mm:ss");
        } else {
            if (!repeat_until) {
                return;
            }
            after = start.format("YYYY-MM-DD HH:mm:ss");
            before = repeat_until.format("YYYY-MM-DD HH:mm:ss");
            switch (repeat) {
                case "år":
                    start_time = start.format("MM-DD HH:mm:ss");
                    end_time = end.format("MM-DD HH:mm:ss");
                    break;
                case "måned":
                    start_time = start.format("DD HH:mm:ss");
                    end_time = end.format("DD HH:mm:ss");
                    break;
                case "uge":
                    start_time = start.format("ddd HH:mm:ss");
                    end_time = end.format("ddd HH:mm:ss");
                    break;
                case "dag":
                    start_time = start.format("HH:mm:ss");
                    end_time = end.format("HH:mm:ss");
                    break;
                default:
                    break;
            }
        }

        this.setTypeChanges([
            {
                uuid: this.state.trigger_uuid,
                id: this.state.trigger_id,
                time: {
                    before,
                    time: start_time,
                    after
                }
            },
            {
                uuid: this.state.inversetrigger_uuid,
                id: this.state.inversetrigger_id,
                time: {
                    before,
                    time: end_time,
                    after
                }
            }
        ]);
    };

    onRepeatUntilChange = repeat_until => this.setState({repeat_until});

    renderEdit = () => (
        <div>
            <p>Start tidspunkt</p>
            <DatePicker
                showTime={{format: 'HH:mm'}}
                format="ddd DD MMM YYYY HH:mm"
                locale={DatePickerLocale}
                value={this.state.start}
                onChange={this.onStartChange}
                disabledDate={current => current >= this.state.end}
            />
            <p>Stop tidspunkt</p>
            <DatePicker
                showTime={{format: 'HH:mm'}}
                format="ddd DD MMM YYYY HH:mm"
                locale={DatePickerLocale}
                value={this.state.end}
                onChange={this.onEndChange}
                disabledDate={current => current <= this.state.start}
            />
            <p>Gentag</p>
            <MMInlineSelect
                options={[
                    {
                        key: "dag",
                        value: "Dagligt"
                    },
                    {
                        key: "uge",
                        value: "Ugentligt"
                    },
                    {
                        key: "måned",
                        value: "Månedligt"
                    },
                    {
                        key: "år",
                        value: "Årligt"
                    }
                ]}
                value={this.state.repeat}
                onChange={repeat => this.setState({repeat})}
            />
            {this.state.repeat
                && (
                    <div>
                        <p>Gentag indtil</p>
                        <DatePicker
                            showTime={{format: 'HH:mm'}}
                            format="ddd DD MMM YYYY HH:mm"
                            locale={DatePickerLocale}
                            value={this.state.repeat_until}
                            onChange={this.onRepeatUntilChange}
                            disabledDate={current => current <= this.state.end}
                        />
                    </div>)}
        </div>);

    invalidInput = () => this.state.new && (!this.state.start || !this.state.end || (this.state.repeat && !this.state.repeat_until));

    render() {
        return (
            <MMModal
                width={800}
                close={this.state.closing}
                closable={!this.state.loading}
                onClose={() => {
                    this.setState({closing: true});
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                }}
                className={`CalendarEntryEditor ${this.props.className ? this.props.className : ""} ${this.state.loading ? "loading" : ""}`}
                header={
                    [
                        <div className="align-left" key="align-left">
                            <p className="action-title">
                                {this.state.new ? "Tilføj " : "Redigér "}
                                begivenhed
                            </p>
                            <LoadingOutlined className="loading-icon"/>
                        </div>
                    ]
                }
                footer={
                    <div>
                        {!this.state.new
                            && (
                                <MMButton
                                    className="delete red"
                                    style={{width: 150}}
                                    onClick={this.deleteEntry}
                                    disabled={this.state.loading}
                                >
                                    Slet begivenhed
                                </MMButton>)}
                        <div className="align-right">
                            <MMButton
                                className="cancel grey"
                                style={{width: 200}}
                                onClick={() => this.discardChanges()}
                                disabled={this.state.loading}
                            >
                                Annullér
                            </MMButton>
                            <MMButton
                                className="submit"
                                style={{width: 200}}
                                disabled={!this.hasChanges() || this.state.loading || this.invalidInput()}
                                onClick={() => this.saveChanges()}
                            >
                                Gem ændringer
                            </MMButton>
                        </div>
                    </div>
                }
            >
                <p>Scenarie</p>
                <MMSelect
                    options={this.getElements("timeline")
                        .map(t => ({
                            key: t.uuid,
                            value: t["webapp-name"] || t.name
                        }))}
                    onChange={this.onChangeTimeline}
                    value={this.state.timeline_uuid}
                />
                {this.state.editable && this.renderEdit()}
            </MMModal>
        );
    }
}

export default MMTriggersComponent.connect(CalendarEntryEditor);
