import React from "react";
import {CSSTransition} from "react-transition-group";
import MMCard from "./MMCard";
import "./MMModal.less";
import constants from "../../less/motomuto_ras/constants";

export default class MMModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closing: false
        };
    }

    componentDidMount() {
        document.body.classList.add("modal");
        document.addEventListener("keydown", this.handleKeyPress, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.close !== this.props.close) { // Parent requests us to close
            this.setState({closing: this.props.close});
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("modal");
        document.removeEventListener("keydown", this.handleKeyPress, false);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 27) { // Exit
            this.onClose(event);
        }
    };

    onClose = event => {
        event.stopPropagation();
        if (!this.props.onClose || !this.props.closable) return;
        this.setState({closing: true});

        if (this.props.onClose) {
            setTimeout(this.props.onClose, parseFloat(constants["modal-transition-duration"]));
        }
    };

    render() {
        return (
            <div
                className={`MMModal ${this.props.className ? this.props.className : ""} ${this.props.loading ? "loading" : ""}`}
            >
                <div className="backdrop" onClick={this.onClose}/>
                <CSSTransition
                    classNames="modal"
                    appear
                    enter={false}
                    timeout={parseFloat(constants["modal-transition-duration"])}
                    in={!this.state.closing}
                >
                    <MMCard width={this.props.width} height={this.props.height} style={{maxWidth: "calc(100% - 10px)"}}>
                        <div className="container">
                            <div className="header">
                                {this.props.header}
                            </div>
                            <div className="body">
                                {this.props.children}
                            </div>
                            <div className="footer">
                                {this.props.footer}
                            </div>
                        </div>
                    </MMCard>
                </CSSTransition>
            </div>
        );
    }
}

MMModal.defaultProps = {
    closable: true
};
