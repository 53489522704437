import React from "react";
import {Radio, Upload} from "antd";
import {connect} from "react-redux";
import moment from "moment";
import {UploadOutlined} from "@ant-design/icons";
import classnames from "classnames";
import MMCard from "../../../elements/layout/MMCard";
import MMIPInput from "../../../elements/inputs/MMIPInput";
import {APP_CONFIG} from "../../../config";
import MMButton from "../../../elements/buttons/MMButton";
import MMDatePicker from "../../../elements/inputs/MMDatePicker";
import MMTimePicker from "../../../elements/inputs/MMTimePicker";
import objectsAreEqual, {isNullable} from "../../../providers/objectsAreEqual";
import {dataActions} from "../../../providers/data/dataActions";
import {notificationsActions} from "../../../providers/notifications/notificationsActions";
import "./Configuration.less";
import {getDefaultPlaylist} from "../../../providers/data/dataMiddleware";
import MMDialogModal from "../../../elements/layout/MMDialogModal";
import constants from "../../../less/motomuto_ras/constants";
import MMTextInput from "../../../elements/inputs/MMTextInput";
import MMRadio from "../../../elements/inputs/MMRadio";
import MMToggle from "../../../elements/inputs/MMToggle";

class Configuration extends React.Component {
    controllerTimeTimer;

    ip_fields = [
        {
            name: "IP-adresse",
            key: "ethernet_ip"
        },
        {
            name: "Subnet",
            key: "ethernet_subnet"
        },
        {
            name: "Gateway",
            key: "ethernet_gateway",
            allow_empty: true
        }
    ];

    config_fields = [
        {
            name: "Enhedsnavn",
            key: "name",
            type: "text"
        },
        {
            name: "DHCP",
            key: "ethernet",
            type: "toggle",
            checked: "dhcp",
            not_checked: "static",
            checked_hide: ["ethernet_ip", "ethernet_subnet", "ethernet_gateway"]
        },
        ...this.ip_fields,
    ];

    constructor(props) {
        super(props);
        this.state = {
            changes: {system_config: {}},
            epoch_time: 0,
            epoch_time_changed_by_user: false,
            date_valid: true,
            time_valid: true
        };
    }

    componentDidMount() {
        this.props.dispatch({type: dataActions.GET_CONTROLLER_TIMESERVER});
        this.calculateControllerTime();
        this.controllerTimeTimer = setInterval(this.calculateControllerTime, 1000);
    }

    componentDidUpdate(prevProps) {
        if ((Object.keys(this.state.changes.system_config).length > 0
            && !objectsAreEqual(prevProps.data.system_config, this.props.data.system_config))
            || (this.state.changes.timeserver && prevProps.data.timeserver !== this.props.data.timeserver)) {
            // Clear saved changes
            this.setState({changes: {system_config: {}}});
        }
    }

    componentWillUnmount() {
        clearInterval(this.controllerTimeTimer);
    }

    calculateControllerTime = () => {
        this.setState(state => {
            if (state.epoch_time_changed_by_user) {
                state.epoch_time++;
            } else {
                const controller_time = new Date(this.props.data.time * 1000);
                const seconds_since_controller_time_update = Date.now() / 1000 - this.props.data.time_updated_at;
                controller_time.setSeconds(controller_time.getSeconds() + seconds_since_controller_time_update);
                const epoch_time = controller_time.getTime() / 1000;
                if (!isNaN(epoch_time)) {
                    state.epoch_time = epoch_time;
                }
            }
            return state;
        });
    };

    pullTimeFromPC = () => {
        this.setState({
            epoch_time_changed_by_user: true,
            epoch_time: parseInt(Date.now() / 1000)
        });
        // Reset date and time input fields
        if (this.timePicker) {
            this.timePicker.reset();
        }
        if (this.datePicker) {
            this.datePicker.reset();
        }
    };

    submitSystemConfiguration = () => {
        const changes = {...this.state.changes.system_config};
        // Send system configuration
        this.props.dispatch({
            type: dataActions.SET_SYSTEM_CONFIGURATION,
            changes
        });

        switch (this.getTimeserverConfig()) {
            case "auto":
            case "timeserver":
                this.props.dispatch({
                    type: dataActions.SET_CONTROLLER_TIMESERVER,
                    timeserver: this.getTimeserver()
                });
                break;
            case "manual":
                if (this.state.epoch_time_changed_by_user || this.state.changes.timeserver !== this.props.data.timeserver) {
                    // Set time on controller
                    this.props.dispatch({
                        type: dataActions.SET_CONTROLLER_TIME,
                        time: this.state.epoch_time,
                        callback: () => {
                            this.setState(state => {
                                const state_changes = {...state.changes};
                                delete state_changes.timeserver;
                                return {
                                    changes: state_changes,
                                    epoch_time_changed_by_user: false
                                };
                            });
                        }
                    });
                }
                break;
            default:
                break;
        }
    };

    submitConfiguration = () => {
        if (this.props.data.showInitialSetup) {
            this.props.dispatch({
                type: dataActions.SET_LIGHT_CONFIGURATION,
                restart: true,
                config: getDefaultPlaylist(APP_CONFIG, this.props.data.system_config), // Use default playlist,
                callback: this.submitSystemConfiguration
            });
        } else {
            this.submitSystemConfiguration();
        }
    };

    setLocation = (event) => {
        const location = event.target.value;
        this.setChange("longitude", [location.longitude]);
        this.setChange("latitude", [location.latitude]);
        this.setChange("elevation", [location.elevation]);
    };

    getLocation = () => {
        if (!APP_CONFIG.GEOGRAPHICAL_LOCATIONS) return null;
        const location_matches = APP_CONFIG.GEOGRAPHICAL_LOCATIONS.filter(location => location.latitude === this.getValue("latitude", 0)
            && location.longitude === this.getValue("longitude", 0)
            && location.elevation === this.getValue("elevation", 0));
        if (location_matches.length > 0) return location_matches[0];
    };

    getTimeserverConfig = () => {
        const timeserver = this.getTimeserver();
        if (timeserver !== "auto" && timeserver !== "manual" && timeserver) {
            return "timeserver";
        }
        return timeserver;
    };

    getTimeserver = () => {
        let timeserver = this.props.data.timeserver;
        if (Object.prototype.hasOwnProperty.call(this.state.changes, "timeserver")) {
            timeserver = this.state.changes.timeserver;
        }
        return timeserver === "timeserver" ? "time.google.com" : timeserver;
    };

    setTimeserver = timeserver => {
        this.setState(state => {
            const changes = {
                ...state.changes,
                timeserver
            };
            if (timeserver === this.props.data.timeserver) {
                delete changes.timeserver;
            }
            return {changes};
        });
    };

    getValue = (key, index = null) => {
        let array = [];
        if (this.state.changes.system_config[key]) {
            array = this.state.changes.system_config[key];
        } else if (this.props.data.system_config && this.props.data.system_config[key]) {
            array = this.props.data.system_config[key];
        }

        if (array && index !== null) {
            if (array.length > index && array[index] !== null) return array[index];
            return "";
        }
        return array;
    };

    setChange = (key, value) => {
        this.setState((state) => {
            state.changes.system_config[key] = value;
            if (isNullable(state.changes.system_config[key])
                || state.changes.system_config[key].length === 0
                || (this.props.data.system_config && objectsAreEqual(this.props.data.system_config[key], state.changes.system_config[key], false))
            ) {
                // Remove dropped changes
                delete state.changes.system_config[key];
            }
            return state;
        });
    };

    closeDialog = () => {
        this.setState({dialog_closing: true});
        setTimeout(() => this.setState({dialog_open: false}), parseFloat(constants["modal-transition-duration"]));
    };

    resetController = () => {
        this.setState({
            dialog_open: true,
            dialog_title: "Er du sikker på at du vil nulstille enheden?",
            dialog_children: null,
            dialog_height: null,
            dialog_width: null,
            dialog_submit_disabled: null,
            dialog_content: "Hvis du fortsætter, slettes dine scenarier og navngivningen af lamperne.\n"
                + "                Netværksopsætning samt dato og tid vil ikke blive nulstillet.",
            dialog_closing: false,
            dialog_onsubmit: () => {
                this.props.dispatch({type: dataActions.RESET_CONTROLLER});
            }
        });
    };

    backupController = () => {
        this.props.dispatch({type: dataActions.BACKUP_CONTROLLER});
    };

    onSubmitRestoreFile = () => {
        const file = this.state.restore.fileList[0].originFileObj;
        if (!file || !file.name || !file.name.endsWith(".json")) {
            this.props.dispatch({
                type: notificationsActions.RESTORE_FAILED,
                error: "Filen er ugyldig"
            });
            this.setState({loading: false});
            return;
        }
        const fileReader = new FileReader();
        fileReader.readAsText(file);

        fileReader.onloadend = () => {
            try {
                const config = JSON.parse(fileReader.result);
                this.props.dispatch({
                    type: dataActions.SET_LIGHT_CONFIGURATION,
                    restart: true,
                    config,
                    callback: () => {
                        this.props.dispatch({type: notificationsActions.RESTORE_COMPLETED});
                    }
                });
                this.closeDialog();
            } catch (e) {
                console.error("JSON parse failed:", e);
                this.props.dispatch({
                    type: notificationsActions.RESTORE_FAILED,
                    error: "Filen er ugyldig"
                });
            }
            this.setState({loading: false});
        };
    };

    restoreController = () => {
        this.setState({
            dialog_open: true,
            dialog_title: "Gendannelse",
            dialog_height: 340,
            dialog_width: null,
            dialog_submit_disabled: true,
            dialog_children:
                <div>
                    <Upload.Dragger
                        accept=".json"
                        beforeUpload={() => false}
                        onChange={(info) => {
                            if (info.fileList.length > 1) {
                                info.fileList.splice(0, 1);
                            }
                            this.setState({
                                restore: info,
                                dialog_submit_disabled: info.fileList.length !== 1
                            });
                        }}
                    >
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined/>
                        </p>
                        <p className="ant-upload-text">Klik eller træk en backup-fil til dette område</p>
                        <p className="ant-upload-hint">Kun filer med filendelsen .json er understøttede</p>
                    </Upload.Dragger>
                </div>,
            dialog_closing: false,
            dialog_onsubmit: this.onSubmitRestoreFile
        });
    };

    isConfigInvalid = () => {
        // eslint-disable-next-line max-len
        const ipv4_regex = /\b(?:(?:25[0-5]\.)|(?:2[0-4][0-9]\.)|(?:1[0-9]?[0-9]?\.)|(?:[0-9][0-9]?\.)){3}(?:(?:25[0-5])|(?:2[0-4]?[0-9]?)|(?:1[0-9]?[0-9]?)|(?:[0-9][0-9]?))\b/;
        const domain_regex = /^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/;
        for (const ip_field of this.getConfigFields(this.ip_fields)) {
            const val = this.getValue(ip_field.key);
            if (!ipv4_regex.test(val) && !(ip_field.allow_empty && val[0] === "")) {
                return true;
            }
        }

        const timeserver = this.getTimeserver();
        if (timeserver !== "auto" && timeserver !== "manual" && !domain_regex.test(timeserver) && !ipv4_regex.test(timeserver)) return true;

        return !this.state.time_valid || !this.state.date_valid;
    };

    getConfigFields = (fields = this.config_fields) => {
        let displayed_fields = fields;
        for (const config_field of this.config_fields) {
            if (config_field.checked_hide) {
                const checked = this.getValue(config_field.key, 0) === config_field.checked;
                if (checked) {
                    displayed_fields = displayed_fields.filter(field => !config_field.checked_hide.includes(field.key));
                }
            }
        }

        return displayed_fields;
    };

    renderInput = element => {
        switch (element.type) {
            case "text":
                return <MMTextInput
                    className="block"
                    onChange={(event, value) => this.setChange(element.key, [value])}
                    value={this.getValue(element.key, 0)}
                />;
            case "toggle":
                return <MMToggle
                    checked={this.getValue(element.key, 0) === element.checked}
                    onChange={(event, value) => this.setChange(element.key, [value ? element.checked : element.not_checked])}
                />;
            default:
                return <MMIPInput
                    className="block"
                    onChange={(event, value) => this.setChange(element.key, [value])}
                    value={this.getValue(element.key, 0)}
                />;
        }
    };

    isBodyOverflowing = () => {
        const el = document.getElementsByClassName("body")[0];
        if (!el) {
            return false;
        }
        return el.scrollHeight > el.clientHeight;
    };

    render() {
        return (
            <div className="ConfigurationPage" style={this.props.style}>
                {this.state.dialog_open
                && (
                    <MMDialogModal
                        closable={!this.state.loading}
                        loading={this.state.loading}
                        submit_disabled={this.state.dialog_submit_disabled}
                        close={this.state.dialog_closing}
                        width={this.state.dialog_width || 600}
                        height={this.state.dialog_height || 300}
                        onClose={() => this.setState({dialog_open: false})}
                        onSubmit={() => {
                            this.setState({loading: true});
                            this.state.dialog_onsubmit();
                        }}
                        title={this.state.dialog_title}
                        content={this.state.dialog_content}
                    >
                        {this.state.dialog_children}
                    </MMDialogModal>)}

                <MMCard width={320} height={640}>
                    <div className="container configuration">
                        <p className="header">Opsætning</p>
                        <div className={classnames("body", {overflow: this.isBodyOverflowing()})}>
                            {this.getConfigFields()
                                .map(element => (
                                    <div className="input-group" key={element.key}>
                                        <p>{element.name}</p>
                                        {this.renderInput(element)}
                                    </div>))}
                            {APP_CONFIG.GEOGRAPHICAL_LOCATIONS ? (
                                <div className="input-group">
                                    <p>Vælg geografisk placering</p>
                                    <Radio.Group onChange={this.setLocation} value={this.getLocation()}>
                                        {APP_CONFIG.GEOGRAPHICAL_LOCATIONS.map(location => (
                                            <Radio value={location} key={location.name}>
                                                {location.name}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </div>
                            ) : null}
                            <div className="input-group backup">
                                <p>Backup & gendannelse</p>
                                {!this.props.data.showInitialSetup
                                && <MMButton className="green" onClick={this.backupController}>Backup</MMButton>}
                                <MMButton
                                    className={"grey " + (this.props.data.showInitialSetup ? "block" : "")}
                                    onClick={this.restoreController}
                                >
                                    Gendan
                                    {' '}
                                    {this.props.data.showInitialSetup ? "fra backup" : ""}
                                </MMButton>
                                {!this.props.data.showInitialSetup
                                && <MMButton className="orange" onClick={this.resetController}>Nulstil</MMButton>}
                            </div>
                            <div className="input-group">
                                <p>Tidskilde</p>
                                <MMRadio
                                    value={this.getTimeserverConfig()}
                                    onChange={this.setTimeserver}
                                    options={[
                                        {
                                            key: "auto",
                                            value: "Internet"
                                        },
                                        {
                                            key: "timeserver",
                                            value: "Lokal"
                                        },
                                        {
                                            key: "manual",
                                            value: "Intern"
                                        }]}
                                />
                                <div className="inline">
                                    {this.getTimeserverConfig() === "manual" && <MMDatePicker
                                        value={moment(this.state.epoch_time * 1000)
                                            .format("YYYY-MM-DD")}
                                        onDateInputChange={(event, value, valid) => {
                                            this.setState({date_valid: valid});
                                        }}
                                        ref={ref => {
                                            this.datePicker = ref;
                                        }}
                                        onChange={(event, value) => {
                                            this.setState((state) => {
                                                if (value.length <= 0) return state;
                                                state.epoch_time_changed_by_user = true;

                                                // Set to new date
                                                const new_date = moment(value, "YYYY-MM-DD");
                                                const current_time = moment(state.epoch_time * 1000);
                                                current_time.year(new_date.year());
                                                current_time.month(new_date.month());
                                                current_time.date(new_date.date());
                                                state.epoch_time = current_time.valueOf() / 1000;
                                                return state;
                                            });
                                        }}
                                    />}
                                    {this.getTimeserverConfig() === "manual" && <MMTimePicker
                                        value={moment(this.state.epoch_time * 1000)
                                            .format("HH:mm")}
                                        onDateInputChange={(event, value, valid) => {
                                            this.setState({time_valid: valid});
                                        }}
                                        ref={ref => {
                                            this.timePicker = ref;
                                        }}
                                        onChange={(event, value) => {
                                            this.setState((state) => {
                                                if (value.length <= 0) return state;
                                                state.epoch_time_changed_by_user = true;

                                                // Set to new time
                                                const new_time = moment(value, "HH:mm");
                                                const current_time = moment(state.epoch_time * 1000);
                                                current_time.hour(new_time.hour());
                                                current_time.minute(new_time.minute());

                                                state.epoch_time = current_time.valueOf() / 1000;
                                                return state;
                                            });
                                        }}
                                    />}
                                </div>
                                {this.getTimeserverConfig() === "manual"
                                && (
                                    <MMButton className="orange block pull-time-from-pc-btn" onClick={this.pullTimeFromPC}>
                                        Hent tid og dato fra denne PC
                                    </MMButton>)}
                                {this.getTimeserverConfig() === "timeserver"
                                && <MMTextInput
                                    className="block"
                                    value={this.getTimeserver()}
                                    onChange={(event, value) => this.setTimeserver(value)}
                                />}
                            </div>
                            <MMButton
                                className="block submit"
                                disabled={
                                    (APP_CONFIG.GEOGRAPHICAL_LOCATIONS && !this.getLocation())
                                    || this.isConfigInvalid()
                                    || (!this.props.data.showInitialSetup && Object.entries(this.state.changes.system_config).length <= 0
                                        && !this.state.epoch_time_changed_by_user && !this.state.changes.timeserver)
                                }
                                onClick={this.submitConfiguration}
                            >
                                Gem opsætning
                                {this.props.data.showInitialSetup ? " og forbind" : ""}
                            </MMButton>
                        </div>
                    </div>
                </MMCard>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: state.data
});

export default connect(mapStateToProps)(Configuration);
