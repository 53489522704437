import React from "react";
import _ from "lodash";
import "./CuelistEditor.less";
import {connect} from "react-redux";
import {Table} from "antd";
import {ReactSVG} from "react-svg";
import {LoadingOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import MMButton from "../../../elements/buttons/MMButton";
import objectsAreEqual, {isNullable} from "../../../providers/objectsAreEqual";
import {uuidv4} from "../../../providers/data/dataMiddleware";
import constants from "../../../less/motomuto_ras/constants";
import {notificationsActions} from "../../../providers/notifications/notificationsActions";
import {dataActions} from "../../../providers/data/dataActions";
import MMToggle from "../../../elements/inputs/MMToggle";
import MMTextInput from "../../../elements/inputs/MMTextInput";
import MMModal from "../../../elements/layout/MMModal";
import {setTypeChanges} from "../../../providers/setTypeChanges";
import {getElements} from "../../../providers/getElements";
import MMSelect from "../../../elements/inputs/MMSelect";
import MMInlineSelect from "../../../elements/inputs/MMInlineSelect";
import icoRemove from "../../../assets/ico-remove.svg";
import icoCopy from "../../../assets/ico-copy.svg";
import MMCard from "../../../elements/layout/MMCard";
import {DragableBodyRow} from "./elements/DragableBodyRow";
import {sortObjects} from "../../../providers/sortObjects";
import {textEllipsis} from "../../../providers/textEllipsis";

class CuelistEditor extends React.PureComponent {
    cueColumns = [
        {
            title: "Scene",
            dataIndex: "scene",
            render: (text, record) => {
                const cuelist = this.getCuelist();
                const index = cuelist.cue.findIndex(e => e.uuid === record.uuid);

                const options = this.getElements("scene")
                    .map(scene => ({
                        key: scene.name,
                        value: scene["webapp-name"] || scene.name
                    }));

                options.push(...this.props.data.videos.map(video => ({
                    key: video,
                    value: video
                })));

                return <MMSelect
                    width={200}
                    value={record.scene || record.video}
                    options={options}
                    onChange={scene => {
                        let changes;
                        if (this.props.data.videos.includes(scene)) {
                            changes = {
                                scene: null,
                                video: scene
                            };
                        } else {
                            changes = {
                                scene,
                                video: null
                            };
                        }
                        this.updateCue(index, changes);
                    }}
                />;
            }
        },
        {
            title: "Samlet varighed",
            key: "combined-duration",
            render: (text, record) => {
                const cuelist = this.getCuelist();
                let total_duration = 0;
                let i = cuelist.cue.findIndex(e => e.uuid === record.uuid);
                const is_parallel = i === 0 || record.parallel === "yes";
                let end = i + 1;
                if (is_parallel) {
                    end = cuelist.cue.findIndex((e, index) => index > i && e.parallel === "yes");
                    if (end < 0) {
                        end = i + 1;
                    }
                }

                let own_time = -1;
                for (; i < end; i++) {
                    const cue_duration = this.getCueDuration(cuelist.cue[i]);

                    if (own_time < 0) {
                        own_time = cue_duration;
                    }

                    const is_looping = cuelist.cue[i].loop === "yes";
                    if (is_looping) {
                        const loop_timeout = parseInt(cuelist.cue[i]["loop-timeout"]);
                        if (loop_timeout > 0) {
                            total_duration += loop_timeout;
                        } else {
                            total_duration = -1;
                        }
                        break;
                    }
                    total_duration += cue_duration;
                }
                let unit = "ms";
                const same_time = own_time === total_duration;
                if (total_duration >= 10000 || total_duration % 100 === 0) {
                    total_duration /= 1000;
                    unit = "s";
                }

                let paragraph = `${total_duration}${unit}`;
                if (!same_time) {
                    let own_time_unit = "ms";

                    if (own_time >= 10000 || own_time % 100 === 0) {
                        own_time /= 1000;
                        own_time_unit = "s";
                    }
                    paragraph += ` / ${own_time}${own_time_unit}`;
                }
                return (
                    <p>
                        {paragraph}
                    </p>);
            }
        },
        {
            title: "Loop",
            dataIndex: "loop",
            render: (text, record) => {
                const cuelist = this.getCuelist();
                const index = cuelist.cue.findIndex(e => e.uuid === record.uuid);

                if (cuelist.cue[index].parallel !== "yes") {
                    let previous_is_looping = false;
                    for (let i = 0; i < index; i++) {
                        if (cuelist.cue[i].loop === "yes") {
                            previous_is_looping = true;
                        } else if (cuelist.cue[i].parallel === "yes") {
                            // Hit new parent, reset
                            previous_is_looping = false;
                        }
                    }

                    if (previous_is_looping) {
                        return null;
                    }
                }
                return (
                    <div className="loop-options">
                        <MMToggle
                            checked={text === "yes"}
                            onChange={(event, checked) => this.updateCue(index, {loop: checked ? "yes" : "no"})}
                        />
                        {text === "yes" && (
                            <div className="text-postfix">
                                <MMTextInput
                                    type="number"
                                    min={0}
                                    step={10}
                                    value={record["loop-timeout"]}
                                    onChange={(event, value) => this.updateCue(index, {"loop-timeout": value})}
                                />
                                <p>ms</p>
                            </div>)}
                    </div>);
            }
        },
        {
            title: "Prioritet offset",
            dataIndex: "priority",
            render: (text, record) => {
                const cuelist = this.getCuelist();
                const index = cuelist.cue.findIndex(e => e.uuid === record.uuid);
                if (index !== 0 && record.parallel !== "yes") {
                    return null;
                }
                return (
                    <MMTextInput
                        type="number"
                        min={0}
                        max={255}
                        value={text}
                        onChange={(event, value) => this.updateCue(index, {priority: value})}
                    />
                );
            }
        },
        {
            title: "Transparens",
            dataIndex: "transparency",
            render: (text, record) => {
                const cuelist = this.getCuelist();
                const index = cuelist.cue.findIndex(e => e.uuid === record.uuid);
                return (
                    <div className="text-postfix">
                        <MMTextInput
                            type="number"
                            min={0}
                            max={100}
                            value={text}
                            onChange={(event, value) => this.updateCue(index, {transparency: value})}
                        />
                        <p>%</p>
                    </div>
                );
            }
        },
        {
            title: "Varighed",
            dataIndex: "duration",
            render: (text, record) => {
                const cuelist = this.getCuelist();
                const index = cuelist.cue.findIndex(e => e.uuid === record.uuid);
                return (
                    <div className="text-postfix">
                        <MMTextInput
                            type="number"
                            min={0}
                            step={10}
                            value={text}
                            onChange={(event, value) => this.updateCue(index, {duration: value})}
                        />
                        <p>ms</p>
                    </div>
                );
            }
        },
        {
            title: "Fade",
            dataIndex: "fade-time",
            render: (text, record) => {
                const cuelist = this.getCuelist();
                const index = cuelist.cue.findIndex(e => e.uuid === record.uuid);
                return (
                    <div className="text-postfix">
                        <MMTextInput
                            type="number"
                            min={0}
                            step={10}
                            value={text}
                            onChange={(event, value) => this.updateCue(index, {"fade-time": value})}
                        />
                        <p>ms</p>
                    </div>
                );
            }
        },
        {
            title: "Shifting / Video output",
            dataIndex: "shift-direction",
            render: (text, record) => {
                const cuelist = this.getCuelist();
                const index = cuelist.cue.findIndex(e => e.uuid === record.uuid);
                if (record.scene) {
                    return this.renderShiftingOptions(text, record, index);
                }
                if (record.video) {
                    return this.renderVideoOptions(text, record, index);
                }
            }
        },
        {
            title: "Valgmuligheder",
            dataIndex: "actions",
            render: (text, record) => {
                const cuelist = this.getCuelist();
                const index = cuelist.cue.findIndex(e => e.uuid === record.uuid);
                return (
                    <div className="actions">
                        <div onClick={() => this.updateCue(index, {delete: true})}>
                            <ReactSVG
                                src={icoRemove}
                                beforeInjection={svg => svg.classList.add("icon-remove-svg")}
                                className="icon icon-remove"
                            />
                        </div>
                        <div onClick={() => this.copyCue(index)}>
                            <ReactSVG
                                src={icoCopy}
                                beforeInjection={svg => svg.classList.add("icon-copy-svg")}
                                className="icon copy"
                            />
                        </div>
                        {index !== 0 && (
                            <div onClick={() => this.toggleCueParallel(index, record)}>
                                {record.parallel === "yes" ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                            </div>)}
                    </div>);
            }
        }
    ];

    components = {
        body: {
            row: DragableBodyRow,
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            changes: {light_config: {playlist: {}}},
            new: isNullable(this.props.uuid),
            uuid: this.props.uuid || uuidv4(),
            closing: false
        };
        // Bind functions to context
        this.setTypeChanges = setTypeChanges.bind(this);
        this.getElements = getElements.bind(this);
    }

    componentDidMount() {
        document.body.classList.add("modal");
        document.addEventListener("keydown", this.handleKeyPress, false);
        window.addEventListener("copy", this.onCopy);
        window.addEventListener("paste", this.onPaste);

        if (this.state.new) {
            this.setTypeChanges("timeline", {
                "webapp-name": "Ny cuelist",
                priority: 0,
                transparency: 0,
                name: this.state.uuid,
                uuid: this.state.uuid
            });
        }

        this.updateCuelist();
        this.updateAvailableUniverses();
    }

    componentDidUpdate(prevProps) {
        if (!objectsAreEqual(prevProps, this.props)) {
            this.updateCuelist();
            this.updateAvailableUniverses();
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("modal");
        document.removeEventListener("keydown", this.handleKeyPress, false);
        window.removeEventListener("copy", this.onCopy);
        window.removeEventListener("paste", this.onPaste);
    }

    updateAvailableUniverses = () => {
        let universe_options = this.getElements("driver")
            .map(universe => ({
                key: universe.universe,
                value: universe["webapp-name"] || universe.universe
            }));
        if (this.props.data.system_config) {
            if (this.props.data.system_config.universe instanceof Array) {
                for (const universe of this.props.data.system_config.universe) {
                    if (!universe_options.find(e => e.key === universe)) {
                        universe_options.push({
                            key: universe,
                            value: universe
                        });
                    }
                }
            }
        }

        if (this.props.data.system_config_slaves) {
            for (const [, config] of Object.entries(this.props.data.system_config_slaves)) {
                if (config.universe instanceof Array) {
                    for (const universe of config.universe) {
                        if (!universe_options.find(e => e.key === universe)) {
                            universe_options.push({
                                key: universe,
                                value: universe
                            });
                        }
                    }
                }
            }
        }
        universe_options = sortObjects(universe_options, ["key"]);
        this.setState({universe_options});
    };

    renderVideoOptions = (text, record, index) => (
        <div className="video-cell">
            <div className="video-options">
                <div>
                    <p>Univers</p>
                    <MMSelect
                        value={record.universe}
                        options={this.state.universe_options}
                        onChange={value => this.updateCue(index, {universe: value})}
                    />
                </div>
                <div>
                    <p>Input adresse</p>
                    <MMTextInput
                        type="number"
                        min={1}
                        step={512}
                        max={512 * 128}
                        value={record["start-address-input"]}
                        onChange={(event, value) => this.updateCue(index, {"start-address-input": value})}
                    />
                </div>
                <div>
                    <p>Output adresse</p>
                    <MMTextInput
                        type="number"
                        min={1}
                        max={512}
                        value={record["start-address-output"]}
                        onChange={(event, value) => this.updateCue(index, {"start-address-output": value})}
                    />
                </div>
                <div>
                    <p>Output længde</p>
                    <MMTextInput
                        type="number"
                        min={1}
                        max={512}
                        value={record.length}
                        onChange={(event, value) => this.updateCue(index, {length: value})}
                    />
                </div>
            </div>
        </div>
    );

    renderShiftingOptions = (text, record, index) => (
        <div className="shift-cell">
            <MMInlineSelect
                value={text}
                options={[
                    {
                        key: "left",
                        value: "Left"
                    },
                    {
                        key: "random",
                        value: "Random"
                    },
                    {
                        key: "right",
                        value: "Right"
                    }
                ]}
                onChange={shift_direction => this.updateCue(index, {"shift-direction": shift_direction})}
            />
            {text && (
                <div className="shift-options">
                    <div>
                        <p>Antal</p>
                        <MMTextInput
                            type="number"
                            min={0}
                            value={record["shift-amount"]}
                            onChange={(event, value) => this.updateCue(index, {"shift-amount": value})}
                        />
                    </div>
                    <div>
                        <p>Hop</p>
                        <MMTextInput
                            type="number"
                            min={1}
                            value={record["shift-jump"]}
                            onChange={(event, value) => this.updateCue(index, {"shift-jump": value})}
                        />
                    </div>
                </div>
            )}
        </div>);

    getCueDuration = cue => {
        const is_shifting = !isNullable(cue["shift-direction"]);
        let multiplier = 1;
        if (is_shifting) {
            multiplier = parseInt(cue["shift-amount"]);
        }
        return (parseInt(cue.duration) + parseInt(cue["fade-time"])) * multiplier;
    };

    getCuelist = () => this.getElements("timeline")
        .find(e => e.uuid === this.state.uuid);

    toggleCueParallel = (index, record) => {
        this.updateCue(index, {parallel: record.parallel === "yes" ? "no" : "yes"});
    };

    updateCuelist = () => {
        const cuelist = this.getCuelist();
        const changes = {};
        if (cuelist) {
            // Fix name and remove outdated options
            if (cuelist["webapp-name"] === undefined) {
                changes["webapp-name"] = cuelist.name || "";
            }
            if (cuelist.loop) {
                if (cuelist.loop === "yes" && cuelist.cue instanceof Array) {
                    for (let index = 0; index < cuelist.cue.length; index++) {
                        if (index === 0 || cuelist.cue[index].parallel === "yes") {
                            this.updateCue(index, {loop: "yes"});
                        }
                    }
                }
                changes.loop = null;
            }
            if (cuelist.transparency) {
                if (cuelist.cue instanceof Array) {
                    for (let index = 0; index < cuelist.cue.length; index++) {
                        this.updateCue(index, {transparency: cuelist.transparency});
                    }
                }
                changes.transparency = null;
            }
            if (Object.keys(changes).length > 0) {
                changes.uuid = this.state.uuid;
                this.setTypeChanges("timeline", changes);
            }
        }
    };

    onCopy = event => {
        if (event.path[0].tagName === "INPUT") {
            return;
        }
        const cuelist = this.getCuelist();
        if (!cuelist) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_COPY});
            return;
        }

        try {
            const data = JSON.stringify(cuelist);
            event.clipboardData.setData("text/plain", data);
            event.preventDefault();
        } catch (err) {
            console.error("Failed to copy data:", err);
            this.props.dispatch({type: notificationsActions.COPY_FAILED});
        }
    };

    onPaste = event => {
        if (event.path[0].tagName === "INPUT") {
            return;
        }
        const text = (event.clipboardData || window.clipboardData).getData('text');

        if (text.length === 0) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_PASTE});
            return;
        }
        let new_cuelist_data = null;
        try {
            new_cuelist_data = JSON.parse(text);
            if (!(new_cuelist_data instanceof Object)) {
                throw new Error("Not an object");
            }
        } catch (e) {
            this.props.dispatch({type: notificationsActions.PASTE_INVALID_DATA});
            console.error("Failed to parse paste:", e);
            return;
        }

        const cuelist = this.getCuelist();
        if (cuelist) {
            new_cuelist_data["webapp-name"] = cuelist["webapp-name"];
            new_cuelist_data.name = cuelist.name;
            new_cuelist_data.uuid = this.state.uuid;
        } else {
            new_cuelist_data.name = this.state.uuid;
            new_cuelist_data.uuid = this.state.uuid;
            if (new_cuelist_data["webapp-name"]) {
                new_cuelist_data["webapp-name"] += " (Kopi)";
            } else {
                new_cuelist_data["webapp-name"] = "Kopieret cuelist";
            }
        }

        this.setTypeChanges("timeline", new_cuelist_data);
        event.preventDefault();
    };

    handleKeyPress = (event) => {
        if (event.keyCode === 27) { // Exit
            this.onClose();
        }
    };

    onClose = () => {
        this.setState({closing: true});

        if (this.props.onClose) {
            setTimeout(this.props.onClose, parseFloat(constants["modal-transition-duration"]));
        }
    };

    submit = () => {
        this.setState({loading: true});
        const cuelist = this.getCuelist();
        if (!cuelist["webapp-name"] || cuelist["webapp-name"] === "") {
            this.props.dispatch({type: notificationsActions.MISSING_SCENE_NAME});
            this.setState({loading: false});
            return;
        }
        // Check for duplicate name
        if (this.props.data.light_config.playlist.timeline instanceof Array
            && this.props.data.light_config.playlist.timeline
                .findIndex(timeline => timeline["webapp-name"] === cuelist["webapp-name"]
                    && timeline.uuid !== this.state.uuid && timeline.location === cuelist.location) >= 0) {
            this.props.dispatch({type: notificationsActions.DUPLICATE_SCENE_NAME});
            this.setState({loading: false});
            return;
        }

        // Save changes
        this.props.dispatch({
            type: dataActions.SET_LIGHT_CONFIGURATION,
            changes: this.state.changes.light_config,
            callback: this.onClose,
            errorCallback: () => {
                this.setState({loading: false});
            }
        });
    };

    deleteCuelist = () => {
        this.setState({loading: true});

        this.setTypeChanges("timeline", {
            uuid: this.state.uuid,
            delete: true
        })
            .then(() => this.props.dispatch({
                type: dataActions.SET_LIGHT_CONFIGURATION,
                changes: this.state.changes.light_config,
                callback: this.onClose,
                errorCallback: () => this.setState({loading: false})
            }));
    };

    copyCue = index => {
        const cuelist = this.getCuelist();
        const cues = _.cloneDeep(cuelist.cue);
        const cloned_cue = {
            ...cues[index],
            uuid: uuidv4()
        };
        delete cloned_cue.parallel;

        const insert_at = cues.findIndex((c, i) => i >= index && c.parallel === "yes");
        if (insert_at >= 0) {
            cues.splice(insert_at, 0, cloned_cue);
        } else {
            cues.push(cloned_cue);
        }

        this.setTypeChanges("timeline", {
            cue: cues,
            uuid: this.state.uuid
        });
    };

    updateCue = (index, changes) => {
        const cuelist = this.getCuelist();
        const cues = _.cloneDeep(cuelist.cue);
        cues[index] = {...cues[index], ...changes};
        this.setTypeChanges("timeline", {
            cue: cues,
            uuid: this.state.uuid
        });
    };

    getCues = () => {
        const cuelist = this.getCuelist();
        if (!cuelist) {
            return [];
        }
        return cuelist.cue ? cuelist.cue : [];
    };

    getRowClassName = (record, index) => (record.parallel === "yes" || index === 0 ? "parallel" : null);

    getCueTable = () => this.getCues()
        .map(cue => ({
            ...cue,
            key: cue.uuid,
        }));

    addCue = () => {
        const cuelist = this.getCuelist();
        const cues = cuelist && cuelist.cue ? _.cloneDeep(cuelist.cue) : [];

        cues.push({
            uuid: uuidv4(),
            "fade-time": 0,
            duration: 0,
            priority: 0,
            transparency: 0,
            "loop-timeout": 0
        });
        this.setTypeChanges("timeline", {
            cue: cues,
            uuid: this.state.uuid
        });
    };

    moveRow = (dragIndex, hoverIndex) => {
        const cues = _.cloneDeep(this.getCues());
        const dragCue = _.cloneDeep(cues[dragIndex]);
        cues[dragIndex].delete = true;
        dragCue.uuid = uuidv4();
        dragCue.parallel = "no";
        if (dragIndex < hoverIndex) {
            hoverIndex++;
        }
        cues.splice(hoverIndex, 0, dragCue);
        this.setTypeChanges("timeline", {
            cue: cues,
            uuid: this.state.uuid
        });
    };

    render() {
        let cuelist = this.getCuelist();
        if (!cuelist) {
            cuelist = {uuid: this.state.uuid};
        }

        const trigger_options = this.getElements("trigger")
            .map(trigger => ({
                key: trigger.id,
                value: textEllipsis(trigger["webapp-name"] || trigger.id, 15)
            }));

        return (
            <MMModal
                width="90vw"
                height="90vh"
                close={this.state.closing}
                closable={!this.state.loading}
                onClose={() => {
                    this.setState({closing: true});
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                }}
                className={`CuelistEditor large ${this.props.className ? this.props.className : ""} ${this.state.loading ? "loading" : ""}`}
                header={
                    [
                        <div className="align-left" key="align-left">
                            <p className="action-title">{`${this.state.new ? "Tilføj" : "Redigér"} cuelist`}</p>
                            <LoadingOutlined className="loading-icon"/>
                        </div>,
                        <div className="align-right" key="align-right">
                            <div className="header-option">
                                <p>Skjul på klient</p>
                                <MMToggle
                                    checked={cuelist["webapp-hidden"] === "yes"}
                                    onChange={(event, checked) => this.setTypeChanges("timeline", {
                                        "webapp-hidden": checked ? "yes" : "no",
                                        uuid: this.state.uuid
                                    })}
                                />
                            </div>
                            <div className="header-option">
                                <p>Rediger på klient</p>
                                <MMToggle
                                    checked={cuelist["webapp-locked"] !== "yes"}
                                    onChange={(event, checked) => this.setTypeChanges("timeline", {
                                        "webapp-locked": checked ? "no" : "yes",
                                        uuid: this.state.uuid
                                    })}
                                />
                            </div>
                            <div className="header-option">
                                <p>Triggers</p>
                                <MMSelect
                                    options={trigger_options}
                                    mode="multiple"
                                    value={cuelist.trigger.filter(t => trigger_options.some(el => el.key === t)) || []}
                                    onChange={value => this.setTypeChanges("timeline", {
                                        trigger: value,
                                        uuid: this.state.uuid
                                    })}
                                />
                            </div>
                            <div className="header-option">
                                <p>Invers triggers</p>
                                <MMSelect
                                    options={trigger_options}
                                    mode="multiple"
                                    value={cuelist.inversetrigger.filter(t => trigger_options.some(el => el.key === t)) || []}
                                    onChange={value => this.setTypeChanges("timeline", {
                                        inversetrigger: value,
                                        uuid: this.state.uuid
                                    })}
                                />
                            </div>
                            <div className="header-option">
                                <p>Prioritet</p>
                                <MMTextInput
                                    type="number"
                                    min={0}
                                    max={255}
                                    value={cuelist.priority}
                                    onChange={(event, value) => this.setTypeChanges("timeline", {
                                        priority: value,
                                        uuid: this.state.uuid
                                    })}
                                />
                            </div>
                            <MMTextInput
                                value={cuelist["webapp-name"]}
                                width={200}
                                className={this.state.new ? "active" : ""}
                                placeholder="Navngiv scene"
                                onChange={(event, value) => this.setTypeChanges("timeline", {
                                    "webapp-name": value,
                                    uuid: this.state.uuid
                                })}
                            />
                        </div>
                    ]
                }
                footer={
                    <div>
                        {!this.state.new
                        && (
                            <MMButton
                                className="delete red"
                                style={{width: 150}}
                                onClick={this.deleteCuelist}
                                disabled={this.state.loading}
                            >
                                Slet cuelist
                            </MMButton>)}
                        <div className="align-right">
                            <MMButton
                                className="cancel grey"
                                style={{width: 200}}
                                onClick={this.onClose}
                                disabled={this.state.loading}
                            >
                                Annullér
                            </MMButton>
                            <MMButton
                                className="submit"
                                style={{width: 200}}
                                disabled={Object.entries(this.state.changes.light_config.playlist).length <= 0 || this.state.loading}
                                onClick={this.submit}
                            >
                                Gem ændringer
                            </MMButton>
                        </div>
                    </div>
                }
            >
                <MMCard width="100%">
                    <div className="table-container">
                        <Table
                            rowClassName={this.getRowClassName}
                            dataSource={this.getCueTable()}
                            columns={this.cueColumns}
                            components={this.components}
                            pagination={false}
                            onRow={(record, index) => ({
                                index,
                                moveRow: this.moveRow,
                            })}
                        />
                    </div>
                    <MMButton className="block add-cue" onClick={this.addCue}>Tilføj cue</MMButton>
                </MMCard>
            </MMModal>
        );
    }
}

const mapStateToProps = state => ({
    data: state.data,
    websocket: state.websocket
});
export default connect(mapStateToProps)(CuelistEditor);
