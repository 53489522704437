import _ from "lodash";
import {MMComponent} from "./MMComponent";
import {dataActions} from "../../providers/data/dataActions";
import {notificationsActions} from "../../providers/notifications/notificationsActions";
import TimelineController from "../../controllers/TimelineController";

export default class MMTriggersComponent extends MMComponent {
    timeline_controller = null;

    constructor(props) {
        super(props, "trigger");
        this.timeline_controller = new TimelineController();
    }

    componentDidMount() {
        super.componentDidMount();
        this.timeline_controller.setElements(this.getElements("timeline"));
    }

    onDataUpdated() {
        this.timeline_controller.setElements(this.getElements("timeline"));
    }

    fireTrigger = trigger_id => this.props.dispatch({
        type: dataActions.FIRE_TRIGGER,
        trigger: trigger_id
    });

    deleteTrigger = (uuid, force = false, message = true) => {
        if (!force) {
            const trigger = this.getElement(uuid);
            if (!trigger) {
                return Promise.resolve();
            }
            const cuelists = this.getTimelinesUsingTrigger(trigger.id, ["trigger", "inversetrigger"]);
            if (cuelists.length > 0) {
                if (message) {
                    this.props.dispatch({
                        type: notificationsActions.TRIGGER_IN_USE,
                        cuelists
                    });
                }
                return Promise.resolve();
            }
        }
        return this.setTypeChanges({
            uuid,
            delete: true
        });
    };

    removeTriggerOnTimeline = (trigger_id, timeline_uuid, needles = ["trigger"]) => {
        if (!timeline_uuid) {
            return Promise.resolve();
        }
        const timeline = _.cloneDeep(this.getElement(timeline_uuid, "timeline"));
        if (!timeline) {
            console.error("Failed to find timeline", timeline_uuid);
            return;
        }

        for (const needle of needles) {
            timeline[needle] = timeline[needle].filter(el => el !== trigger_id);
        }

        return this.setTypeChanges({
            uuid: timeline.uuid,
            trigger: timeline.trigger,
            inversetrigger: timeline.inversetrigger
        }, "timeline")
            .then(() => {
                const trigger = this.getElement(trigger_id, "trigger", "id");
                if (trigger) {
                    return this.deleteTrigger(trigger.uuid, false, false);
                }
                return Promise.resolve();
            });
    };

    addTriggerOnTimeline = (trigger_id, timeline_uuid, type = "trigger") => {
        if (!timeline_uuid) {
            return Promise.resolve();
        }
        const timeline = _.cloneDeep(this.getElement(timeline_uuid, "timeline"));
        if (!timeline) {
            console.error("Failed to find timeline", timeline_uuid);
            return;
        }

        timeline[type].push(trigger_id);

        return this.setTypeChanges({
            uuid: timeline.uuid,
            [type]: timeline[type]
        }, "timeline");
    };

    getTimelinesUsingTrigger = (trigger_id, needles = ["trigger"]) => this.timeline_controller.getTimelinesUsingTrigger(trigger_id, needles);
}
