import React from "react";
import _ from "lodash";
import {DeleteOutlined, LeftCircleOutlined, RightCircleOutlined} from "@ant-design/icons";
import {uuidv4} from "../../../providers/data/dataMiddleware";
import MMTextInput from "../../../elements/inputs/MMTextInput";
import MMInlineSelect from "../../../elements/inputs/MMInlineSelect";
import MMButton from "../../../elements/buttons/MMButton";
import MMCard from "../../../elements/layout/MMCard";
import {dataActions} from "../../../providers/data/dataActions";
import colorProvider from "../../../providers/colors/color.provider";
import "./PaletteEditor.less";
import MMSelect from "../../../elements/inputs/MMSelect";
import {APP_CONFIG} from "../../../config";
import MMPalettesComponent from "../../../elements/components/MMPalettesComponent";

class PaletteEditor extends MMPalettesComponent {
    constructor(props) {
        super(props);
        this.state = {
            palette_uuid: this.props.palette_uuid,
            edit_color: null
        };

        if (this.props.new) {
            this.state.palette_uuid = this.props.palette_uuid || uuidv4();
        }

        const palette = this.getPalette();
        if (palette && palette.color) {
            this.state.edit_color = Object.keys(palette.color)[0];
        }
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.props.new) {
            const default_props = {
                uuid: this.state.palette_uuid,
                id: this.props.palette_id || this.state.palette_uuid,
                "webapp-name": ""
            };

            this.setTypeChanges(default_props);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.palette_uuid !== this.props.palette_uuid) {
            this.setState({palette_uuid: this.props.palette_uuid || uuidv4});
            const palette = this.getPalette();
            if (palette && palette.color) {
                this.setState({edit_color: Object.keys(palette.color)[0]});
            }
        }
    }

    getPalette = (uuid = this.state.palette_uuid) => {
        const palette = this.getElements()
            .filter(pal => pal.uuid === uuid && uuid);
        return palette.length > 0 ? palette[0] : null;
    };

    delete = () => {
        this.deletePalette(this.state.palette_uuid)
            .then(this.submit);
    };

    submit = () => {
        this.setState({loading: true});
        const palette = this.getPalette();

        // Save changes
        this.props.dispatch({
            type: dataActions.SET_LIGHT_CONFIGURATION,
            changes: this.state.changes && this.state.changes.light_config,
            callback: () => {
                if (this.props.submit) {
                    this.props.submit(palette.uuid);
                }
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            errorCallback: () => {
                this.setState({loading: false});
            }
        });
    };

    deleteColor = () => {
        const palette = this.getPalette();
        const color = _.cloneDeep(palette.color);
        const edit_color_type = APP_CONFIG.COLOR_TYPES.find(type => type.key === this.state.edit_color);
        delete color[edit_color_type.key];
        this.setState({edit_color: null}, () => this.setTypeChanges({
            uuid: palette.uuid,
            color
        }));
    };

    renderPaletteColorInputWithDefinition = (palette, edit_color_type) => edit_color_type.definition.map(channel => (
        <div key={channel} className="channel">
            <MMTextInput
                className="value"
                type="number"
                value={palette.color[edit_color_type.key][channel]}
                min={0}
                max={255}
                height={30}
                onChange={(event, value) => {
                    const color = _.cloneDeep(palette.color);
                    color[edit_color_type.key][channel] = value;
                    this.setTypeChanges({
                        uuid: palette.uuid,
                        color
                    });
                }}
            />
            <p className="key">{channel}</p>
        </div>));

    renderPaletteColorInputRaw = (palette, edit_color_type) => [
        <div className="channel" key="length">
            <MMTextInput
                className="value"
                type="number"
                value={palette.color[edit_color_type.key].value && palette.color[edit_color_type.key].value.length}
                min={0}
                max={100}
                height={30}
                onChange={(event, value) => {
                    const color = _.cloneDeep(palette.color);
                    if (!color[edit_color_type.key].value) {
                        color[edit_color_type.key].value = [];
                    }
                    color[edit_color_type.key].value.length = value;
                    this.setTypeChanges({
                        uuid: palette.uuid,
                        color
                    });
                }}
            />
            <p className="key">Længde</p>
        </div>,
        ...(palette.color[edit_color_type.key].value || []).map((val, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="channel">
                <MMTextInput
                    className="value"
                    type="number"
                    value={val}
                    min={0}
                    max={255}
                    height={30}
                    onChange={(event, value) => {
                        const color = _.cloneDeep(palette.color);
                        color[edit_color_type.key].value[i] = value;
                        this.setTypeChanges({
                            uuid: palette.uuid,
                            color
                        });
                    }}
                />
                <p className="key">{i}</p>
            </div>
        ))
    ];

    renderPaletteColorInput = (palette, edit_color_type) => {
        if (edit_color_type.block_size === 0) {
            return this.renderPaletteColorInputRaw(palette, edit_color_type);
        }
        if (edit_color_type.definition) {
            return this.renderPaletteColorInputWithDefinition(palette, edit_color_type);
        }
    };

    render() {
        const palette = this.getPalette();
        if (!palette) {
            return null;
        }
        const palette_type = colorProvider.getPaletteType(palette);

        if (palette["webapp-name"] === undefined) {
            palette["webapp-name"] = palette.id;
        }
        if (!palette.color) {
            palette.color = {};
        }

        const edit_color_type = APP_CONFIG.COLOR_TYPES.find(type => type.key === this.state.edit_color);
        return (
            <div className="PaletteEditor">
                <MMCard height="fit-content" width={200} padding={5}>
                    <div className="container">
                        <div className="header">
                            <p>{`${this.props.new ? "Tilføj" : "Redigér"} palette`}</p>
                            <div className="actions">
                                {!this.props.new && <LeftCircleOutlined onClick={this.props.previousPalette}/>}
                                {!this.props.new && <RightCircleOutlined onClick={this.props.nextPalette}/>}
                            </div>
                        </div>
                        <div className="body">
                            <p>Navn</p>
                            <MMTextInput
                                className="block"
                                value={palette["webapp-name"]}
                                height={30}
                                onChange={(event, value) => {
                                    this.setTypeChanges({
                                        uuid: palette.uuid,
                                        "webapp-name": value
                                    });
                                }}
                            />
                            <p>Type</p>
                            <MMInlineSelect
                                className="block"
                                value={palette_type}
                                options={colorProvider.PALETTE_TYPES}
                                onChange={value => {
                                    const type_values = {};
                                    for (const type of colorProvider.PALETTE_TYPES) {
                                        type_values[type.key] = value === type.key ? "yes" : "no";
                                    }
                                    delete type_values.standard;
                                    this.setTypeChanges({
                                        uuid: palette.uuid,
                                        ...type_values
                                    });
                                }}
                            />

                            {palette_type === "standard" && (
                                <div>
                                    <p>Farver</p>
                                    <MMSelect
                                        options={APP_CONFIG.COLOR_TYPES.filter(type => type.color_type !== false)
                                            .map(type => ({
                                                ...type,
                                                value: (palette.color[type.key] ? "Rediger " : "Tilføj ") + type.value
                                            }))}
                                        onChange={edit_color => {
                                            if (!palette.color[edit_color]) {
                                                this.setTypeChanges({
                                                    uuid: palette.uuid,
                                                    color: {
                                                        ...palette.color,
                                                        [edit_color]: (APP_CONFIG.COLOR_TYPES.find(type => type.key === edit_color)
                                                            .definition || [])
                                                            .reduce((object, val) => {
                                                                object[val] = 0;
                                                                return object;
                                                            }, {})
                                                    }
                                                });
                                            }
                                            this.setState({edit_color});
                                        }}
                                        value={this.state.edit_color}
                                    />

                                    {edit_color_type && (edit_color_type.definition || edit_color_type.block_size === 0) && (
                                        <div className="channels">
                                            {this.renderPaletteColorInput(palette, edit_color_type)}
                                            <DeleteOutlined onClick={this.deleteColor} className="delete-color"/>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="footer">
                            {!this.props.new && (
                                <MMButton
                                    className="link delete"
                                    disabled={this.state.loading}
                                    onClick={this.delete}
                                >
                                    Slet
                                </MMButton>
                            )}
                            <MMButton
                                className="link"
                                disabled={this.state.loading}
                                onClick={this.props.onClose}
                            >
                                Annullér
                            </MMButton>
                            <MMButton
                                className="link save"
                                disabled={this.state.loading || !this.state.changes || !this.state.changes.light_config}
                                onClick={this.submit}
                            >
                                Gem
                            </MMButton>
                        </div>
                    </div>
                </MMCard>
            </div>
        );
    }
}

PaletteEditor.defaultProps = {
    palette_uuid: null,
    palette_id: null
};

export default MMPalettesComponent.connect(PaletteEditor);
