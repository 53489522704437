import {triggerToCalendarEntries} from "../providers/triggers/triggerToCalendarEntries";
import {timelinesAreConflicting} from "../providers/playlistErrorChecker";
import {APP_CONFIG} from "../config";

export default class TriggerCalendarController {
    #triggers = [];

    #entries = [];

    #entries_start = null;

    #entries_end = null;

    #event_colors = null;

    #timeline_controller = null;

    #scene_controller = null;

    constructor(timeline_controller, scene_controller) {
        this.#timeline_controller = timeline_controller;
        this.#scene_controller = scene_controller;
    }

    setTriggers = triggers => {
        this.#triggers = triggers.filter(t => t.time && t.time.time);
    };

    setStart = start => {
        this.#entries_start = start;
    };

    getStart = () => this.#entries_start;

    setEnd = end => {
        this.#entries_end = end;
    };

    getEnd = () => this.#entries_end;

    setEventColors = event_colors => {
        this.#event_colors = event_colors;
    };

    getEntries = () => this.#entries;

    calculate = () => {
        const entries = [];

        for (const trigger of this.#triggers) {
            try {
                const trigger_entries = triggerToCalendarEntries(trigger, this.#entries_start, this.#entries_end);
                entries.push(...trigger_entries);
            } catch (e) {
                console.error(e);
            }
        }

        let timeline_entries = entries.map(a => this.#timeline_controller.getTimelinesUsingTrigger(a.id)
            .map(timeline => {
                const inversetrigger = this.#getTimelineInverseTrigger(a, timeline, this.#triggers);
                let end = new Date(a.start);
                end.setFullYear(end.getFullYear() + 1);
                if (inversetrigger && inversetrigger.start) {
                    end = inversetrigger && inversetrigger.start;
                }
                return ({
                    ...a,
                    timeline,
                    title: timeline["webapp-name"] || timeline.name,
                    priority: timeline.priority,
                    color: this.#event_colors && this.#event_colors[timeline.index % this.#event_colors.length],
                    inversetrigger,
                    end
                });
            }))
            .flat()
            .filter((el, i, arr) => arr.findIndex(el2 => el2.timeline.uuid === el.timeline.uuid && el.start - el2.start === 0) === i)
            .sort((a, b) => (a.start < b.start ? -1 : 1));

        const scenes = this.#scene_controller.getElements();
        for (const [index, entry] of timeline_entries.entries()) {
            for (let offset = 1; offset + index < timeline_entries.length; offset++) {
                const next_entry = timeline_entries[offset + index];
                if (next_entry
                    && entry.end > next_entry.start && next_entry.start > entry.start
                    && timelinesAreConflicting(entry.timeline, next_entry.timeline, scenes)) {
                    if (entry.timeline.uuid === next_entry.timeline.uuid) {
                        if (entry.end < next_entry.end) {
                            entry.end = next_entry.end;
                            entry.inversetrigger = next_entry.inversetrigger;
                        }
                        timeline_entries.splice(offset + index, 1);
                        offset--;
                    } else {
                        entry.end_timeline = next_entry.timeline;
                        entry.end = new Date(next_entry.start);
                        break;
                    }
                }
            }
        }

        if (APP_CONFIG && APP_CONFIG.SHOW_HIDDEN_CUELISTS === false) {
            timeline_entries = timeline_entries.filter(el => el.timeline["webapp-hidden"] !== "yes");
        }
        this.#entries = timeline_entries;
    };

    #getTimelineInverseTrigger = (entry, timeline, triggers) => {
        const search_end = new Date(entry.start);
        search_end.setFullYear(search_end.getFullYear() + 1); // Search 1 year in the future

        // Find inverse trigger time entry
        const inverse_triggers = triggers.filter(a => timeline.inversetrigger
            && timeline.inversetrigger.includes(a.id));
        const inverse_trigger_entries = inverse_triggers.map(trigger => triggerToCalendarEntries(trigger, entry.start, search_end, true))
            .flat()
            .sort((a, b) => (a.start < b.start ? -1 : 1));

        return inverse_trigger_entries[0];
    };
}
