import React from "react";
import "./Settings.less";
import {Row} from "antd";
import {connect} from "react-redux";
import {ReactSVG} from "react-svg";
import MMCard from "../../elements/layout/MMCard";
import {APP_CONFIG} from "../../config";
import MMButton from "../../elements/buttons/MMButton";
import NamingComponent from "./NamingComponent/NamingComponent";
import {isNullable} from "../../providers/objectsAreEqual";
import {dataActions} from "../../providers/data/dataActions";
import Watermark from "../../elements/layout/Watermark";
import {notificationsActions} from "../../providers/notifications/notificationsActions";
import Configuration from "./Configuration/Configuration";
import {setTypeChanges} from "../../providers/setTypeChanges";
import {getElements} from "../../providers/getElements";
import {sortObjects} from "../../providers/sortObjects";
import Universes from "./Universes/Universes";

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changes: {light_config: {playlist: {}}}
        };

        // Bind functions to context
        this.setTypeChanges = setTypeChanges.bind(this);
        this.getElements = getElements.bind(this);
    }

    submitNaming = () => {
        const fixtures = this.getElements("dmx-fixture");
        for (const fixture of fixtures) {
            if (fixtures.filter(fixture_entry => fixture_entry["webapp-name"] === fixture["webapp-name"]).length > 1) {
                this.props.dispatch({type: notificationsActions.DUPLICATE_FIXTURE_NAME});
                return;
            }
        }
        this.props.dispatch({
            type: dataActions.SET_LIGHT_CONFIGURATION,
            changes: this.state.changes.light_config,
            callback: () => {
                this.setState(state => {
                    state.changes.light_config = {playlist: {}};
                    return state;
                });
            }
        });
    };

    isNamingInvalid = () => {
        if (this.state.changes.light_config.playlist["dmx-fixture"] instanceof Array) {
            for (const fixture of this.state.changes.light_config.playlist["dmx-fixture"]) {
                if (isNullable(fixture["webapp-name"])) return true;
            }
        }
        return false;
    };

    renderFixturesAtLocation = (location) => {
        const sorted_fixtures = sortObjects(this.getElements("dmx-fixture"), ["universe", "offset"]);

        return (
            <Row key={(location && location.location) || "default"}>
                {location
                && (
                    <div className="location-header">
                        {location.iconTitle && <ReactSVG
                            src={location.iconTitle}
                            beforeInjection={svg => svg.classList.add(`icon-title-${location.location}`)}
                        />}
                        <p>{location.title}</p>
                    </div>)}
                <p className="dali-label">
                    DALI
                    <span>DALI</span>
                </p>
                {sorted_fixtures.filter(fixture => !location || fixture.location === location.location)
                    .map(fixture => (<NamingComponent
                        fixture={fixture}
                        key={fixture.uuid}
                        onChange={(event, fixtureWebappName) => this.setTypeChanges("dmx-fixture", [{
                            uuid: fixture.uuid,
                            "webapp-name": fixtureWebappName
                        }])}
                    />))}
            </Row>);
    };

    render() {
        if (!this.props.data.light_config || !(this.props.data.light_config.playlist instanceof Object)) {
            return (<Watermark/>); // Don't display page if unable to pull data
        }
        return (
            <div className="SettingsPage">
                {((!APP_CONFIG.SETTINGS || APP_CONFIG.SETTINGS.CONFIGURATION) || this.props.configuration)
                && <Configuration style={{marginRight: 36}}/>}
                {((!APP_CONFIG.SETTINGS || APP_CONFIG.SETTINGS.NAMING) || this.props.naming)
                && (
                    <MMCard width={600} height={640}>
                        <div className="container naming">
                            <p className="header">Navngivning</p>
                            <div className="body">
                                {APP_CONFIG.SCENARIOS && APP_CONFIG.SCENARIOS.length > 0
                                    ? APP_CONFIG.SCENARIOS.map(this.renderFixturesAtLocation)
                                    : this.renderFixturesAtLocation(null)}

                                <MMButton
                                    className="submit"
                                    disabled={Object.entries(this.state.changes.light_config.playlist).length <= 0 || this.isNamingInvalid()}
                                    onClick={this.submitNaming}
                                >
                                    Gem navngivning
                                </MMButton>
                            </div>
                        </div>
                    </MMCard>)}
                {((!APP_CONFIG.SETTINGS || APP_CONFIG.SETTINGS.UNIVERSES) || this.props.universes)
                && <Universes/>}
                <Watermark/>
                <p className="watermark-version">
                    Version:
                    {process.env.REACT_APP_VERSION}
                </p>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: state.data
});

export default connect(mapStateToProps)(Settings);
