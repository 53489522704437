import {isNullable} from "./objectsAreEqual";

export default function reducerUpdater(state, mutations, path = "") {
    if (isNullable(path)) {
        return {...state, ...mutations};
    }

    const new_state = {...state};
    let current_subdir = new_state;
    const directories = path.split("/");
    for (const directory_index in directories) {
        if (Object.prototype.hasOwnProperty.call(directories, directory_index)) {
            if (!current_subdir[directories[directory_index]]) {
                current_subdir[directories[directory_index]] = {};
            } else {
                current_subdir[directories[directory_index]] = {...current_subdir[directories[directory_index]]};
            }
            if (directory_index >= directories.length - 1) {
                if (typeof mutations === "object") {
                    current_subdir[directories[directory_index]] = {...current_subdir[directories[directory_index]], ...mutations};
                } else {
                    current_subdir[directories[directory_index]] = mutations;
                }
            } else {
                current_subdir = current_subdir[directories[directory_index]];
            }
        }
    }
    return new_state;
}
