import {LoadingOutlined} from "@ant-design/icons";
import React from "react";
import MMModal from "./MMModal";
import MMButton from "../buttons/MMButton";
import constants from "../../less/motomuto_ras/constants";
import "./MMDialogModal.less";

class MMDialogModal extends React.Component {
    onCloseTimer;

    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentWillUnmount() {
        clearTimeout(this.onCloseTimer);
    }

    closeModal = () => {
        this.setState({modal_closing: true});
        this.onCloseTimer = setTimeout(this.props.onClose, parseFloat(constants["modal-transition-duration"]));
    };

    onSubmit = (event) => {
        this.props.onSubmit(event);
        this.closeModal();
    }

    render() {
        return (
            <MMModal
                header={
                    <div className="align-left">
                        <p className="action-title">{this.props.title}</p>
                        <LoadingOutlined className="loading-icon"/>
                    </div>
                }
                footer={
                    <div className="align-right">
                        <MMButton
                            className="cancel submit"
                            key="cancel"
                            onClick={this.closeModal}
                            disabled={this.props.loading || !this.props.closable}
                        >
                            {this.props.cancel_title || "Annullér"}
                        </MMButton>
                        <MMButton
                            className="submit orange"
                            key="submit"
                            disabled={this.props.loading || this.props.submit_disabled}
                            onClick={this.onSubmit}
                        >
                            {this.props.submit_title || "Fortsæt"}
                        </MMButton>
                    </div>
                }
                closable={this.props.closable}
                loading={this.props.loading}
                submit_disabled={this.props.submit_disabled}
                width={this.props.width}
                height={this.props.height}
                onClose={this.props.onClose}
                onSubmit={this.props.onSubmit}
                title={this.props.title}
                content={this.props.content}
                className={"MMDialogModal " + this.props.className}
                close={this.state.modal_closing || this.props.dialog_closing}
            >
                {this.props.children || <p>{this.props.content}</p>}
            </MMModal>
        );
    }
}

export default MMDialogModal;
