import {websocketActions} from "./websocketActions";
import WebSocketInstance from "./webSocketInstance";
import {getServerBaseURL, getSlaves} from "../data/dataMiddleware";

export default class WebsocketMiddlewareInstance {
    pingInterval = null;

    master = new WebSocketInstance(getServerBaseURL());

    slaves = {};

    lastResponseTime = null;

    registeredVariables = [];

    connect = (store, action) => {
        for (const slave of getSlaves(store.getState())) {
            if (!this.slaves[slave]) {
                this.slaves[slave] = new WebSocketInstance(slave, this.master.registeredVariables);
            }
        }

        this.master.connect(store, action);
        for (const slave of Object.keys(this.slaves)) {
            this.slaves[slave].connect(store, action);
        }
    };

    disconnect = () => {
        this.master.disconnect();
        for (const slave of Object.keys(this.slaves)) {
            this.slaves[slave].disconnect();
        }
    };

    doRegister = variable => {
        this.master.doRegister(variable);
        for (const slave of Object.keys(this.slaves)) {
            this.slaves[slave].doRegister(variable);
        }
    };

    doUnRegister = variable => {
        this.master.doUnRegister(variable);
        for (const slave of Object.keys(this.slaves)) {
            this.slaves[slave].doUnRegister(variable);
        }
    };

    doSet = (variable, value) => {
        this.master.doSet(variable, value);
        for (const slave of Object.keys(this.slaves)) {
            this.slaves[slave].doSet(variable, value);
        }
    };

    middleware = (store) => (next) => (action) => {
        switch (action.type) {
            case websocketActions.WEBSOCKET_RECONNECT:
                this.disconnect();
                this.connect(store, action);
                return next(action);
            case websocketActions.WEBSOCKET_CONNECT:
                this.connect(store, action);
                return next(action);
            case websocketActions.WEBSOCKET_DISCONNECT:
                this.disconnect();
                return next(action);
            case websocketActions.WEBSOCKET_REGISTER_VARIABLE:
                this.doRegister(action.variable);
                return next(action);
            case websocketActions.WEBSOCKET_UNREGISTER_VARIABLE:
                this.doUnRegister(action.variable);
                return next(action);
            case websocketActions.WEBSOCKET_SET_VARIABLE:
                this.doSet(action.variable, action.value);
                return next(action);
            default:
                return next(action);
        }
    };
}
