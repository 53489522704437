import X2JS from "x2js";
import axiosImport from "axios";
import FileSaver from 'file-saver';
import moment from "moment";
import SunCalc from "suncalc";
import Blob from "blob";
import _ from "lodash";
import {dataActions} from "./dataActions";
import {APP_CONFIG} from "../../config";
import {notificationsActions} from "../notifications/notificationsActions";
import reducerUpdater from "../reducerUpdater";
import {websocketActions} from "../websocket/websocketActions";
import {isNullable} from "../objectsAreEqual";
import {checkPlaylistForErrors} from "../playlistErrorChecker";
import {toArray} from "../toArray";

const DATA_TYPES = ["dmx-fixture", "scene", "trigger", "timeline", "palette", "driver-middleware", "driver", "timer"];

export const getServerBaseURL = () => {
    if (process.env.REACT_APP_SERVER_BASE_URL) {
        return process.env.REACT_APP_SERVER_BASE_URL;
    }
    return window.location.origin;
};

const getAxios = baseURL => axiosImport.create({
    baseURL,
    timeout: 20000
});

const axios = getAxios(getServerBaseURL());

export function getSlaves(state) {
    if (state.data.system_config && state.data.system_config.slaves instanceof Array) {
        return state.data.system_config.slaves;
    }
    return [];
}

function getActiveProject(store) {
    return store.getState().data.active_project || 0;
}

function getAuthParams(store) {
    const auth_type = store.getState().login ? store.getState().login.auth_type : null;
    let auth_params = null;
    if (auth_type === "login") {
        auth_params = {token: store.getState().login.token};
    }
    return auth_params;
}

function multiAxiosPost(store, endpoint, body) {
    const axios_config = {
        params: {
            ...getAuthParams(store),
            project: getActiveProject(store)
        }
    };

    const axiosPromises = [];
    axiosPromises.push(axios.post(endpoint, body, axios_config));
    for (const slave of getSlaves(store.getState())) {
        axiosPromises.push(getAxios("http://" + slave)
            .post(endpoint, body, axios_config));
    }

    return Promise.all(axiosPromises);
}

function multiAxiosGetCustom(store, controllers, endpoint) {
    const axios_config = {
        params: {
            ...getAuthParams(store),
            project: getActiveProject(store)
        }
    };

    const axiosPromises = [];
    for (let controller of controllers) {
        if (!controller.startsWith("http://") && !controller.startsWith("https://")) {
            controller = "http://" + controller;
        }
        axiosPromises.push(getAxios(controller)
            .get(endpoint, axios_config)
            .then(r => ({
                ...r,
                controller
            })));
    }

    return Promise.all(axiosPromises);
}

function multiAxiosGet(store, endpoint) {
    return multiAxiosGetCustom(store,
        [getServerBaseURL(), ...getSlaves(store.getState())], endpoint);
}

function removeEmpty(arr) {
    return arr.map(o => _.pickBy(o, e => e !== null && e !== undefined));
}

function convertJSONToXMLString(jsonObj) {
    return new Promise(
        (resolve, reject) => {
            const xmlAsStr = (new X2JS()).js2xml(jsonObj);
            if (xmlAsStr) {
                resolve(xmlAsStr);
            } else {
                reject(new Error("Couldn't parse JSON to XML string"));
            }
        }
    );
}

function convertXMLStringToJSON(xmlString) {
    return new Promise((resolve, reject) => {
        const jsonObj = (new X2JS()).xml2js(xmlString);
        if (jsonObj) {
            resolve(jsonObj);
        } else {
            reject(new Error("Couldn't parse XML string to JSON"));
        }
    });
}

function convertOptionsStringToJSON(options_string) {
    const options = {};
    const lines = options_string.split("\n");
    for (let i = 0; i < lines.length; i++) {
        // Ignore comments
        if (lines[i].charAt(0) !== "#") {
            let a = 0;
            let temp_val = "";
            const option = {
                key: "",
                value: []
            };
            // Read key
            while (lines[i].charAt(a) !== " " && lines[i].charAt(a) !== "=" && lines[i].length > a) {
                option.key += lines[i].charAt(a);
                a++;
            }
            // Read until value
            while (lines[i].charAt(a) !== "\"" && lines[i].charAt(a) !== "'" && lines[i].length > a) {
                a++;
            }
            // Jump to start char of value
            a++;
            // Read value
            while (lines[i].charAt(a) !== "\"" && lines[i].charAt(a) !== "'" && lines[i].length > a) {
                temp_val += lines[i].charAt(a);
                a++;
            }
            option.value = temp_val.split(",");
            if (option.key !== "") {
                options[option.key] = option.value;
            }
        }
    }
    return options;
}

function convertJSONToOptionsString(options) {
    let options_string = "";
    for (const option_key in options) {
        if (option_key && Object.prototype.hasOwnProperty.call(options, option_key) && options[option_key]) {
            options_string += `${option_key} = "${options[option_key].filter(e => !isNullable(e))
                .join(",")}";\n`;
        }
    }
    return options_string;
}

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function recursive_delete(data) {
    data = toArray(data);
    for (const element of data) {
        for (const key in element) {
            if (Object.prototype.hasOwnProperty.call(element, key) && element[key] instanceof Array) {
                element[key] = recursive_delete(element[key]);
            }
        }
    }
    return data.filter(el => !el.delete);
}

export function merge_by_uuid(data, changes, doDelete = false, uuid_property = "uuid") {
    // Return if undefined/null
    if (!data && !changes) return [];

    if (!((data instanceof Object || !data) && (changes instanceof Object || !changes))) {
        throw new Error("Parameters have to be an instance of Object");
    }
    data = _.cloneDeep(toArray(data));
    changes = _.cloneDeep(toArray(changes));

    let updated_data;
    if (data.length > 0 && data.findIndex(data_element => data_element[uuid_property]) < 0) {
        // Update existing entries without property uuid
        updated_data = changes;
    } else {
        // Update existing entries that has property uuid
        updated_data = data.map(data_element => {
            const change_elements = changes.filter(change => change[uuid_property] === data_element[uuid_property]);
            for (const change_element of change_elements) {
                // Merge subcomponents
                const subcomponents = {};
                for (const subcomponent_key in change_element) {
                    if (Object.prototype.hasOwnProperty.call(change_element, subcomponent_key)
                        && (data_element[subcomponent_key] instanceof Array
                            || change_element[subcomponent_key] instanceof Array)) {
                        subcomponents[subcomponent_key] = merge_by_uuid(
                            data_element[subcomponent_key], change_element[subcomponent_key], doDelete, uuid_property
                        );
                    }
                }

                // Merge components
                data_element = {...data_element, ...change_element};
                for (const subcomponent_key in subcomponents) {
                    if (Object.prototype.hasOwnProperty.call(subcomponents, subcomponent_key)) {
                        data_element[subcomponent_key] = subcomponents[subcomponent_key];
                    }
                }
            }
            return data_element;
        });
    }

    if (doDelete) {
        updated_data = recursive_delete(updated_data);
        changes = recursive_delete(changes);
    }

    // add new entries
    let offset = 0;
    const add_to_end = !changes.some(change => updated_data.some(e => e[uuid_property] === change[uuid_property]));
    /* if (!add_to_end) {
        console.log("Splicing", updated_data, changes);
    } */
    for (const [index, change] of changes.entries()) {
        while (updated_data[index + offset] && updated_data[index + offset].delete) {
            offset++;
        }
        if (!updated_data.some(data_element => data_element[uuid_property] === change[uuid_property])
            && !(doDelete && change.delete)) {
            if (change instanceof Object && !(change instanceof Array) && !change[uuid_property]) {
                change[uuid_property] = uuidv4();
            }

            if (add_to_end) {
                updated_data.push(change);
            } else {
                updated_data.splice(index + offset, 0, change);
            }
        }
    }
    return updated_data;
}

function migratePlaylist(playlist) {
    if (playlist && playlist.playlist) {
        // Migrate old palette syntax
        const migrated_palettes = [];
        let has_old_syntax = false;
        for (const palette of toArray(playlist.playlist.palette)) {
            if (!palette.id && palette.color) {
                // eslint-disable-next-line no-console
                console.log("migratePlaylist: Found palettes with old syntax, migrating them");
                has_old_syntax = true;
                for (const color of toArray(palette.color)) {
                    const migrated_palette = {
                        id: color.id,
                        uuid: color.uuid || uuidv4(),
                        color
                    };
                    delete migrated_palette.color.id;
                    delete migrated_palette.color.uuid;
                    migrated_palettes.push(migrated_palette);
                }
            } else {
                // Palette is already using newest syntax
                migrated_palettes.push(palette);
            }
        }

        if (has_old_syntax) {
            playlist.playlist.palette = migrated_palettes;
            // eslint-disable-next-line no-console
            console.log(playlist);
        }
    }
    return playlist;
}

export function parseJSONPlaylist(config) {
    if (!config.playlist || config.playlist === "") {
        config.playlist = {};
    }
    config = migratePlaylist(config);
    for (const element_type of DATA_TYPES) {
        if (config.playlist[element_type]) {
            config.playlist[element_type] = toArray(config.playlist[element_type]);
            // Inject uuid's
            for (let i = 0; i < config.playlist[element_type].length; i++) {
                if (!config.playlist[element_type][i].uuid) {
                    config.playlist[element_type][i].uuid = uuidv4();
                }
                if (element_type === "timeline") {
                    // Convert trigger, inversetrigger, cue property to array
                    config.playlist[element_type][i].trigger = toArray(config.playlist[element_type][i].trigger);
                    config.playlist[element_type][i].inversetrigger = toArray(config.playlist[element_type][i].inversetrigger);
                    config.playlist[element_type][i].cue = toArray(config.playlist[element_type][i].cue);

                    // Inject uuid's into cues
                    for (const cue of config.playlist[element_type][i].cue) {
                        cue.uuid = uuidv4();
                    }
                } else if (element_type === "scene") {
                    config.playlist[element_type][i]["scene-component"] = toArray(config.playlist[element_type][i]["scene-component"]);
                } else if (element_type === "palette") {
                    // Following is only used for the deprecated syntax
                    /* config.playlist[element_type][i].color = toArray(config.playlist[element_type][i].color);
                    for (let j = 0; j < config.playlist[element_type][i].color.length; j++) {
                        if (config.playlist[element_type][i].color[j].raw && config.playlist[element_type][i].color[j].raw.value) {
                            config.playlist[element_type][i].color[j].raw.value = toArray(config.playlist[element_type][i].color[j].raw.value);
                        }
                    } */
                }
            }
        }
    }

    return config;
}

export function getDefaultFixtures(config, system_config) {
    const fixtures = [];
    let default_offset = 1;
    let default_universe = 1;
    let default_length = 1;
    let fixtures_config = config.PLAYLIST && config.PLAYLIST.FIXTURES;
    if (fixtures_config instanceof Function) {
        fixtures_config = fixtures_config(system_config);
    }
    for (const fixture of toArray(fixtures_config)) {
        const offset = parseInt(fixture.offset || default_offset);
        const fixture_type = fixture.type && APP_CONFIG.COLOR_TYPES ? APP_CONFIG.COLOR_TYPES.find(color => color.key === fixture.type) : null;
        const length = parseInt(fixture.length || (fixture_type && fixture_type.block_size) || default_length);
        const universe = fixture.universe || default_universe;
        fixtures.push({
            id: fixture.id || uuidv4(),
            uuid: uuidv4(),
            "webapp-name": fixture["webapp-name"],
            universe,
            offset,
            length,
            "webapp-type": fixture["webapp-type"] || fixture["icon-type"] || "fader",
            location: fixture.location || (config.SCENARIOS && config.SCENARIOS[0] && config.SCENARIOS[0].location) || "",
            x: fixture.x || 0,
            y: fixture.y || 0
        });
        default_offset = offset + length;
        default_universe = universe;
        default_length = length;
    }
    return fixtures;
}

export function arraysContainSameElement(a, b) {
    return a === b || toArray(a, false)
        .findIndex(a_val => toArray(b, false)
            .indexOf(a_val) >= 0) >= 0;
}

export function getDefaultSceneComponents(config, scenario, location, fixtures) {
    const scene_components = [];
    const palettes = [];

    for (const fixture of fixtures.filter(fix => arraysContainSameElement(fix.location, location))) {
        let palette = uuidv4();
        let generate_palette = true;
        let raw_values = new Array(fixture.length).fill(0);
        if (scenario.VALUES && scenario.VALUES[fixture.id]) {
            if (typeof scenario.VALUES[fixture.id] === "string") {
                palette = scenario.VALUES[fixture.id];
                generate_palette = false;
            } else {
                raw_values = toArray(scenario.VALUES[fixture.id]);
            }
        } else if (scenario.DEFAULT_VALUES && scenario.DEFAULT_VALUES[fixture["webapp-type"] || fixture["icon-type"]]) {
            if (typeof scenario.DEFAULT_VALUES[fixture["webapp-type"] || fixture["icon-type"]] === "string") {
                palette = scenario.DEFAULT_VALUES[fixture["webapp-type"] || fixture["icon-type"]];
                generate_palette = false;
            } else {
                raw_values = toArray(scenario.DEFAULT_VALUES[fixture["webapp-type"] || fixture["icon-type"]]);
            }
        } else if (typeof (scenario.DEFAULT_VALUES) === "string" || scenario.DEFAULT_VALUES instanceof String) {
            palette = scenario.DEFAULT_VALUES;
            generate_palette = false;
        } else if (APP_CONFIG.PLAYLIST && APP_CONFIG.PLAYLIST.PALETTES instanceof Array) {
            const default_palette = APP_CONFIG.PLAYLIST.PALETTES.find(palette_entry => palette_entry.default
                && (isNullable(palette_entry.location) || palette_entry.location === fixture.location));
            if (default_palette) {
                palette = default_palette.id;
                generate_palette = false;
            }
        }

        if (generate_palette) {
            // Apply scaler to values
            raw_values = raw_values.map(val => Math.round(val * 255 / 100));

            palettes.push({
                id: palette,
                uuid: uuidv4(),
                color: {raw: {value: raw_values}},
            });
        }

        scene_components.push({
            "dmx-fixture": fixture.id,
            uuid: uuidv4(),
            palette
        });
    }

    return {
        scene_components,
        palettes
    };
}

export function createOverrideCuelist(cuelist, config) {
    const overrider_cuelist = {
        ...cuelist,
        name: cuelist.name + "-override",
        trigger: [cuelist.name + "-override", ...toArray(config.PLAYLIST.OVERRIDER && config.PLAYLIST.OVERRIDER.trigger)],
        inversetrigger: [cuelist.name + "-override-inverse", ...toArray(config.PLAYLIST.OVERRIDER && config.PLAYLIST.OVERRIDER.inversetrigger)],
        priority: parseInt(cuelist.priority) + 1,
        type: "override",
        uuid: uuidv4()
    };

    delete overrider_cuelist["webapp-name"];
    delete overrider_cuelist["webapp-locked"];
    delete overrider_cuelist.x;
    delete overrider_cuelist.y;

    return overrider_cuelist;
}

function getDefaultCues(config, scenario) {
    return (scenario.cues ? scenario.cues.map((cue, index) => {
        const cue_entry = {
            duration: config.PLAYLIST.CUE.DURATION,
            "fade-time": config.PLAYLIST.CUE.FADE_TIME,
            ...scenario,
            scene: scenario.name + "-" + index,
            ...cue,
            uuid: uuidv4()
        };

        if (scenario.VALUES && cue.VALUES) {
            cue_entry.VALUES = {...scenario.VALUES, ...cue.VALUES};
        }
        if (scenario.DEFAULT_VALUES && cue.DEFAULT_VALUES) {
            cue_entry.DEFAULT_VALUES = {...scenario.DEFAULT_VALUES, ...cue.DEFAULT_VALUES};
        }
        return cue_entry;
    }) : [{
        duration: config.PLAYLIST.CUE.DURATION,
        "fade-time": config.PLAYLIST.CUE.FADE_TIME,
        ...scenario,
        scene: scenario.name,
        uuid: uuidv4()
    }]).map(cue => {
        cue.scene = cue.name;
        delete cue.DEFAULT_VALUES;
        delete cue.VALUES;
        delete cue.DUPLICATES;
        delete cue.priority;
        delete cue.trigger;
        delete cue.inversetrigger;
        delete cue["webapp-media"];
        delete cue.cues;
        delete cue.name;
        return cue;
    });
}

export function getDefaultScenes(config, fixtures) {
    const scenes = [];
    const timelines = [];
    const palettes = [];
    let trigger_name_list = [];
    let default_x = 0;
    let default_y = 0;
    let last_location = "";

    for (const scenario of toArray(config.PLAYLIST && config.PLAYLIST.SCENARIOS)) {
        const name = scenario.name || uuidv4();
        const location = scenario.location || (config.SCENARIOS && config.SCENARIOS[0] && config.SCENARIOS[0].location) || "";

        if (location !== last_location) {
            default_x = 0;
            default_y = 0;
        }

        const x = scenario.x || default_x;
        const y = scenario.x || default_y;
        let phyBtn = (APP_CONFIG.PLAYLIST.PHYSICAL_BUTTONS || APP_CONFIG.PHYSICAL_BUTTONS || [])
            .filter(pbtn => pbtn.x === x && pbtn.y === y && arraysContainSameElement(pbtn.location, location));
        phyBtn = phyBtn.length > 0 ? phyBtn[0] : null;
        const trigger_list = [
            name,
            ...(APP_CONFIG.ENABLE_OVERRIDER ? [name + "-override-inverse"] : []),
            ...toArray(scenario.triggers || scenario.trigger),
            ...(phyBtn ? ["gpio-pin-" + phyBtn.gpio_pin] : []),
        ];
        const inversetrigger_list = [
            name + "-inverse",
            ...toArray(scenario.inversetriggers || scenario.inversetrigger)
        ];

        trigger_name_list = [...trigger_name_list, ...trigger_list, ...inversetrigger_list];

        const cues = getDefaultCues(config, {
            ...scenario,
            name
        });

        for (const cue of cues) {
            const {
                scene_components,
                palettes: new_palettes
            } = getDefaultSceneComponents(config, {...scenario, ...cue}, location, fixtures);

            palettes.push(...new_palettes);

            scenes.push({
                name: cue.scene,
                uuid: uuidv4(),
                "scene-component": scene_components,
            });
        }

        const timeline = {
            ...scenario,
            name,
            uuid: uuidv4(),
            "webapp-locked": scenario["webapp-locked"] ? "yes" : "no",
            "webapp-hidden": scenario["webapp-hidden"] ? "yes" : "no",
            location,
            x,
            y,
            trigger: trigger_list,
            inversetrigger: inversetrigger_list,
            priority: !isNullable(scenario.priority) ? scenario.priority : config.PLAYLIST.CUELIST.PRIORITY,
            loop: (!isNullable(scenario.loop) ? scenario.loop : config.PLAYLIST.CUELIST.LOOP) ? "yes" : "no",
            cue: cues
        };
        delete timeline.DEFAULT_VALUES;
        delete timeline.VALUES;
        delete timeline.DUPLICATES;
        delete timeline.cues;

        timelines.push(timeline);

        if (APP_CONFIG.ENABLE_OVERRIDER) {
            // Create overrider timeline
            timelines.push(createOverrideCuelist(timeline, config));
            trigger_name_list.push(timeline.name + "-override");
            trigger_name_list.push(timeline.name + "-override-inverse");
        }

        // Create duplicates of timeline (inherited timelines)
        for (const duplicate of toArray(scenario.DUPLICATES)) {
            let timeline_cues = timeline.cue;
            if (duplicate.cues) {
                timeline_cues = getDefaultCues(config, scenario);
                for (const cue of timeline_cues) {
                    const {
                        scene_components,
                        palettes: new_palettes
                    } = getDefaultSceneComponents(config, {...scenario, ...cue}, location, fixtures);

                    palettes.push(...new_palettes);

                    scenes.push({
                        name: cue.name,
                        uuid: uuidv4(),
                        "scene-component": scene_components,
                    });
                }
            }

            const duplicate_timeline = {
                ...timeline,
                ...duplicate,
                name: duplicate.name || uuidv4(),
                uuid: uuidv4(),
                trigger: toArray(duplicate.triggers || duplicate.trigger || timeline.trigger),
                inversetrigger: toArray(duplicate.inversetriggers || duplicate.inversetrigger || timeline.inversetrigger),
                cue: timeline_cues
            };

            delete duplicate_timeline["webapp-name"];
            delete duplicate_timeline["webapp-locked"];
            delete duplicate_timeline.x;
            delete duplicate_timeline.y;

            trigger_name_list = [...trigger_name_list, ...duplicate_timeline.trigger, ...duplicate_timeline.inversetrigger];
            timelines.push(duplicate_timeline);
        }

        last_location = location;
        default_x = x + 1;
        default_y = y;
        if (default_x >= toArray(config.SCENARIOS)
            .filter(s => arraysContainSameElement(s.location, location))[0]) {
            // Jump to next line
            default_y++;
            default_x = 0;
        }
    }

    return {
        scene: scenes,
        timeline: timelines,
        palette: palettes,
        // Remove all duplicates from array and map them to trigger objects with Playback Controller syntax
        trigger: [...new Set(trigger_name_list)].map((trigger) => ({
            id: trigger,
            uuid: uuidv4(),
            ...(trigger.startsWith("gpio-pin-") ? {
                gpio: {
                    pin: trigger.split("-")[2],
                    detect: trigger.split("-")[3] === "inverse" ? "falling-edge" : "rising-edge"
                }
            } : null),
            default: trigger === "default" ? "yes" : "no"
        }))
    };
}

function getDefaultDrivers(config) {
    if (!config.PLAYLIST.DRIVERS) return [];
    return config.PLAYLIST.DRIVERS.map(driver => {
        const obj = {
            ...driver
        };
        const range = [...toArray(driver.ranges), ...toArray(driver.range)];
        if (range.length > 0) {
            obj.range = range;
        }
        delete obj.ranges;
        return obj;
    });
}

function getDefaultDriverMiddlewares(config) {
    if (!config.PLAYLIST.DRIVER_MIDDLEWARES) return [];
    return config.PLAYLIST.DRIVER_MIDDLEWARES.map(middleware => {
        const obj = {
            ...middleware
        };
        const range = [...toArray(middleware.ranges), ...toArray(middleware.range)];
        if (range.length > 0) {
            obj.range = range;
        }
        delete obj.ranges;
        return obj;
    });
}

function getDefaultPalettes(config) {
    return toArray(config.PLAYLIST && config.PLAYLIST.PALETTES)
        .map(palette_entry => {
            const palette = {
                color: {},
                ...palette_entry,
                "from-config": "yes"
            };
            if (!palette.id) {
                palette.id = uuidv4();
            }

            for (const [color_type, color_value] of Object.entries(palette_entry)) {
                if (color_type === "raw") {
                    delete palette.raw;
                    palette.color[color_type] = {value: color_value};
                } else {
                    const color_type_definition = APP_CONFIG.COLOR_TYPES.find(type => type.key === color_type);
                    if (color_type_definition) {
                        delete palette[color_type];
                        if (color_value instanceof Array) {
                            // Convert array definition to the correct object based one
                            palette.color[color_type] = {};
                            for (let i = 0; i < color_value.length; i++) {
                                palette.color[color_type][color_type_definition.definition[i]] = color_value[i];
                            }
                        }
                    }
                }
            }

            return palette;
        });
}

export function getDefaultPlaylist(config, system_config) {
    if (config.PLAYLIST && config.PLAYLIST.DEFAULT_FILE) {
        const playlist = parseJSONPlaylist(config.PLAYLIST.DEFAULT_FILE);
        // eslint-disable-next-line no-console
        console.log(playlist);
        return playlist;
    }
    const fixtures = getDefaultFixtures(config, system_config);
    const {
        scene,
        timeline,
        palette,
        trigger
    } = getDefaultScenes(config, fixtures);
    palette.push(...getDefaultPalettes(config));
    const playlist = {
        playlist: {
            ...(config.PLAYLIST && config.PLAYLIST.DRIVERS ? {driver: getDefaultDrivers(config)} : null),
            ...(config.PLAYLIST && config.PLAYLIST.DRIVER_MIDDLEWARES ? {"driver-middleware": getDefaultDriverMiddlewares(config)} : null),
            "dmx-fixture": fixtures,
            scene,
            timeline,
            palette,
            trigger,
            ...(config.PLAYLIST && config.PLAYLIST.TIMERS ? {timer: config.PLAYLIST.TIMERS} : null)
        }
    };
    // eslint-disable-next-line no-console
    console.log(playlist);
    return playlist;
}

export const dataMiddleware = (store) => (next) => (action) => {
    const auth_type = store.getState().login ? store.getState().login.auth_type : null;
    const active_project = store.getState().data.active_project || 0;
    let auth_params = null;
    if (auth_type === "login") {
        auth_params = {token: store.getState().login.token};
    }
    switch (action.type) {
        case dataActions.GET_LIGHT_CONFIGURATION:
            axios.get(APP_CONFIG.API_ENDPOINTS.LIGHT_CONFIGURATION.GET, {
                params: {
                    ...auth_params,
                    project: active_project
                }
            })
                .then(
                    (response) => {
                        if (response.data === "") {
                            if (APP_CONFIG.DISABLE_INITIAL_SETUP) {
                                // Load default fixtures
                                action.config = getDefaultPlaylist(APP_CONFIG,
                                    store.getState() && store.getState().data ? store.getState().data.system_config : null);
                                return next(action);
                            }
                            store.dispatch({type: dataActions.SHOW_INITIAL_SETUP});
                            return;
                        }
                        convertXMLStringToJSON(response.data)
                            .then(jsonConfig => {
                                action.config = parseJSONPlaylist(jsonConfig);
                                // eslint-disable-next-line no-console
                                console.log(action.config);
                                return next(action);
                            })
                            .catch(error => {
                                console.error("Failed to parse light configuration from controller:", error);
                                store.dispatch({type: dataActions.LIGHT_CONFIG_PARSE_FAILED});

                                // Load default fixtures
                                action.config = getDefaultPlaylist(APP_CONFIG,
                                    store.getState() && store.getState().data ? store.getState().data.system_config : null);
                                return next(action);
                            });
                    }
                )
                .catch((error) => {
                    if (error.response && error.response.status === 500 && error.response.data
                        && error.response.data.message === "Could not read playlist XML file") {
                        if (APP_CONFIG.DISABLE_INITIAL_SETUP) {
                            // Load default fixtures
                            action.config = getDefaultPlaylist(APP_CONFIG,
                                store.getState() && store.getState().data ? store.getState().data.system_config : null);
                            return next(action);
                        }
                        store.dispatch({type: dataActions.SHOW_INITIAL_SETUP});
                        return;
                    }
                    store.dispatch({
                        type: notificationsActions.CONNECTION_FAILED,
                        callback: action.errorCallback
                    });
                    if (auth_type === "none") {
                        // Keep retrying with a delay of 2 seconds
                        setTimeout(() => store.dispatch(action), 2000);
                    }
                });
            break;
        case dataActions.GET_VIDEOS:
            axios.get(APP_CONFIG.API_ENDPOINTS.VIDEOS.GET, {
                params: {
                    ...auth_params,
                    project: active_project
                }
            })
                .then(response => {
                    action.config = response.data;
                    return next(action);
                })
                .catch(error => {
                    console.error("Failed to get videos from controller:", error);
                    store.dispatch({
                        type: notificationsActions.CONNECTION_FAILED,
                        callback: action.errorCallback
                    });
                    if (auth_type === "none") {
                        // Keep retrying with a delay of 2 seconds
                        setTimeout(() => store.dispatch(action), 2000);
                    }
                });
            break;
        case dataActions.GET_SYSTEM_CONFIGURATION:
            axios.get(APP_CONFIG.API_ENDPOINTS.SYSTEM_CONFIGURATION.GET, {
                params: {
                    ...auth_params,
                    project: active_project
                }
            })
                .then(
                    (response) => {
                        action.config = convertOptionsStringToJSON(response.data);
                        store.dispatch({
                            type: dataActions.CALCULATE_SUN_TIMES,
                            system_config: action.config
                        });
                        if (action.config.slaves instanceof Array && action.config.slaves.length > 0) {
                            store.dispatch({
                                type: dataActions.GET_SYSTEM_CONFIGURATION_SLAVES,
                                slaves: action.config.slaves
                            });
                        }
                        return next(action);
                    }
                )
                .catch(error => {
                    console.error("Failed to get system configuration from controller:", error);
                    store.dispatch({
                        type: notificationsActions.CONNECTION_FAILED,
                        callback: action.errorCallback
                    });
                    if (auth_type === "none") {
                        // Keep retrying with a delay of 2 seconds
                        setTimeout(() => store.dispatch(action), 2000);
                    }
                });
            break;
        case dataActions.GET_SYSTEM_CONFIGURATION_SLAVES:
            multiAxiosGetCustom(store, action.slaves, APP_CONFIG.API_ENDPOINTS.SYSTEM_CONFIGURATION.GET)
                .then(responses => {
                    action.config = {};
                    for (const response of responses) {
                        action.config[response.controller] = convertOptionsStringToJSON(response.data);
                    }
                    return next(action);
                })
                .catch(error => {
                    console.error("Failed to get system configuration from controller slaves:", error);
                    store.dispatch({
                        type: notificationsActions.CONNECTION_FAILED,
                        callback: action.errorCallback
                    });
                });
            break;
        case dataActions.GET_CONTROLLER_TIME:
            // Get time from controller, and start a timer that adds a minute to it every minute
            axios.get(APP_CONFIG.API_ENDPOINTS.TIME_CONFIGURATION.GET, {
                params: {
                    ...auth_params,
                    project: active_project
                }
            })
                .then(response => {
                    action.config = parseInt(response.data);
                    store.dispatch({
                        type: dataActions.CALCULATE_SUN_TIMES,
                        time: action.config
                    });
                    return next(action);
                })
                .catch((error) => {
                    console.error("Failed to get time from controller:", error);
                    store.dispatch({type: notificationsActions.CONNECTION_FAILED});
                });
            break;
        case dataActions.SET_CONTROLLER_TIME:
            // Set time on controller
            multiAxiosGet(store, APP_CONFIG.API_ENDPOINTS.TIME_CONFIGURATION.SET + "/" + action.time)
                .then(() => {
                    // Read new time and timeserver from controller
                    store.dispatch({type: dataActions.GET_CONTROLLER_TIME});
                    store.dispatch({type: dataActions.GET_CONTROLLER_TIMESERVER});
                    return next(action);
                })
                .catch((error) => {
                    console.error("Failed to set time on controller:", error);
                    store.dispatch({type: notificationsActions.CONNECTION_FAILED});
                });
            break;
        case dataActions.GET_CONTROLLER_TIMESERVER:
            // Get timeserver configuration from controller
            axios.get(APP_CONFIG.API_ENDPOINTS.TIMESERVER_CONFIGURATION.GET, {
                params: {
                    ...auth_params,
                    project: active_project
                }
            })
                .then(response => {
                    action.config = response.data;
                    return next(action);
                })
                .catch((error) => {
                    console.error("Failed to get timeserver from controller:", error);
                    store.dispatch({type: notificationsActions.CONNECTION_FAILED});
                });
            break;
        case dataActions.SET_CONTROLLER_TIMESERVER:
            // Set timeserver on controller
            multiAxiosGet(store, APP_CONFIG.API_ENDPOINTS.TIMESERVER_CONFIGURATION.SET + "/" + action.timeserver)
                .then(() => {
                    // Read new time and timeserver from controller
                    store.dispatch({type: dataActions.GET_CONTROLLER_TIME});
                    store.dispatch({type: dataActions.GET_CONTROLLER_TIMESERVER});
                    return next(action);
                })
                .catch((error) => {
                    console.error("Failed to set timeserver on controller:", error);
                    store.dispatch({type: notificationsActions.CONNECTION_FAILED});
                });
            break;
        case dataActions.SET_SYSTEM_CONFIGURATION: {
            const updated_options = reducerUpdater(store.getState().data.system_config, action.changes);
            const options_string = convertJSONToOptionsString(updated_options);
            // Disconnect from websocket while restarting playback controller
            store.dispatch({
                type: websocketActions.WEBSOCKET_DISCONNECT,
                callback: () => {
                    axios.post(APP_CONFIG.API_ENDPOINTS.SYSTEM_CONFIGURATION.SET, options_string, {
                        params: {
                            ...auth_params,
                            project: active_project
                        }
                    })
                        .then(
                            () => {
                                action.config = updated_options;
                                // Make sure we don't connect to terminating instance of playctl
                                setTimeout(() => store.dispatch({type: websocketActions.WEBSOCKET_CONNECT}), 1000);

                                // Check if IP changed, and redirect user
                                if (action.config.ethernet && action.config.ethernet[0] === "static"
                                    && action.config.ethernet_ip && window.location.host !== action.config.ethernet_ip[0]
                                    && isNullable(process.env.REACT_APP_SERVER_BASE_URL)) {
                                    store.dispatch({
                                        type: dataActions.IP_CHANGED_REDIRECT_LOCATION,
                                        address: action.config.ethernet_ip[0]
                                    });
                                }
                                return next(action);
                            }
                        )
                        .catch(() => {
                            store.dispatch({
                                type: notificationsActions.CONNECTION_FAILED,
                                callback: action.errorCallback
                            });
                        });
                }
            });
            break;
        }
        case dataActions.SET_LIGHT_CONFIGURATION: {
            const config = store.getState().data.light_config;
            let new_config = {};
            if (action.config) {
                new_config = action.config;
            } else {
                const playlist = DATA_TYPES.map(type => {
                    const type_config = config && config.playlist && config.playlist[type];
                    const type_config_changes = action.changes && action.changes.playlist && action.changes.playlist[type];
                    let merged = merge_by_uuid(type_config, type_config_changes, true);
                    merged = removeEmpty(merged);
                    return [type, merged];
                })
                    .reduce((object, val) => {
                        object[val[0]] = val[1];
                        return object;
                    }, {});

                checkPlaylistForErrors(store, playlist, action.changes && action.changes.playlist);
                new_config = {playlist};
            }

            for (const [key, value] of Object.entries(new_config.playlist)) {
                if (value instanceof Array && value.length === 0) {
                    delete new_config.playlist[key];
                }
            }

            // eslint-disable-next-line no-console
            console.log(new_config);

            const endpoint = action.restart
                ? APP_CONFIG.API_ENDPOINTS.LIGHT_CONFIGURATION.SET_RESTART
                : APP_CONFIG.API_ENDPOINTS.LIGHT_CONFIGURATION.SET_RELOAD;

            convertJSONToXMLString(new_config)
                .then((xmlString) => {
                    multiAxiosPost(store, endpoint, xmlString)
                        .then(
                            () => {
                                action.config = new_config;
                                // Make sure we don't connect to terminating instance of playctl
                                setTimeout(() => store.dispatch({type: websocketActions.WEBSOCKET_CONNECT}), 1000);
                                return next(action);
                            }
                        )
                        .catch(() => {
                            store.dispatch({
                                type: notificationsActions.CONNECTION_FAILED,
                                callback: action.errorCallback
                            });
                        });
                })
                .catch((error) => {
                    console.error(error);
                    store.dispatch({
                        type: dataActions.LIGHT_CONFIG_WRITE_FAILED,
                        callback: action.errorCallback
                    });
                });
            break;
        }
        case dataActions.RESET_CONTROLLER:
            // Upload empty playlist to controller and jump to initial setup page
            multiAxiosPost(store, action.restart ? APP_CONFIG.API_ENDPOINTS.LIGHT_CONFIGURATION.SET_RESTART
                : APP_CONFIG.API_ENDPOINTS.LIGHT_CONFIGURATION.SET_RELOAD, "")
                .then(
                    () => {
                        action.config = {};
                        store.dispatch({
                            type: dataActions.SHOW_INITIAL_SETUP
                        });
                        return next(action);
                    }
                )
                .catch(() => {
                    store.dispatch({
                        type: notificationsActions.CONNECTION_FAILED,
                        callback: action.errorCallback
                    });
                });
            break;
        case dataActions.BACKUP_CONTROLLER:
            try {
                // Create a file blob
                const blob = new Blob([JSON.stringify(store.getState().data.light_config)], {type: "text/json;charset=utf-8"});
                // eslint-disable-next-line
                FileSaver.saveAs(blob, `controller-backup-${moment()
                    .format("YYYY-MM-DD-HH_mm_ss")}.json`);
                return next(action);
            } catch (e) {
                console.error("Failed to save backup:", e);
                store.dispatch({
                    type: notificationsActions.BACKUP_FAILED,
                    error: e,
                    callback: action.errorCallback
                });
            }
            break;
        case dataActions.FIRE_TRIGGER: {
            let version = APP_CONFIG.DEFAULT_CONTROLLER_VERSION;
            if (store.getState().data.active_project !== null && store.getState().data.projects_config instanceof Array) {
                const project = store.getState().data.projects_config[store.getState().data.active_project];
                if (project && project.version !== undefined) {
                    version = project.version;
                }
            }

            if (version >= 2) {
                store.dispatch({
                    type: websocketActions.WEBSOCKET_SET_VARIABLE,
                    variable: "trigger",
                    value: action.trigger
                });
            } else {
                multiAxiosGet(store, APP_CONFIG.API_ENDPOINTS.FIRE_TRIGGER + action.trigger)
                    .then(() => next(action))
                    .catch((error) => {
                        console.error(error);
                        store.dispatch({
                            type: notificationsActions.CONNECTION_FAILED,
                            callback: action.errorCallback
                        });
                    });
            }
            break;
        }
        case dataActions.FIRE_CUELIST: {
            store.dispatch({
                type: websocketActions.WEBSOCKET_SET_VARIABLE,
                variable: "cuelist",
                value: action.cuelist
            });
            break;
        }
        case dataActions.IP_CHANGED_REDIRECT_LOCATION:
            setTimeout(() => {
                window.location.href = `http://${action.address}/`;
            }, 5000);
            return next(action);
        case dataActions.CALCULATE_SUN_TIMES: {
            let latitude = 56.202064;
            let longitude = 10.231917;
            let elevation = 4.49;
            if (action.system_config || (store.getState().data && store.getState().data.system_config)) {
                if ((action.system_config || store.getState().data.system_config).latitude) {
                    latitude = parseFloat((action.system_config || store.getState().data.system_config).latitude);
                }
                if ((action.system_config || store.getState().data.system_config).longitude) {
                    longitude = parseFloat((action.system_config || store.getState().data.system_config).longitude);
                }
                if ((action.system_config || store.getState().data.system_config).elevation) {
                    elevation = parseFloat((action.system_config || store.getState().data.system_config).elevation);
                }
            }

            // Calculate current controller time
            const controller_time = new Date((action.time || store.getState().data.time || 0) * 1000);
            if (!action.time && store.getState().data.time_updated_at) {
                const seconds_since_controller_time_update = Date.now() / 1000 - store.getState().data.time_updated_at;
                controller_time.setSeconds(controller_time.getSeconds() + seconds_since_controller_time_update);
            }

            const sun_calculations = SunCalc.getTimes(controller_time, latitude, longitude, elevation);
            action.config = {
                sunrise: Math.round(sun_calculations.sunrise.getTime() / 1000),
                sunset: Math.round(sun_calculations.sunset.getTime() / 1000)
            };
            return next(action);
        }
        case dataActions.GET_PROJECTS:
            if (!APP_CONFIG.API_ENDPOINTS.CONTROLLER_CONFIGURATION || !APP_CONFIG.API_ENDPOINTS.CONTROLLER_CONFIGURATION.GET_PROJECTS) {
                return next(action); // Projects not supported on controller
            }
            axios.get(APP_CONFIG.API_ENDPOINTS.CONTROLLER_CONFIGURATION.GET_PROJECTS, {params: auth_params})
                .then(
                    response => {
                        action.config = response.data;
                        return next(action);
                    }
                )
                .catch(error => {
                    console.error("Failed to get projects:", error);
                    store.dispatch({
                        type: notificationsActions.CONNECTION_FAILED,
                        callback: action.errorCallback
                    });
                    if (auth_type === "none") {
                        // Keep retrying with a delay of 2 seconds
                        setTimeout(() => store.dispatch(action), 2000);
                    }
                });
            break;
        default:
            return next(action);
    }
};
