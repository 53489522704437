import React from "react";
import moment from "moment";
import {isNullable} from "../../providers/objectsAreEqual";
import "./MMDatePicker.less";
import isAlphaNumeric from "../../providers/isAlphaNumeric";

export default class MMDatePicker extends React.Component {
    cursorBlinkerInterval;

    constructor(props) {
        super(props);
        this.state = MMDatePicker.getNewStateFromProps(props);
    }

    static getNewStateFromProps = (props) => {
        let formatted_date = props.format;
        if (!isNullable(props.value)) {
            const moment_date = moment(props.value, props.data_format);
            if (moment_date.isValid()) {
                formatted_date = moment_date.format(props.format);
                formatted_date += "\u2063"; // Cursor location
            }
        }
        return {
            value: !isNullable(props.value) ? props.value : "",
            formatted_date,
            rawValue: formatted_date.replace(/\D/g, ''), // Only numbers
            cursor: ""
        };
    };

    static getDerivedStateFromProps(props, current_state) {
        // Props have been updated, update state accordingly
        const new_state_from_props = MMDatePicker.getNewStateFromProps(props);
        const moment_date = moment(current_state.value, props.data_format);
        if (moment_date.isValid() // User is not currently editing field
            && current_state.value !== new_state_from_props.value) {
            return new_state_from_props;
        }
        return null;
    }

    reset = () => {
        this.setState(MMDatePicker.getNewStateFromProps(this.props));
    };

    onFocus = () => {
        // Show cursor immediately
        this.setState({
            cursor: (
                <span
                    key="cursor"
                    style={{
                        position: "initial",
                        width: 0,
                        marginLeft: -5.3 / 2,
                        marginRight: -5.3 / 2
                    }}
                >
                    |
                </span>)
        });
        this.cursorBlinkerInterval = setInterval(() => {
            this.setState(state => {
                if (state.cursor !== "") {
                    state.cursor = "";
                } else {
                    state.cursor = (
                        <span
                            key="cursor"
                            style={{
                                position: "initial",
                                width: 0,
                                marginLeft: -5.3 / 2,
                                marginRight: -5.3 / 2
                            }}
                        >
                            |
                        </span>);
                }
                return state;
            });
        }, 500);
    };

    onBlur = () => {
        clearInterval(this.cursorBlinkerInterval);
        this.setState({cursor: ""});
    };

    onDateInputChange = (event) => {
        const rawValue = event.target.value.replace(/\D/g, '');
        let formatted_date = "";
        let input_val_index = 0;
        let cursor_inserted = false;

        for (let i = 0; i < this.props.format.length; i++) { // Insert input
            if (rawValue.length > input_val_index && isAlphaNumeric(this.props.format.charAt(i))) {
                formatted_date += rawValue.charAt(input_val_index);
                input_val_index++;
            } else {
                if (rawValue.length === input_val_index) {
                    input_val_index++;
                    formatted_date += "\u2063"; // Cursor location
                    cursor_inserted = true;
                }
                formatted_date += this.props.format.charAt(i);
            }
        }

        let value = "";
        const moment_date = moment(formatted_date, this.props.format);

        if (!cursor_inserted) {
            formatted_date += "\u2063"; // Cursor location
        }

        const valid = moment_date.isValid() && rawValue.length === 6;

        if (this.props.onDateInputChange) this.props.onDateInputChange(event, value, valid);

        if (moment_date.isValid() || rawValue.length === 0 || rawValue.substr(-1) === "0") {
            if (rawValue.length === this.props.format.replace(/[\W_]+/g, "").length) { // Value is empty until date is complete
                value = moment_date.format("YYYY-MM-DD");
            }

            if (this.props.onChange) this.props.onChange(event, value);
            this.setState({
                formatted_date,
                value,
                rawValue
            });
        }
    };

    renderFormattedDate = () => {
        const formatted_data_split = this.state.formatted_date.split("\u2063");
        if (formatted_data_split.length === 1) {
            return [this.state.cursor, formatted_data_split[0]];
        }
        if (formatted_data_split.length > 1) {
            return [formatted_data_split[0], this.state.cursor, formatted_data_split[1]];
        }
        return [this.state.cursor];
    };

    render() {
        return (
            <div className={this.props.error ? "MMDatePicker error" : "MMDatePicker"}>
                <div className="input-group">
                    <input
                        onChange={this.onDateInputChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSelect={(event) => { // Keep cursor at end
                            // noinspection JSUnresolvedFunction
                            event.target.setSelectionRange(event.target.value.length, event.target.value.length);
                        }}
                        id={this.props.id}
                        disabled={this.props.disabled}
                        value={this.state.rawValue}
                        maxLength={this.props.format.replace(/[\W_]+/g, "").length}
                    />
                    <span>{this.renderFormattedDate()}</span>
                </div>
            </div>
        );
    }
}

MMDatePicker.defaultProps = {
    format: "DD MM YY",
    data_format: "YYYY-MM-DD"
};
