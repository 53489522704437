import React from "react";
import "./MMCard.less";

export default function MMCard(props) {
    return (
        <div
            className={`MMCard ${props.className || ""}`}
            style={{
                height: props.height || 600,
                width: props.width || 320,
                backgroundColor: props.backgroundColor,
                ...props.style
            }}
        >
            <div
                className="content"
                style={{
                    height: `calc(100% - ${(props.padding || 15) * 2}px)`,
                    width: `calc(100% - ${(props.padding || 15) * 2}px)`,
                    padding: props.padding || 15
                }}
            >
                {props.children}
            </div>
        </div>
    );
}
