import axiosImport from "axios";
import Cookies from "universal-cookie";
import {loginActions} from "./loginActions";
import {APP_CONFIG} from "../../config";
import {notificationsActions} from "../notifications/notificationsActions";
import {dataActions} from "../data/dataActions";
import {getServerBaseURL} from "../data/dataMiddleware";

const axios = axiosImport.create({
    baseURL: getServerBaseURL(),
    timeout: 5000
}); // Timeout after 5 seconds

const get_token = () => {
    const token = (new Cookies()).get("token", {path: '/'});
    return token === "undefined" ? null : token;
};

const getAuthenticationType = () => new Promise((resolve, reject) => {
    // Get the authentication type used by the server
    if (APP_CONFIG.API_ENDPOINTS.VERSION === 1) {
        resolve(APP_CONFIG.ENABLE_PIN_LOGIN ? "client-pin" : "none");
    } else {
        axios.get(APP_CONFIG.API_ENDPOINTS.AUTHENTICATION.GET_AUTHENTICATION_TYPE)
            .then(response => {
                if (response && response.data && response.data.type) {
                    if (response.data.type === "none" && APP_CONFIG.ENABLE_PIN_LOGIN) {
                        resolve("client-pin");
                    } else {
                        resolve(response.data.type);
                    }
                } else {
                    reject(notificationsActions.CONNECTION_FAILED);
                }
            })
            .catch(error => {
                console.error("Failed to get authentication type:", error);
                reject(notificationsActions.CONNECTION_FAILED);
            });
    }
});

const validateToken = token => new Promise((resolve, reject) => {
    const reply = {
        token_expired: false,
        token_empty: false,
        user: null
    };
    if (!token) {
        reply.token_empty = true;
        return resolve(reply);
    }
    axios.post(APP_CONFIG.API_ENDPOINTS.AUTHENTICATION.GET_USER_INFO, {token})
        .then(response => {
            reply.user = response.data;
            resolve(reply);
        })
        .catch(error => {
            console.error("Failed to get user info:", error);
            reject(notificationsActions.CONNECTION_FAILED);
        });
});

const getToken = (username, password) => new Promise((resolve, reject) => {
    axios.post(APP_CONFIG.API_ENDPOINTS.AUTHENTICATION.GET_TOKEN, {
        username,
        password
    })
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            if (error.response.status === 403) {
                reject(loginActions.INVALID_CREDENTIALS);
            } else {
                reject(notificationsActions.CONNECTION_FAILED);
            }
        });
});

export const loginMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        case loginActions.DO_SIGNOUT:
            if (APP_CONFIG.API_ENDPOINTS.AUTHENTICATION && APP_CONFIG.API_ENDPOINTS.AUTHENTICATION.LOGOUT) {
                axios.post(APP_CONFIG.API_ENDPOINTS.AUTHENTICATION.LOGOUT, {token: store.getState().login.token})
                    .catch(() => null);

                if (store.getState().login.auth_type === "login") {
                    store.dispatch({type: dataActions.CLEAR_LOCAL_DATA});
                }
            }
        // eslint-disable-next-line no-fallthrough
        case loginActions.EXPIRED_TOKEN:
            action.username = null;
            action.loggedIn = false;
            return next(action);
        case loginActions.SEND_CREDENTIALS:
            getAuthenticationType()
                .then(auth_type => {
                    action.auth_type = auth_type;
                    switch (auth_type) {
                        case "client-pin":
                            if (action.pincode === (APP_CONFIG.ADMIN_PIN || "1488")) {
                                action.username = "admin";
                                return next(action);
                            }
                            if (action.pincode === "1111") {
                                action.username = "user";
                                return next(action);
                            }
                            return store.dispatch({
                                type: loginActions.INVALID_PIN_CODE,
                                callback: action.errorCallback
                            });
                        case "none":
                            action.username = APP_CONFIG.ENABLE_PIN_TO_EDIT ? "user" : "admin";
                            return next(action);
                        case "login":
                            getToken(action.username, action.password)
                                .then(user_data => next({...action, ...user_data}))
                                .catch(type => store.dispatch({
                                    type,
                                    callback: action.errorCallback
                                }));
                            return;
                        default:
                            return store.dispatch({
                                type: notificationsActions.CONNECTION_FAILED,
                                callback: action.errorCallback
                            });
                    }
                })
                .catch(type => store.dispatch({type}));
            break;
        case loginActions.AUTHENTICATE:
            getAuthenticationType()
                .then(auth_type => {
                    action.auth_type = auth_type;
                    action.loggedIn = false;
                    switch (auth_type) {
                        case "client-pin":
                            return next(action);
                        case "none":
                            action.username = APP_CONFIG.ENABLE_PIN_TO_EDIT ? "user" : "admin";
                            action.loggedIn = true;
                            return next(action);
                        case "login":
                            action.token = get_token();
                            validateToken(action.token)
                                .then(response => {
                                    if (response.user) {
                                        action.username = response.user.username;
                                        action.projects = response.user.projects;
                                        action.properties = response.user.properties;
                                        action.loggedIn = true;
                                    } else if (response.token_expired) {
                                        store.dispatch({
                                            type: loginActions.EXPIRED_TOKEN,
                                            callback: action.errorCallback
                                        });
                                    }
                                    next(action);
                                })
                                .catch(type => store.dispatch({
                                    type,
                                    callback: action.errorCallback
                                }));
                            return;
                        default:
                            return store.dispatch({type: notificationsActions.CONNECTION_FAILED});
                    }
                })
                .catch(type => store.dispatch({type}));
            break;
        default:
            return next(action);
    }
};
