import React from "react";
import "./ScenarioEditor.less";
import {connect} from "react-redux";
import {Row} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import memoize from "memoize-one";

import MMButton from "../../../elements/buttons/MMButton";
import {isNullable} from "../../../providers/objectsAreEqual";
import {createOverrideCuelist, uuidv4} from "../../../providers/data/dataMiddleware";
import constants from "../../../less/motomuto_ras/constants";
import {notificationsActions} from "../../../providers/notifications/notificationsActions";
import {dataActions} from "../../../providers/data/dataActions";
import {APP_CONFIG} from "../../../config";
import MMToggle from "../../../elements/inputs/MMToggle";
import MMTextInput from "../../../elements/inputs/MMTextInput";
import {websocketActions} from "../../../providers/websocket/websocketActions";
import MMModal from "../../../elements/layout/MMModal";
import {setTypeChanges} from "../../../providers/setTypeChanges";
import {getElements} from "../../../providers/getElements";
import {synchronousSetState} from "../../../providers/synchronousSetState";
import MMSliderWithInput from "../../../elements/inputs/MMSliderWithInput";
import {toArray} from "../../../providers/toArray";

class ScenarioEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changes: {light_config: {playlist: {}}},
            new: isNullable(this.props.uuid),
            closing: false
        };
        // Bind functions to context
        this.setTypeChanges = setTypeChanges.bind(this);
        this.getElements = getElements.bind(this);

        if (this.state.new) {
            this.createNewCuelist.bind({
                ...this,
                setState: synchronousSetState.bind({state: this.state})
            })();
            if (APP_CONFIG.LIVE_EDIT) {
                // Add scene in backend
                const types = ["palette", "scene", "timeline"];
                for (const type of types) {
                    this.doLiveEdit(type);
                }
            }
        }

        // Connect to WebSocket and get live edit status
        if (this.props.data.light_config && this.props.data.light_config.playlist && this.props.data.light_config.playlist.timeline
            && this.props.data.light_config.playlist.timeline.length > 0) {
            // Only connect if data exists, otherwise websocket server is closed
            this.props.dispatch({
                type: websocketActions.WEBSOCKET_CONNECT
            });
        }
        if (APP_CONFIG.LIVE_EDIT) {
            // Trigger opened scene
            this.props.dispatch({
                type: dataActions.FIRE_TRIGGER,
                trigger: this.getCuelist().name
            });
        }
    }

    componentDidMount() {
        document.body.classList.add("modal");
        document.addEventListener("keydown", this.handleKeyPress, false);
    }

    componentWillUnmount() {
        document.body.classList.remove("modal");
        document.removeEventListener("keydown", this.handleKeyPress, false);

        if (APP_CONFIG.LIVE_EDIT) {
            // Clear changes in controller backend
            this.props.dispatch({
                type: websocketActions.WEBSOCKET_SET_VARIABLE,
                variable: "reload"
            });
        }
    }

    getScenario = memoize(() => {
        const cuelist = this.getCuelist();
        if (!cuelist) return null;
        if (this.state.new) {
            return this.state.changes.light_config.playlist.scene[0];
        }
        const scenarios = this.getElements("scene")
            .filter(scene => cuelist.cue && cuelist.cue.find(cue => cue.scene === scene.name));
        return scenarios.length > 0 ? scenarios[0] : null;
    });

    getPalettesInScenario = () => {
        const scenario = this.getScenario();
        return this.getElements("palette")
            .filter(palette => scenario["scene-component"]
                .findIndex(component => component.palette === palette.id) >= 0);
    };

    doLiveEdit = type => {
        // Ignore elements that are getting deleted
        // eslint-disable-next-line no-unreachable
        const changed_elements = this.state.changes.light_config.playlist[type]
            && this.state.changes.light_config.playlist[type].filter(el => !el.delete);
        if (changed_elements && changed_elements.length > 0) {
            this.props.dispatch({
                type: websocketActions.WEBSOCKET_SET_VARIABLE,
                variable: `update_${type}`,
                value: changed_elements
            });
        }
    };

    handleKeyPress = (event) => {
        if (event.keyCode === 27) { // Exit
            this.onClose();
        }
    };

    getPaletteByUuid = (paletteUuid) => {
        const palette = this.getPalettesInScenario()
            .filter(pal => pal.uuid === paletteUuid);
        if (palette.length <= 0) {
            // Shouldn't be possible
            console.error(`Scene is missing palette with UUID: ${paletteUuid}, this shouldn't be possible!!`);
            return null;
        }
        return palette[0];
    };

    getPalette = (paletteName) => {
        let palette = this.getPalettesInScenario()
            .filter(pal => pal.id === paletteName);
        if (palette.length <= 0) {
            // Palette doesn't exist, data is either corrupt, or more fixtures have been added, without adding the scene components
            console.error(`Scene is missing palette ${paletteName}, this shouldn't be possible!!`);
            palette = {
                uuid: uuidv4(),
                id: paletteName,
                color: [{
                    raw: {
                        value: [0]
                    }
                }]
            };
            this.setTypeChanges("palette", palette, () => this.doLiveEdit("palette"));
            return palette;
        }
        return palette[0];
    };

    getCuelist = (property = "uuid", value = this.props[property]) => {
        if (this.state.new) {
            return this.state.changes.light_config.playlist.timeline[0];
        }
        const cuelists = this.getElements("timeline")
            .filter(cuelist => cuelist[property] === value);
        return cuelists.length > 0 ? cuelists[0] : null;
    };

    getScenarioComponent = (fixtureName) => {
        const scenario = this.getScenario();
        let scenarioComponent = scenario["scene-component"].filter(element => element["dmx-fixture"] === fixtureName);
        if (scenarioComponent.length <= 0) {
            // Scene component doesn't exist, data is either corrupt, or more fixtures have been added, without adding the scene components
            console.error(`Scene is missing scene component for fixture ${fixtureName}, this shouldn't be possible!!`);

            // Create a new scene component
            scenarioComponent = {
                "dmx-fixture": fixtureName,
                palette: uuidv4()
            };
            scenario["scene-component"].push(scenarioComponent);
            this.setTypeChanges("scene", scenario, () => this.doLiveEdit("palette"));
            return scenarioComponent;
        }
        return scenarioComponent[0];
    };

    onClose = () => {
        this.setState({closing: true});

        if (this.props.onClose) {
            setTimeout(this.props.onClose, parseFloat(constants["modal-transition-duration"]));
        }
    };

    submit = () => {
        this.setState({loading: true});
        const cuelist = this.getCuelist();
        if (!cuelist["webapp-name"] || cuelist["webapp-name"] === "") {
            this.props.dispatch({type: notificationsActions.MISSING_SCENE_NAME});
            this.setState({loading: false});
            return;
        }
        // Check for duplicate name
        if (this.props.data.light_config.playlist.timeline instanceof Array
            && this.props.data.light_config.playlist.timeline
                .findIndex(timeline => timeline["webapp-name"] === cuelist["webapp-name"]
                    && timeline.uuid !== cuelist.uuid && timeline.location === cuelist.location) >= 0) {
            this.props.dispatch({type: notificationsActions.DUPLICATE_SCENE_NAME});
            this.setState({loading: false});
            return;
        }

        // Save changes
        this.props.dispatch({
            type: dataActions.SET_LIGHT_CONFIGURATION,
            changes: this.state.changes.light_config,
            callback: this.onClose,
            errorCallback: () => {
                this.setState({loading: false});
            }
        });
    };

    getTriggerById = (id) => {
        if (this.state.changes.light_config.playlist.trigger instanceof Array) {
            const trigger = this.state.changes.light_config.playlist.trigger.filter(trig => trig.id === id);
            if (trigger.length > 0) return trigger[0];
        }

        if (this.props.data.light_config.playlist.trigger instanceof Array) {
            const trigger = this.props.data.light_config.playlist.trigger.filter(trig => trig.id === id);
            if (trigger.length > 0) return trigger[0];
        }
        return null;
    };

    deleteScene = () => {
        this.setState({loading: true});
        const cuelist = this.getCuelist();
        const scenario = this.getScenario();
        const overrider_cuelist = this.getCuelist("name", cuelist.name + "-override");

        new Promise((resolve) => {
            this.setState(state => {
                // Remove triggers except for GPIO
                for (const trigger_id of [
                    ...cuelist.trigger,
                    ...cuelist.inversetrigger,
                    ...(overrider_cuelist ? overrider_cuelist.trigger : []),
                    ...(overrider_cuelist ? overrider_cuelist.inversetrigger : [])]) {
                    const trigger = this.getTriggerById(trigger_id);
                    if (trigger && !trigger.gpio) {
                        // Remove trigger
                        setTypeChanges.bind({setState: synchronousSetState.bind({state})})("trigger", {
                            uuid: trigger.uuid,
                            delete: true
                        });
                    }
                }

                // Remove scene
                setTypeChanges.bind({setState: synchronousSetState.bind({state})})("scene", {
                    uuid: scenario.uuid,
                    delete: true
                });

                // Remove cuelist
                setTypeChanges.bind({setState: synchronousSetState.bind({state})})("timeline", {
                    uuid: cuelist.uuid,
                    delete: true
                });
                if (overrider_cuelist) {
                    // Remove overrider cuelist
                    setTypeChanges.bind({setState: synchronousSetState.bind({state})})("timeline", {
                        uuid: overrider_cuelist.uuid,
                        delete: true
                    });
                }

                // Remove palettes
                setTypeChanges.bind({setState: synchronousSetState.bind({state})})("palette", this.getPalettesInScenario()
                    .map(palette => ({
                        uuid: palette.uuid,
                        delete: true
                    })));
            }, resolve);
        }).then(() => {
            this.props.dispatch({
                type: dataActions.SET_LIGHT_CONFIGURATION,
                changes: this.state.changes.light_config,
                callback: this.onClose,
                errorCallback: () => {
                    this.setState({loading: false});
                }
            });
        });
    };

    onChangeSlider = (uuid, value) => {
        if (isNullable(value) || isNaN(value)) {
            return;
        }
        this.setTypeChanges("palette", {
            uuid,
            color: {raw: {value: [parseInt(value)]}}
        }, () => this.doLiveEdit("palette"));
    };

    renderFixture = fixture => {
        const scenario_component = this.getScenarioComponent(fixture.id);
        const palette = this.getPalette(scenario_component.palette);

        let palette_value = 0;
        if (palette && palette.color && palette.color.raw) {
            palette_value = toArray(palette.color.raw.value)[0];
        }

        const fixture_type = this.getFixtureType(fixture);
        return (
            <div key={fixture.uuid} className={`fixture-scene-component ${fixture_type}`}>
                <p className="fixture-name">{fixture["webapp-name"] || fixture.id}</p>
                {this.getFixtureContents(fixture_type)(palette, palette_value)}
            </div>
        );
    };

    getFixtureContents = fixture_type => {
        switch (fixture_type) {
            case "fader":
            default:
                return (palette, palette_value) => (<MMSliderWithInput
                    step={2.55}
                    max={255}
                    value={palette_value}
                    id={palette.uuid}
                    onChange={this.onChangeSlider}
                />);
            case "relay":
                return (palette, palette_value) => (
                    <MMToggle
                        checked={parseInt(palette_value) > 0}
                        onChange={(event, checked) => {
                            this.setTypeChanges("palette", {
                                uuid: palette.uuid,
                                color: {raw: {value: [checked ? 255 : 0]}}
                            }, () => this.doLiveEdit("palette"));
                        }}
                    />);
            case "daylight-control":
                return (palette, palette_value) => (
                    <MMToggle
                        checked={parseInt(palette_value) > 0}
                        onChange={(event, checked) => this.setTypeChanges("palette", {
                            uuid: palette.uuid,
                            color: {raw: {value: [checked ? 34 : 0]}}
                        }, () => this.doLiveEdit("palette"))}
                    />);
        }
    };

    getFixtureType = fixture => fixture["webapp-type"] || fixture["icon-type"] || fixture.type || "fader";

    getFixturePlacement = fixture => {
        if (fixture.placement) {
            return fixture.placement;
        }
        // Old configurations don't have a fixture placement, determine placement from type
        const fixture_type = this.getFixtureType(fixture);
        switch (fixture_type) {
            case "fader":
            default:
                return "center";
            case "relay":
                return "bottom";
            case "daylight-control":
                return "top";
        }
    };

    createNewCuelist() {
        const cuelist_name = uuidv4();
        const cuelist = {
            name: cuelist_name,
            uuid: uuidv4(),
            "webapp-name": "",
            location: this.props.location,
            x: this.props.x,
            y: this.props.y,
            trigger: [cuelist_name, ...(APP_CONFIG.ENABLE_OVERRIDER ? [cuelist_name + "-override-inverse"] : [])],
            inversetrigger: [cuelist_name + "-inverse"],
            priority: APP_CONFIG.PLAYLIST.CUELIST.PRIORITY,
            loop: APP_CONFIG.PLAYLIST.CUELIST.LOOP ? "yes" : "no",
            cue: [{
                uuid: uuidv4(),
                duration: APP_CONFIG.PLAYLIST.CUE.DURATION,
                "fade-time": APP_CONFIG.PLAYLIST.CUE.FADE_TIME,
                scene: cuelist_name
            }]
        };

        // Create overrider timeline
        const overrider_cuelist = APP_CONFIG.ENABLE_OVERRIDER ? createOverrideCuelist(cuelist, APP_CONFIG) : null;

        const palettes = [];
        const scene = {
            name: cuelist_name,
            uuid: uuidv4(),
            "scene-component": this.props.data.light_config.playlist["dmx-fixture"].filter(fixture => fixture.location === this.props.location)
                .map(fixture => ({
                    "dmx-fixture": fixture.id,
                    uuid: uuidv4(),
                    palette: (() => {
                        const palette_id = uuidv4();
                        palettes.push({
                            id: palette_id,
                            uuid: uuidv4(),
                            color: {
                                raw: {
                                    value: [0]
                                }
                            }
                        });
                        return palette_id;
                    })()
                }))
        };
        const triggers = [
            {
                id: cuelist_name,
                uuid: uuidv4()
            },
            {
                id: cuelist_name + "-inverse",
                uuid: uuidv4()
            },
            ...(APP_CONFIG.ENABLE_OVERRIDER ? [
                {
                    id: cuelist_name + "-override",
                    uuid: uuidv4()
                },
                {
                    id: cuelist_name + "-override-inverse",
                    uuid: uuidv4()
                }] : [])
        ];
        return new Promise((resolve) => {
            this.setState(state => {
                state.changes.light_config.playlist.timeline = [cuelist, ...(overrider_cuelist ? [overrider_cuelist] : [])];
                state.changes.light_config.playlist.trigger = triggers;
                state.changes.light_config.playlist.scene = [scene];
                state.changes.light_config.playlist.palette = palettes;
                return state;
            }, resolve);
        });
    }

    renderFixtures = (fixture_placement, location) => {
        if (!this.props.data.light_config || !this.props.data.light_config.playlist
            || !this.props.data.light_config.playlist["dmx-fixture"]) {
            return [];
        }

        const fixtures = this.props.data.light_config.playlist["dmx-fixture"]
            .filter(fixture => fixture.location === location
                && this.getFixturePlacement(fixture) === fixture_placement);

        return fixtures.map(this.renderFixture);
    };

    render() {
        const cuelist = this.getCuelist();
        if (!cuelist) return null;
        const scenario = this.getScenario();
        if (!scenario) return null;
        return (
            <MMModal
                width={900}
                close={this.state.closing}
                closable={!this.state.loading}
                onClose={() => {
                    this.setState({closing: true});
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                }}
                className={`ScenarioEditor ${this.props.className ? this.props.className : ""} ${this.state.loading ? "loading" : ""}`}
                header={
                    [
                        <div className="align-left" key="align-left">
                            <p className="action-title">{`${this.state.new ? "Tilføj" : "Redigér"} scene`}</p>
                            <LoadingOutlined className="loading-icon"/>
                        </div>,
                        <div className="align-right" key="align-right">
                            <MMTextInput
                                value={cuelist["webapp-name"]}
                                width={200}
                                className={this.state.new ? "active" : ""}
                                placeholder="Navngiv scene"
                                onChange={(event, value) => {
                                    this.setTypeChanges("timeline", {
                                        "webapp-name": value,
                                        uuid: cuelist.uuid
                                    });
                                }}
                            />
                        </div>
                    ]
                }
                footer={
                    <div>
                        {!this.state.new
                        && (
                            <MMButton
                                className="delete red"
                                style={{width: 150}}
                                onClick={this.deleteScene}
                                disabled={this.state.loading || cuelist["webapp-locked"] === "yes"}
                            >
                                Slet scene
                            </MMButton>)}
                        <div className="align-right">
                            <MMButton
                                className="cancel grey"
                                style={{width: 200}}
                                onClick={this.onClose}
                                disabled={this.state.loading}
                            >
                                Annullér
                            </MMButton>
                            <MMButton
                                className="submit"
                                style={{width: 200}}
                                disabled={Object.entries(this.state.changes.light_config.playlist).length <= 0 || this.state.loading}
                                onClick={this.submit}
                            >
                                Gem ændringer
                            </MMButton>
                        </div>
                    </div>
                }
            >
                <Row>
                    <div className="align-top">
                        {this.renderFixtures("top", cuelist.location)}
                    </div>
                    <div className="align-center">
                        {this.renderFixtures("center", cuelist.location)}
                    </div>
                    <div className="align-bottom">
                        {this.renderFixtures("bottom", cuelist.location)}
                    </div>
                </Row>
            </MMModal>
        );
    }
}

const mapStateToProps = state => ({
    data: state.data,
    websocket: state.websocket
});
export default connect(mapStateToProps)(ScenarioEditor);
