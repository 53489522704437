import React from "react";
import {ReactSVG} from "react-svg";
import {connect} from "react-redux";
import {Divider} from "antd";
import icoRemove from "../../../assets/ico-remove.svg";
import icoEdit from "../../../assets/ico-edit.svg";
import icoAddSmall from "../../../assets/ico-add-scenario-small.svg";
import "./ScenesHeader.less";
import MMSelect from "../../../elements/inputs/MMSelect";
import {APP_CONFIG} from "../../../config";
import {uuidv4} from "../../../providers/data/dataMiddleware";

class ScenesHeader extends React.PureComponent {
    onSceneChange = uuid => {
        if (this.props.onSceneChange) {
            this.props.onSceneChange(uuid);
        }
    };

    addScene = () => {
        const uuid = uuidv4();
        this.props.addScene(uuid);
    };

    renameScene = () => {
        this.props.renameScene(this.props.uuid);
    };

    deleteScene = () => {
        this.props.deleteScene(this.props.uuid);
    };

    render() {
        const scenes = this.props.scenes;
        const selected_scene = scenes.find(e => e.uuid === this.props.uuid);
        return (
            <div className="ScenesHeader">
                <MMSelect
                    className="block"
                    options={scenes
                        .filter(scene => scene["webapp-hidden"] !== "yes" && !(APP_CONFIG.SCENARIOS instanceof Array))
                        .map(scene => ({
                            key: scene.uuid,
                            value: scene["webapp-name"] || scene.name
                        }))}
                    onChange={this.onSceneChange}
                    value={selected_scene && (selected_scene["webapp-name"] || selected_scene.name)}
                />
                <div className="buttons">
                    <button
                        type="button"
                        className={`menu-item ${this.props.active_action === "delete" ? "active" : ""}`}
                        onClick={this.props.deleteFixtures}
                        disabled={this.props.marked_elements_length === 0 || !selected_scene}
                    >
                        <ReactSVG src={icoRemove} beforeInjection={svg => svg.classList.add("icon-remove-svg")} className="icon icon-remove"/>
                        <p>
                            Slet armatur
                            <span style={{opacity: this.props.marked_elements_length !== 1 ? 1 : 0}}>er</span>
                        </p>
                    </button>
                    <button
                        type="button"
                        className={`menu-item ${this.props.active_action === "delete" ? "active" : ""}`}
                        onClick={this.props.editFixtures}
                        disabled={this.props.marked_elements_length === 0 || !selected_scene}
                    >
                        <ReactSVG src={icoEdit} beforeInjection={svg => svg.classList.add("icon-edit-svg")} className="icon icon-edit"/>
                        <p>
                            Redigér armatur
                            <span style={{opacity: this.props.marked_elements_length !== 1 ? 1 : 0}}>er</span>
                        </p>
                    </button>
                    <Divider/>
                    <button
                        type="button"
                        className={`menu-item ${this.props.active_action === "delete" ? "active" : ""}`}
                        onClick={this.deleteScene}
                        disabled={!selected_scene}
                    >
                        <ReactSVG src={icoRemove} beforeInjection={svg => svg.classList.add("icon-remove-svg")} className="icon icon-remove"/>
                        <p>
                            Slet scene
                        </p>
                    </button>
                    <button
                        type="button"
                        className={`menu-item ${this.props.active_action === "rename" ? "active" : ""}`}
                        onClick={this.renameScene}
                        disabled={!selected_scene}
                    >
                        <ReactSVG src={icoEdit} beforeInjection={svg => svg.classList.add("icon-edit-svg")} className="icon icon-edit"/>
                        <p>
                            Omdøb scene
                        </p>
                    </button>
                    <button
                        type="button"
                        className="menu-item"
                        onClick={this.addScene}
                    >
                        <ReactSVG src={icoAddSmall} beforeInjection={svg => svg.classList.add("icon-add-svg")} className="icon icon-add"/>
                        <p>Tilføj scene</p>
                    </button>
                </div>
            </div>);
    }
}

const mapStateToProps = state => ({
    data: state.data
});

export default connect(mapStateToProps)(ScenesHeader);
