import React from "react";
import {connect} from "react-redux";
import moment from "moment";
import ControllerTimeController from "../../controllers/ControllerTimeController";

class ControllerCurrentTime extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentTime: "",
        };
        this.controllerTime = new ControllerTimeController(this.onTimeChange, 1000, this.props.dispatch);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.time !== this.props.data.time || prevProps.data.time_updated_at !== this.props.data.time_updated_at) {
            this.controllerTime.update(this.props.data.time, this.props.data.time_updated_at);
        }
    }

    componentWillUnmount() {
        this.stop();
    }

    onTimeChange = date_time => this.setState({
        currentTime: moment(date_time)
            .format("DD-MM-YYYY HH:mm")
    });

    start = () => {
        this.controllerTime.start();
        this.controllerTime.startPoll();
    }

    stop = () => this.controllerTime.stop();

    render() {
        return this.state.currentTime;
    }
}

const mapStateToProps = state => ({
    data: state.data
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(ControllerCurrentTime);
