import {CALCULATE_RESPONSIVE_STATE} from "redux-responsive";

export default function layoutReducer(state = {}, action) {
    switch (action.type) {
        case CALCULATE_RESPONSIVE_STATE: {
            const notifications = document.getElementsByClassName("ant-notification")[0];
            if (notifications) {
                notifications.style.marginTop = `${window.scrollY}px`;
            }
            break;
        }
        default:
            break;
    }
    return state;
}
