import React from "react";
import "./Login.less";
import connect from "react-redux/es/connect/connect";
import {ReactSVG} from "react-svg";
import {loginActions} from "../../providers/login/loginActions";
import Watermark from "../../elements/layout/Watermark";
import MMCard from "../../elements/layout/MMCard";
import {APP_CONFIG} from "../../config";
import MMLoginBox from "../../elements/layout/MMLoginBox";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggingIn: false
        };
    }

    componentDidMount() {
        document.body.classList.add("login");
    }

    componentWillUnmount() {
        document.body.classList.remove("login");
    }

    doLogin = data => {
        if (this.state.idle) return; // Screensaver is shown
        this.setState({loggingIn: true});
        this.props.dispatch({
            type: loginActions.SEND_CREDENTIALS,
            ...data,
            callback: () => this.setState({loggingIn: false}),
            errorCallback: this.resetFields
        });
    };

    resetFields = () => {
        if (this.loginBoxRef) {
            this.setState({loggingIn: false});
            this.loginBoxRef.resetFields();
        }
    };

    render() {
        let brand_logo = APP_CONFIG.BRAND_LOGO;
        // Support old brand logo format
        if (!(brand_logo instanceof Object)) {
            brand_logo = {img: brand_logo};
        }

        return (
            <div className="login-page">
                <div className="login-brand-logo-container" style={brand_logo.style}>
                    {(() => {
                        if (brand_logo.img.includes(".svg")) {
                            return <ReactSVG
                                src={brand_logo.img}
                                alt=""
                                className="login-brand-logo"
                                beforeInjection={svg => svg.classList.add("login-brand-logo-svg", brand_logo.id)}
                            />;
                        }
                        return <img src={brand_logo.img} alt="" className="login-brand-logo"/>;
                    })()}
                </div>
                {APP_CONFIG.PRODUCT_NAME && <p className="product-name">{APP_CONFIG.PRODUCT_NAME}</p>}
                <MMCard height={320}>
                    <MMLoginBox
                        sub_title={this.props.data.system_config && this.props.data.system_config.name && this.props.data.system_config.name[0]}
                        submit={this.doLogin}
                        loading={this.state.loggingIn}
                        type={this.props.login.auth_type}
                        getRef={ref => {
                            this.loginBoxRef = ref;
                        }}
                    />
                </MMCard>
                <Watermark/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    login: state.login,
    browser: state.browser,
    data: state.data
});

export default connect(mapStateToProps)(Login);
