import {dataActions} from "../providers/data/dataActions";

export default class ControllerTimeController {
    constructor(onTimeChange, calculateInterval = 1000, dispatch = null) {
        this.onTimeChange = onTimeChange;
        this.calculateInterval = calculateInterval;
        this.dispatch = dispatch;
    }

    start = () => {
        this.calculateControllerTime();
        clearInterval(this.currentTimeTimer);
        this.currentTimeTimer = setInterval(this.calculateControllerTime, this.calculateInterval);
    };

    startPoll = () => {
        this.pollControllerTimeTimer = setInterval(this.pollControllerTime, 30000);
    };

    update = (time, time_updated_at) => {
        this.time = time;
        this.time_updated_at = time_updated_at;
        this.calculateControllerTime();
    };

    stop = () => {
        clearInterval(this.currentTimeTimer);
        clearInterval(this.pollControllerTimeTimer);
    };

    calculateControllerTime = () => {
        const controller_time = new Date(this.time * 1000);
        const seconds_since_controller_time_update = Date.now() / 1000 - this.time_updated_at;
        controller_time.setSeconds(controller_time.getSeconds() + seconds_since_controller_time_update);
        const epoch_time = controller_time.getTime() / 1000;
        if (!isNaN(epoch_time)) {
            this.onTimeChange(controller_time);
        }
    };

    pollControllerTime = () => {
        if (this.dispatch) {
            this.dispatch({type: dataActions.GET_CONTROLLER_TIME});
        }
    };
}
