import React from "react";
import MMSlider from "./MMSlider";
import MMTextInput from "./MMTextInput";
import {isNullable} from "../../providers/objectsAreEqual";
import "./MMSliderWithInput.less";

export default class MMSliderWithInput extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: !isNullable(this.props.value) ? this.props.value : 0
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }

    onChange = (value) => {
        if (isNullable(value) || isNaN(value)) {
            return;
        }
        this.setState({value});
        if (this.props.onChange) {
            this.props.onChange(this.props.id, value);
        }
    };

    getMax = () => this.props.max || 100;

    onChangeText = (event, value) => {
        this.onChange(Math.round(parseInt(value, 10) / 100 * this.getMax()));
    };

    render() {
        const value_percent = Math.round(this.state.value / this.getMax() * 100);
        return (
            <div className="MMSliderWithInput">
                <MMSlider
                    min={this.props.min || 0}
                    max={this.props.max || 100}
                    step={this.props.step || 2.55}
                    width={this.props.width || 225}
                    value={this.state.value}
                    onChange={this.onChange}
                />
                <MMTextInput
                    type="number"
                    className="value-percentage"
                    value={value_percent}
                    step={5}
                    min={0}
                    max={100}
                    onChange={this.onChangeText}
                />
                <p className="value-percentage">%</p>
            </div>);
    }
}
