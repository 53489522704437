import React from "react";
import "./MMTimePicker.less";
import moment from "moment";
import isAlphaNumeric from "../../providers/isAlphaNumeric";
import MMDatePicker from "./MMDatePicker";

export default class MMTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = MMDatePicker.getNewStateFromProps(props);
    }

    reset = () => {
        this.setState(MMDatePicker.getNewStateFromProps(this.props));
    };

    onFocus = () => {
        // Show cursor immediately
        this.setState({
            cursor: (
                <span
                    key="cursor"
                    style={{
                        position: "initial",
                        width: 0,
                        marginLeft: -5.3 / 2,
                        marginRight: -5.3 / 2
                    }}
                >
                |
                </span>)
        });
        this.cursorBlinkerInterval = setInterval(() => {
            this.setState(state => {
                if (state.cursor !== "") {
                    state.cursor = "";
                } else {
                    state.cursor = (
                        <span
                            key="cursor"
                            style={{
                                position: "initial",
                                width: 0,
                                marginLeft: -5.3 / 2,
                                marginRight: -5.3 / 2
                            }}
                        >
                        |
                        </span>);
                }
                return state;
            });
        }, 500);
    };

    onBlur = () => {
        clearInterval(this.cursorBlinkerInterval);
        this.setState({cursor: ""});
    };

    onDateInputChange = (event) => {
        let rawValue = event.target.value.replace(/\D/g, '');

        if (rawValue[0] === "2" && rawValue[1] === "4") {
            // Replace 24 with 00
            rawValue = "00" + rawValue.slice(2);
        }

        if (rawValue.length === 1 && parseInt(rawValue) > 2) {
            // First digit in hour is bigger than max, move digit to the right
            rawValue = "0" + rawValue;
        }

        if (rawValue.length === 3 && parseInt(rawValue[2]) > 5) {
            // First digit in minute is bigger than max, move digit to the right
            rawValue = rawValue.slice(0, 2) + "0" + rawValue[2];
        }

        let formatted_date = "";
        let input_val_index = 0;
        let cursor_inserted = false;
        for (let i = 0; i < this.props.format.length; i++) { // Insert input
            if (rawValue.length > input_val_index && isAlphaNumeric(this.props.format.charAt(i))) {
                formatted_date += rawValue.charAt(input_val_index);
                input_val_index++;
            } else {
                if (rawValue.length === input_val_index) {
                    input_val_index++;
                    formatted_date += "\u2063"; // Cursor location
                    cursor_inserted = true;
                }
                formatted_date += this.props.format.charAt(i);
            }
        }

        let value = "";
        const moment_date = moment(formatted_date, this.props.format);

        if (!cursor_inserted) {
            formatted_date += "\u2063"; // Cursor location
        }

        const valid = moment_date.isValid() && rawValue.length === 4;

        if (this.props.onDateInputChange) this.props.onDateInputChange(event, value, valid);

        if (moment_date.isValid() || rawValue.length === 0 || rawValue.substr(-1) === "0") {
            if (rawValue.length === this.props.format.replace(/[\W_]+/g, "").length) { // Value is empty until date is complete
                value = moment_date.format("HH:mm");
            }

            if (this.props.onChange) this.props.onChange(event, value);
            this.setState({
                formatted_date,
                rawValue
            });
        }
    };

    renderFormattedDate = () => {
        const formatted_data_split = this.state.formatted_date.split("\u2063");
        if (formatted_data_split.length === 1) {
            return [this.state.cursor, formatted_data_split[0]];
        }
        if (formatted_data_split.length > 1) {
            return [formatted_data_split[0], this.state.cursor, formatted_data_split[1]];
        }
        return [this.state.cursor];
    };

    render() {
        return (
            <div className={this.props.error ? "MMTimePicker error" : "MMTimePicker"}>
                <div className="input-group">
                    <input
                        onChange={this.onDateInputChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSelect={(event) => { // Keep cursor at end
                            // noinspection JSUnresolvedFunction
                            event.target.setSelectionRange(event.target.value.length, event.target.value.length);
                        }}
                        id={this.props.id}
                        disabled={this.props.disabled}
                        value={this.state.rawValue}
                        maxLength={this.props.format.replace(/[\W_]+/g, "").length}
                    />
                    <span>{this.renderFormattedDate()}</span>
                </div>
            </div>
        );
    }
}

MMTimePicker.getDerivedStateFromProps = MMDatePicker.getDerivedStateFromProps;
MMTimePicker.defaultProps = {
    format: "HH:mm",
    data_format: "HH:mm"
};
