import React from "react";
import {connect} from "react-redux";
import "./Screensaver.less";
import moment from "moment";
import {CSSTransition} from "react-transition-group";
import Watermark from "../../elements/layout/Watermark";
import {websocketActions} from "../../providers/websocket/websocketActions";
import {dataActions} from "../../providers/data/dataActions";
import constants from "../../less/motomuto_ras/constants";
import {APP_CONFIG} from "../../config";
import Cuelist, {CuelistState} from "../Light/Cuelist/Cuelist";
import getActiveScenarios from "../../providers/getActiveScenarios";
import getActiveCuelists, {getHighestPriorityCuelist} from "../../providers/getActiveCuelists";
import default_scene_icon from "../../assets/ico-fader.svg";
import ControllerTimeController from "../../controllers/ControllerTimeController";

class Screensaver extends React.Component {
    constructor(props) {
        super(props);

        this.props.dispatch({
            type: dataActions.GET_LIGHT_CONFIGURATION,
            callback: (action) => {
                if (action.config && action.config.playlist && action.config.playlist.timeline
                    && action.config.playlist.timeline.length > 0) {
                    // Only connect if data exists, otherwise websocket server is closed
                    this.props.dispatch({
                        type: websocketActions.WEBSOCKET_CONNECT
                    });
                }
            }
        });
        this.props.dispatch({type: dataActions.GET_SYSTEM_CONFIGURATION});
        // Always register variables, as they will be saved by websocket provider
        this.props.dispatch({
            type: websocketActions.WEBSOCKET_REGISTER_VARIABLE,
            variable: "cuelists"
        });

        this.controllerTime = new ControllerTimeController(this.onTimeChange, 1000, this.props.dispatch);
    }

    componentDidMount() {
        this.props.dispatch({type: dataActions.GET_CONTROLLER_TIME});
        this.controllerTime.start();
        this.controllerTime.startPoll();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.time !== this.props.data.time || prevProps.data.time_updated_at !== this.props.data.time_updated_at) {
            this.controllerTime.update(this.props.data.time, this.props.data.time_updated_at);
        }
    }

    componentWillUnmount() {
        this.controllerTime.stop();
    }

    onTimeChange = date_time => this.setState({
        currentTime: moment(date_time)
            .format("HH:mm"),
        currentDate: moment(date_time)
            .format("DD / MM / YYYY")
    });

    createCuelists = location => {
        const active_scenarios = getActiveScenarios.bind(this)();
        let scenarios = [];
        if (this.props.data.light_config
            && this.props.data.light_config.playlist
            && this.props.data.light_config.playlist.scene instanceof Array
            && this.props.data.light_config.playlist.timeline instanceof Array) {
            scenarios = this.props.data.light_config.playlist.timeline.filter(cuelist => (!location || cuelist.location === location.location)
                && cuelist.cue && cuelist.cue.find(cue => active_scenarios.find(scenario => scenario.name === cue.scene)))
                .map(cuelist => (
                    <Cuelist
                        displayName={cuelist["webapp-name"] || cuelist.name}
                        color={location && location.colors ? location.colors[location.maxX * parseInt(cuelist.y) + parseInt(cuelist.x)] : null}
                        icon={location && location.iconScene ? location.iconScene : default_scene_icon}
                        key={cuelist.uuid}
                        location={location && location.location}
                        state={getActiveCuelists.bind(this)()
                            .findIndex(c => c.name === cuelist.name + "-override") >= 0 ? CuelistState.override : CuelistState.on}
                        readonly
                    />
                ));
        }
        return scenarios;
    };

    render() {
        const highest_priority_cuelist = getHighestPriorityCuelist.bind(this)();
        return (
            <div className="ScreensaverPage">
                <CSSTransition
                    classNames="screensaver"
                    mountOnEnter={false}
                    unmountOnExit
                    in={!this.props.hidden}
                    timeout={parseFloat(constants["modal-transition-duration"])}
                >
                    <div className="content">
                        <p className="time">{this.state && this.state.currentTime}</p>
                        <p className="date">{this.state && this.state.currentDate}</p>
                        {this.props.data.system_config && this.props.data.system_config.name && this.props.data.system_config.name[0]
                        && <p className="device-name">{this.props.data.system_config.name[0]}</p>}
                        <div className="current-scenarios">
                            {APP_CONFIG.SCENARIOS
                                ? APP_CONFIG.SCENARIOS.map(location => (
                                    <div
                                        className="cuelist-container"
                                        key={location.location}
                                    >
                                        <p className="location-title">{location.title}</p>
                                        {this.createCuelists(location)}
                                    </div>))
                                : this.createCuelists(null)}
                        </div>
                        {((highest_priority_cuelist && highest_priority_cuelist["webapp-screensaver-message"])
                            || (APP_CONFIG.SCREENSAVER && APP_CONFIG.SCREENSAVER.DEFAULT_MESSAGE))
                        && (
                            <p
                                className="message"
                                style={{
                                    color: (highest_priority_cuelist && highest_priority_cuelist["webapp-screensaver-message-color"])
                                        || (APP_CONFIG.SCREENSAVER && APP_CONFIG.SCREENSAVER.DEFAULT_MESSAGE_COLOR)
                                }}
                            >
                                {(highest_priority_cuelist && highest_priority_cuelist["webapp-screensaver-message"])
                                || (APP_CONFIG.SCREENSAVER && APP_CONFIG.SCREENSAVER.DEFAULT_MESSAGE)}
                            </p>)}
                        <Watermark/>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    websocket: state.websocket,
    data: state.data
});

export default connect(mapStateToProps)(Screensaver);
