import Cookies from "universal-cookie";
import moment from "moment";
import {loginActions} from "./loginActions";
import reducerUpdater from "../reducerUpdater";
import {notificationsActions} from "../notifications/notificationsActions";

const delete_token = () => {
    (new Cookies()).remove("token", {path: '/'});
};

const set_token = token => {
    const C = new Cookies();
    C.remove("token", {path: '/'});
    C.set("token", token, {
        path: '/',
        expires: moment()
            .year(moment()
                .year() + 2)
            .toDate()
    });
};

export default function loginReducer(state = {}, action) {
    switch (action.type) {
        case loginActions.SEND_CREDENTIALS: {
            set_token(action.token);
            const filtered_action = {...action};
            delete filtered_action.callback;
            delete filtered_action.errorCallback;
            delete filtered_action.type;
            delete filtered_action.password;
            delete filtered_action.pincode;
            return reducerUpdater(state, {
                loggedIn: true,
                ...filtered_action
            });
        }
        case loginActions.EXPIRED_TOKEN:
        case loginActions.DO_SIGNOUT:
            if (state.auth_type === "none") return state;
            delete_token();
            return reducerUpdater(state, {
                loggedIn: false,
                token: null,
                username: null
            });
        case notificationsActions.CONNECTION_FAILED:
            if (state.auth_type !== "client-pin") return state;

            return reducerUpdater(state, {
                loggedIn: false,
                token: null,
                username: null
            });
        case loginActions.INVALID_PIN_CODE:
        case loginActions.INVALID_CREDENTIALS:
            if (state.auth_type === "none") return state;
            return reducerUpdater(state, {
                loggedIn: false,
                token: null,
                username: null
            });
        case loginActions.AUTHENTICATE:
            return reducerUpdater(state, {
                loggedIn: action.loggedIn,
                token: action.token,
                username: action.username,
                properties: action.properties,
                auth_type: action.auth_type
            });
        case loginActions.SET_CURRENT_USER:
            return reducerUpdater(state, {
                loggedIn: !!action.username,
                username: action.username
            });
        default:
            return state;
    }
}
