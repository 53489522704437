import _ from "lodash";
import reducerUpdater from "../reducerUpdater";
import {websocketActions} from "./websocketActions";

export default function websocketReducer(state = {}, action) {
    switch (action.type) {
        case websocketActions.WEBSOCKET_VARIABLE_UPDATE: {
            const mutations = {[action.key]: action.value};
            if (action.url) {
                mutations[action.url] = reducerUpdater(state[action.url], {[action.key]: action.value});
            }
            return reducerUpdater(state, mutations);
        }
        case websocketActions.WEBSOCKET_OPENED:
            return reducerUpdater(state, {[action.url]: {connected: true}});
        case websocketActions.WEBSOCKET_CONNECTION_FAILED:
        case websocketActions.WEBSOCKET_DISCONNECT:
        case websocketActions.WEBSOCKET_CLOSED: {
            if (action.url) {
                const new_state = _.cloneDeep(state);
                delete new_state[action.url];
                return new_state;
            }
            return {};
        }
        default:
            return state;
    }
}
