// Functions for drawing on a HTML5 canvas

import React from "react";
import memoize from "memoize-one";
import {toArray} from "../../providers/toArray";
import {isNullable} from "../../providers/objectsAreEqual";
import ignoreErrors from "../../providers/ignoreErrors";
import "./MMCanvas.less";

export default class MMCanvas extends React.PureComponent {
    static calculate_graph_points = memoize((graph_functions, min_x, max_x, resolution, offset_x = 0, offset_y = 0) => {
        const array_length = Math.ceil(max_x - min_x + 1);
        if (array_length <= 0 || !MMCanvas.real_number(array_length)) {
            return [];
        }

        const x_coords = [...Array(array_length)
            .keys()].filter((x, i) => i % resolution === 0)
            .map(x => x + min_x);

        return toArray(graph_functions)
            .map(graph_function => {
                const coords = x_coords.map(x => {
                    let y = NaN;
                    if (typeof graph_function === "function") {
                        y = ignoreErrors(graph_function, x);
                    } else if (graph_function instanceof Array && graph_function.length === 2) {
                        if (graph_function[0] === x) {
                            y = graph_function[1];
                        }
                    }
                    if (!MMCanvas.real_number(y)) {
                        y = NaN;
                    }
                    return ({
                        x: x + offset_x,
                        y: y + offset_y
                    });
                });
                // Flip x coordinates array for each graph function, in order to avoid
                x_coords.reverse();
                return coords;
            })
            .flat()
            .filter(coords => MMCanvas.real_number(coords.y));
    });

    static calculate_graph_limits = memoize((graph_functions, begin_x = -5000, end_x = 5000, resolution = 1) => {
        let max_y;
        let min_y;
        let max_x;
        let min_x;
        const graph_points = MMCanvas.calculate_graph_points(graph_functions, begin_x, end_x, resolution);

        if (graph_points.length > 0) {
            min_x = Math.min(...graph_points.map(coords => coords.x));
            max_x = Math.max(...graph_points.map(coords => coords.x));
            min_y = Math.min(...graph_points.map(coords => coords.y));
            max_y = Math.max(...graph_points.map(coords => coords.y));
        }

        if (!MMCanvas.real_number(min_x)) min_x = 0;
        if (!MMCanvas.real_number(max_x)) max_x = 0;
        if (!MMCanvas.real_number(min_y)) min_y = 0;
        if (!MMCanvas.real_number(max_y)) max_y = 0;

        return {
            min_x,
            max_x,
            min_y,
            max_y
        };
    });

    static calculate_total_distance = memoize(graph_points => {
        // Calculate the total distance between a set of graph coordinates
        let distance = 0;
        for (let i = 1; i < graph_points.length; i++) {
            distance += MMCanvas.calculate_distance_between_points(graph_points[i - 1], graph_points[i]);
        }
        return distance;
    });

    static calculate_element_coordinates = memoize((graph_properties, graph_functions, num_of_elements,
        elements_start_offset = 0, resolution = 1) => {
        const graph_points = MMCanvas.calculate_graph_points(graph_functions, graph_properties.min_x, graph_properties.max_x,
            resolution, graph_properties.offset_x, graph_properties.offset_y);
        if (graph_points.length <= 1) {
            // We can't plot any elements, because there's too few graph points
            return;
        }

        let previous_graph_point_index = -1;
        let previous_graph_point = {
            x: graph_points[0].x,
            y: graph_points[0].y
        };
        const total_pixel_distance = MMCanvas.calculate_total_distance(graph_points);
        const pixel_distance_between_elements = total_pixel_distance / num_of_elements;

        return [...Array(num_of_elements)
            .keys()].map(() => {
            let graph_point = null;
            let graph_point_index = -1;
            let distance = 0;
            let minimum_distance = pixel_distance_between_elements;
            let j = previous_graph_point_index;
            while (!graph_point) {
                j++;
                if (j === 0) {
                    minimum_distance = elements_start_offset;
                } else if (j >= graph_points.length) {
                    j = 1;
                    previous_graph_point_index = j - 1;
                    previous_graph_point = graph_points[previous_graph_point_index];
                }
                distance += MMCanvas.calculate_distance_between_points(previous_graph_point, graph_points[j]);

                if (distance < minimum_distance) {
                    minimum_distance -= distance;
                    distance = 0;
                    previous_graph_point = graph_points[j];
                    previous_graph_point_index = j;
                } else {
                    graph_point_index = j;
                    graph_point = graph_points[graph_point_index];

                    if (distance > minimum_distance) {
                        // Calculate a point on the line with the exact distance
                        const a = (previous_graph_point.y - graph_point.y) / (previous_graph_point.x - graph_point.x);

                        let distance_x = 0;
                        let distance_y = minimum_distance;
                        if (a !== Infinity && a !== -Infinity) {
                            distance_x = minimum_distance / Math.sqrt(a ** 2 + 1);
                            distance_y = a * distance_x;
                        }

                        let x = previous_graph_point.x + distance_x;
                        if (!(previous_graph_point.x <= x && x <= graph_point.x)
                            && !(graph_point.x <= x && x <= previous_graph_point.x)) {
                            // fixture coordinates are not located on graph function
                            distance_x *= -1;
                            x = previous_graph_point.x + distance_x;
                        }

                        let y = previous_graph_point.y + distance_y;

                        if (!(previous_graph_point.y <= y && y <= graph_point.y)
                            && !(graph_point.y <= y && y <= previous_graph_point.y)) {
                            // fixture coordinates are not located on graph function
                            distance_y *= -1;
                            y = previous_graph_point.y + distance_y;
                        }

                        graph_point = {
                            x,
                            y
                        };
                        graph_point_index = j - 1;
                    }
                }
            }

            previous_graph_point_index = graph_point_index;
            previous_graph_point = graph_point;

            return {
                x: graph_point.x / graph_properties.scale,
                y: graph_point.y / graph_properties.scale
            };
        })
            .filter(el => el);
    });

    static calculate_graph_properties = memoize((graph_functions, canvas_height, canvas_width, center_x, center_y,
        begin_x = -5000, end_x = 5000, resolution = 1) => {
        const {
            min_x,
            max_x,
            min_y,
            max_y
        } = MMCanvas.calculate_graph_limits(graph_functions, begin_x, end_x, resolution);
        // Calculate the height and width of the graph
        const height = Math.abs(max_y - min_y);
        const width = Math.abs(max_x - min_x);

        const height_scale = height / canvas_height;
        const width_scale = width / canvas_width;
        let scale = width_scale;
        if (height_scale > width_scale) {
            scale = height_scale;
        }

        scale *= 1.1; // add padding

        // Calculate offsets, so the function will be drawed with a center in center_x, center_y
        let offset_x = 0;
        let offset_y = 0;

        if (!isNullable(center_x)) {
            offset_x = center_x * scale - min_x - width / 2;
        } else if (min_x < 0) {
            offset_x = -min_x;
        }
        if (!isNullable(center_y)) {
            offset_y = center_y * scale - min_y - height / 2;
        } else if (min_y < 0) {
            offset_y = -min_y;
        }

        return {
            offset_x,
            offset_y,
            min_x,
            max_x,
            min_y,
            max_y,
            height,
            width,
            scale
        };
    });

    static calculate_distance_between_points = (point_a, point_b) => {
        if (!point_a || !point_b) {
            return 0;
        }
        const distance_x = point_a.x - point_b.x;
        const distance_y = point_a.y - point_b.y;
        return Math.sqrt(distance_x ** 2 + distance_y ** 2);
    };

    static real_number = number => isFinite(number) && number !== false && !isNullable(number);

    canvas = null;

    ctx = null;

    calculate_element_coordinates_on_graph = memoize((graph_functions, num_of_elements, center_x = null, center_y = null,
        elements_start_offset = 0, begin_x = -5000, end_x = 5000, resolution = 1) => {
        const canvas_height = this.canvas.getBoundingClientRect().height;
        const canvas_width = this.canvas.getBoundingClientRect().width;
        const graph_properties = MMCanvas.calculate_graph_properties(graph_functions, canvas_height, canvas_width,
            center_x, center_y, begin_x, end_x, resolution);
        return MMCanvas.calculate_element_coordinates(graph_properties, graph_functions, num_of_elements, elements_start_offset, resolution);
    });

    arrow = (fromx, fromy, center_x, center_y, r) => {
        if (!this.ctx) {
            return;
        }
        this.ctx.beginPath();
        let angle = Math.atan2(center_y - fromy, center_x - fromx);
        let x = r * Math.cos(angle) + center_x;
        let y = r * Math.sin(angle) + center_y;
        this.ctx.moveTo(x, y);

        for (let i = 0; i < 2; i++) {
            angle += (1 / 3) * (2 * Math.PI);
            x = r * Math.cos(angle) + center_x;
            y = r * Math.sin(angle) + center_y;
            this.ctx.lineTo(x, y);
        }

        this.ctx.closePath();
        this.ctx.fill();
    };

    line = (fromx, fromy, tox, toy) => {
        if (!this.ctx) {
            return;
        }
        // Draw the line
        this.ctx.beginPath();
        this.ctx.moveTo(fromx, fromy);
        this.ctx.lineTo(tox, toy);
        this.ctx.stroke();
    };

    rectangle = (center_x, center_y, size) => {
        if (!this.ctx) {
            return;
        }
        this.ctx.fillRect(center_x - size / 2, center_y - size / 2, size, size);
    };

    circle = (center_x, center_y, radius) => {
        if (!this.ctx) {
            return;
        }
        this.ctx.beginPath();
        this.ctx.arc(center_x, center_y, radius, 0, 2 * Math.PI);
        this.ctx.stroke();
    };

    text = (text, center_x, center_y, size = 12) => {
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.font = size + "px sans-serif";
        this.ctx.fillText(text, center_x, center_y, size);
    };

    clear = () => {
        if (this.ctx) {
            if (this.props.transparent) {
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            } else {
                this.ctx.fillStyle = "white";
                this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
            }
        }
    };

    draw_graph = (graph_functions, center_x = null, center_y = null, begin_x = -5000, end_x = 5000, resolution = 1) => {
        if (!this.ctx) {
            return;
        }

        const canvas_height = this.canvas.getBoundingClientRect().height;
        const canvas_width = this.canvas.getBoundingClientRect().width;
        const graph_properties = MMCanvas.calculate_graph_properties(graph_functions, canvas_height, canvas_width,
            center_x, center_y, begin_x, end_x, resolution);

        const graph_points = MMCanvas.calculate_graph_points(graph_functions, graph_properties.min_x, graph_properties.max_x,
            resolution, graph_properties.offset_x, graph_properties.offset_y);
        if (graph_points.length === 0) {
            return;
        }

        this.ctx.beginPath();
        this.ctx.moveTo(graph_points[0].x / graph_properties.scale, graph_points[0].y / graph_properties.scale);
        for (const graph_point of graph_points) {
            this.ctx.lineTo(graph_point.x / graph_properties.scale, graph_point.y / graph_properties.scale);
        }
        this.ctx.stroke();
    };

    paint_grid = () => {
        for (let x = this.props.grid_padding; x <= this.canvas.width; x += this.props.grid_size) {
            this.ctx.moveTo(x, 0);
            this.ctx.lineTo(x, this.canvas.height);
        }

        for (let y = this.props.grid_padding; y <= this.canvas.height; y += this.props.grid_size) {
            this.ctx.moveTo(0, y);
            this.ctx.lineTo(this.canvas.width, y);
        }
        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = "black";
        this.ctx.stroke();
    };

    render() {
        return (
            <div className="MMCanvas" style={this.props.style}>
                <canvas
                    className={this.props.className}
                    height={this.props.height}
                    width={this.props.width}
                    onClick={this.props.onClick}
                    onMouseDown={this.props.onMouseDown}
                    onMouseUp={this.props.onMouseUp}
                    onMouseMove={this.props.onMouseMove}
                    onWheel={this.props.onWheel}
                    onCopy={this.props.onCopy}
                    onPaste={this.props.onPaste}
                    contentEditable={this.props.contentEditable}
                    ref={canvas_ref => {
                        this.canvas = canvas_ref;
                        this.ctx = canvas_ref ? canvas_ref.getContext("2d", {alpha: this.props.transparent}) : null;
                    }}
                />
            </div>
        );
    }
}

MMCanvas.defaultProps = {
    className: "",
    grid_padding: 0,
    grid_size: 10,
    transparent: false
};
