import React from "react";
import "./Watermark.less";
import {ReactSVG} from "react-svg";
import {APP_CONFIG} from "../../config";
import {uuidv4} from "../../providers/data/dataMiddleware";
import {toArray} from "../../providers/toArray";

// eslint-disable-next-line react/display-name
const Watermark = React.memo(() => (
    <div className="Watermarks">
        {toArray(APP_CONFIG.WATERMARK)
            .map(watermark => (
                <div
                    key={watermark.id || uuidv4()}
                    className={"WatermarkContainer " + watermark.class || ""}
                    style={watermark.style}
                >
                    {watermark.text_before ? <p className="WatermarkTextBefore">{watermark.text_before}</p> : null}
                    {(() => {
                        if (watermark.img.includes(".svg")) {
                            return <ReactSVG
                                src={watermark.img}
                                alt=""
                                className="Watermark"
                                beforeInjection={svg => svg.classList.add("watermark-svg", ...watermark.id.split(" "))}
                            />;
                        }
                        return <img src={watermark.img} alt="" className="Watermark"/>;
                    })()}
                </div>))}
    </div>));

export default Watermark;
