// The new Playback Controller + Remote Access Server API that supports authentication

export const api_v2 = {
    VERSION: 2,
    AUTHENTICATION: {
        GET_TOKEN: "/api/get/token",
        GET_USER_INFO: "/api/get/user",
        GET_AUTHENTICATION_TYPE: "/api/get/auth_type",
        LOGOUT: "/api/logout"
    },
    TIME_CONFIGURATION: {
        GET: "/api/get/time",
        SET: "/api/set/time"
    },
    LIGHT_CONFIGURATION: {
        GET: '/api/get/file/playlist.xml',
        SET_RELOAD: '/api/set/file/playlist.xml?reload=true',
        SET_RESTART: '/api/set/file/playlist.xml?restart=true'
    },
    SYSTEM_CONFIGURATION: {
        GET: '/api/get/file/playback-controller.conf',
        SET: '/api/set/file/playback-controller.conf?restart=true'
    },
    CONTROLLER_CONFIGURATION: {
        GET_PROJECTS: "/api/get/projects"
    },
    VIDEOS: {
        GET: "/api/get/videos"
    },
    FIRE_TRIGGER: "/api/trigger/"
};
