import React from "react";
import "./NodeRED.less";
import {getServerBaseURL} from "../../providers/data/dataMiddleware";

class NodeRED extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: this.parseURL(getServerBaseURL())
        };
    }

    parseURL = url => {
        let parsed_url = url;
        const is_http = parsed_url.startsWith("http://");
        const is_https = parsed_url.startsWith("https://");
        if (!is_http && !is_https) {
            parsed_url = "http://" + parsed_url;
        }

        if (!parsed_url.slice(8)
            .includes(":")) {
            if (parsed_url.endsWith("/")) {
                parsed_url = parsed_url.slice(0, -1);
            }
            parsed_url += ":1880";
        }
        return parsed_url;
    };

    render() {
        return (
            <div className="node-red">
                <iframe src={this.state.src} title="Node-RED"/>
            </div>
        );
    }
}

export default NodeRED;
