import React from "react";
import {Table} from "antd";
import {ReactSVG} from "react-svg";
import {PlayCircleOutlined} from "@ant-design/icons";
import "./Triggers.less";
import MMInlineSelect from "../../elements/inputs/MMInlineSelect";
import MMTextInput from "../../elements/inputs/MMTextInput";
import MMToggle from "../../elements/inputs/MMToggle";
import MMButton from "../../elements/buttons/MMButton";
import {uuidv4} from "../../providers/data/dataMiddleware";
import icoRemove from "../../assets/ico-remove.svg";

const TRIGGER_TYPES = ["time", "gpio", "sms", "mic"];

class TriggersList extends React.PureComponent {
    triggerColumns = [
        {
            title: "Navn",
            dataIndex: "webapp-name",
            render: (text, record) => (
                <MMTextInput
                    className="name"
                    value={text}
                    onChange={(event, value) => this.props.setTypeChanges({
                        uuid: record.uuid,
                        "webapp-name": value
                    })}
                />)
        },
        {
            title: "Standard",
            dataIndex: "default",
            render: (text, record) => (
                <MMToggle
                    checked={text === "yes"}
                    onChange={(event, checked) => this.props.setTypeChanges({
                        uuid: record.uuid,
                        default: checked ? "yes" : "no"
                    })}
                />)
        },
        {
            title: "Type",
            key: "type",
            render: (text, record) => <MMInlineSelect
                value={this.getTriggerType(record)}
                options={[
                    {
                        key: "time",
                        value: "Tid"
                    },
                    {
                        key: "gpio",
                        value: "GPIO"
                    },
                    {
                        key: "sms",
                        value: "SMS"
                    },
                    {
                        key: "mic",
                        value: "Mikrofon"
                    }
                ]}
                onChange={type => this.props.setTypeChanges({
                    uuid: record.uuid,
                    ...(TRIGGER_TYPES.reduce((obj, item) => ({
                        ...obj,
                        [item]: item === type ? {} : null
                    }), {}))
                })}
            />
        },
        {
            title: "Egenskaber",
            key: "properties",
            render: (text, record) => this.renderTriggerProps(record)
        },
        {
            title: "Valgmuligheder",
            key: "actions",
            render: (text, record) => (
                <div className="actions">
                    <div onClick={() => this.props.fireTrigger(record.id)}>
                        <PlayCircleOutlined/>
                    </div>
                    <div onClick={() => this.props.deleteTrigger(record.uuid)}>
                        <ReactSVG
                            src={icoRemove}
                            beforeInjection={svg => svg.classList.add("icon-remove-svg")}
                            className="icon icon-remove"
                        />
                    </div>
                </div>)
        }
    ];

    getTriggerType = trigger => {
        for (const type of TRIGGER_TYPES) {
            if (trigger[type]) {
                return type;
            }
        }
        return null;
    };

    getTriggerTable = () => this.props.elements.map(trigger => ({
        key: trigger.uuid,
        "webapp-name": trigger.id,
        ...trigger
    }));

    addTrigger = () => {
        this.props.setTypeChanges({
            uuid: uuidv4(),
            id: uuidv4(),
            "webapp-name": "Ny trigger"
        });
    };

    renderTriggerProps = trigger => {
        const type = this.getTriggerType(trigger);
        switch (type) {
            case "time":
                return (
                    <div className="trigger-props time">
                        <div>
                            <p>Start</p>
                            <MMTextInput
                                value={trigger.time.after}
                                onChange={(event, value) => this.props.setTypeChanges({
                                    uuid: trigger.uuid,
                                    time: {
                                        ...trigger.time,
                                        after: value
                                    }
                                })}
                            />
                        </div>
                        <div>
                            <p>Tidspunkt</p>
                            <MMTextInput
                                value={trigger.time.time}
                                onChange={(event, value) => this.props.setTypeChanges({
                                    uuid: trigger.uuid,
                                    time: {
                                        ...trigger.time,
                                        time: value
                                    }
                                })}
                            />
                        </div>
                        <div>
                            <p>Slut</p>
                            <MMTextInput
                                value={trigger.time.before}
                                onChange={(event, value) => this.props.setTypeChanges({
                                    uuid: trigger.uuid,
                                    time: {
                                        ...trigger.time,
                                        before: value
                                    }
                                })}
                            />
                        </div>
                    </div>);
            case "gpio":
                return (
                    <div className="trigger-props gpio">
                        <div>
                            <p>Pin</p>
                            <MMTextInput
                                value={trigger.gpio.pin}
                                type="number"
                                min={1}
                                max={8}
                                onChange={(event, value) => this.props.setTypeChanges({
                                    uuid: trigger.uuid,
                                    gpio: {
                                        ...trigger.gpio,
                                        pin: value
                                    }
                                })}
                            />
                        </div>
                        <div>
                            <p>Tilstand</p>
                            <MMInlineSelect
                                value={trigger.gpio.detect}
                                options={[
                                    {
                                        key: "falling-edge",
                                        value: "Åben"
                                    },
                                    {
                                        key: "rising-edge",
                                        value: "Lukket"
                                    }
                                ]}
                                onChange={detect => this.props.setTypeChanges({
                                    uuid: trigger.uuid,
                                    gpio: {
                                        ...trigger.gpio,
                                        detect
                                    }
                                })}
                            />
                        </div>
                    </div>
                );
            case "sms":
                return (
                    <div className="trigger-props sms">
                        <div>
                            <p>Kode</p>
                            <MMTextInput
                                value={trigger.sms.code}
                                type="number"
                                min={0}
                                max={9999}
                                onChange={(event, value) => this.props.setTypeChanges({
                                    uuid: trigger.uuid,
                                    sms: {
                                        ...trigger.sms,
                                        code: value
                                    }
                                })}
                            />
                        </div>
                    </div>
                );
            case "mic":
                return (
                    <div className="trigger-props mic">
                        <div>
                            <p>Min</p>
                            <MMTextInput
                                value={trigger[type].min}
                                type="number"
                                min={0}
                                max={200}
                                onChange={(event, value) => this.props.setTypeChanges({
                                    uuid: trigger.uuid,
                                    [type]: {
                                        ...trigger[type],
                                        min: value
                                    }
                                })}
                            />
                        </div>
                        <div>
                            <p>Max</p>
                            <MMTextInput
                                value={trigger[type].max}
                                type="number"
                                min={0}
                                max={200}
                                onChange={(event, value) => this.props.setTypeChanges({
                                    uuid: trigger.uuid,
                                    [type]: {
                                        ...trigger[type],
                                        max: value
                                    }
                                })}
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    render() {
        return (
            <div>
                <Table dataSource={this.getTriggerTable()} columns={this.triggerColumns} pagination={false}/>
                <MMButton className="add-trigger" onClick={this.addTrigger}>Tilføj trigger</MMButton>
            </div>
        );
    }
}

export default TriggersList;
