import {isNullable} from "./objectsAreEqual";

export function toArray(obj, nullify = true) {
    if (obj instanceof Array) {
        if (nullify) {
            return [...obj].filter(entry => !isNullable(entry));
        }
        return [...obj];
    }
    if (isNullable(obj) && nullify) {
        return [];
    }
    return [obj];
}
