import {APP_CONFIG} from "../../config";
import colorProvider from "../colors/color.provider";

export default class fixtureProvider {
    static getFixtureColorType = (fixture) => {
        let color_type = APP_CONFIG.COLOR_TYPES.find(type => type.key === fixture.type);
        if (!color_type) {
            color_type = APP_CONFIG.COLOR_TYPES.find(type => type.key === "raw");
        }
        return color_type;
    };

    static getFixtureLength = (fixture) => {
        const color_type = this.getFixtureColorType(fixture);
        if (color_type.key === "raw") {
            return parseInt(fixture.length);
        }
        return color_type.block_size;
    };

    static getFixtureColor = (fixture, output, intensity = 100) => {
        const color_type = fixtureProvider.getFixtureColorType(fixture);
        if (!output || output.length === 0) {
            return "black";
        }
        return colorProvider.convertToCSSColor(color_type, output, intensity);
    };
}
