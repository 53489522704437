import React from "react";
import "./Fixtures.less";
import {connect} from "react-redux";
import {websocketActions} from "../../providers/websocket/websocketActions";
import Watermark from "../../elements/layout/Watermark";
import {setTypeChanges} from "../../providers/setTypeChanges";
import objectsAreEqual, {isNullable} from "../../providers/objectsAreEqual";
import {getElements, getRelativeElement} from "../../providers/getElements";
import {fixture_sorting_algorithm, sortObjects} from "../../providers/sortObjects";
import FixtureEditor from "./FixtureEditor/FixtureEditor";
import MMCanvas from "../../elements/layout/MMCanvas";
import {dataActions} from "../../providers/data/dataActions";
import FixtureWizard from "./FixtureWizard/FixtureWizard";
import MMSlider from "../../elements/inputs/MMSlider";
import fixtureProvider from "../../providers/fixtures/fixture.provider";
import FixturesHeader from "./FixturesHeader/FixturesHeader";
import CanvasFixture from "./Canvas/CanvasFixture";
import MMButton from "../../elements/buttons/MMButton";
import isRangesOverlapping from "../../providers/isRangesOverlapping";
import {notificationsActions} from "../../providers/notifications/notificationsActions";
import {uuidv4} from "../../providers/data/dataMiddleware";
import {toArray} from "../../providers/toArray";

const FIXTURE_SIZE = 0.5;
const FIXTURE_PADDING = 1.5;
const VIEW_HEIGHT = 10000;
const VIEW_WIDTH = 10000;
const VIEW_PADDING = 1;
const MIN_VIEW_SCALE = 20;
const MAX_VIEW_SCALE = 200;
const PAINT_SCALE = 25;

let previous_view_scale = MIN_VIEW_SCALE;

export class FixturesComponent extends React.PureComponent {
    ctrlPressed = false;

    shiftPressed = false;

    mouse_moving = false;

    mouse_down = false;

    mouse_multiselect = false;

    mouse_down_x = 0;

    mouse_down_y = 0;

    canvas_fixtures = [];

    do_repaint = false;

    delayedSaveTimeout = null;

    fixture_editing_enabled = true;

    remove_live_mode = false;

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            view_container_ref: null,
            mousedown_on_element: null,
            has_active_element: false,
            marked_elements_length: 0,
            active_action: null,
            newest_fixture_uuid: null,
            universes: [],
            live_mode: !this.remove_live_mode
        };

        // Bind functions to context
        this.setTypeChanges = setTypeChanges.bind(this);
        this.getElements = getElements.bind(this);
    }

    componentDidMount() {
        window.addEventListener("keydown", this.onKeyDown);
        window.addEventListener("keyup", this.onKeyUp);
        window.addEventListener("wheel", this.onWheel, {passive: false});
        window.addEventListener("copy", this.onCopy);
        window.addEventListener("paste", this.onPaste);
        this.setViewScale(previous_view_scale);
        this.onCanvasCreated();
        this.onDataUpdated();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.canvas_ref !== this.state.canvas_ref || prevState.canvas_ref_bg !== this.state.canvas_ref_bg) {
            this.onCanvasCreated();
        }
        if (!objectsAreEqual(prevState.changes, this.state.changes) || !objectsAreEqual(prevProps.data, this.props.data)) {
            this.onDataUpdated();
        }

        if (!objectsAreEqual(prevProps.data, this.props.data) || (prevState.changes && !this.state.changes)) {
            // Configuration changed or local changes were cleared, clear local changes and make sure the marked elements are updated
            this.setState({changes: {}});
        }

        if (this.props.websocket && this.state.live_mode) {
            const changed_universes = [];
            for (const universe of this.state.universes) {
                if (this.props.websocket["driver." + universe] !== prevProps.websocket["driver." + universe]) {
                    changed_universes.push(universe);
                }
            }
            for (const universe of changed_universes) {
                // Paint fixtures with the new values
                const canvas_fixtures = this.canvas_fixtures.filter(entry => entry.fixture.universe === universe);

                for (const canvas_fixture of canvas_fixtures) {
                    canvas_fixture.updateOutput(this.getFixtureOutput(canvas_fixture.fixture));
                    if (!this.do_repaint) {
                        canvas_fixture.paint();
                    }
                }
            }
        }

        if (this.do_repaint) {
            this.repaintView();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
        window.removeEventListener("keyup", this.onKeyUp);
        window.removeEventListener("wheel", this.onWheel);
        window.removeEventListener("copy", this.onCopy);
        window.removeEventListener("paste", this.onPaste);
        this.unRegisterUniverses(this.state.universes);
        previous_view_scale = this.state.view_scale;
    }

    onDataUpdated() {
        let changed_fixtures = false;
        const universes = new Set();

        const fixtures_missing_coords = [];
        for (const fixture of this.getElements("dmx-fixture")) {
            if (isNaN(fixture.x) || isNaN(fixture.y)) {
                fixtures_missing_coords.push(fixture);
            }
            let canvas_fixture = this.canvas_fixtures.find(e => e.fixture.uuid === fixture.uuid);
            if (canvas_fixture) {
                if (canvas_fixture.updateFixture(fixture)) {
                    changed_fixtures = true;
                }
                if (!changed_fixtures) {
                    canvas_fixture.paint();
                }
            } else {
                canvas_fixture = new CanvasFixture(this.state.canvas_ref, fixture, FIXTURE_SIZE, PAINT_SCALE, FIXTURE_PADDING);
                this.canvas_fixtures.push(canvas_fixture);
                changed_fixtures = true;
            }
            universes.add(fixture.universe);
        }

        if (!objectsAreEqual(this.state.universes, universes)) {
            this.setState({universes});
        }

        if (changed_fixtures) {
            this.registerUniverses(universes);
            this.do_repaint = true;

            if (this.canvas_fixtures.length > 0) {
                this.updateFixtureOrder();
                this.count_marked_elements();
            }
        }

        if (fixtures_missing_coords.length > 0) {
            this.setTypeChanges("dmx-fixture", fixtures_missing_coords.map(fix => ({
                ...fix,
                x: isNaN(fix.x) ? 0 : fix.x,
                y: isNaN(fix.y) ? 0 : fix.y
            })));
        }
    }

    updateFixtureOrder = () => {
        this.canvas_fixtures = sortObjects(this.canvas_fixtures, ["universe", "offset"], "fixture");
        for (const [index, canvas_fixture] of this.canvas_fixtures.entries()) {
            canvas_fixture.updateNextFixture(this.canvas_fixtures[index + 1]);
        }

        if (this.canvas_fixtures.length > 0) {
            this.setState({newest_fixture_uuid: this.canvas_fixtures[this.canvas_fixtures.length - 1].fixture.uuid});
        } else {
            this.setState({newest_fixture_uuid: null});
        }
    };

    registerUniverses = universes => {
        if (this.props.websocket && this.state.live_mode) {
            for (const universe of universes) {
                this.props.dispatch({
                    type: websocketActions.WEBSOCKET_REGISTER_VARIABLE,
                    variable: "driver." + universe
                });
            }
        }
    };

    unRegisterUniverses = universes => {
        if (this.props.websocket) {
            for (const universe of universes) {
                this.props.dispatch({
                    type: websocketActions.WEBSOCKET_UNREGISTER_VARIABLE,
                    variable: "driver." + universe
                });
            }
        }

        for (const canvas_fixture of this.canvas_fixtures) {
            canvas_fixture.updateOutput([]);
            canvas_fixture.paint();
        }
    };

    moveMarkedFixtures = (delta_x, delta_y, use_start_coords = true) => {
        const marked_canvas_fixtures = this.canvas_fixtures.filter(e => e.is_marked);

        for (const canvas_fixture of marked_canvas_fixtures) {
            // Make sure the fixtures are within the view and not overlapping other fixtures
            const coords = use_start_coords ? canvas_fixture.start_coords : canvas_fixture.fixture;
            const new_x = parseFloat(coords.x) + delta_x;
            const new_y = parseFloat(coords.y) + delta_y;
            if (new_x < 0 || new_x >= VIEW_WIDTH) {
                // Fixture will be out of bounds on x-axis, so don't move it
                delta_x = 0;
            }

            if (new_y < 0 || new_y >= VIEW_HEIGHT) {
                // Fixture will be out of bounds on y-axis, so don't move it
                delta_y = 0;
            }

            if (delta_x !== 0 || delta_y !== 0) {
                const overlapping_fixtures = this.getOverlappingCanvasFixtures({
                    x: new_x,
                    y: new_y
                })
                    .filter(e => !e.is_marked);

                if (overlapping_fixtures.length > 0) {
                    // Fixture will overlap with existing if moved, so don't move it
                    delta_x = 0;
                    delta_y = 0;
                }
            }
        }

        const updated_fixtures = marked_canvas_fixtures.map(canvas_fixture => {
            const coords = use_start_coords ? canvas_fixture.start_coords : canvas_fixture.fixture;
            const updated_element = {uuid: canvas_fixture.fixture.uuid};
            if (delta_x !== 0) {
                updated_element.x = parseFloat(coords.x) + delta_x;
            }
            if (delta_y !== 0) {
                updated_element.y = parseFloat(coords.y) + delta_y;
            }
            return updated_element;
        });

        this.setTypeChanges("dmx-fixture", updated_fixtures);
    };

    onCopy = event => {
        if (this.state.marked_elements_length === 0) {
            return;
        }
        try {
            const data = JSON.stringify(this.canvas_fixtures.filter(e => e.is_marked)
                .map(e => e.fixture));
            event.clipboardData.setData("text/plain", data);
            event.preventDefault();
        } catch (err) {
            console.error("Failed to copy data:", err);
            this.props.dispatch({type: notificationsActions.COPY_FAILED});
        }
    };

    onPaste = event => {
        const text = (event.clipboardData || window.clipboardData).getData('text');

        if (text.length === 0) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_PASTE});
            return;
        }
        let new_fixtures = null;
        try {
            new_fixtures = JSON.parse(text);
        } catch (e) {
            this.props.dispatch({type: notificationsActions.PASTE_INVALID_DATA});
            console.error("Failed to parse paste:", e);
            return;
        }

        if (new_fixtures.length === 0) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_PASTE});
            return;
        }

        const newest_fix = this.getElements("dmx-fixture")
            .find(e => e.uuid === this.state.newest_fixture_uuid);
        let offset = 0;
        let universe_prefix = "";
        let universe_offset = 0;
        if (newest_fix && fixture_sorting_algorithm(new_fixtures[0], newest_fix, ["universe", "offset"]) !== 1) {
            if (newest_fix) {
                offset = fixtureProvider.getFixtureLength(newest_fix) + parseInt(newest_fix.offset) - parseInt(new_fixtures[0].offset);
            }
            if (new_fixtures.length >= 100) {
                // New fixtures are behind or at same address as the newest fixture, bump universe
                if (!isNullable(newest_fix.universe)) {
                    if (newest_fix.universe.includes("-")) {
                        const arr = newest_fix.universe.split("-");
                        universe_prefix = arr[0] + "-";
                        universe_offset = parseInt(arr[1]);
                    } else {
                        universe_offset = parseInt(newest_fix.universe);
                    }
                }
                if (new_fixtures[0].universe.includes("-")) {
                    const arr = new_fixtures[0].universe.split("-");
                    universe_offset -= parseInt(arr[1]);
                } else {
                    universe_offset -= parseInt(new_fixtures[0].universe);
                }
                universe_offset++;
            }
        }

        for (const fixture of new_fixtures) {
            const uuid = uuidv4();
            fixture.uuid = uuid;
            fixture.id = uuid;
            fixture.x = parseFloat(fixture.x) + 2;
            fixture.y = parseFloat(fixture.y) + 2;
            if (universe_offset === 0) {
                fixture.offset = parseInt(fixture.offset) + offset;
            } else if (fixture.universe.includes("-")) {
                const arr = fixture.universe.split("-");
                fixture.universe = universe_prefix + (parseInt(arr[1]) + universe_offset);
            } else {
                fixture.universe = universe_prefix + (parseInt(fixture.universe) + universe_offset);
            }
        }

        this.setTypeChanges("dmx-fixture", new_fixtures, () => {
            for (const canvas_fixture of this.canvas_fixtures) {
                canvas_fixture.updateMarked(!!new_fixtures.find(e => e.uuid === canvas_fixture.fixture.uuid));
                if (!this.do_repaint) {
                    canvas_fixture.paint();
                }
            }
            this.saveChanges();
        });

        event.preventDefault();
    };

    onKeyDown = event => {
        if (this.state.dialog) {
            return;
        }
        switch (event.keyCode) {
            case 16: // Shift
                this.shiftPressed = true;
                break;
            case 17: // Ctrl
                this.ctrlPressed = true;
                break;
            case 37: // Left arrow
                if (!this.fixture_editing_enabled) {
                    return;
                }
                if (this.state.has_active_element) {
                    // Change to the previous fixture
                    if (this.canvas_fixtures.length < 2) return;
                    const active_element_index = this.canvas_fixtures.findIndex(e => e.is_active);
                    if (active_element_index < 0) return;
                    let previous_fixture_index = active_element_index - 1;
                    if (previous_fixture_index < 0) previous_fixture_index += this.canvas_fixtures.length;
                    this.openFixtureEditor(this.canvas_fixtures[previous_fixture_index]);
                    event.preventDefault();
                } else if (this.state.marked_elements_length > 0) {
                    clearTimeout(this.delayedSaveTimeout);
                    this.moveMarkedFixtures(-1, 0, false);
                    event.preventDefault();
                }
                break;
            case 38: // Up arrow
                if (!this.fixture_editing_enabled) {
                    return;
                }
                if (this.state.marked_elements_length > 0) {
                    clearTimeout(this.delayedSaveTimeout);
                    this.moveMarkedFixtures(0, -1, false);
                    event.preventDefault();
                }
                break;
            case 39: // Right arrow
                if (!this.fixture_editing_enabled) {
                    return;
                }
                if (this.state.has_active_element) {
                    // Change to the next fixture
                    if (this.canvas_fixtures.length < 2) return;
                    const active_element_index = this.canvas_fixtures.findIndex(e => e.is_active);
                    if (active_element_index < 0) return;
                    let next_fixture_index = active_element_index + 1;
                    if (next_fixture_index >= this.canvas_fixtures.length) next_fixture_index -= this.canvas_fixtures.length;
                    this.openFixtureEditor(this.canvas_fixtures[next_fixture_index]);
                    event.preventDefault();
                } else if (this.state.marked_elements_length > 0) {
                    clearTimeout(this.delayedSaveTimeout);
                    this.moveMarkedFixtures(1, 0, false);
                    event.preventDefault();
                }
                break;
            case 40: // Down arrow
                if (!this.fixture_editing_enabled) {
                    return;
                }
                if (this.state.marked_elements_length > 0) {
                    clearTimeout(this.delayedSaveTimeout);
                    this.moveMarkedFixtures(0, 1, false);
                    event.preventDefault();
                }
                break;
            case 8: // Backspace
            case 46: // Delete
                this.deleteFixtures();
                break;
            case 65: // A
                if (this.ctrlPressed) {
                    for (const canvas_fixture of this.canvas_fixtures) {
                        canvas_fixture.updateMarked(true);
                        if (!this.do_repaint) {
                            canvas_fixture.paint();
                        }
                    }
                    this.setState({marked_elements_length: this.canvas_fixtures.length});
                }
                break;
            default:
                // console.log("Key:", event.keyCode);
                break;
        }
    };

    saveChanges = () => {
        if (this.state.changes && this.state.changes.light_config) {
            // Save changes
            this.props.dispatch({
                type: dataActions.SET_LIGHT_CONFIGURATION,
                changes: this.state.changes.light_config
            });
        }
    };

    onKeyUp = event => {
        switch (event.keyCode) {
            case 16: // Shift
                this.shiftPressed = false;
                break;
            case 17: // Ctrl
                this.ctrlPressed = false;
                break;
            case 37: // Arrows
            case 38:
            case 39:
            case 40:
                // Save changes after 500 ms without a key press
                this.delayedSaveTimeout = setTimeout(this.saveChanges, 500);
                break;
            default:
                break;
        }
    };

    onWheel = event => {
        if (this.state.marked_elements_length > 0 && this.shiftPressed && this.ctrlPressed) {
            event.preventDefault();
            const marked_canvas_fixtures = this.canvas_fixtures.filter(e => e.is_marked);
            if (this.state.marked_elements_length !== marked_canvas_fixtures.length) {
                this.setState({marked_elements_length: marked_canvas_fixtures.length});
            }

            let multiplier = (100 + event.deltaY / 50) / 100;
            for (const canvas_fixture of marked_canvas_fixtures) {
                // Make sure the fixtures are within the view and not overlapping other fixtures
                const new_x = parseFloat(canvas_fixture.fixture.x) * multiplier;
                const new_y = parseFloat(canvas_fixture.fixture.y) * multiplier;
                if (new_x < 0 || new_x >= VIEW_WIDTH) {
                    // Fixture will be out of bounds on x-axis, so don't move it
                    multiplier = 1;
                }

                if (new_y < 0 || new_y >= VIEW_HEIGHT) {
                    // Fixture will be out of bounds on y-axis, so don't move it
                    multiplier = 1;
                }

                if (multiplier !== 1) {
                    const overlapping_fixtures = this.getOverlappingCanvasFixtures({
                        x: new_x,
                        y: new_y
                    })
                        .filter(e => !e.is_marked);

                    if (overlapping_fixtures.length > 0) {
                        // Fixture will overlap with existing if moved, so don't move it
                        multiplier = 1;
                    }
                }
            }

            if (multiplier === 1) {
                return;
            }

            const updated_fixtures = marked_canvas_fixtures.map(canvas_fixture => {
                const updated_element = {uuid: canvas_fixture.fixture.uuid};
                updated_element.x = parseFloat(canvas_fixture.fixture.x) * multiplier;
                updated_element.y = parseFloat(canvas_fixture.fixture.y) * multiplier;
                return updated_element;
            });

            this.setTypeChanges("dmx-fixture", updated_fixtures);

            // Save changes after 500 ms without a key press
            clearTimeout(this.delayedSaveTimeout);
            this.delayedSaveTimeout = setTimeout(this.saveChanges, 500);
        }
    };

    closePopupMenu = () => {
        const canvas_fixture = this.canvas_fixtures.find(e => e.is_active);
        if (canvas_fixture) {
            canvas_fixture.updateActive(false);
            // canvas_fixture.updateMarked(false);
            if (!this.do_repaint) {
                canvas_fixture.paint();
            }
        }

        this.setState({
            dialog: null,
            has_active_element: false
        });
    };

    closeModal = () => {
        this.setState({modal: null});
    };

    openFixtureEditor = canvas_fixtures => {
        const canvas_fixture = toArray(canvas_fixtures)[0];
        let dialog;
        if (canvas_fixtures instanceof Array && canvas_fixtures.length > 1) { // Multiple existing fixtures
            this.setState({active_action: null});
            for (const [i, entry] of canvas_fixtures.entries()) {
                if (i + 1 < canvas_fixtures.length && entry.next_canvas_fixture !== canvas_fixtures[i + 1]) {
                    console.error("MULTI_EDIT_FIXTURES_ARE_NOT_CONNECTED:", entry, canvas_fixtures[i + 1]);
                    this.props.dispatch({type: notificationsActions.MULTI_EDIT_FIXTURES_ARE_NOT_CONNECTED});
                    return;
                }
            }
            dialog = () => <FixtureEditor
                fixture_uuid={canvas_fixtures.map(e => e.fixture.uuid)}
                onClose={this.closePopupMenu}
            />;
        } else if (canvas_fixture.fixture.uuid) { // Existing fixture
            dialog = () => <FixtureEditor
                fixture_uuid={canvas_fixture.fixture.uuid}
                onClose={this.closePopupMenu}
                previousFixture={() => this.openFixtureEditor(getRelativeElement(this.canvas_fixtures, canvas_fixture, -1))}
                nextFixture={() => this.openFixtureEditor(getRelativeElement(this.canvas_fixtures, canvas_fixture, 1))}
            />;
        } else { // New fixture
            if (this.state.dialog) {
                return; // Menu is open, ignore event
            }

            dialog = () => <FixtureEditor
                new
                fixture_x={canvas_fixture.fixture.x}
                fixture_y={canvas_fixture.fixture.y}
                previous_fixture_uuid={this.state.newest_fixture_uuid}
                submit={uuid => this.setState({newest_fixture_uuid: uuid})}
                onClose={this.closePopupMenu}
            />;
        }

        const old_canvas_fixture = this.canvas_fixtures.find(e => e.is_active);
        if (old_canvas_fixture) {
            old_canvas_fixture.updateActive(false);
            // canvas_fixture.updateMarked(false);
            if (!this.do_repaint) {
                old_canvas_fixture.paint();
            }
        }

        canvas_fixture.updateActive(true);
        if (!this.do_repaint) {
            canvas_fixture.paint();
        }
        this.setState({
            has_active_element: true,
            dialog
        });
    };

    getCanvasFixtureInRange = (x, y) => {
        const max_offset = FIXTURE_SIZE * PAINT_SCALE / 2 + 5;
        return this.canvas_fixtures.find(e => e.coords[0] + max_offset >= x && x >= e.coords[0] - max_offset
            && e.coords[1] + max_offset >= y && y >= e.coords[1] - max_offset);
    };

    viewOnclick = event => {
        if (this.mouse_moving) {
            return;
        }
        const x = event.nativeEvent.offsetX;
        const y = event.nativeEvent.offsetY;
        const canvas_fixture = this.getCanvasFixtureInRange(x, y);
        if (canvas_fixture) {
            switch (this.state.active_action) {
                case "delete":
                    this.setTypeChanges("dmx-fixture", {
                        uuid: canvas_fixture.fixture.uuid,
                        delete: true
                    }, this.saveChanges);
                    break;
                case "edit":
                    this.openFixtureEditor(canvas_fixture);
                    break;
                default:
                    canvas_fixture.updateMarked(!canvas_fixture.is_marked);
                    if (!this.do_repaint) {
                        canvas_fixture.paint();
                    }
                    this.setState(state => ({marked_elements_length: state.marked_elements_length + (canvas_fixture.is_marked ? 1 : -1)}));
                    break;
            }
        } else {
            if (this.state.dialog) {
                return; // Menu is open, ignore event
            }
            switch (this.state.active_action) {
                case "add": {
                    const coords = this.getFixtureCoordinates(x, y);
                    const fixture = {
                        x: coords[0],
                        y: coords[1]
                    };
                    const new_canvas_fixture = this.addCanvasFixture(fixture);

                    this.openFixtureEditor(new_canvas_fixture);
                    break;
                }
                default:
                    for (const canvas_fixture_entry of this.canvas_fixtures) {
                        canvas_fixture_entry.updateMarked(false);
                        if (!this.do_repaint) {
                            canvas_fixture_entry.paint();
                        }
                    }
                    this.setState({marked_elements_length: 0});
                    break;
            }
        }
    };

    viewOnMouseDown = (event) => {
        const x = event.nativeEvent.offsetX;
        const y = event.nativeEvent.offsetY;
        if (event.touches && event.touches.length > 1) {
            if (this.mouse_down) {
                this.mouse_down = false;
                // Cancel changes
                // Don't destruct the event, because we need it in the setState callback
                event.persist();
                this.setState({
                    changes: null
                }, () => {
                    // Tell MMTransformWrapper to start handling touch events again
                    const on_touch_start_event = new TouchEvent("touchstart", {
                        cancelable: true,
                        bubbles: true,
                        touches: event.touches,
                        targetTouches: event.targetTouches,
                        changedTouches: event.touches
                    });
                    event.target.dispatchEvent(on_touch_start_event);
                });
            }
            return; // Ignore multitouch
        }

        this.mouse_down = true;
        this.mouse_down_x = x;
        this.mouse_down_y = y;

        if (this.fixture_editing_enabled) {
            const canvas_fixture_in_range = this.getCanvasFixtureInRange(x, y);
            if (canvas_fixture_in_range) {
                canvas_fixture_in_range.setStartCoords();

                const marked_canvas_fixtures = this.canvas_fixtures.filter(e => e.is_marked);
                for (const canvas_fixture of marked_canvas_fixtures) {
                    canvas_fixture.setStartCoords();
                }
            }
            this.setState({mousedown_on_element: canvas_fixture_in_range});
        }
    };

    viewOnMouseUp = () => {
        // Update locations of marked elements
        this.mouse_down = false;

        if (this.mouse_moving) {
            setTimeout(() => {
                this.mouse_moving = false;
            }, 100);
        }
        this.setState({mousedown_on_element: null});
        if (this.mouse_multiselect) {
            const marked_canvas_fixtures = this.canvas_fixtures.filter(e => e.is_marked);
            if (this.state.marked_elements_length !== marked_canvas_fixtures.length) {
                this.setState({marked_elements_length: marked_canvas_fixtures.length});
            }
            // Remove multiselect box
            const mm_canvas2 = this.state.canvas_ref2;
            mm_canvas2.clear();
        }
        this.saveChanges();
    };

    viewOnMouseMove = (event) => {
        if (!this.mouse_down) {
            return;
        }
        const x = event.nativeEvent.offsetX;
        const y = event.nativeEvent.offsetY;
        const width = x - this.mouse_down_x;
        const height = y - this.mouse_down_y;
        const delta_x = width / PAINT_SCALE;
        const delta_y = height / PAINT_SCALE;

        if (this.state.view_container_ref) {
            // Auto scroll
            const el = this.state.view_container_ref;
            const currentX = x * this.state.view_scale / 100;
            const currentY = y * this.state.view_scale / 100;
            const border = 50;
            if (currentX + border >= el.offsetWidth) {
                const scrollLeft = (currentX + border - el.offsetWidth);
                if (scrollLeft > el.scrollLeft) {
                    el.scrollLeft = scrollLeft;
                }
            }
            if (currentX - el.scrollLeft <= border) {
                const scrollLeft = (currentX - border);
                if (scrollLeft < el.scrollLeft) {
                    el.scrollLeft = scrollLeft;
                }
            }

            if (currentY + border >= el.offsetHeight) {
                const scrollTop = (currentY + border - el.offsetHeight);
                if (scrollTop > el.scrollTop) {
                    el.scrollTop = scrollTop;
                }
            }
            if (currentY - el.scrollTop <= border) {
                const scrollTop = (currentY - border);
                if (scrollTop < el.scrollTop) {
                    el.scrollTop = scrollTop;
                }
            }
        }

        if ((delta_x !== 0 || delta_y !== 0) && !isNaN(delta_x) && !isNaN(delta_y)) {
            this.mouse_moving = true;
            if (this.state.mousedown_on_element) {
                if (!this.state.mousedown_on_element.is_marked) {
                    this.state.mousedown_on_element.updateMarked(true);
                    if (!this.do_repaint) {
                        this.state.mousedown_on_element.paint();
                    }
                }

                this.moveMarkedFixtures(delta_x, delta_y);
            } else if (this.state.canvas_ref2 && this.state.canvas_ref2.ctx) {
                // Multiselect
                this.mouse_multiselect = true;
                const fixture_range = FIXTURE_SIZE * PAINT_SCALE / 2;
                let marked_elements_length = 0;
                let marked_changes = false;
                for (const canvas_fixture of this.canvas_fixtures) {
                    const x_in_range = isRangesOverlapping(this.mouse_down_x, this.mouse_down_x + width,
                        canvas_fixture.coords[0] - fixture_range, canvas_fixture.coords[0] + fixture_range);
                    const y_in_range = isRangesOverlapping(this.mouse_down_y, this.mouse_down_y + height,
                        canvas_fixture.coords[1] - fixture_range, canvas_fixture.coords[1] + fixture_range);
                    const in_range = x_in_range && y_in_range;
                    const changed = canvas_fixture.updateMarked(in_range);
                    if (in_range) {
                        marked_elements_length++;
                    }
                    if (changed) {
                        marked_changes = true;
                        if (!this.do_repaint) {
                            canvas_fixture.paint();
                        }
                    }
                }
                if (marked_changes) {
                    this.setState({marked_elements_length});
                }
                const mm_canvas2 = this.state.canvas_ref2;
                mm_canvas2.clear();
                mm_canvas2.ctx.strokeStyle = "#00ff00";
                const thickness = Math.round(100 / this.state.view_scale);
                mm_canvas2.ctx.lineWidth = Math.round(thickness);
                mm_canvas2.ctx.strokeRect(this.mouse_down_x, this.mouse_down_y, width, height);
            }
        }
    };

    getFixtureCoordinates = (x, y) => {
        const fixture_x = parseFloat(x) / PAINT_SCALE - FIXTURE_PADDING;
        const fixture_y = parseFloat(y) / PAINT_SCALE - FIXTURE_PADDING;
        return [fixture_x, fixture_y];
    };

    getOverlappingCanvasFixtures = fixture => this.canvas_fixtures
        .filter(entry => parseInt(entry.fixture.x) === parseInt(fixture.x) && parseInt(entry.fixture.y) === parseInt(fixture.y));

    repaintView = () => {
        const mm_canvas = this.state.canvas_ref;
        if (mm_canvas) {
            this.do_repaint = false;
            mm_canvas.clear();

            // Draw fixtures
            for (const canvas_fixture of this.canvas_fixtures) {
                // Draw link to the next fixture
                canvas_fixture.paintLink();

                canvas_fixture.forcePaint();
            }
        }
    };

    getFixtureOutput = fixture => {
        if (this.state.live_mode && this.props.websocket && this.props.websocket["driver." + fixture.universe]) {
            const output = [];
            for (let i = 0; i < fixtureProvider.getFixtureLength(fixture); i++) {
                output.push(this.props.websocket["driver." + fixture.universe][parseInt(fixture.offset) + i - 1]);
            }
            return output;
        }
        return [];
    };

    onCanvasCreated = () => {
        const mm_canvas_bg = this.state.canvas_ref_bg;
        if (mm_canvas_bg) {
            mm_canvas_bg.clear();
            mm_canvas_bg.paint_grid();
        }
        for (const canvas_fixture of this.canvas_fixtures) {
            canvas_fixture.updateCanvas(this.state.canvas_ref);
        }
    };

    addCanvasFixture = fixture => {
        const new_canvas_fixture = new CanvasFixture(this.state.canvas_ref, fixture, FIXTURE_SIZE,
            PAINT_SCALE, FIXTURE_PADDING);
        this.canvas_fixtures.push(new_canvas_fixture);
        this.canvas_fixtures = sortObjects(this.canvas_fixtures, ["universe", "offset"], "fixture");
        for (const [index, canvas_fixture] of this.canvas_fixtures.entries()) {
            canvas_fixture.updateNextFixture(this.canvas_fixtures[index + 1]);
        }
        this.do_repaint = true;
        return new_canvas_fixture;
    };

    count_marked_elements = () => {
        const marked_canvas_fixtures = this.canvas_fixtures.filter(e => e.is_marked);
        if (this.state.marked_elements_length !== marked_canvas_fixtures.length) {
            this.setState({marked_elements_length: marked_canvas_fixtures.length});
        }
    };

    deleteFixtures = () => {
        if (!this.fixture_editing_enabled) {
            return;
        }
        const delete_fixtures = [];
        for (let i = 0; i < this.canvas_fixtures.length;) {
            if (this.canvas_fixtures[i].is_marked) {
                delete_fixtures.push({
                    ...this.canvas_fixtures[i].fixture,
                    delete: true
                });
                this.canvas_fixtures.splice(i, 1);
            } else {
                i++;
            }
        }

        if (delete_fixtures.length > 0) {
            this.do_repaint = true;
            this.setTypeChanges("dmx-fixture", delete_fixtures, () => {
                this.saveChanges();
                this.count_marked_elements();
                this.updateFixtureOrder();
            });
        } else {
            this.setState(state => ({active_action: state.active_action === "delete" ? null : "delete"}));
        }
    };

    editFixtures = () => {
        const marked_canvas_fixtures = this.canvas_fixtures.filter(e => e.is_marked);
        if (marked_canvas_fixtures.length > 0) {
            this.openFixtureEditor(marked_canvas_fixtures);
        } else {
            this.setState(state => ({active_action: state.active_action === "edit" ? null : "edit"}));
        }
    };

    addFixtures = () => {
        this.setState(state => ({active_action: state.active_action === "add" ? null : "add"}));
    };

    onFixtureWizardSubmit = fixtures => {
        setTimeout(() => {
            for (const canvas_fixture of this.canvas_fixtures) {
                canvas_fixture.updateMarked(!!fixtures.find(e => e.uuid === canvas_fixture.fixture.uuid));
                if (!this.do_repaint) {
                    canvas_fixture.paint();
                }
            }
        }, 100);
        this.setState({marked_elements_length: fixtures.length});
    };

    addFixturesWizard = () => {
        // Open the fixture wizard modal
        this.setState(state => {
            if (state.active_action === "add-wizard") {
                return {
                    active_action: null,
                    modal: null
                };
            }

            return {
                active_action: state.active_action === "add-wizard" ? null : "add-wizard",
                modal: <FixtureWizard
                    previous_fixture_uuid={state.newest_fixture_uuid}
                    submit={this.onFixtureWizardSubmit}
                    onClose={() => {
                        this.closeModal();
                        this.setState({active_action: null});
                    }}
                />
            };
        });
        this.closePopupMenu();
    };

    setViewScale = view_scale => {
        this.setState({
            view_scale,
            hide_grid: view_scale < 40
        });
    };

    toggleLive = () => {
        this.setState(state => ({live_mode: !state.live_mode}), () => {
            if (this.state.live_mode) {
                this.registerUniverses(this.state.universes);
            } else {
                this.unRegisterUniverses(this.state.universes);
            }
        });
    };

    renderHeader = () => <FixturesHeader
        fixtures_exist={this.getElements("dmx-fixture").length > 0}
        active_action={this.state.active_action}
        has_active_element={this.state.has_active_element}
        marked_elements_length={this.state.marked_elements_length}
        deleteFixtures={this.deleteFixtures}
        editFixtures={this.editFixtures}
        addFixtures={this.addFixtures}
        addFixturesWizard={this.addFixturesWizard}
    />;

    render() {
        if (!this.props.data.light_config || !(this.props.data.light_config.playlist instanceof Object)) {
            return <Watermark/>; // Don't display page if unable to pull data
        }

        const show_watermark = this.props.browser.height > 800 || this.props.browser.width > 1400;

        return (
            <div className="FixturesPage">
                {this.renderHeader()}

                {show_watermark && <Watermark/>}
                {this.state.modal}
                <div
                    className="view-container"
                    ref={view_container_ref => !this.state.view_container_ref && this.setState({view_container_ref})}
                >
                    <div className="scaler" style={{transform: `scale(${this.state.view_scale / 100})`}}>
                        <MMCanvas
                            style={{display: this.state.hide_grid && "none"}}
                            ref={canvas_ref_bg => !this.state.canvas_ref_bg && this.setState({canvas_ref_bg})}
                            height={VIEW_HEIGHT}
                            width={VIEW_WIDTH}
                            grid_padding={VIEW_PADDING}
                            grid_size={PAINT_SCALE}
                        />
                        <MMCanvas
                            ref={canvas_ref => !this.state.canvas_ref && this.setState({canvas_ref})}
                            height={VIEW_HEIGHT}
                            width={VIEW_WIDTH}
                            transparent
                        />
                        <MMCanvas
                            ref={canvas_ref2 => !this.state.canvas_ref2 && this.setState({canvas_ref2})}
                            height={VIEW_HEIGHT}
                            width={VIEW_WIDTH}
                            onClick={this.viewOnclick}
                            onMouseDown={this.viewOnMouseDown}
                            onMouseUp={this.viewOnMouseUp}
                            onMouseMove={this.viewOnMouseMove}
                            transparent
                        />
                    </div>
                </div>
                {this.state.dialog && this.state.dialog()}
                <div className="control-footer">
                    <p>Zoom</p>
                    <MMSlider
                        min={MIN_VIEW_SCALE}
                        max={MAX_VIEW_SCALE}
                        step={5}
                        width={255}
                        value={this.state.view_scale}
                        onChange={this.setViewScale}
                    />
                    {!this.remove_live_mode && <MMButton onClick={this.toggleLive}>{this.state.live_mode ? "Disable live" : "Enable live"}</MMButton>}
                    <div>
                        <p>
                            {this.state.marked_elements_length}
                            &nbsp;armatur
                            {this.state.marked_elements_length === 1 ? "" : "er"}
                            &nbsp;er valgt
                        </p>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = state => ({
    data: state.data,
    websocket: state.websocket,
    login: state.login,
    browser: state.browser
});

export default connect(mapStateToProps)(FixturesComponent);
