import React from "react";
import {ReactSVG} from "react-svg";
import icoRemove from "../../../assets/ico-remove.svg";
import icoEdit from "../../../assets/ico-edit.svg";
import icoAddSmall from "../../../assets/ico-add-scenario-small.svg";
import "./FixturesHeader.less";

const FixturesHeader = React.memo(props => (
    <div className="FixturesHeader">
        <button
            type="button"
            className={`menu-item ${props.fixtures_exist && props.active_action === "delete" ? "active" : ""}`}
            onClick={props.deleteFixtures}
            disabled={!props.fixtures_exist}
        >
            <ReactSVG src={icoRemove} beforeInjection={svg => svg.classList.add("icon-remove-svg")} className="icon icon-remove"/>
            <p>
                Slet lampe
                <span style={{opacity: props.marked_elements_length !== 1 ? 1 : 0}}>r</span>
                <span style={{opacity: props.marked_elements_length === 0 ? 1 : 0}}>...</span>
            </p>
        </button>
        <button
            type="button"
            className={`menu-item ${props.fixtures_exist && props.active_action === "edit" ? "active" : ""}`}
            onClick={props.editFixtures}
            disabled={!props.fixtures_exist}
        >
            <ReactSVG src={icoEdit} beforeInjection={svg => svg.classList.add("icon-edit-svg")} className="icon icon-edit"/>
            <p>
                Redigér lampe
                <span style={{opacity: props.marked_elements_length !== 1 ? 1 : 0}}>r</span>
                <span style={{opacity: props.marked_elements_length === 0 ? 1 : 0}}>...</span>
            </p>
        </button>
        <button
            type="button"
            className={`menu-item ${props.active_action === "add" ? "active" : ""}`}
            onClick={props.addFixtures}
        >
            <ReactSVG src={icoAddSmall} beforeInjection={svg => svg.classList.add("icon-add-svg")} className="icon icon-add"/>
            <p>Tilføj lamper...</p>
        </button>
        <button
            type="button"
            className={`menu-item ${props.active_action === "add-wizard" ? "active" : ""}`}
            onClick={props.addFixturesWizard}
        >
            <ReactSVG src={icoAddSmall} beforeInjection={svg => svg.classList.add("icon-add-svg")} className="icon icon-add"/>
            <p>Tilføj lampemønster...</p>
        </button>
    </div>));
FixturesHeader.displayName = "FixturesHeader";

export default FixturesHeader;
