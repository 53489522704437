import React from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {push} from "react-router-redux";
import {withRouter} from "react-router-dom";
import {FixturesComponent} from "../Fixtures/Fixtures";
import ScenesHeader from "./ScenesHeader/ScenesHeader";
import {uuidv4} from "../../providers/data/dataMiddleware";
import MMTextInput from "../../elements/inputs/MMTextInput";
import MMDialogModal from "../../elements/layout/MMDialogModal";
import colorProvider from "../../providers/colors/color.provider";
import SceneEditor from "./SceneEditor/SceneEditor";
import {notificationsActions} from "../../providers/notifications/notificationsActions";
import {isNullable} from "../../providers/objectsAreEqual";
import {sortObjects} from "../../providers/sortObjects";

class Scenes extends FixturesComponent {
    constructor(props) {
        super(props);
        this.state.name = "";
        this.state.live_mode = false;
        this.remove_live_mode = true;
        this.fixture_editing_enabled = false;
    }

    componentDidMount() {
        super.componentDidMount();
        const uuid = this.props.location.pathname.split("/")[2];
        if (uuid) {
            this.onSceneChange(uuid);
        }
    }

    getScene = (uuid = this.state.uuid) => this.getElements("scene")
        .find(e => e.uuid === uuid);

    onDialogClose = () => {
        this.setState({
            dialog: null,
            active_action: null
        });
    };

    onDialogSubmit = uuid => {
        this.setTypeChanges("scene", {
            uuid,
            "webapp-name": this.state.name
        }, this.saveChanges);
    };

    addScene = (uuid = uuidv4()) => {
        this.setTypeChanges("scene", {
            name: uuid,
            uuid,
            "scene-component": [],
            "webapp-name": "Ny scene"
        }, () => {
            this.saveChanges();
            this.onSceneChange(uuid);
        });
    };

    deleteScene = uuid => {
        const scene = this.getScene(uuid);
        const dependent_cuelists = this.getElements("timeline")
            .filter(c => c.cue instanceof Array && c.cue.some(cue => cue.scene === scene.name));
        if (dependent_cuelists.length > 0) {
            this.props.dispatch({
                type: notificationsActions.SCENE_IN_USE,
                cuelists: dependent_cuelists
            });
            return;
        }

        this.setTypeChanges("scene", {
            uuid,
            delete: true
        }, () => {
            this.saveChanges();
            this.onSceneChange();
        });
    };

    map_scene_components_to_marked_elements = (from_scene, palettes, marked_fixtures) => {
        const scene_components = from_scene["scene-component"].map((el, index) => {
            const new_index = Math.floor(index * marked_fixtures.length / from_scene["scene-component"].length);
            return {
                ...el,
                index,
                new_index
            };
        });

        let min_index = 0;
        const non_fade_scene_components = scene_components.filter(sc => palettes.find(pal => pal.fade !== "yes" && sc.palette === pal.id))
            .map(sc => {
                let new_index = sc.new_index;
                if (new_index < min_index) {
                    new_index = min_index;
                }
                if (new_index >= marked_fixtures.length) {
                    new_index = marked_fixtures.length - 1;
                }
                min_index = new_index + 1;
                return {
                    ...sc,
                    new_index
                };
            });

        const fade_palette = palettes.find(pal => pal.fade === "yes") || {};
        return Array.from({length: marked_fixtures.length})
            .map((a, index) => {
                const sc = non_fade_scene_components.find(e => e.new_index === index);
                let palette = fade_palette.id;
                if (sc) {
                    palette = sc.palette;
                }
                return {
                    "dmx-fixture": marked_fixtures[index],
                    palette
                };
            })
            .filter(sc => sc.palette);
    };

    onCopy = event => {
        if (!this.state.uuid) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_COPY});
            return;
        }

        const scene = this.getScene();
        if (!scene) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_COPY});
            return;
        }

        const cloned_scene = _.cloneDeep(scene);
        if (scene["scene-component"] instanceof Array && this.state.marked_elements_length > 0) {
            const marked_fixtures = this.canvas_fixtures.filter(e => e.is_marked)
                .map(e => e.fixture.id);
            cloned_scene["scene-component"] = scene["scene-component"].filter(sc => marked_fixtures.includes(sc["dmx-fixture"]));
        }

        try {
            const data = JSON.stringify(cloned_scene);
            event.clipboardData.setData("text/plain", data);
            event.preventDefault();
        } catch (err) {
            console.error("Failed to copy data:", err);
            this.props.dispatch({type: notificationsActions.COPY_FAILED});
        }
    };

    onPaste = event => {
        const text = (event.clipboardData || window.clipboardData).getData('text');

        if (text.length === 0) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_PASTE});
            return;
        }
        let new_scene_data = null;
        try {
            new_scene_data = JSON.parse(text);
        } catch (e) {
            this.props.dispatch({type: notificationsActions.PASTE_INVALID_DATA});
            console.error("Failed to parse paste:", e);
            return;
        }

        const scene = this.getScene();
        if (scene) {
            new_scene_data["webapp-name"] = scene["webapp-name"];
            new_scene_data.name = scene.name;
            new_scene_data.uuid = scene.uuid;
        } else {
            const uuid = uuidv4();
            new_scene_data.name = uuid;
            new_scene_data.uuid = uuid;
            if (new_scene_data["webapp-name"]) {
                new_scene_data["webapp-name"] += " (Kopi)";
            } else {
                new_scene_data["webapp-name"] = "Kopieret scene";
            }
        }

        let marked_fixtures;

        if (this.state.marked_elements_length > 0) {
            marked_fixtures = this.canvas_fixtures.filter(e => e.is_marked)
                .map(e => e.fixture.id);
        } else {
            marked_fixtures = this.canvas_fixtures.map(e => e.fixture.id);
        }

        marked_fixtures = sortObjects(marked_fixtures, ["y", "x"], "fixture");

        new_scene_data["scene-component"] = this.map_scene_components_to_marked_elements(
            new_scene_data, this.getElements("palette"), marked_fixtures
        );

        for (const old_sc of scene["scene-component"]) {
            const fixture_conflict = new_scene_data["scene-component"].find(sc => sc["dmx-fixture"] === old_sc["dmx-fixture"]);
            if (!fixture_conflict) {
                new_scene_data["scene-component"].push(old_sc);
            }
        }

        event.preventDefault();
        this.setTypeChanges("scene", new_scene_data);
    };

    paintScene = scene => {
        const scene_components = scene["scene-component"];
        for (const canvas_fixture of this.canvas_fixtures) {
            const scene_component_index = scene_components.findIndex(sc => sc["dmx-fixture"] === canvas_fixture.fixture.id);
            if (scene_component_index >= 0) {
                const scene_component = scene_components[scene_component_index];
                const palette = this.getElements("palette")
                    .find(p => p.id === scene_component.palette);
                canvas_fixture.updateInUse(true);
                // canvas_fixture.updateText(scene_component_index + 1);
                if (palette) {
                    const color = colorProvider.getPaletteColor(palette);
                    const intensity = isNullable(scene_component.intensity) ? 100 : parseInt(scene_component.intensity);
                    if (color && color.values) {
                        if (color.type.key === "fade" || color.type.key === "transparent") {
                            canvas_fixture.updateOutputColor(color.type.key);
                        } else {
                            canvas_fixture.updateOutput(color.values, intensity);
                        }
                    }
                } else {
                    console.error(`Palette ${scene_component.palette} doesn't exist`);
                }
            } else {
                canvas_fixture.updateInUse(false);
                canvas_fixture.updateOutput([]);
                canvas_fixture.updateText("");
            }
            if (!this.do_repaint) {
                canvas_fixture.paint();
            }
        }
    };

    renameScene = uuid => {
        if (this.state.active_action === "rename") {
            this.setState({
                dialog: null,
                active_action: null
            });
            return;
        }

        const scene = this.getScene(uuid);
        const name = typeof scene["webapp-name"] === "string" ? scene["webapp-name"] : scene.name;

        this.setState({name});

        const dialog = () => (
            <MMDialogModal
                className="rename-dialog"
                title="Omdøb scene"
                width={375}
                height={220}
                closable
                onClose={this.onDialogClose}
                submit_title="OK"
                submit_disabled={this.state.name.length === 0}
                onSubmit={() => this.onDialogSubmit(uuid)}
            >
                <p className="title">Navn</p>
                <MMTextInput
                    value={this.state.name}
                    placeholder="Min scene"
                    onChange={(event, value) => this.setState({name: value})}
                />
            </MMDialogModal>);

        this.setState({
            dialog,
            active_action: "rename"
        });
    };

    onSceneChange = (uuid = null) => {
        if (uuid) {
            this.setState({uuid});
        } else {
            uuid = this.state.uuid;
        }
        const scene = this.getScene(uuid);
        if (scene) {
            this.props.dispatch(push(`/${this.props.location.pathname.split("/")[1]}/${uuid}`));
            this.paintScene(scene);
        } else {
            for (const canvas_fixture of this.canvas_fixtures) {
                canvas_fixture.reset();
                if (!this.do_repaint) {
                    canvas_fixture.paint();
                }
            }
        }
    };

    onDataUpdated = () => {
        super.onDataUpdated();
        const scene = this.getScene();
        if (scene) {
            this.paintScene(scene);
        }
    };

    editFixtures = () => {
        const marked_canvas_fixtures = this.canvas_fixtures.filter(e => e.is_marked);
        const dialog = () => <SceneEditor
            scene_uuid={this.state.uuid}
            fixture_id={marked_canvas_fixtures.map(e => e.fixture.id)}
            onClose={this.closePopupMenu}
        />;

        this.setState({
            dialog
        });
    };

    deleteFixtures = () => {
        const scene = this.getScene(this.state.uuid);
        if (scene && this.state.marked_elements_length > 0) {
            const scene_components = [...scene["scene-component"]];
            const marked_canvas_fixtures = this.canvas_fixtures.filter(e => e.is_marked);
            for (const canvas_fixture of marked_canvas_fixtures) {
                const index = scene_components.findIndex(sc => sc["dmx-fixture"] === canvas_fixture.fixture.id);
                if (index >= 0) {
                    scene_components.splice(index, 1);
                    canvas_fixture.updateInUse(false);
                    canvas_fixture.updateOutput([]);
                    if (!this.do_repaint) {
                        canvas_fixture.paint();
                    }
                }
            }
            this.setTypeChanges("scene", {
                uuid: this.state.uuid,
                "scene-component": scene_components
            });
        }
    };

    renderHeader = () => <ScenesHeader
        scenes={this.getElements("scene")}
        addScene={this.addScene}
        renameScene={this.renameScene}
        deleteFixtures={this.deleteFixtures}
        editFixtures={this.editFixtures}
        deleteScene={this.deleteScene}
        onSceneChange={this.onSceneChange}
        uuid={this.state.uuid}
        active_action={this.state.active_action}
        has_active_element={this.state.has_active_element}
        marked_elements_length={this.state.marked_elements_length}
    />;
}

const mapStateToProps = state => ({
    data: state.data,
    websocket: state.websocket,
    login: state.login,
    browser: state.browser
});

export default connect(mapStateToProps)(withRouter(Scenes));
