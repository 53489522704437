export const websocketActions = {
    WEBSOCKET_CONNECT: "WEBSOCKET_CONNECT",
    WEBSOCKET_DISCONNECT: "WEBSOCKET_DISCONNECT",
    WEBSOCKET_REGISTER_VARIABLE: "WEBSOCKET_REGISTER_VARIABLE",
    WEBSOCKET_UNREGISTER_VARIABLE: "WEBSOCKET_UNREGISTER_VARIABLE",
    WEBSOCKET_SET_VARIABLE: "WEBSOCKET_SET_VARIABLE",
    WEBSOCKET_RECONNECT: "WEBSOCKET_RECONNECT",

    // Dispatched by socket
    WEBSOCKET_CLOSED: "WEBSOCKET_CLOSED",
    WEBSOCKET_OPENED: "WEBSOCKET_OPENED",
    WEBSOCKET_VARIABLE_UPDATE: "WEBSOCKET_VARIABLE_UPDATE",
    WEBSOCKET_CONNECTION_FAILED: "WEBSOCKET_CONNECTION_FAILED"
};
