import React from "react";
import Select, {Option} from "rc-select";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";
import {isNullable} from "../../providers/objectsAreEqual";
import "./MMSelect.less";

export default class MMSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: !isNullable(this.props.value) ? props.value : "",
            dropdown_open: false
        };
    }

    componentDidUpdate(prevProps) {
        if (!isNullable(prevProps.value) && isNullable(this.props.value)) {
            this.setState({value: null});
        }
    }

    onChange = (value) => {
        this.setState({value});
        if (this.props.onChange) this.props.onChange(value);
    };

    onDropdownVisibleChange = dropdown_open => this.setState({dropdown_open});

    render() {
        return (
            <div className={`MMSelect ${this.props.className}`}>
                <Select
                    disabled={this.props.disabled}
                    value={this.props.value || this.state.value}
                    onChange={this.onChange}
                    mode={this.props.mode}
                    maxTagCount={this.props.maxTagCount}
                    onDropdownVisibleChange={this.onDropdownVisibleChange}
                >
                    {this.props.options instanceof Array && this.props.options.map(option => (
                        <Option key={option.key} value={option.key} disabled={option.disabled}>{option.value}</Option>))}
                </Select>
                {!this.props.disabled && (this.state.dropdown_open ? <CaretUpOutlined/> : <CaretDownOutlined/>)}
            </div>);
    }
}

MMSelect.defaultProps = {
    options: [],
    mode: "default"
};
