// The common Playback Controller API written in Python3 with the Flask framework

export const api_v1 = {
    VERSION: 1,
    LIGHT_CONFIGURATION: {
        GET: '/api/get_playlist',
        SET_RELOAD: '/api/set_playlist/reload',
        SET_RESTART: '/api/set_playlist'
    },
    SYSTEM_CONFIGURATION: {
        GET: '/api/get_options',
        SET: '/api/set_options'
    },
    TIME_CONFIGURATION: {
        GET: "/api/get_time",
        SET: "/api/set_time"
    },
    TIMESERVER_CONFIGURATION: {
        GET: "/api/get_timeserver",
        SET: "/api/set_timeserver"
    },
    VIDEOS: {
        GET: '/api/get_videos'
    },
    FIRE_TRIGGER: "/api/trigger/"
};
