import React from "react";
import {connect} from "react-redux";
import {LoadingOutlined} from "@ant-design/icons";
import MMModal from "../../../elements/layout/MMModal";
import MMButton from "../../../elements/buttons/MMButton";
import {setTypeChanges} from "../../../providers/setTypeChanges";
import {getElements} from "../../../providers/getElements";
import constants from "../../../less/motomuto_ras/constants";
import "./FixtureWizard.less";
import MMCanvas from "../../../elements/layout/MMCanvas";
import MMCodeEditor from "../../../elements/inputs/MMCodeEditor";
import MMTextInput from "../../../elements/inputs/MMTextInput";
import {parseJavaScript} from "../../../providers/parseJavaScript";
import objectsAreEqual from "../../../providers/objectsAreEqual";
import fixtureProvider from "../../../providers/fixtures/fixture.provider";
import {uuidv4} from "../../../providers/data/dataMiddleware";
import {dataActions} from "../../../providers/data/dataActions";
import MMInlineSelect from "../../../elements/inputs/MMInlineSelect";
import {APP_CONFIG} from "../../../config";
import {sortObjects} from "../../../providers/sortObjects";
import MMSelect from "../../../elements/inputs/MMSelect";

let lastState = null;

class FixtureWizard extends React.Component {
    canvas_height = 200;

    canvas_width = 900 - 15 * 2 - 40 - 170;

    graph_height = 50;

    graph_width = 100;

    presets = [
        {
            id: "circle",
            code: "graph_x => Math.sqrt(100 ** 2 - graph_x ** 2)\n"
                + "graph_x => -Math.sqrt(100 ** 2 - graph_x ** 2)"
        },
        {
            id: "square",
            code: "[0, 0],\n"
                + "[100, 0],\n"
                + "[100, 100],\n"
                + "[0, 100],\n"
                + "[0, 0]"
        },
        {
            id: "triangle",
            code: "[0, 0],\n"
                + "[50, -Math.sqrt(100**2-50**2)],\n"
                + "[100, 0],\n"
                + "[0, 0]"
        },
        {
            id: "sinus",
            code: "x => 0 <= x && x <= 100 && Math.sin(x / 5) * 5"
        },
        {
            id: "line",
            code: "[0, 0],\n"
                + "[100, 0]"
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            closing: false,
            universe: "1",
            offset: 1,
            type: "rgb",
            canvas_ref: null,
            graph_code: this.presets[0].code,
            number_of_fixtures: 10,
            fixtures_start_offset: 0,
            preset_refs: {}
        };

        if (lastState) {
            this.state.type = lastState.type;
            this.state.graph_code = lastState.graph_code;
            this.state.number_of_fixtures = lastState.number_of_fixtures;
            this.state.fixtures_start_offset = lastState.fixtures_start_offset;
        }

        // Bind functions to context
        this.setTypeChanges = setTypeChanges.bind(this);
        this.getElements = getElements.bind(this);

        if (this.props.previous_fixture_uuid) {
            const previous_fixture = this.getFixture(this.props.previous_fixture_uuid);
            if (previous_fixture) {
                this.state.universe = previous_fixture.universe;
                this.state.offset = parseInt(previous_fixture.offset) + fixtureProvider.getFixtureLength(previous_fixture);
                this.state.type = previous_fixture.type;
            }
        }
    }

    componentDidMount() {
        this.updateAvailableUniverses();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.canvas_ref !== this.state.canvas_ref
            || prevState.graph_code !== this.state.graph_code
            || prevState.number_of_fixtures !== this.state.number_of_fixtures
            || prevState.fixtures_start_offset !== this.state.fixtures_start_offset) {
            this.repaintCanvas();
        }
        if (!objectsAreEqual(prevState.preset_refs, this.state.preset_refs)) {
            for (const preset of this.presets) {
                this.drawPreset(this.state.preset_refs[preset.id], preset);
            }
        }

        if (!objectsAreEqual(prevProps.data.system_config_slaves, this.props.data.system_config_slaves)
            || !objectsAreEqual(prevProps.data.system_config, this.props.data.system_config)
            || !objectsAreEqual(prevProps.data.light_config, this.props.data.light_config)) {
            this.updateAvailableUniverses();
        }
    }

    updateAvailableUniverses = () => {
        let universe_options = this.getElements("driver")
            .map(universe => ({
                key: universe.universe,
                value: universe["webapp-name"] || universe.universe
            }));
        if (this.props.data.system_config) {
            if (this.props.data.system_config.universe instanceof Array) {
                for (const universe of this.props.data.system_config.universe) {
                    if (!universe_options.find(e => e.key === universe)) {
                        universe_options.push({
                            key: universe,
                            value: universe
                        });
                    }
                }
            }
        }

        if (this.props.data.system_config_slaves) {
            for (const [, config] of Object.entries(this.props.data.system_config_slaves)) {
                if (config.universe instanceof Array) {
                    for (const universe of config.universe) {
                        if (!universe_options.find(e => e.key === universe)) {
                            universe_options.push({
                                key: universe,
                                value: universe
                            });
                        }
                    }
                }
            }
        }
        universe_options.push({
            key: "dummy",
            value: "Dummy"
        });
        universe_options = sortObjects(universe_options, ["key"]);
        this.setState({universe_options});
    };

    getFixture = (uuid = this.state.fixture_uuid) => {
        const fixture = this.getElements("dmx-fixture")
            .filter(fix => fix.uuid === uuid && uuid);
        return fixture.length > 0 ? fixture[0] : null;
    };

    generateFixtures = () => {
        const graph_functions = parseJavaScript(this.state.graph_code);
        const graph_properties = MMCanvas.calculate_graph_properties(graph_functions, this.graph_height, this.graph_width,
            this.graph_width / 2, this.graph_height / 2);
        const elements = MMCanvas.calculate_element_coordinates(graph_properties, graph_functions, this.state.number_of_fixtures || 0,
            this.state.fixtures_start_offset || 0);

        if (!elements) {
            return [];
        }

        return elements.map((coords, index) => ({
            ...coords,
            id: uuidv4(),
            uuid: uuidv4(),
            universe: this.state.universe,
            offset: parseInt(this.state.offset) + index * fixtureProvider.getFixtureLength({type: this.state.type}),
            type: this.state.type
        }));
    };

    onClose = () => {
        this.setState({closing: true});

        if (this.props.onClose) {
            setTimeout(this.props.onClose, parseFloat(constants["modal-transition-duration"]));
        }
    };

    submit = () => {
        this.setState({loading: true});

        const fixtures = this.generateFixtures();

        lastState = {...this.state};
        this.props.dispatch({
            type: dataActions.SET_LIGHT_CONFIGURATION,
            changes: {playlist: {"dmx-fixture": fixtures}},
            callback: () => {
                if (this.props.submit) {
                    this.props.submit(fixtures);
                }
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            errorCallback: () => {
                this.setState({loading: false});
            }
        });
    };

    repaintCanvas = () => {
        if (this.state.canvas_ref) {
            const mm_canvas = this.state.canvas_ref;
            mm_canvas.clear();

            const x = this.canvas_width / 2;
            const y = this.canvas_height / 2;

            /*
            #### Various functions for plotting figures ####
            Circle:
            graph_x => Math.sqrt(60 ** 2 - graph_x ** 2)
            graph_x => -Math.sqrt(60 ** 2 - graph_x ** 2)

            Rectangle:
            [0, 0],
            [100, 0],
            [100, 100],
            [0, 100],
            [0, 0]
             */

            const graph_functions = parseJavaScript(this.state.graph_code);
            mm_canvas.draw_graph(graph_functions, x, y);

            const elements = mm_canvas.calculate_element_coordinates_on_graph(graph_functions, this.state.number_of_fixtures || 0,
                x, y, this.state.fixtures_start_offset || 0);

            if (elements) {
                for (const [i, element] of elements.entries()) {
                    mm_canvas.ctx.fillStyle = "red";
                    mm_canvas.rectangle(element.x, element.y, 10);
                    mm_canvas.ctx.fillStyle = "white";
                    mm_canvas.text(i + 1, element.x, element.y);
                }
            }
        }
    };

    onEditorChange = (event, value) => {
        this.setState({graph_code: value});
    };

    drawPreset = (ref, preset) => {
        if (ref) {
            ref.clear();
            ref.draw_graph(parseJavaScript(preset.code), 20, 20);
        }
    };

    render() {
        return (
            <MMModal
                width={900}
                closable={!this.state.loading}
                className={`FixtureWizard ${this.props.className ? this.props.className : ""} ${this.state.loading ? "loading" : ""}`}
                close={this.state.closing}
                onClose={() => {
                    this.setState({closing: true});
                    if (this.props.onClose) {
                        this.props.onClose();
                    }
                }}
                header={[
                    <p className="action-title" key="action-title">Tilføj lampemønster</p>,
                    <LoadingOutlined className="loading-icon" key="loading-icon"/>
                ]}
                footer={
                    <div className="align-right">
                        <MMButton
                            className="cancel grey"
                            style={{width: 200}}
                            onClick={this.onClose}
                            disabled={this.state.loading}
                        >
                            Annullér
                        </MMButton>
                        <MMButton
                            className="submit"
                            style={{width: 200}}
                            disabled={this.state.loading}
                            onClick={this.submit}
                        >
                            Gem ændringer
                        </MMButton>
                    </div>
                }
            >
                <div className="canvas-view">
                    <div className="presets">
                        {this.presets.map((preset) => <MMCanvas
                            className={this.state.graph_code === preset.code ? "selected" : ""}
                            ref={ref => !this.state.preset_refs[preset.id] && this.setState(state => ({
                                preset_refs: {
                                    ...state.preset_refs,
                                    [preset.id]: ref
                                }
                            }))}
                            key={preset.id}
                            width={40}
                            height={40}
                            onClick={() => this.setState({graph_code: preset.code})}
                            transparent
                        />)}
                    </div>
                    <MMCanvas
                        ref={canvas_ref => !this.state.canvas_ref && this.setState({canvas_ref})}
                        className="fixture-wizard-canvas"
                        width={this.canvas_width}
                        height={this.canvas_height}
                        style={{
                            width: this.canvas_width,
                            height: this.canvas_height
                        }}
                    />
                    <div className="fixture-settings">
                        <div className="input-group">
                            <p>Universe</p>
                            <MMSelect
                                className="block"
                                value={this.state.universe}
                                options={this.state.universe_options}
                                onChange={value => this.setState({universe: value})}
                            />
                        </div>
                        <div className="input-group">
                            <p>Adresse</p>
                            <MMTextInput
                                type="number"
                                width={150}
                                min={1}
                                max={512 - fixtureProvider.getFixtureLength(this.state.type)}
                                value={this.state.offset}
                                onChange={(event, value) => this.setState({offset: parseInt(value)})}
                            />
                        </div>
                        <div className="input-group">
                            <p>Type</p>
                            <MMInlineSelect
                                className="block"
                                value={this.state.type}
                                options={APP_CONFIG.COLOR_TYPES.filter(type => type.fixture_type !== false)}
                                onChange={value => this.setState({type: value})}
                            />
                        </div>
                    </div>
                </div>
                <div className="graph-settings">
                    <div className="input-group">
                        <p>Antal lamper</p>
                        <MMTextInput
                            type="number"
                            width={150}
                            min={0}
                            value={this.state.number_of_fixtures}
                            onChange={(event, value) => this.setState({number_of_fixtures: parseInt(value)})}
                        />
                    </div>
                    <div className="input-group">
                        <p>Forskydning</p>
                        <MMTextInput
                            type="number"
                            width={150}
                            min={0}
                            value={this.state.fixtures_start_offset}
                            onChange={(event, value) => this.setState({fixtures_start_offset: parseInt(value)})}
                        />
                    </div>
                </div>
                <MMCodeEditor
                    height={200}
                    width={900 - 15 * 2}
                    language="javascript"
                    value={this.state.graph_code}
                    onChange={this.onEditorChange}
                />
            </MMModal>);
    }
}

const mapStateToProps = state => ({
    data: state.data
});

export default connect(mapStateToProps)(FixtureWizard);
