export const color_types = [
    {
        key: "rgb",
        value: "RGB",
        block_size: 3,
        definition: ["red", "green", "blue"],
        name: ["Rød", "Grøn", "Blå"]
    },
    {
        key: "rgbw",
        value: "RGBW",
        block_size: 4,
        definition: ["red", "green", "blue", "white"],
        name: ["Rød", "Grøn", "Blå", "Hvid"]
    },
    {
        key: "rgbww",
        value: "RGBWW",
        block_size: 5,
        definition: ["red", "green", "blue", "cold-white", "warm-white"],
        name: ["Rød", "Grøn", "Blå", "Kold", "Varm"]
    },
    {
        key: "white",
        value: "White",
        block_size: 1,
        definition: ["white"],
        name: ["Hvid"]
    },
    {
        key: "aw",
        value: "AW",
        block_size: 2,
        definition: ["amber", "white"],
        name: ["Rav", "Hvid"]
    },
    {
        key: "raw",
        value: "RAW",
        block_size: 0
    },
    {
        key: "fade",
        value: "Fade",
        block_size: 0,
        fixture_type: false,
        color_type: false
    },
    {
        key: "transparent",
        value: "Transparent",
        block_size: 0,
        fixture_type: false,
        color_type: false
    }
];
