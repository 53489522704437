import React from "react";
import "./Cuelist.less";
import {ReactSVG} from "react-svg";
import icoAddScenario from "../../../assets/ico-add-scenario.svg";
import icoDots from "../../../assets/ico-dots.svg";
import icoClose from "../../../assets/ico-close.svg";
import {APP_CONFIG} from "../../../config";

export const CuelistState = {
    override: "override",
    on: "on",
    partly_on: "partly_on",
    off: "off",
    empty: "",
    gone: "gone"
};

export default class Cuelist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {actions_open: false};
    }

    componentDidMount() {
        document.body.addEventListener("click", this.handleClick, false);
    }

    componentWillUnmount() {
        document.body.removeEventListener("click", this.handleClick, false);
    }

    handleClick = (event) => {
        if (!event.target.closest(".open") && !event.target.closest(".single")) {
            this.setState({actions_open: false});
        }
    };

    onClick = () => {
        if (this.props.state !== CuelistState.override && this.props.onClick) { // Check if cuelist is locked and if so, ignore click
            this.props.onClick(this.props.cuelist, this.props.state);
        }
    };

    onClickActions = (event) => {
        event.stopPropagation(); // Don't trigger onClick function
        if (this.props.actions.length === 1) {
            this.props.actions[0].onClick(this.props.cuelist, this.props.state);
        } else {
            // Open dialog
            this.setState(state => ({
                actions_open: !state.actions_open
            }));
        }
    };

    render() {
        return (
            <div
                className={"CuelistElement " + this.props.state}
                onClick={this.props.state !== CuelistState.gone ? this.onClick : null}
            >
                {this.props.state !== CuelistState.gone && (
                    <div>
                        {this.props.actions && this.props.actions.length > 0
                        && (
                            <div
                                className={["action-container",
                                    this.state.actions_open ? "open" : "closed",
                                    this.props.actions.length === 1 ? "single" : ""].join(" ")}
                            >
                                <div className="action-button" onClick={this.onClickActions}>
                                    <ReactSVG src={this.state.actions_open ? icoClose : icoDots} beforeInjection={svg => svg.classList.add("dots")}/>
                                </div>
                                {this.props.actions.map(action => (
                                    <div
                                        key={action.id}
                                        className="action-element"
                                        onClick={(event) => {
                                            event.stopPropagation(); // Don't trigger onClick function
                                            action.onClick(this.props.cuelist, this.props.state);
                                            this.setState({actions_open: false});
                                        }}
                                    >
                                        <ReactSVG src={action.icon} beforeInjection={svg => svg.classList.add(action.id)}/>
                                    </div>
                                ))}
                            </div>)}
                        <div className="ico-container">
                            {this.props.phyBtn && (
                                <div className="phybtn-container">
                                    <p>{this.props.phyBtn}</p>
                                </div>)}
                            {this.props.cuelist && APP_CONFIG.SHOW_PRIORITY_ON_CUELIST && (
                                <div className="priority-container">
                                    <p>{this.props.cuelist.priority}</p>
                                </div>)}
                            {this.props.color
                            && <div className="color-container" style={{borderLeftColor: this.props.color}}/>}
                            <ReactSVG src={this.props.icon} beforeInjection={svg => svg.classList.add("scenario-icon-svg")}/>
                        </div>
                        <div className="id-container">
                            <p>{this.props.displayName}</p>
                        </div>
                    </div>
                )}
            </div>);
    }
}

Cuelist.defaultProps = {
    displayName: "Tom",
    icon: icoAddScenario,
    state: CuelistState.empty,
    phyBtn: null,
    location: "",
    cuelist: null,
    actions: []
};
