import {MMComponent} from "./MMComponent";
import {notificationsActions} from "../../providers/notifications/notificationsActions";

export default class MMPalettesComponent extends MMComponent {
    constructor(props) {
        super(props, "palette");
    }

    deletePalette = (uuid, force = false) => new Promise((resolve, reject) => {
        if (!force) {
            const palette = this.getElement(uuid);
            const scenes = this.getScenesUsingPalette(palette.id);
            if (scenes.length > 0) {
                this.props.dispatch({
                    type: notificationsActions.PALETTE_IN_USE,
                    scenes
                });
                return reject();
            }
        }
        this.setTypeChanges({
            uuid,
            delete: true
        })
            .then(resolve);
    });

    getScenesUsingPalette = palette_id => this.getElements("scene")
        .map((a, index) => ({
            ...a,
            index
        }))
        .filter(b => b["scene-component"] && b["scene-component"].some(sc => sc.palette === palette_id));
}
