import _ from "lodash";
import {merge_by_uuid} from "./data/dataMiddleware";

export function setTypeChanges(type, changes, callback) {
    return new Promise(resolve => {
        if (!changes || changes.length === 0) {
            if (callback) {
                callback();
            }
            return resolve();
        }
        this.setState(state => {
            let state_changes = {};
            if (state.changes) {
                // Always modify a copy of the state
                state_changes = _.cloneDeep(state.changes);
            }
            if (!state_changes.light_config) {
                state_changes.light_config = {};
            }
            if (!state_changes.light_config.playlist) {
                state_changes.light_config.playlist = {};
            }
            if (!state_changes.light_config.playlist[type]) {
                state_changes.light_config.playlist[type] = [];
            }
            state_changes.light_config.playlist[type] = merge_by_uuid(state_changes.light_config.playlist[type], changes);
            return {changes: state_changes};
        }, () => {
            if (callback) {
                callback();
            }
            resolve();
        });
    });
}
