import React from "react";
import {connect} from "react-redux";

class PullDataComponent extends React.PureComponent {
    ismounted = false;

    timeoutTimer;

    constructor(props) {
        super(props);
        this.state = {
            pullFinished: false,
            document_loaded: false
        };
        this.doPull(false);
    }

    componentDidMount() {
        this.ismounted = true;
        document.addEventListener('readystatechange', event => {
            if (event.target.readyState === "complete") {
                this.documentLoaded();
            }
        });
        // Setup a failsafe
        this.timeoutTimer = setTimeout(this.documentLoaded, 2000);
    }

    componentWillUnmount() {
        this.ismounted = false;
        clearTimeout(this.timeoutTimer);
    }

    doPull = (setState = true) => {
        if (setState) {
            this.setState({
                pullFinished: false
            });
        }
        const callbackPromises = [];
        for (const type of this.props.actions) {
            callbackPromises.push(new Promise((resolve, reject) => {
                this.props.dispatch({
                    type,
                    callback: resolve,
                    errorCallback: reject
                });
            }));
        }
        Promise.all(callbackPromises)
            .then(() => {
                this.pullComplete();
                if (this.props.onComplete) {
                    this.props.onComplete();
                }
            })
            .catch((error) => {
                console.error("Pull failed:", error);
                this.pullComplete();
                if (this.props.onError) {
                    this.props.onError();
                }
            });
    };

    pullComplete = () => {
        if (this.ismounted) {
            this.setState({pullFinished: true});
        }
    };

    documentLoaded = () => {
        if (this.ismounted) {
            this.setState({document_loaded: true});
        }
    };

    render() {
        return (
            <div>
                {(!this.state.pullFinished || !this.state.document_loaded) && this.props.loadingLayout}
                {this.props.children}
            </div>);
    }
}

export default connect(null, null, null, {forwardRef: true})(PullDataComponent);
