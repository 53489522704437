import fixtureProvider from "../../../providers/fixtures/fixture.provider";
import objectsAreEqual from "../../../providers/objectsAreEqual";

const COORDS_TYPE = "coords";

export default class CanvasFixture {
    constructor(mm_canvas, fixture, size, scale, padding) {
        this.transparent_img = new window.Image();
        this.transparent_img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQB'
            + 'BZG9iZSBJbWFnZVJlYWR5ccllPAAAABZJREFUeNpi2r9//38gYGAEESAAEGAAasgJOgzOKCoAAAAASUVORK5CYII=';

        this.mm_canvas = mm_canvas;
        this.fixture = fixture;
        this.next_canvas_fixture = null;
        this.size = size;
        this.scale = scale;
        this.padding = padding;
        this.is_active = false;
        this.is_marked = false;
        this.in_use = false;
        this.output = [];
        this.intensity = 100;
        this.text = "";
        this.unpainted_changes = true;
        this.coords = this.getFixtureOffsetCoordinates();
        this.coords_snap = this.getFixtureOffsetCoordinatesSnap();
        this.output_color = fixtureProvider.getFixtureColor(this.fixture, this.output);
    }

    getFixtureOffsetCoordinates = () => {
        const fixture_x = (parseFloat(this.fixture.x) + this.padding) * this.scale;
        const fixture_y = (parseFloat(this.fixture.y) + this.padding) * this.scale;
        return [fixture_x, fixture_y];
    };

    getFixtureOffsetCoordinatesSnap = () => {
        const fixture_x = (Math.round(parseFloat(this.fixture.x)) + this.padding) * this.scale;
        const fixture_y = (Math.round(parseFloat(this.fixture.y)) + this.padding) * this.scale;
        return [fixture_x, fixture_y];
    };

    setStartCoords = () => {
        this.start_coords = {
            x: this.fixture.x,
            y: this.fixture.y
        };
    };

    updateMarked = is_marked => {
        if (is_marked !== this.is_marked) {
            this.is_marked = is_marked;
            this.unpainted_changes = true;
            return true;
        }
        return false;
    };

    updateActive = is_active => {
        if (is_active !== this.is_active) {
            this.is_active = is_active;
            this.unpainted_changes = true;
        }
    };

    updateInUse = in_use => {
        if (in_use !== this.in_use) {
            this.in_use = in_use;
            this.unpainted_changes = true;
        }
    };

    updateOutput = (output, intensity = 100) => {
        if (!objectsAreEqual(output, this.output) || intensity !== this.intensity) {
            this.output = output;
            this.intensity = intensity;
            this.output_color = fixtureProvider.getFixtureColor(this.fixture, this.output, this.intensity);
            this.unpainted_changes = true;
        }
    };

    updateOutputColor = output_color => {
        if (output_color !== this.output_color) {
            this.output = "UNSET";
            this.output_color = output_color;
            this.unpainted_changes = true;
        }
    };

    updateText = text => {
        if (text !== this.text) {
            this.text = text;
            this.unpainted_changes = true;
        }
    };

    updateFixture = fixture => {
        if (!objectsAreEqual(fixture, this.fixture)) {
            this.fixture = fixture;
            this.coords = this.getFixtureOffsetCoordinates();
            this.coords_snap = this.getFixtureOffsetCoordinatesSnap();
            this.unpainted_changes = true;
            return true;
        }
        return false;
    };

    updateNextFixture = fixture => {
        this.next_canvas_fixture = fixture;
    };

    updateScale = scale => {
        if (scale !== this.scale) {
            this.scale = scale;
            this.coords = this.getFixtureOffsetCoordinates();
            this.coords_snap = this.getFixtureOffsetCoordinatesSnap();
            this.unpainted_changes = true;
        }
    };

    updateCanvas = canvas => {
        if (canvas !== this.mm_canvas) {
            this.mm_canvas = canvas;
            this.unpainted_changes = true;
        }
    };

    reset = () => {
        this.updateMarked(false);
        this.updateActive(false);
        this.updateInUse(false);
        this.updateOutput([]);
    };

    forcePaint = () => {
        this.unpainted_changes = true;
        this.paint();
    };

    paint = () => {
        if (this.unpainted_changes && this.mm_canvas && this.mm_canvas.ctx) {
            this.unpainted_changes = false;
            // Draw fixture border
            if (this.is_active) {
                // Element is active
                this.mm_canvas.ctx.fillStyle = "#0000ff";
            } else if (this.is_marked) {
                // Element is marked
                this.mm_canvas.ctx.fillStyle = "#00ff00";
            } else if (this.in_use) {
                // Element is in use
                this.mm_canvas.ctx.fillStyle = "#ff0000";
            } else {
                this.mm_canvas.ctx.fillStyle = "black";
            }

            this.mm_canvas.rectangle(this[COORDS_TYPE][0], this[COORDS_TYPE][1], this.size * this.scale + 5);

            // Draw fixture
            if (this.output_color === "fade") {
                const center_x = this[COORDS_TYPE][0];
                const center_y = this[COORDS_TYPE][1];
                const size = this.size * this.scale;

                const grd = this.mm_canvas.ctx.createLinearGradient(center_x - size / 2, center_y - size / 2,
                    center_x + size / 2, center_y - size / 2);
                grd.addColorStop(0, "white");
                grd.addColorStop(1, "black");
                this.mm_canvas.ctx.fillStyle = grd;
            } else if (this.output_color === "transparent") {
                this.mm_canvas.ctx.fillStyle = "white";
                this.mm_canvas.rectangle(this[COORDS_TYPE][0], this[COORDS_TYPE][1], this.size * this.scale);
                this.mm_canvas.ctx.fillStyle = this.mm_canvas.ctx.createPattern(this.transparent_img, "repeat");
            } else {
                this.mm_canvas.ctx.fillStyle = this.output_color;
            }
            this.mm_canvas.rectangle(this[COORDS_TYPE][0], this[COORDS_TYPE][1], this.size * this.scale);
            this.mm_canvas.ctx.fillStyle = "white";
            if (this.text) {
                this.mm_canvas.text(this.text, this[COORDS_TYPE][0], this[COORDS_TYPE][1], 1.5 * this.size * this.scale);
            }
        }
    };

    paintLink = () => {
        // Draw link to the next fixture
        if (this.mm_canvas && this.next_canvas_fixture && this.next_canvas_fixture.fixture.universe === this.fixture.universe
            && parseInt(this.next_canvas_fixture.fixture.offset)
            === parseInt(this.fixture.offset) + fixtureProvider.getFixtureLength(this.fixture)) {
            // Draw the line
            this.mm_canvas.line(this[COORDS_TYPE][0], this[COORDS_TYPE][1],
                this.next_canvas_fixture[COORDS_TYPE][0], this.next_canvas_fixture[COORDS_TYPE][1]);

            // Draw an arrow on the line
            const center = [
                this[COORDS_TYPE][0] + (this.next_canvas_fixture[COORDS_TYPE][0] - this[COORDS_TYPE][0]) / 2,
                this[COORDS_TYPE][1] + (this.next_canvas_fixture[COORDS_TYPE][1] - this[COORDS_TYPE][1]) / 2
            ];

            this.mm_canvas.ctx.fillStyle = "black";
            this.mm_canvas.arrow(this[COORDS_TYPE][0], this[COORDS_TYPE][1], center[0], center[1], 5);
        }
    };
}
