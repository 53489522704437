import React from "react";
import "./MMButton.less";
import {ReactSVG} from "react-svg";

const MMButton = React.memo((props) => {
    let style = {marginRight: 20};
    if (!props.children) {
        style = {margin: "auto"};
    }
    return (
        <button
            className={"MMButton " + (props.className || "")}
            type="submit"
            onClick={props.onClick}
            disabled={props.loading || (typeof props.disabled === "function" ? props.disabled() : props.disabled)}
            style={props.style}
        >
            {(() => {
                if (!props.ico) {
                    return null;
                }
                if (props.ico.includes(".svg")) {
                    return <ReactSVG
                        src={props.ico}
                        alt=""
                        className="ico"
                        beforeInjection={svg => svg.classList.add("MMButton-svg")}
                        style={style}
                    />;
                }
                return <img src={props.ico} alt="" className="ico" style={style}/>;
            })()}
            <div className="text">
                <p className={props.loading ? "loading" : ""}>{props.children}</p>
            </div>
        </button>
    );
});

MMButton.displayName = "MMButton";
export default MMButton;
