import getActiveCuelists from "./getActiveCuelists";

export default function getActiveScenarios() {
    if (!this.props.data || !this.props.data.light_config || !this.props.data.light_config.playlist || !this.props.data.light_config.playlist.scene) {
        return [];
    }

    // Get scenarios
    return getActiveCuelists.bind(this)().map(cuelist => {
        const scenario = this.props.data.light_config.playlist.scene.filter(
            scene => cuelist.cue && cuelist.cue.find(cue => cue.scene === scene.name)
        );
        return scenario.length > 0 ? scenario[0] : null;
    }).filter(scenario => scenario);
}
