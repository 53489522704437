import React from "react";
import "./Triggers.less";
import {notificationsActions} from "../../providers/notifications/notificationsActions";
import {MMComponent} from "../../elements/components/MMComponent";
import MMTriggersComponent from "../../elements/components/MMTriggersComponent";

class TriggersWrapper extends MMTriggersComponent {
    onCopy = event => {
        try {
            const data = JSON.stringify(this.getElements());
            event.clipboardData.setData("text/plain", data);
            event.preventDefault();
        } catch (err) {
            console.error("Failed to copy data:", err);
            this.props.dispatch({type: notificationsActions.COPY_FAILED});
        }
    };

    onPaste = event => {
        const text = (event.clipboardData || window.clipboardData).getData('text');

        if (text.length === 0) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_PASTE});
            return;
        }
        let new_triggers = null;
        try {
            new_triggers = JSON.parse(text);
        } catch (e) {
            this.props.dispatch({type: notificationsActions.PASTE_INVALID_DATA});
            console.error("Failed to parse paste:", e);
            return;
        }

        if (new_triggers.length === 0) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_PASTE});
            return;
        }

        this.setTypeChanges(new_triggers);
        event.preventDefault();
    };

    render() {
        const elements = this.getElements();
        return (
            <div className="TriggersPage">
                {React.cloneElement(this.props.children, {
                    elements,
                    setTypeChanges: this.setTypeChanges,
                    fireTrigger: this.fireTrigger,
                    deleteTrigger: this.deleteTrigger,
                    data: this.props.data,
                })}
                {this.renderSubmit()}
            </div>
        );
    }
}

export default MMComponent.connect(TriggersWrapper);
