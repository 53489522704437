import _ from "lodash";
import {APP_CONFIG} from "../config";
import {arraysContainSameElement} from "./data/dataMiddleware";
import {toArray} from "./toArray";

export default function getActiveCuelists() {
    if (!this.props.websocket || !(this.props.websocket.cuelists instanceof Array)) return [];

    // Get objects for all active cuelists
    let active_cuelists = [];
    if (this.props.data.light_config && this.props.data.light_config.playlist && this.props.data.light_config.playlist.timeline instanceof Array) {
        const active_cuelist_ids = [];
        let partly_active_cuelist_ids = [];

        const controllers = Object.keys(this.props.websocket)
            .filter(ip => this.props.websocket[ip].connected);
        for (const controller of controllers) {
            if (this.props.websocket[controller].cuelists instanceof Array) {
                partly_active_cuelist_ids.push(...this.props.websocket[controller].cuelists);
            }
        }
        // Remove duplicates
        partly_active_cuelist_ids = [...new Set(partly_active_cuelist_ids)];

        for (const partly_active_cuelist_id of partly_active_cuelist_ids) {
            if (!controllers.some(controller => !(this.props.websocket[controller].cuelists instanceof Array)
                || !this.props.websocket[controller].cuelists.includes(partly_active_cuelist_id))) {
                active_cuelist_ids.push(partly_active_cuelist_id);
            }
        }

        active_cuelists = partly_active_cuelist_ids.map(cuelist_name => {
            const cuelist = _.cloneDeep(this.props.data.light_config.playlist.timeline.find(timeline => timeline.name === cuelist_name));
            if (cuelist) {
                cuelist.partly_active = !active_cuelist_ids.includes(cuelist.name);
            }
            return cuelist;
        })
            .filter(cuelist => cuelist);
    }
    if (!APP_CONFIG.SHOW_ALL_ACTIVE_CUELISTS) {
        if (APP_CONFIG.SCENARIOS instanceof Array) {
            // Get highest priority at all locations
            const highest_priorities = {};

            for (const location of APP_CONFIG.SCENARIOS) {
                const active_cuelists_location = active_cuelists.filter(cuelist => arraysContainSameElement(cuelist.location, location.location));
                highest_priorities[location.location] = Math.max(...active_cuelists_location.map(cuelist => parseInt(cuelist.priority)));
            }

            // Remove cuelists with a lower priority at this location
            active_cuelists = active_cuelists.filter(cuelist => {
                // Check whether cuelist has the highest priority in any of its locations
                for (const cuelist_location of toArray(cuelist.location, false)) {
                    if (cuelist.priority >= highest_priorities[cuelist_location]) {
                        return true;
                    }
                }
                return false;
            });
        } else {
            const highest_priority = Math.max(...active_cuelists.map(cuelist => parseInt(cuelist.priority)));
            active_cuelists = active_cuelists.filter(cuelist => cuelist.priority >= highest_priority);
        }
    }

    return active_cuelists;
}

export function getHighestPriorityCuelist(location = null) {
    let active_cuelists = getActiveCuelists.bind(this)();
    if (location) {
        active_cuelists = active_cuelists.filter(active_cuelist => active_cuelist.location === location);
    }
    if (active_cuelists.length === 0) return null;
    return active_cuelists.reduce((prev, current) => (parseInt(prev.priority) > parseInt(current.priority) ? prev : current));
}
