import {APP_CONFIG} from "../../config";
import {websocketActions} from "./websocketActions";
import {loginActions} from "../login/loginActions";

const unSplit = (arr, start, delim) => {
    let assembled = "";
    for (let i = start; i < arr.length; i++) {
        assembled += arr[i] + delim;
    }
    return assembled.slice(0, -1);
};

// Do a Promise.reject() after 500ms
const rejectDelay = (error) => new Promise((resolve, reject) => {
    setTimeout(() => reject(error), 500);
});

const sleep = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds));

export default class WebSocketInstance {
    pingInterval = null;

    websocket = null;

    lastResponseTime = null;

    registeredVariables = [];

    constructor(url, registeredVariables = []) {
        this.url = url;
        this.registeredVariables = registeredVariables;
    }

    createWSURL = store => {
        const auth_type = store.getState().login ? store.getState().login.auth_type : null;
        const active_project = store.getState().data.active_project || 0;
        let params_string = "/?project=" + active_project;
        if (auth_type === "login") {
            params_string += "&token=" + store.getState().login.token;
        }

        let server_base_url = this.url.replace(/\//g, "")
            .replace("http:", "ws://")
            .replace("https:", "wss://");
        if (!server_base_url.startsWith("ws://") && !server_base_url.startsWith("wss://")) {
            server_base_url = "ws://" + server_base_url;
        }
        let server_port = APP_CONFIG.WEBSOCKET_PORT || ":40000";
        if (APP_CONFIG.WEBSOCKET_PORT === "") {
            server_port = "";
        }
        if (server_port) {
            server_base_url.replace(":" + window.location.port, "");
        }
        return server_base_url + server_port + params_string;
    };

    doConnect = (store) => new Promise((resolve, reject) => {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
            return;
        }

        if (this.websocket && this.websocket.readyState !== WebSocket.CLOSED) {
            // Wait 2000 ms for websocket to close or open, then call doConnect again
            setTimeout(() => this.doConnect(store)
                .catch(reject), 2000);
            return;
        }
        this.disconnect();
        const url = this.createWSURL(store);
        this.websocket = new WebSocket(url);
        this.websocket.onerror = reject;
        this.websocket.onopen = () => this.onOpen(store);
    });

    doPing = store => {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
            if (this.lastResponseTime !== null && (new Date()).getTime() - this.lastResponseTime > 20000) {
                // More than 5 seconds has passed since last response from server, kill connection
                console.error("WebSocket ping timed out, closing connection to " + this.url);
                store.dispatch({
                    type: websocketActions.WEBSOCKET_CONNECTION_FAILED,
                    url: this.url
                });
                // Try to reconnect
                this.disconnect();
                this.connect(store);
            } else {
                this.websocket.send("");
            }
        }
    };

    doRegister = async (variable) => {
        if (this.registeredVariables.includes(variable)) return; // already registered
        this.registeredVariables = [...new Set([...this.registeredVariables, variable])];
        if (!this.websocket) return;
        while (this.websocket.readyState === WebSocket.CONNECTING) {
            // eslint-disable-next-line no-await-in-loop
            await sleep(500);
            if (!this.websocket) return;
        }
        if (this.websocket.readyState !== WebSocket.OPEN) return; // Fail silently
        // noinspection JSUnresolvedFunction
        this.websocket.send(`R:${variable}`);
    };

    doUnRegister = async (variable) => {
        const index = this.registeredVariables.indexOf(variable);
        if (index < 0) return; // already unregistered
        this.registeredVariables.splice(index, 1);
        if (!this.websocket) return;
        while (this.websocket.readyState === WebSocket.CONNECTING) {
            // eslint-disable-next-line no-await-in-loop
            await sleep(500);
            if (!this.websocket) return;
        }
        if (this.websocket.readyState !== WebSocket.OPEN) return; // Fail silently
        // noinspection JSUnresolvedFunction
        this.websocket.send(`U:${variable}`);
    };

    doSet = async (variable, value) => {
        if (!this.websocket) return false;
        while (this.websocket.readyState === WebSocket.CONNECTING) {
            // eslint-disable-next-line no-await-in-loop
            await sleep(500);
            if (!this.websocket) return false;
        }
        if (this.websocket.readyState !== WebSocket.OPEN) return false;
        if (value === undefined) {
            value = "";
        }
        if (value instanceof Object) {
            value = JSON.stringify(value);
        }
        this.websocket.send(`S:${variable}:${value}`);
    };

    connect = (store, action) => {
        if ((!this.websocket || this.websocket.readyState === WebSocket.CLOSED) && store.getState().login.loggedIn) {
            // Try connecting 5 times with a delay of 500ms before failing
            let p = Promise.reject();
            for (let i = 0; i < 5; i++) {
                p = p.catch(() => this.doConnect(store))
                    .catch(rejectDelay);
            }

            // Handle connection failure after 5 retries
            p.catch(
                () => {
                    store.dispatch({
                        type: websocketActions.WEBSOCKET_CONNECTION_FAILED,
                        url: this.url,
                        callback: action && action.errorCallback
                    });
                    // Try to reconnect
                    this.connect(store, action);
                }
            );
        }
    };

    onOpen = store => {
        this.websocket.onerror = event => this.onError(event, store);
        this.websocket.onclose = event => this.onClose(event, store);
        // Register all previously registered variables
        for (const variable of this.registeredVariables) {
            // noinspection JSUnresolvedFunction
            this.websocket.send(`R:${variable}`);
        }

        // Send a ping frame every second, this forces the browser to check whether or not the websocket connection is alive.
        // This also resolves a bug in the Safari browser, where the socket would hang forever, and never call onclose().
        this.pingInterval = setInterval(() => this.doPing(store), 1000);
        this.websocket.onmessage = message => this.onMessage(message, store);

        store.dispatch({
            type: websocketActions.WEBSOCKET_OPENED,
            url: this.url
        });
    };

    onMessage = (message, store) => {
        const dataArr = message.data.split(":");
        // Epoch ms, when response is received
        this.lastResponseTime = (new Date()).getTime();

        if (dataArr.length > 0) {
            switch (dataArr[0]) {
                case "S": {
                    const raw_value = unSplit(dataArr, 2, ":");
                    let parsed_value;
                    try {
                        parsed_value = JSON.parse(raw_value);
                    } catch {
                        // Maybe not JSON, send out raw value
                        parsed_value = raw_value;
                    }
                    if (!parsed_value) {
                        return;
                    }

                    store.dispatch({
                        type: websocketActions.WEBSOCKET_VARIABLE_UPDATE,
                        key: dataArr[1],
                        value: parsed_value,
                        url: this.url
                    });
                    break;
                }
                case "error": {
                    if (dataArr[1] === "Invalid token") {
                        store.dispatch({
                            type: loginActions.EXPIRED_TOKEN
                        });
                    }
                    break;
                }
                default:
                    break;
            }
        }
    };

    onError = (event, store) => {
        console.error(`Websocket ${this.url} closed due to error`, event);
        store.dispatch({
            type: websocketActions.WEBSOCKET_CLOSED,
            url: this.url
        });
    };

    onClose = (event, store) => {
        // Clear variables
        this.websocket = null;
        clearInterval(this.pingInterval);
        this.pingInterval = null;
        this.lastResponseTime = null;

        if (!event.wasClean) {
            store.dispatch({
                type: websocketActions.WEBSOCKET_CLOSED,
                url: this.url
            });
        }
        // Try to reconnect
        setTimeout(() => this.connect(store), 1000);
    };

    disconnect = () => {
        if (this.websocket) {
            this.websocket.close();
        }
    };
}
