export function isNullable(data) {
    return data === null || data === undefined || data === "";
}

export default function objectsAreEqual(a, b, strict_types = true) {
    if (a === b) {
        return true;
    }
    // eslint-disable-next-line
    if ((!strict_types && a == b) ||
        (isNullable(a) && isNullable(b)) // a & b can be converted to null
    ) {
        return true;
    }
    if (isNullable(a) || isNullable(b) // Only one of them can be converted to null
        || typeof a !== "object" || typeof b !== "object") { // One or both aren't objects, and all non object comparisons failed
        return false;
    }

    if (a instanceof Set) {
        a = [...a];
    }
    if (b instanceof Set) {
        b = [...b];
    }

    for (const key of [...new Set([...Object.keys(a), ...Object.keys(b)])]) {
        if (!objectsAreEqual(a[key], b[key])) {
            return false;
        }
    }
    return true;
}
