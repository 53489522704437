import React from "react";
import {connect} from "react-redux";
import {LightComponent} from "../Light/Light";
import CuelistEditor from "./CuelistEditor/CuelistEditor";

class Cuelists extends LightComponent {
    newScene = () => {
        this.setState({
            modal: (<CuelistEditor onClose={() => this.setState({modal: null})}/>)
        });
    };

    editCuelist = (cuelist) => {
        this.setState({
            modal: (<CuelistEditor onClose={() => this.setState({modal: null})} uuid={cuelist.uuid}/>)
        });
    };
}

const mapStateToProps = state => ({
    data: state.data,
    websocket: state.websocket,
    login: state.login,
    browser: state.browser
});

export default connect(mapStateToProps)(Cuelists);
