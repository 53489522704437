import memoize from "memoize-one";
import ignoreErrors from "./ignoreErrors";

const validateFunctions = functions => functions
    && functions.length > 0
    && (functions[0] instanceof Array || functions[0] instanceof Function);

export const parseJavaScript = memoize((code) => {
    let functions = null;

    ignoreErrors(() => {
        // eslint-disable-next-line no-eval
        functions = eval(code);

        if (!validateFunctions(functions)) {
            functions = null;
        }
    });

    if (!functions) {
        ignoreErrors(() => {
            // eslint-disable-next-line no-eval
            functions = eval(`[${code}]`);
            if (!validateFunctions(functions)) {
                functions = null;
            }
            // eslint-disable-next-line no-empty
        });
    }

    if (!functions) {
        functions = [];
        for (const code_line of code.split("\n")) {
            if (code_line.replace(" ", "") !== "") {
                ignoreErrors(() => {
                    // eslint-disable-next-line no-eval
                    const graph_function = eval(code_line);
                    if (validateFunctions([functions])) {
                        functions.push(graph_function);
                    }
                    // eslint-disable-next-line no-empty
                });
            }
        }
    }
    return functions;
});

export default parseJavaScript;
