import React from "react";
import "./MMIPInput.less";
import {isNullable} from "../../providers/objectsAreEqual";

export default class MMIPInput extends React.Component {
    onChange = (event) => {
        let value = event.target.value.replace(/\D/g, '');
        const index = event.key;
        if (value > 255) {
            value = 255;
        } else if (value < 0) value = 0;

        if (value > 99 && event.target.parentElement.nextElementSibling && event.target.parentElement.nextElementSibling.firstElementChild) {
            event.target.parentElement.nextElementSibling.firstElementChild.focus();
        }

        const assembled_value = this.dotStringToArray(this.props.value);
        assembled_value[index] = value;

        if (this.props.onChange) this.props.onChange(event, assembled_value.filter(e => e !== "").join("."));
    };

    dotStringToArray = (textString, index = null) => {
        if (!textString) return index ? "" : [];
        const array = textString.split(".");

        if (index !== null) {
            if (array.length > index && array[index] !== null) return array[index];
            return "";
        }
        return array;
    };

    render() {
        return (
            <div className={`MMIPInput ${this.props.className ? this.props.className : ""}`} id={this.props.id}>
                {[0, 1, 2, 3].map(index => (
                    <div className="input-subgroup" key={index}>
                        <input
                            placeholder={this.dotStringToArray(this.props.placeholder, index)}
                            min="0"
                            max="255"
                            step="1"
                            type="number"
                            onKeyPress={(event) => {
                                if (event.charCode < 48 || event.charCode > 57) {
                                    // Non digit character detected, don't show it in the input field
                                    event.preventDefault();
                                }
                            }}
                            onKeyDown={(event) => {
                                if (event.keyCode === 8 && isNullable(event.target.value)
                                    && event.target.parentElement.previousElementSibling
                                    && event.target.parentElement.previousElementSibling.firstElementChild) {
                                    // Backspace detected and current field is empty, focus previous field
                                    event.target = event.target.parentElement.previousElementSibling.firstElementChild;
                                    event.key = (index - 1).toString();

                                    const int_value = parseInt(event.target.value);
                                    if (!isNaN(int_value)) {
                                        event.target.value = int_value.toString();
                                    }

                                    this.onChange(event);
                                    // noinspection JSUnresolvedFunction
                                    event.target.focus();
                                } else if (event.keyCode === 190
                                    && event.target.parentElement.nextElementSibling
                                    && event.target.parentElement.nextElementSibling.firstElementChild) {
                                    // Dot detected, jump to next field
                                    // noinspection JSUnresolvedFunction
                                    event.target.parentElement.nextElementSibling.firstElementChild.focus();
                                }
                            }}
                            onKeyUp={event => {
                                if (event.keyCode >= 48 && event.keyCode <= 57) {
                                    // Digit entered
                                    const int_value = parseInt(event.target.value);
                                    if (!isNaN(int_value)) {
                                        event.target.value = int_value.toString();
                                    }

                                    if (event.target.value === "0" && event.target.parentElement.nextElementSibling
                                        && event.target.parentElement.nextElementSibling.firstElementChild) {
                                        // Zero detected as first character in field, jump to next field
                                        event.target.parentElement.nextElementSibling.firstElementChild.focus();
                                    }
                                }
                            }}
                            onFocus={(event) => {
                                if (event.target.parentElement.previousElementSibling
                                    && event.target.parentElement.previousElementSibling.firstElementChild
                                    && isNullable(event.target.parentElement.previousElementSibling.firstElementChild.value)) {
                                    event.target.parentElement.previousElementSibling.firstElementChild.focus();
                                }
                            }}
                            onChange={
                                (event) => {
                                    event.key = index;
                                    const int_value = parseInt(event.target.value);
                                    if (!isNaN(int_value)) {
                                        event.target.value = int_value.toString();
                                    }
                                    this.onChange(event);
                                }
                            }
                            disabled={this.props.disabled}
                            value={this.props.value ? this.dotStringToArray(this.props.value, index) || "" : ""}
                        />
                    </div>))}
            </div>
        );
    }
}
