import {APP_CONFIG} from "../../config";

export default class colorProvider {
    static PALETTE_TYPES = [
        {
            key: "transparent",
            value: "Transparent"
        },
        {
            key: "fade",
            value: "Fade"
        },
        {
            key: "standard",
            value: "Standard"
        }
    ];

    static getPaletteType = palette => {
        if (palette.fade === "yes") {
            return "fade";
        }
        if (palette.transparent === "yes") {
            return "transparent";
        }
        return "standard";
    };

    static convertToRGBColor = (color_type, values) => {
        switch (color_type.key) {
            case "rgbw":
                return this.RGBWToRGB(values);
            case "rgbww":
                return this.RGBWWToRGB(values);
            case "rgb":
                return values;
            default:
                return [0, 0, 0];
        }
    };

    static convertToRGBColor = (color_type, values) => {
        switch (color_type.key) {
            case "rgbw":
                return this.RGBWToRGB(values);
            case "rgbww":
                return this.RGBWWToRGB(values);
            case "rgb":
                return values;
            default:
                return [0, 0, 0];
        }
    };

    static convertToCSSColor = (color_type, values, intensity = 100) => {
        switch (color_type.key) {
            case "rgbw":
            case "rgbww":
            case "rgb":
                values = this.convertToRGBColor(color_type, values)
                    .map(x => x * intensity / 100);
                break;
            case "transparent":
                return 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQB'
                    + 'BZG9iZSBJbWFnZVJlYWR5ccllPAAAABZJREFUeNpi2r9//38gYGAEESAAEGAAasgJOgzOKCoAAAAASUVORK5CYII=)';
            case "fade":
                return "linear-gradient(90deg, white, black)";
            case "raw":
                return this.RAWToRGB(values);

            default:
                return "grey";
        }
        return `rgb(${values[0]}, ${values[1]}, ${values[2]})`;
    };

    static RGBColorIsWhite = values => {
        if (values[0] === 255 && values[1] === 255 && values[2] === 255) {
            return true;
        }
        return false;
    };

    static RGBWToRGB = values => values.map(val => this.addWhite(val, values[3]))
        .slice(0, 3);

    static RGBWWToRGB = values => {
        let [red, green, blue] = values;
        const [, , , cw, ww] = values;
        if (Math.abs(cw - ww) < 30) {
            red += cw + ww;
            red /= 3;
            green += cw + ww;
            green /= 3;
            blue += cw + ww;
            blue /= 3;
        } else if (cw > ww) {
            // 8000K
            red = 227 * cw / 255;
            green = 233 * cw / 255;
            blue = 255 * cw / 255;
        } else {
            // 2700K
            red = 255 * ww / 255;
            green = 169 * ww / 255;
            blue = 87 * ww / 255;
        }

        return [red, green, blue];
    };
    // const average = list => list.reduce((prev, curr) => prev + curr) / list.length;

    static RAWToRGB = values => {
        switch (values.length) {
            case 1:
                return [values[0], values[0], values[0]];
            case 2: {
                let red = values[1];
                const white = values[0];
                red += white;
                if (red > 255) {
                    red = 255;
                }
                return [red, white, white];
            }
            case 3:
                return values;
            case 4:
                return this.RGBWToRGB(values);
            case 5:
                return this.RGBWWToRGB(values);
            default:
                return [128, 128, 128];
        }
    };

    static addWhite = (value, white) => (parseInt(value) + parseInt(white) <= 255 ? parseInt(value) + parseInt(white) : 255);

    static getPaletteColor = palette => {
        for (const type of APP_CONFIG.COLOR_TYPES) {
            if (palette && palette.color && palette.color[type.key] || palette[type.key] === "yes") {
                const values = [];
                if (type.definition) {
                    for (const definition of type.definition) {
                        values.push(palette.color[type.key][definition]);
                    }
                }
                return {
                    type,
                    values
                };
            }
        }
        return null;
    };
}
