export const dataActions = {
    GET_LIGHT_CONFIGURATION: "GET_LIGHT_CONFIGURATION",
    GET_VIDEOS: "GET_VIDEOS",
    GET_SYSTEM_CONFIGURATION: "GET_SYSTEM_CONFIGURATION",
    GET_SYSTEM_CONFIGURATION_SLAVES: "GET_SYSTEM_CONFIGURATION_SLAVES",
    GET_CONTROLLER_TIME: "GET_CONTROLLER_TIME",
    GET_CONTROLLER_TIMESERVER: "GET_CONTROLLER_TIMESERVER",
    SET_LIGHT_CONFIGURATION: "SET_LIGHT_CONFIGURATION",
    SET_SYSTEM_CONFIGURATION: "SET_SYSTEM_CONFIGURATION",
    SET_CONTROLLER_TIME: "SET_CONTROLLER_TIME",
    SET_CONTROLLER_TIMESERVER: "SET_CONTROLLER_TIMESERVER",
    RESET_CONTROLLER: "RESET_CONTROLLER",
    BACKUP_CONTROLLER: "BACKUP_CONTROLLER",
    FIRE_TRIGGER: "FIRE_TRIGGER",
    FIRE_CUELIST: "FIRE_CUELIST",
    LIGHT_CONFIG_PARSE_FAILED: "LIGHT_CONFIG_PARSE_FAILED",
    LIGHT_CONFIG_WRITE_FAILED: "LIGHT_CONFIG_WRITE_FAILED",
    SHOW_INITIAL_SETUP: "SHOW_INITIAL_SETUP",
    IP_CHANGED_REDIRECT_LOCATION: "IP_CHANGED_REDIRECT_LOCATION",
    CALCULATE_SUN_TIMES: "CALCULATE_SUN_TIMES",
    GET_PROJECTS: "GET_PROJECTS",
    SET_ACTIVE_PROJECT: "SET_ACTIVE_PROJECT",
    CLEAR_LOCAL_DATA: "CLEAR_LOCAL_DATA"
};
