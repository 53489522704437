import React from "react";
import {Radio} from "antd";
import "./MMRadio.less";

export default class MMRadio extends React.Component {
    onChange = event => {
        const key = event.target.value;
        if (this.props.onChange) {
            this.props.onChange(key);
        }
    };

    render() {
        return (
            <Radio.Group className={`MMRadio ${this.props.className}`} onChange={this.onChange} value={this.props.value}>
                {this.props.options && this.props.options.map(option => (
                    <Radio key={option.key} value={option.key}>
                        {option.value}
                    </Radio>
                ))}
            </Radio.Group>);
    }
}
