import memoize from "memoize-one";
import {merge_by_uuid} from "./data/dataMiddleware";

const getElements_merge_by_uuid_memoize = memoize(merge_by_uuid);

export function getElements(type, with_changes = true, with_deleted = false) {
    let elements = [];
    if (this.props.data && this.props.data.light_config && this.props.data.light_config.playlist
        && this.props.data.light_config.playlist[type]) {
        elements = this.props.data.light_config.playlist[type];
    }

    let elements_changes = null;
    if (with_changes && this.state && this.state.changes && this.state.changes.light_config
        && this.state.changes.light_config.playlist && this.state.changes.light_config.playlist[type]) {
        elements_changes = this.state.changes.light_config.playlist[type];
    }

    return getElements_merge_by_uuid_memoize(elements, elements_changes, !with_deleted);
}

export function getRelativeElement(elements, element, delta_index) {
    const index = elements.findIndex(entry => entry === element);
    if (index < 0) {
        return element;
    }
    if (index + delta_index < 0) {
        return elements[elements.length + delta_index];
    }
    if (index + delta_index >= elements.length) {
        return elements[index + delta_index - elements.length];
    }
    return elements[index + delta_index];
}
