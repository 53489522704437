import _ from "lodash";
import {notificationsActions} from "./notifications/notificationsActions";
import {toArray} from "./toArray";

function getElements(playlist, type) {
    if (playlist && playlist[type] instanceof Array) {
        return playlist[type];
    }
    return [];
}

function getElement(playlist, type, identifier, needle = "uuid") {
    return getElements(playlist, type)
        .find(e => e[needle] === identifier);
}

function getSceneFixtures(scene) {
    return scene ? scene["scene-component"].map(sc => sc["dmx-fixture"]) : [];
}

function getScene(scenes, scene_name) {
    return scenes.find(el => el.name === scene_name);
}

function getTimelinesUsingScene(playlist, scene_name) {
    return getElements(playlist, "timeline")
        .filter(timeline => timeline.cue.some(sc => sc.scene === scene_name));
}

function scenesAreConflicting(scene1, scene2) {
    const conflicts = [];
    scene1 = toArray(scene1);
    scene2 = toArray(scene2);

    if (scene1[0].priority === scene2[0].priority) {
        for (let i = 0; i < scene1.length; i++) {
            const scene1a = scene1[i];
            const fixtures1 = getSceneFixtures(scene1a);
            for (let j = 0; j < scene2.length; j++) {
                const scene2a = scene2[j];
                const fixtures2 = getSceneFixtures(scene2a);
                const conflict = fixtures1.some(fix1 => fixtures2.includes(fix1));
                if (conflict) {
                    conflicts.push([scene1a, scene2a]);
                }
            }
        }
    }
    return conflicts;
}

export function timelinesAreConflicting(timeline1, timeline2, scenes) {
    const p1 = parseInt(timeline1.priority) || 0;
    const p2 = parseInt(timeline2.priority) || 0;
    return p1 === p2 && timeline1.cue instanceof Array && timeline2.cue instanceof Array && timeline1.cue.some(cue1 => {
        const scene1 = getScene(scenes, cue1.scene);
        return scene1 && timeline2.cue.some(cue2 => {
            const scene2 = getScene(scenes, cue2.scene);
            return scene2 && scenesAreConflicting(scene1, scene2);
        });
    });
}

function checkForSelfKillingTimelines(store, playlist, changes) {
    const timeline_has_changes = changes.timeline instanceof Array;
    if (timeline_has_changes) {
        for (const timeline_change of changes.timeline) {
            if (timeline_change.cue instanceof Array) {
                let cuelist_scene = null;
                const cuelists_scenes = [];
                const timeline = getElement(playlist, "timeline", timeline_change.uuid);
                for (const cue of timeline_change.cue.filter(e => !e.delete)) {
                    const scene = _.cloneDeep(getElement(playlist, "scene", cue.scene, "name"));
                    if (scene) {
                        scene.priority = parseInt(cue.priority) || 0;
                        if (!cuelist_scene || cue.parallel === "yes") {
                            if (cuelist_scene) {
                                cuelists_scenes.push(cuelist_scene);
                            }
                            cuelist_scene = [scene];
                        } else {
                            cuelist_scene.push(scene);
                        }
                    }
                }
                if (cuelist_scene) {
                    cuelists_scenes.push(cuelist_scene);
                }

                for (let i = 0; i < cuelists_scenes.length; i++) {
                    for (let j = i + 1; j < cuelists_scenes.length; j++) {
                        // Compare parallel cues
                        const conflicts = scenesAreConflicting(cuelists_scenes[i], cuelists_scenes[j]);
                        for (const conflict of conflicts) {
                            store.dispatch({
                                type: notificationsActions.PARALLEL_CUES_CONFLICT,
                                timeline: timeline["webapp-name"] || timeline.name,
                                scene1: conflict[0]["webapp-name"] || conflict[0].name,
                                scene2: conflict[1]["webapp-name"] || conflict[1].name
                            });
                        }
                    }
                }
            }
        }
    } else {
        let timelines = [];
        for (const scene_change of changes.scene) {
            const scene = getElement(playlist, "scene", scene_change.uuid);
            if (scene) {
                const scene_timelines = getTimelinesUsingScene(playlist, scene.name);
                timelines.push(...scene_timelines);
            }
        }
        // Remove duplicates
        timelines = timelines.filter((item, index) => timelines.findIndex(item2 => item.uuid === item2.uuid) === index);
        return checkForSelfKillingTimelines(store, playlist, {timeline: timelines});
    }
}

export function checkPlaylistForErrors(store, playlist, changes) {
    if (!changes) {
        return;
    }
    if (changes.timeline instanceof Array || changes.scene instanceof Array) {
        checkForSelfKillingTimelines(store, playlist, changes);
    }
}
