import React from "react";
import "./MMSlider.less";
import is from "is_js";
import constants from "../../less/motomuto_ras/constants";
import {toArray} from "../../providers/toArray";

export default class MMSlider extends React.PureComponent {
    onChangeTimer = null;

    constructor(props, context) {
        super(props, context);
        this.state = {
            value: parseFloat(this.props.value) || 0,
            value_end: parseFloat(this.props.value_end) || this.getMax(),
            clicks: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({value: parseFloat(this.props.value)});
        }
        if (this.props.value_end !== prevProps.value_end) {
            this.setState({value_end: parseFloat(this.props.value_end)});
        }
    }

    sendChangeToParentTimeout = () => {
        this.onChangeTimer = null;
        let value = this.state.value;
        if (this.props.range) {
            value = {
                startIndex: value,
                endIndex: this.state.value_end
            };
        }
        this.props.onChange(value);
    };

    sendChangeToParent = () => {
        if (this.props.onChange && !this.onChangeTimer) {
            this.onChangeTimer = setTimeout(this.sendChangeToParentTimeout, 100);
        }
    };

    onChange = (event, value) => {
        const {
            min,
            step
        } = this.props;
        const max = this.getMax();
        if (value instanceof Array) value = value[0];
        value = parseFloat(value);
        if (this.props.range) {
            const value_end = this.state.value_end;
            if (value > value_end - (this.props.minDistance || this.props.step)) value = value_end - (this.props.minDistance || this.props.step);
        }
        if (value < min) value = min;
        if (value > max) value = max;
        value = Math.round(value / step) * step;
        this.setState({value}, this.sendChangeToParent);
    };

    onChangeEnd = (event, value_end) => {
        const {
            min,
            step
        } = this.props;
        const max = this.getMax();
        if (value_end instanceof Array) value_end = value_end[0];
        value_end = parseFloat(value_end);
        const value = this.state.value;
        if (value_end < value + (this.props.minDistance || this.props.step)) value_end = value + (this.props.minDistance || this.props.step);
        if (value_end < min) value_end = min;
        if (value_end > max) value_end = max;
        value_end = Math.round(value_end / step) * step;
        this.setState({value_end}, this.sendChangeToParent);
    };

    getPercentage = () => Math.abs(
        (this.state.value - (this.props.range ? this.state.value_end : this.props.min))
        / (this.getMax() - this.props.min)
        * 100
    );

    getMax = () => (this.props.data && this.props.range ? this.props.data.length - 1 : this.props.max);

    onDragStart = (event, values, onChange) => {
        if (event.dataTransfer && event.dataTransfer.setDragImage) {
            event.dataTransfer.setDragImage(document.createElement("span"), 0, 0);
        }
        let clientX = event.clientX;
        if (event.touches) {
            // User is using a touch screen
            if (event.touches.length > 1) {
                // Ignore multitouch
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            clientX = event.touches[0].clientX;
            if (this.state.clicks > 0) {
                // Double click on range slider, reset slider, prevent double tap to zoom
                event.preventDefault();
                this.reset(event);
            } else {
                this.setState(state => ({clicks: state.clicks + 1}));
                // Timeout double click after 500 ms
                clearTimeout(this.doubleClickTimer);
                this.doubleClickTimer = setTimeout(() => this.setState({clicks: 0}), 500);
            }
        }
        this.setState({
            clientX,
            clientValues: toArray(values)
        });
        if (is.firefox()) {
            // Disable Firefox drag implementation
            event.preventDefault();
            // Use custom drag implementation using mouse events
            const onDrag = e => this.onDrag(e, onChange);
            const onDragEnd = e => {
                onDrag(e);
                window.removeEventListener("mousemove", onDrag);
                window.removeEventListener("mouseup", onDragEnd);
            };
            window.addEventListener("mousemove", onDrag);
            window.addEventListener("mouseup", onDragEnd);
        }
    };

    onDrag = (event, onChange) => {
        // Fix bug in Chrome were a onDrag event is sent together with onDragEnd, and the coordinates in the onDrag event are all zero
        if ([event.pageX, event.pageY, event.clientX, event.clientY, event.movementX, event.movementY, event.screenX, event.screenY]
            .every(coordinate => coordinate === 0)) {
            return;
        }
        const {
            min,
            width
        } = this.props;
        const max = this.getMax();
        let clientX = event.clientX;
        if (event.touches) {
            // User is using a touch screen
            if (event.touches.length > 1) {
                // Ignore multitouch
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            clientX = event.touches[0].clientX;
        }
        const deltaX = clientX - this.state.clientX;
        const deltaValue = (max - min) / (width - parseFloat(constants["fader-thumb-border"]) * 2) * deltaX;
        const calculated_values = this.state.clientValues.map(value => value + deltaValue);
        onChange(event, calculated_values);
    };

    onSliderChange = (event, value) => {
        const {min} = this.props;
        const max = this.getMax();
        if (value[0] <= min) {
            // Left end reached, make sure that the length of the range is still the same as before
            const length = this.state.clientValues[1] - this.state.clientValues[0];
            value[0] = min;
            value[1] = min + length;
        }
        if (value[1] >= max) {
            // Right end reached, make sure that the length of the range is still the same as before
            const length = this.state.clientValues[1] - this.state.clientValues[0];
            value[1] = max;
            value[0] = max - length;
        }
        this.onChange(event, value[0]);
        this.onChangeEnd(event, value[1]);
    };

    onClickSlider = (event) => {
        const {
            min,
            width
        } = this.props;
        const max = this.getMax();
        const inner_width = width - 2 * parseFloat(constants["fader-thumb-border"]);
        const fader_height = parseFloat(constants["fader-height"]);
        const pos = event.nativeEvent.offsetX;
        const value = ((-max - min) * fader_height + (-2 * pos + 2 * inner_width) * min + 2 * pos * max) / (2 * inner_width - 2 * fader_height);
        this.onChange(event, value);
    };

    reset = (event = null) => {
        if (this.props.range) {
            this.onChangeEnd(event, this.getMax());
            this.onChange(event, this.props.min);
        }
    };

    render() {
        const {
            min,
            width,
            range,
            tooltip,
            data
        } = this.props;
        const max = this.getMax();
        const value = this.state.value;
        const value_end = this.state.value_end;
        const inner_width = width - 2 * parseFloat(constants["fader-thumb-border"]);
        return (
            <div
                className="MMSlider"
                style={{
                    width,
                    minWidth: width
                }}
            >
                <div
                    className="fill-selected"
                    style={{
                        left: range
                            ? ((max + min - 2 * value) * parseFloat(constants["fader-thumb-border"]) - (-value + min) * inner_width) / (max - min)
                            : constants["fader-thumb-border"],
                        width: (inner_width - parseFloat(constants["fader-thumb-size"]))
                            * this.getPercentage() / 100
                            + parseFloat(constants["fader-thumb-size"]),
                        cursor: (() => {
                            if (range) {
                                return this.getPercentage() === 100 ? "not-allowed" : "grab";
                            }
                            return "pointer";
                        })()
                    }}
                    draggable={range && this.getPercentage() !== 100}
                    onClick={range ? null : this.onClickSlider}
                    onDoubleClick={range ? this.reset : null}
                    onDragStart={range ? event => this.onDragStart(event, [value, value_end], this.onSliderChange) : null}
                    onTouchStart={range ? event => this.onDragStart(event, [value, value_end], this.onSliderChange) : null}
                    onDrag={range ? event => this.onDrag(event, this.onSliderChange) : null}
                    onDragEnd={range ? event => this.onDrag(event, this.onSliderChange) : null}
                    onTouchMove={range ? event => this.onDrag(event, this.onSliderChange) : null}
                >
                    {range && (
                        <div className="fader-arrows">
                            <div className="fader-arrow"/>
                            <div className="fader-arrow"/>
                        </div>)}
                </div>
                <div
                    className="fill-background"
                    onClick={range ? null : this.onClickSlider}
                    style={{cursor: range ? "not-allowed" : "pointer"}}
                />
                <div
                    className="traveller"
                    draggable
                    style={{left: (inner_width - parseFloat(constants["fader-height"])) * (value - min) / (max - min)}}
                    onDragStart={event => this.onDragStart(event, value, this.onChange)}
                    onDrag={event => this.onDrag(event, this.onChange)}
                    onDragEnd={event => this.onDrag(event, this.onChange)}
                    onTouchStart={event => this.onDragStart(event, value, this.onChange)}
                    onTouchMove={event => this.onDrag(event, this.onChange)}
                >
                    {tooltip && <p className="tooltip">{data[value]}</p>}
                </div>
                {range
                && (
                    <div
                        className="traveller"
                        draggable
                        style={{left: (inner_width - parseFloat(constants["fader-height"])) * (value_end - min) / (max - min)}}
                        onDragStart={event => this.onDragStart(event, value_end, this.onChangeEnd)}
                        onDrag={event => this.onDrag(event, this.onChangeEnd)}
                        onDragEnd={event => this.onDrag(event, this.onChangeEnd)}
                        onTouchStart={event => this.onDragStart(event, value_end, this.onChangeEnd)}
                        onTouchMove={event => this.onDrag(event, this.onChangeEnd)}
                    >
                        {tooltip && <p className="tooltip">{data[value_end]}</p>}
                    </div>)}
            </div>);
    }
}

MMSlider.defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    width: 900,
    minDistance: null
};
