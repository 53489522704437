import React from "react";
import {Emitter} from "react-emitter";
import MMButton from "../buttons/MMButton";
import MMTextInput from "../inputs/MMTextInput";
import MMPinInput from "../inputs/MMPinInput";
import {eventActions} from "../../providers/event/eventActions";
import constants from "../../less/motomuto_ras/constants";
import "./MMLoginBox.less";

class MMLoginBox extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pincode: "",
            username: "",
            password: "",
            idle: false
        };
    }

    componentDidMount() {
        if (this.props.getRef) {
            this.props.getRef(this);
        }

        document.addEventListener("keydown", this.handleKeyPress, false);
        this.listener1 = this.props.addListener(eventActions.USER_ACTIVE, () => {
            setTimeout(() => {
                this.setState({idle: false});
                // Auto focus input field
                if (this.input) {
                    this.input.focus();
                }
            }, parseFloat(constants["modal-transition-duration"]));
        });
        this.listener2 = this.props.addListener(eventActions.USER_INACTIVE, () => {
            setTimeout(() => {
                this.setState({idle: true});
            }, parseFloat(constants["modal-transition-duration"]));
        });
        if (this.input) {
            this.input.focus();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress, false);
        this.listener1.remove();
        this.listener2.remove();
    }

    resetFields = () => {
        this.setState({
            pincode: "",
            username: "",
            password: ""
        });
    };

    updatePincode = (event, pincode) => {
        new Promise(resolve => {
            this.setState({pincode}, () => resolve());
        }).then(() => {
            if (pincode.length === 4) {
                this.submit();
            }
        });
    };

    renderLoginInput = () => {
        switch (this.props.type) {
            case "login":
                return (
                    <div className="login-credentials">
                        {!this.state.idle
                        && (
                            <div>
                                <p className="login-subsubtitle">Brugernavn</p>
                                <MMTextInput
                                    className="block"
                                    ref={ref => {
                                        if (ref) {
                                            if (!this.input && !this.state.idle) {
                                                ref.focus();
                                            }
                                            this.input = ref;
                                        }
                                    }}
                                    value={this.state.username}
                                    onChange={(event, value) => this.setState({username: value})}
                                />
                                <p className="login-subsubtitle">Kodeord</p>
                                <MMTextInput
                                    className="block"
                                    type="password"
                                    value={this.state.password}
                                    onChange={(event, value) => this.setState({password: value})}
                                />
                            </div>)}
                    </div>);
            case "client-pin":
                return (
                    <div>
                        <p className="login-subsubtitle">{this.props.pin_title}</p>
                        <div className="login-pincode">
                            {!this.state.idle
                            && <MMPinInput
                                ref={ref => {
                                    if (this.input && !this.state.idle) {
                                        this.input.focus();
                                    }
                                    this.input = ref;
                                }}
                                onChange={this.updatePincode}
                                value={this.state.pincode}
                            />}
                        </div>
                    </div>);
            default:
                return null;
        }
    };

    submit = () => {
        if (this.props.loading) {
            return;
        }
        this.props.submit({
            username: this.state.username,
            password: this.state.password,
            pincode: this.state.pincode,
        });
    };

    handleKeyPress = (event) => {
        if (event.keyCode === 13) { // Enter
            this.submit();
        }
    };

    render() {
        return (
            <div className="MMLoginBox">
                {this.props.title && <p className="login-title">{this.props.title}</p>}
                {this.props.sub_title && <p className="login-subtitle">{this.props.sub_title}</p>}
                {this.renderLoginInput()}
                <MMButton
                    className="login-submit block"
                    loading={this.props.loading}
                    onClick={this.submit}
                >
                    {this.props.submit_title}
                </MMButton>
            </div>
        );
    }
}

MMLoginBox.defaultProps = {
    title: "Log ind",
    sub_title: null,
    submit_title: "Godkend",
    type: "client-pin",
    pin_title: "Indtast PIN-kode for at logge ind"
};

export default Emitter(MMLoginBox);
