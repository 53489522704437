import React from "react";
import "./MMTextInput.less";
import {isNullable} from "../../providers/objectsAreEqual";

export default class MMTextInput extends React.PureComponent {
    hasFocus = false;

    constructor(props) {
        super(props);
        this.state = {
            value: !isNullable(this.props.value) ? props.value : "",
            step: this.props.step || 1
        };
    }

    componentDidMount() {
        window.addEventListener("keydown", this.onKeyDown);
        window.addEventListener("keyup", this.onKeyUp);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);
        window.removeEventListener("keyup", this.onKeyUp);
    }

    onKeyDown = event => {
        if (!this.hasFocus || event.repeat) {
            return;
        }
        if (event.shiftKey) {
            this.setState({step: 10});
        } else if (event.ctrlKey) {
            this.setState({step: 100});
        } else {
            this.setState({step: this.props.step || 1});
        }
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };

    onKeyUp = event => {
        if (!this.hasFocus) {
            return;
        }
        if (event.shiftKey) {
            this.setState({step: 10});
        } else if (event.ctrlKey) {
            this.setState({step: 100});
        } else {
            this.setState({step: this.props.step || 1});
        }
        if (this.props.onKeyUp) {
            this.props.onKeyUp(event);
        }
    };

    onFocus = event => {
        this.hasFocus = true;
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    };

    onBlur = event => {
        this.hasFocus = false;
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    onChange = (event) => {
        let value = event.target.value;
        if (this.props.max && value > parseInt(this.props.max)) value = this.props.max;
        if (this.props.min && value < parseInt(this.props.min)) value = this.props.min;
        this.setState({value});
        if (this.props.onChange) {
            this.props.onChange(event, value);
        }
    };

    getValue = () => {
        if (isNullable(this.props.value) && this.props.value === "" && this.props.type !== "number") {
            return this.props.value;
        }
        if (!isNullable(this.props.value)) {
            if (this.props.type !== "number") {
                return this.props.value;
            }
            if (isFinite(this.props.value)) {
                return this.props.value;
            }
        }
        if (!isNullable(this.state.value)) {
            if (this.props.type !== "number") {
                return this.state.value;
            }
            if (isFinite(this.state.value)) {
                return this.state.value;
            }
        }
        return "";
    };

    focus = () => {
        this.input.focus();
    };

    blur = () => {
        this.input.blur();
    };

    render() {
        return (
            <div
                className={(this.props.error ? "MMTextInput error" : "MMTextInput") + ` ${this.props.className}`}
                style={{
                    height: this.props.height,
                    width: this.props.width
                }}
                onClick={this.focus}
            >
                <div
                    className="input-group"
                    style={{
                        height: this.props.height - 4,
                        width: this.props.width
                    }}
                >
                    <input
                        type={this.props.type}
                        placeholder={this.props.placeholder}
                        step={this.state.step}
                        min={this.props.min}
                        max={this.props.max}
                        maxLength={this.props.maxlength}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        id={this.props.id}
                        disabled={this.props.disabled}
                        style={{
                            height: this.props.height - 4,
                            width: this.props.width
                        }}
                        value={this.getValue()}
                        ref={ref => {
                            this.input = ref;
                        }}
                    />
                    {this.props.children}
                </div>
            </div>);
    }
}

MMTextInput.defaultProps = {
    height: null,
    width: null
};
