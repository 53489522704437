import {notification} from "antd";
import {ReactSVG} from "react-svg";
import React from "react";
import {loginActions} from "../login/loginActions";
import {notificationsActions} from "./notificationsActions";
import icoInfo from "../../assets/ico-info.svg";
import {dataActions} from "../data/dataActions";
import {websocketActions} from "../websocket/websocketActions";

const icon = <ReactSVG src={icoInfo} beforeInjection={svg => svg.classList.add("ico-info")}/>;
const dismissedNotifications = [];

const dismissNotification = (type) => () => {
    dismissedNotifications.push(type);
};

export const notificationsMiddleware = () => (next) => (action) => {
    if (dismissedNotifications.includes(action.type)) {
        // Notification has been dismissed
        return next(action);
    }
    switch (action.type) {
        case notificationsActions.CONNECTION_FAILED:
            notification.error({
                message: "Fejl: Kan ikke forbinde til controller",
                icon,
                key: action.type,
                duration: 0
            });
            break;
        case loginActions.INVALID_CREDENTIALS:
            notification.error({
                message: "Brugernavn eller kodeord var forkert",
                icon,
                key: action.type
            });
            break;
        case loginActions.INVALID_PIN_CODE:
            notification.error({
                message: "PIN-kode var forkert",
                icon,
                key: action.type
            });
            break;
        case loginActions.EXPIRED_TOKEN:
            notification.error({
                message: "Din login session er udløbet",
                icon,
                key: action.type
            });
            break;
        case dataActions.LIGHT_CONFIG_PARSE_FAILED:
            notification.error({
                message: "Fejl: Kunne ikke læse konfigurationen",
                icon,
                key: action.type
            });
            break;
        case dataActions.LIGHT_CONFIG_WRITE_FAILED:
            notification.error({
                message: "Fejl: Kunne ikke skrive konfigurationen",
                icon,
                key: action.type
            });
            break;
        case websocketActions.WEBSOCKET_CONNECTION_FAILED:
            notification.error({
                message: "Fejl: Kunne ikke forbinde til WebSocket på " + action.url,
                icon,
                key: action.type,
                duration: 0,
                onClose: dismissNotification(action.type)
            });
            break;
        case notificationsActions.MISSING_SCENE_NAME:
            notification.error({
                message: "Scenen mangler et navn",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.DUPLICATE_SCENE_NAME:
            notification.error({
                message: "En scene med dette navn eksisterer allerede",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.DUPLICATE_FIXTURE_NAME:
            notification.error({
                message: "Flere fixturer har fået tildelt samme navn",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.DUPLICATE_UNIVERSE_NAME:
            notification.error({
                message: "Flere universer har fået tildelt samme navn",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.NO_SCENES:
            if (process.env.REACT_APP_VARIANT === "motomuto_synslab") {
                notification.error({
                    message: "Der findes ingen scener. Start med at oprette én ny.",
                    icon,
                    key: action.type
                });
            } else {
                notification.error({
                    message: "Der findes ingen scener. Gå til Belysning og tilføj én",
                    icon,
                    key: action.type
                });
            }
            break;
        case notificationsActions.COPY_TO_SAME_DAY:
            notification.error({
                message: "Du kan ikke kopiere et døgnur til samme dag",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.CONFLICTING_TIME_TRIGGER:
            notification.error({
                message: "Du kan ikke tilføje en begivenhed oveni en eksisterende",
                icon,
                key: action.type
            });
            break;
        case dataActions.SHOW_INITIAL_SETUP:
            notification.close(notificationsActions.CONNECTION_FAILED); // Connection restored, close notification
            notification.error({
                message: "Gennemgå venligst opsætning før første login",
                icon,
                key: action.type,
                duration: 0
            });
            break;
        case dataActions.IP_CHANGED_REDIRECT_LOCATION:
            notification.error({
                message: "Omdirigerer dig til den nye IP-adresse",
                icon,
                key: action.type
            });
            break;
        case dataActions.SET_LIGHT_CONFIGURATION:
        case dataActions.SET_SYSTEM_CONFIGURATION:
            notification.close(dataActions.SHOW_INITIAL_SETUP); // Initial setup complete, close notification
        /* falls through */
        case dataActions.GET_SYSTEM_CONFIGURATION:
        case dataActions.GET_LIGHT_CONFIGURATION:
        case dataActions.GET_VIDEOS:
        case dataActions.FIRE_TRIGGER:
        case loginActions.SEND_CREDENTIALS:
        case dataActions.GET_CONTROLLER_TIME:
            notification.close(notificationsActions.CONNECTION_FAILED); // Connection restored, close notification
            break;
        case websocketActions.WEBSOCKET_OPENED:
            notification.close(websocketActions.WEBSOCKET_CONNECTION_FAILED); // WebSocket connection restored, close notification
            notification.close(notificationsActions.CONNECTION_FAILED); // Connection restored, close notification
            break;
        case notificationsActions.BACKUP_FAILED:
            notification.error({
                message: "Kunne ikke generere en backup: " + action.error,
                icon,
                key: action.type
            });
            break;
        case notificationsActions.RESTORE_FAILED:
            notification.error({
                message: "Kunne ikke indlæse backup-fil: " + action.error || "En ukendt fejl opstod",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.RESTORE_COMPLETED:
            notification.error({
                message: "Enheden er blevet gendannet fra backup-filen",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.OVERRIDE_ENABLED:
            notification.error({
                message: "Bemærk at scenen låses op igen ved tilkobling af alarm",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.AIA_ARMED_SCENES_LOCKED:
            notification.error({
                message: "Scenen kunne ikke aktiveres, da tyverialarmen er tilkoblet",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.OVERRIDE_ENABLED_SCENES_LOCKED:
            notification.error({
                message: "Scenen kunne ikke aktiveres, da en anden scene er låst",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.SCENE_PRIORITY_LOWER_THAN_ACTIVE:
            notification.error({
                message: "Scenen kunne ikke aktiveres, da en aktiv scene har en større prioritet",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.SCENE_MISSING_TRIGGER:
            notification.error({
                message: "Scenen kunne ikke aktiveres, da der ikke er konfigureret nogen trigger til at aktivere den.",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.SCENE_MISSING_INVERSETRIGGER:
            notification.error({
                message: "Scenen kunne ikke deaktiveres, da der ikke er konfigureret nogen trigger til at deaktivere den.",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.FIXTURE_ADDRESS_OVERLAP:
            notification.error({
                message: "En lampe bruger allerede denne adresse i dette univers.",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.FIXTURE_INVALID_PROPERTY:
            notification.error({
                message: "Lampen kunne ikke gemmes, da den har en ugyldig indstilling.",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.MULTI_EDIT_FIXTURES_ARE_NOT_CONNECTED:
            notification.error({
                message: "Lamperne kan ikke redigeres, da en eller flere ikke er forbundet til samme link.",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.NOTHING_TO_COPY:
            notification.error({
                message: "Der er intet at kopiere.",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.NOTHING_TO_PASTE:
            notification.error({
                message: "Udklipsholderen er tom.",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.PASTE_FAILED:
            notification.error({
                message: "Kunne ikke indlæse data fra udklipsholderen.",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.COPY_FAILED:
            notification.error({
                message: "Kunne ikke skrive data til udklipsholderen.",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.PARALLEL_CUES_CONFLICT:
            notification.error({
                message: `Der er opstået en konflikt mellem de parallelle scener ${action.scene1} og ${action.scene2} i cuelisten ${action.timeline}`,
                icon
            });
            break;
        case notificationsActions.NO_FIXTURES_SELECTED:
            notification.error({
                message: "Der er ikke valgt nogen lamper at indsætte scenen på.",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.PASTE_INVALID_DATA:
            notification.error({
                message: "Data i udklipsholderen er ugyldigt.",
                icon,
                key: action.type
            });
            break;
        case notificationsActions.SCENE_IN_USE:
            notification.error({
                message: `Scenen kan ikke slettes, da den bruges i følgende cuelister: ${action.cuelists.map(c => c["webapp-name"] || c.name)
                    .join(", ")}`,
                icon
            });
            break;
        case notificationsActions.TRIGGER_IN_USE:
            notification.error({
                message: `Triggeren kan ikke slettes, da den bruges i følgende cuelister: ${action.cuelists.map(c => c["webapp-name"] || c.name)
                    .join(", ")}`,
                icon
            });
            break;
        case notificationsActions.PALETTE_IN_USE:
            notification.error({
                message: `Paletten kan ikke slettes, da den bruges i følgende scener: ${action.scenes.map(s => s["webapp-name"] || s.name)
                    .join(", ")}`,
                icon
            });
            break;
        case notificationsActions.EDIT_DISALLOWED:
            notification.error({
                message: "Dette element kan ikke redigeres",
                icon
            });
            break;
        default:
            break;
    }
    return next(action);
};
