import React from "react";
import {connect} from "react-redux";
import {LeftCircleOutlined, RightCircleOutlined} from "@ant-design/icons";
import MMCard from "../../../elements/layout/MMCard";
import MMButton from "../../../elements/buttons/MMButton";
import "./FixtureEditor.less";
import {setTypeChanges} from "../../../providers/setTypeChanges";
import {getElements} from "../../../providers/getElements";
import MMTextInput from "../../../elements/inputs/MMTextInput";
import MMInlineSelect from "../../../elements/inputs/MMInlineSelect";
import {APP_CONFIG} from "../../../config";
import {dataActions} from "../../../providers/data/dataActions";
import {synchronousSetState} from "../../../providers/synchronousSetState";
import {uuidv4} from "../../../providers/data/dataMiddleware";
import isRangesOverlapping from "../../../providers/isRangesOverlapping";
import {notificationsActions} from "../../../providers/notifications/notificationsActions";
import fixtureProvider from "../../../providers/fixtures/fixture.provider";
import MMSelect from "../../../elements/inputs/MMSelect";
import {sortObjects} from "../../../providers/sortObjects";
import objectsAreEqual from "../../../providers/objectsAreEqual";
import {toArray} from "../../../providers/toArray";

class FixtureEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fixture_uuid: this.props.fixture_uuid
        };

        // Bind functions to context
        this.setTypeChanges = setTypeChanges.bind(this);
        this.getElements = getElements.bind(this);

        if (this.props.new) {
            const default_props = {
                uuid: this.props.fixture_uuid || uuidv4(),
                id: this.props.fixture_id || uuidv4(),
                universe: this.props.fixture_universe,
                offset: this.props.fixture_offset,
                type: this.props.fixture_type,
                x: this.props.fixture_x,
                y: this.props.fixture_y
            };
            if (this.props.previous_fixture_uuid) {
                const previous_fixture = this.getFixture(this.props.previous_fixture_uuid);
                if (previous_fixture) {
                    default_props.universe = previous_fixture.universe;
                    default_props.offset = parseInt(previous_fixture.offset) + fixtureProvider.getFixtureLength(previous_fixture);
                    default_props.type = previous_fixture.type;
                }
            }
            this.state.fixture_uuid = default_props.uuid;
            setTypeChanges.bind({setState: synchronousSetState.bind(this)})("dmx-fixture", default_props);
        }
    }

    componentDidMount() {
        this.updateAvailableUniverses();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fixture_uuid !== this.props.fixture_uuid) {
            this.setState({fixture_uuid: this.props.fixture_uuid || uuidv4});
        }

        if (!objectsAreEqual(prevProps.data.system_config_slaves, this.props.data.system_config_slaves)
            || !objectsAreEqual(prevProps.data.system_config, this.props.data.system_config)
            || !objectsAreEqual(prevProps.data.light_config, this.props.data.light_config)) {
            this.updateAvailableUniverses();
        }
    }

    updateAvailableUniverses = () => {
        let universe_options = this.getElements("driver")
            .map(universe => ({
                key: universe.universe,
                value: universe["webapp-name"] || universe.universe
            }));
        if (this.props.data.system_config) {
            if (this.props.data.system_config.universe instanceof Array) {
                for (const universe of this.props.data.system_config.universe) {
                    if (!universe_options.find(e => e.key === universe)) {
                        universe_options.push({
                            key: universe,
                            value: universe
                        });
                    }
                }
            }
        }

        if (this.props.data.system_config_slaves) {
            for (const [, config] of Object.entries(this.props.data.system_config_slaves)) {
                if (config.universe instanceof Array) {
                    for (const universe of config.universe) {
                        if (!universe_options.find(e => e.key === universe)) {
                            universe_options.push({
                                key: universe,
                                value: universe
                            });
                        }
                    }
                }
            }
        }
        universe_options.push({
            key: "dummy",
            value: "Dummy"
        });
        universe_options = sortObjects(universe_options, ["key"]);
        this.setState({universe_options});
    };

    getFixture = (uuid = toArray(this.state.fixture_uuid)[0]) => this.getElements("dmx-fixture")
        .find(fix => fix.uuid === uuid && uuid);

    submit = () => {
        this.setState({loading: true});
        const fixture = this.getFixture();

        const fixture_length = fixtureProvider.getFixtureLength(fixture);
        if (fixture_length === 0) {
            this.props.dispatch({
                type: notificationsActions.FIXTURE_INVALID_PROPERTY
            });
            this.setState({loading: false});
            return;
        }
        const index_of_overlapping_fixture = this.getElements("dmx-fixture")
            .findIndex(entry => {
                if (entry.universe !== fixture.universe || entry.uuid === fixture.uuid) return false;
                const entry_length = fixtureProvider.getFixtureLength(fixture);
                if (entry_length === 0) return false;

                return isRangesOverlapping(
                    parseInt(entry.offset), parseInt(entry.offset) + entry_length - 1,
                    parseInt(fixture.offset), parseInt(fixture.offset) + fixture_length - 1
                );
            });

        if (index_of_overlapping_fixture >= 0) {
            this.props.dispatch({
                type: notificationsActions.FIXTURE_ADDRESS_OVERLAP
            });
            this.setState({loading: false});
            return;
        }

        // Save changes
        this.props.dispatch({
            type: dataActions.SET_LIGHT_CONFIGURATION,
            changes: this.state.changes.light_config,
            callback: () => {
                if (this.props.submit) {
                    this.props.submit(fixture.uuid);
                }
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            errorCallback: () => {
                this.setState({loading: false});
            }
        });
    };

    updateFixture = update => {
        const changes = [];

        for (const uuid of toArray(this.state.fixture_uuid)) {
            changes.push({uuid, ...update});
            if (update.offset || update.type || update.length) {
                const fixture = this.getFixture(uuid);
                if (update.offset === undefined) {
                    update.offset = fixture.offset;
                }
                update.offset = parseInt(update.offset) + fixtureProvider.getFixtureLength({
                    ...fixture,
                    type: update.type || fixture.type,
                    length: update.length || fixture.length
                });
            }
        }

        this.setTypeChanges("dmx-fixture", changes);
    };

    render() {
        const fixture = this.getFixture();
        const multiple = this.state.fixture_uuid instanceof Array;
        return (
            <div className="FixtureEditor" onClick={event => event.stopPropagation()}>
                <MMCard height="fit-content" width={200} padding={5}>
                    <div className="container">
                        <div className="header">
                            <p>{`${this.props.new ? "Tilføj" : "Redigér"} lampe${multiple ? "r" : ""}`}</p>
                            <div className="actions">
                                {!this.props.new && this.props.previousFixture && <LeftCircleOutlined onClick={this.props.previousFixture}/>}
                                {!this.props.new && this.props.nextFixture && <RightCircleOutlined onClick={this.props.nextFixture}/>}
                            </div>
                        </div>
                        <div className="body">
                            {!multiple && [
                                <p key="desc">Navn</p>,
                                <MMTextInput
                                    key="input"
                                    className="block"
                                    value={fixture["webapp-name"]}
                                    height={30}
                                    onChange={(event, value) => this.updateFixture({"webapp-name": value})}
                                />
                            ]}
                            <p>Univers</p>
                            <MMSelect
                                className="block"
                                value={fixture.universe}
                                options={this.state.universe_options}
                                onChange={value => this.updateFixture({universe: value})}
                            />
                            <p>Adresse</p>
                            <MMTextInput
                                className="block"
                                type="number"
                                value={fixture.offset}
                                height={30}
                                onChange={(event, value) => this.updateFixture({offset: value})}
                            />
                            <p>Type</p>
                            <MMInlineSelect
                                className="block"
                                value={fixture.type}
                                options={APP_CONFIG.COLOR_TYPES.filter(type => type.fixture_type !== false)}
                                onChange={value => {
                                    let length = 0;
                                    const entry = APP_CONFIG.COLOR_TYPES.filter(type => type.key === value);
                                    if (entry.length > 0) {
                                        length = entry[0].block_size;
                                    }
                                    this.updateFixture({
                                        type: value,
                                        length
                                    });
                                }}
                            />
                            {(APP_CONFIG.COLOR_TYPES.findIndex(type => type.key === fixture.type) < 0 || fixture.type === "raw") && (
                                <div>
                                    <p>Længde</p>
                                    <MMTextInput
                                        className="block"
                                        type="number"
                                        value={fixture.length}
                                        height={30}
                                        onChange={(event, value) => this.updateFixture({length: value})}
                                    />
                                </div>)}
                        </div>
                        <div className="footer">
                            <MMButton
                                className="link"
                                disabled={this.state.loading || !this.state.changes || !this.state.changes.light_config}
                                onClick={this.submit}
                            >
                                Gem
                            </MMButton>
                            <MMButton
                                className="link"
                                disabled={this.state.loading}
                                onClick={this.props.onClose}
                            >
                                Annullér
                            </MMButton>
                        </div>
                    </div>
                </MMCard>
            </div>);
    }
}

FixtureEditor.defaultProps = {
    fixture_uuid: null,
    fixture_id: null,
    fixture_universe: "1",
    fixture_offset: "1",
    fixture_type: "rgb"
};

const mapStateToProps = state => ({
    data: state.data
});

export default connect(mapStateToProps)(FixtureEditor);
