import React from "react";
import "./NamingComponent.less";
import {ReactSVG} from "react-svg";
import MMTextInput from "../../../elements/inputs/MMTextInput";
import icoFader from "../../../assets/ico-fader.svg";
import icoRelay from "../../../assets/ico-relay.svg";

export default class NamingComponent extends React.Component {
    componentDidMount() {
        if (!this.props.fixture["webapp-name"]) {
            this.props.fixture["webapp-name"] = this.props.fixture.id;
        }
    }

    onChange = (event, value) => {
        if (this.props.onChange) {
            this.props.onChange(event, value);
        }
    };

    toString = (num, digits = 2) => {
        let s = String(num);
        while (s.length < digits) {
            s = "0" + s;
        }
        return s;
    }

    render() {
        return (
            <div className={`NamingComponent ${this.props.className ? this.props.className : ""}`}>
                <ReactSVG
                    className="icon"
                    beforeInjection={svg => svg.classList.add("icon-svg")}
                    src={this.props.fixture["icon-type"] === "relay" || this.props.fixture.type === "relay" ? icoRelay : icoFader}
                />
                <p className="address">{this.toString(this.props.fixture.offset)}</p>
                <MMTextInput
                    width="100%"
                    height={32}
                    id={this.props.id}
                    className="name-input"
                    maxlength={17}
                    onChange={this.onChange}
                    placeholder={this.props.placeholder}
                    value={this.props.fixture["webapp-name"]}
                />
            </div>
        );
    }
}
