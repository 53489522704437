import React from "react";
import "./MMPinInput.less";
import {isNullable} from "../../providers/objectsAreEqual";

export default class MMPinInput extends React.Component {
    input = [];

    componentDidMount() {
        for (const input of this.input) {
            if (!window.getComputedStyle(input).webkitTextSecurity) {
                // Change input type to password if webkitTextSecurity isn't supported
                input.setAttribute("type", "password");
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value.length !== this.props.value && this.props.value.length === 0) { // Field reset
            document.getElementsByClassName("MMPinInput")[0].children[0].focus();
        }
    }

    onChange = (event) => {
        let value = event.target.value.replace(/\D/g, '');
        const index = event.key;
        if (!isNullable(value)) {
            if (value > 9) {
                value = 9;
            } else if (value < 0) value = 0;
            if (event.target.nextElementSibling) {
                event.target.nextElementSibling.focus();
            }
        }

        this.setState((state) => {
            let value_assembled = this.props.value.substr(0, index + 1) + value + this.props.value.substr(index + 1, this.props.value.length);
            if (isNullable(value)) {
                value_assembled = value_assembled.substr(0, index);
            }

            if (this.props.onChange) this.props.onChange(event, value_assembled);
            return state;
        });
    };

    focus = () => {
        if (this.input[0]) {
            this.input[0].focus();
        }
    };

    blur = () => {
        for (const input of this.input) {
            input.blur();
        }
    };

    render() {
        return (
            <div className={`MMPinInput ${this.props.className ? this.props.className : ""}`} id={this.props.id}>
                {[0, 1, 2, 3].map(index => (<input
                    key={index}
                    min="0"
                    max="9"
                    type="number"
                    ref={ref => {
                        this.input[index] = ref;
                    }}
                    onKeyDown={(event) => {
                        if (event.keyCode === 8 && isNullable(event.target.value)) {
                            if (event.target.previousElementSibling) {
                                event.key = (index - 1).toString();
                                this.onChange(event);
                                event.target.previousElementSibling.focus();
                            }
                        }
                    }}
                    onFocus={(event) => {
                        if (event.target.previousElementSibling && isNullable(event.target.previousElementSibling.value)) {
                            event.target.previousElementSibling.focus();
                        }
                    }}
                    onChange={
                        (event) => {
                            event.key = index;
                            this.onChange(event);
                        }
                    }
                    disabled={this.props.disabled}
                    value={this.props.value[index] || ""}
                />))}
            </div>
        );
    }
}
