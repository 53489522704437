import React from "react";
import MonacoEditor from "react-monaco-editor";

export default class MMCodeEditor extends React.Component {
    onChange = (value, event) => {
        if (this.props.onChange) {
            this.props.onChange({target: document.getElementById(this.props.id), ...event}, value);
        }
    };

    render() {
        return (
            <div className={this.props.error ? "MMCodeEditor error" : "MMCodeEditor"} id={this.props.id}>
                <div className="input-group">
                    <MonacoEditor
                        width={this.props.width}
                        height={this.props.height}
                        language={this.props.language}
                        value={this.props.value}
                        options={this.props.options}
                        onChange={this.onChange}
                        editorDidMount={editor => editor.focus()}
                        theme="vs-dark"
                    />
                    {this.props.children}
                </div>
            </div>
        );
    }
}

MMCodeEditor.defaultProps = {
    value: "",
    width: "100%",
    height: "100%"
};
