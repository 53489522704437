import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import IdleTimer from "react-idle-timer";
import {ReactSVG} from "react-svg";
import {Emitter, EmitterProvider} from "react-emitter";
import {push} from "react-router-redux";
import {Menu} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {loginActions} from "./providers/login/loginActions";
import "./App.less";
import Login from "./pages/Login/Login";
import icoSignout from "./assets/ico-signout.svg";
import PullDataComponent from "./elements/layout/PullDataComponent";
import {dataActions} from "./providers/data/dataActions";
import {APP_CONFIG} from "./config";
import Screensaver from "./pages/Screensaver/Screensaver";
import Configuration from "./pages/Settings/Configuration/Configuration";
import {eventActions} from "./providers/event/eventActions";
import {websocketActions} from "./providers/websocket/websocketActions";
import icoIndoorSmall from "./assets/ico-indoor-small.svg";
import Cuelist, {CuelistState} from "./pages/Light/Cuelist/Cuelist";
import MMModal from "./elements/layout/MMModal";
import constants from "./less/motomuto_ras/constants";
import icoMotomuto from "./assets/logos/motomuto.svg";
import icoEdit from "./assets/ico-edit.svg";
import MMLoginBox from "./elements/layout/MMLoginBox";
import ControllerCurrentTime from "./elements/layout/ControllerCurrentTime";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idle: false,
            modal_open: false,
            modal_closable: true,
            active_project: null,
            modal_class: null
        };
        window.showScreenSaver = this.onIdle;
    }

    componentDidMount() {
        this.props.dispatch({type: loginActions.AUTHENTICATE});
        if (process.env.NODE_ENV !== "development") {
            // Clear console 10 seconds while the app is running, in order to prevent sluggish performance
            // eslint-disable-next-line no-console
            this.clearConsoleTimer = setInterval(console.clear, 10000);
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.login.loggedIn && this.props.login.loggedIn) {
            this.userLoggedIn();
        } else if (prevProps.login.loggedIn && !this.props.login.loggedIn) {
            this.userLoggedOut();
        }
        if (prevProps.data.active_project !== this.props.data.active_project || this.props.data.controller_config_changed) {
            // Pull new project config from server
            if (this.pullDataComponentRef) {
                this.pullDataComponentRef.doPull();
            }
            // this.props.dispatch({type: websocketActions.WEBSOCKET_RECONNECT});
        }
        if (!prevProps.data.system_config && this.props.data.system_config) {
            // Connect to WebSocket
            this.props.dispatch({type: websocketActions.WEBSOCKET_CONNECT});
        }
    }

    componentWillUnmount() {
        clearInterval(this.clearConsoleTimer);
    }

    userLoggedIn = () => {
        if (this.controllerCurrentTimeRef) {
            this.controllerCurrentTimeRef.start();
        }
    };

    userLoggedOut = () => {
        document.body.classList.add("disable-menu");

        if (this.controllerCurrentTimeRef) {
            this.controllerCurrentTimeRef.stop();
        }
    };

    signout = () => {
        this.props.dispatch({type: loginActions.DO_SIGNOUT});
        this.setActiveProject(null);
    };

    renderPinBox = () => (
        <MMLoginBox
            pin_title="Indtast PIN-kode for at redigere"
            submit_title="Aktiver redigeringstilstand"
            submit={this.onEditEnable}
            getRef={ref => {
                this.loginBoxRef = ref;
            }}
        />);

    onEditEnable = ({pincode}) => {
        if (this.state.loading || this.state.modal_closing) {
            return;
        }
        if (pincode === (APP_CONFIG.EDIT_PIN || "1488")) {
            this.props.dispatch({
                type: loginActions.SET_CURRENT_USER,
                username: "admin",
                callback: () => {
                    this.setState({
                        modal_closing: true,
                        loading: true
                    });
                    setTimeout(() => this.setState({
                        modal_open: false,
                        loading: false
                    }), parseFloat(constants["modal-transition-duration"]));
                }
            });
        } else {
            this.props.dispatch({type: loginActions.INVALID_PIN_CODE});
        }
        if (this.loginBoxRef) {
            this.loginBoxRef.resetFields();
        }
    };

    toggleScenarioEdit = () => {
        if (this.props.login.username === "admin") {
            this.props.dispatch({
                type: loginActions.SET_CURRENT_USER,
                username: "user"
            });
        } else {
            this.setState({
                modal_header: null,
                modal_open: true,
                modal_closable: true,
                modal_closing: false,
                modal_width: 322,
                modal_height: 322,
                modal_children: this.renderPinBox,
                modal_class: "login"
            });
        }
    };

    onIdle = () => {
        // Dispatch an event using react-emitter
        this.props.emit(eventActions.USER_INACTIVE);
        this.signoutTimer = setTimeout(() => {
            // Wait 5 seconds before signing out user when screen saver has appeared
            this.signout();
        }, 5000);
        this.setState({idle: true});
    };

    handleMenuClick = (event) => {
        this.props.dispatch(push("/" + event.key));
    };

    selectedKeys = () => {
        if (this.props.history.location && this.props.history.location.pathname && this.props.history.location.pathname.length > 1) {
            const routepath = this.props.history.location.pathname.substr(1)
                .split("/")[0];
            // Make sure route exists
            const paths = APP_CONFIG.ROUTES.filter(route => !route.users || route.users.includes(this.props.login.username))
                .map(route => route.path);
            if (paths.includes(routepath)) return [routepath];
        }
        let default_route = APP_CONFIG.ROUTES.filter(route => route.default);
        default_route = default_route.length > 0 ? default_route[0] : APP_CONFIG.ROUTES[0];
        return [default_route.path];
    };

    #onActive = () => {
        if (this.state.idle) {
            this.setState({idle: false});
            // Dispatch an event using react-emitter
            this.props.emit(eventActions.USER_ACTIVE);

            // Clear signout timer
            clearTimeout(this.signoutTimer);
        }
    };

    renderProjects = () => (
        <div className="project-elements">
            {this.props.data.projects_config.map((project, index) => (
                <div
                    key={project.id}
                    className="project-element"
                >
                    <Cuelist
                        displayName={project["webapp-name"]}
                        icon={icoIndoorSmall}
                        state={index === this.state.active_project ? CuelistState.on : CuelistState.off}
                        onClick={() => {
                            this.setActiveProject(index);
                            setTimeout(() => this.setState({
                                modal_open: false,
                                loading: false
                            }), parseFloat(constants["modal-transition-duration"]));
                        }}
                    />
                </div>))}
        </div>);

    setActiveProject = index => {
        this.setState({
            active_project: index,
            modal_closing: true,
            loading: true
        });
        this.props.dispatch({
            type: dataActions.SET_ACTIVE_PROJECT,
            project: index
        });
    };

    showProjectsDialog = () => {
        if (this.props.data.projects_config) {
            if (this.props.data.projects_config.length === 1) {
                this.setActiveProject(0);
            } else {
                this.setState(state => ({
                    modal_header:
                        <div className="align-left">
                            <p className="action-title">Vælg projekt</p>
                            <LoadingOutlined className="loading-icon"/>
                        </div>,
                    modal_open: true,
                    modal_closable: state.active_project !== null,
                    modal_closing: false,
                    modal_width: 800,
                    modal_height: "auto",
                    modal_children: this.renderProjects,
                    modal_class: null
                }));
            }
        }
    };

    getDeviceName = () => {
        if (this.state.active_project !== null && this.props.data.projects_config instanceof Array
            && this.props.data.projects_config[this.state.active_project]) {
            return (
                <div
                    className="project-name"
                    onClick={this.showProjectsDialog}
                >
                    {this.props.data.projects_config[this.state.active_project]["webapp-name"]}
                </div>);
        }
        if (this.props.data.system_config && this.props.data.system_config.name
            && this.props.data.system_config.name[0]) {
            return <div className="device-name">{this.props.data.system_config.name[0]}</div>;
        }
        return null;
    };

    loadingPage = () => (
        <div className="loading-container">
            <div>
                <ReactSVG src={icoMotomuto} beforeInjection={svg => svg.classList.add("loading-svg")}/>
                <div className="spinContainer">
                    <LoadingOutlined/>
                </div>
            </div>
        </div>);

    renderRoute = route => {
        let renderFunction;
        if (route.wrapper) {
            renderFunction = () => (
                <route.wrapper {...route.props}>
                    <route.component/>
                </route.wrapper>);
        } else {
            renderFunction = () => <route.component {...route.props}/>;
        }

        return (<Route
            key={route.path}
            path={"/" + route.path}
            render={renderFunction}
        />);
    };

    userCanAccessRoute = route => {
        if (!route.users && !route.if_user_has_property) {
            return true;
        }
        if (route.users && route.users.includes(this.props.login.username)) {
            return true;
        }

        return route.if_user_has_property && this.props.login.properties
            && route.if_user_has_property.some(prop => this.props.login.properties.includes(prop));
    };

    getRoutes = () => APP_CONFIG.ROUTES.filter(this.userCanAccessRoute);

    getMenuItems = () => {
        const routes = this.getRoutes();
        return APP_CONFIG.MENU.filter(el => routes.some(route => route.path === el.key));
    };

    getPullActions = () => {
        const actions = [dataActions.GET_PROJECTS];
        if (!APP_CONFIG.USES_PROJECTS || this.state.active_project !== null) {
            actions.push(...[dataActions.GET_VIDEOS, dataActions.GET_LIGHT_CONFIGURATION,
                dataActions.GET_SYSTEM_CONFIGURATION]);
        }
        return actions;
    };

    render() {
        if (this.props.data.showInitialSetup) {
            // Pull the system configuration, and show the initial setup page
            document.body.classList.add("disable-menu");
            return (
                <PullDataComponent
                    actions={[dataActions.GET_SYSTEM_CONFIGURATION]}
                    loadingLayout={this.loadingPage()}
                >
                    <div className="initial-setup">
                        <Configuration/>
                    </div>
                </PullDataComponent>);
        }

        let default_route = APP_CONFIG.ROUTES.filter(route => route.default);
        default_route = default_route.length > 0 ? default_route[0] : APP_CONFIG.ROUTES[0];
        return (
            <div>
                {APP_CONFIG.TIMEOUT_TO_LOGIN_SCREEN_MS && APP_CONFIG.TIMEOUT_TO_LOGIN_SCREEN_MS > 0 ? [
                    <IdleTimer
                        ref={ref => {
                            this.idleTimer = ref;
                        }}
                        key="idle-timer"
                        timeout={APP_CONFIG.TIMEOUT_TO_LOGIN_SCREEN_MS}
                        onIdle={this.onIdle}
                        onActive={this.#onActive}
                        onAction={this.#onActive}
                        events={["click", "touchstart", "keydown"]}
                    />,
                    <Screensaver key="screen-saver" hidden={!this.state.idle}/>] : null}
                {this.props.login.loggedIn ? (
                    <PullDataComponent
                        ref={ref => {
                            this.pullDataComponentRef = ref;
                        }}
                        onComplete={() => {
                            if (APP_CONFIG.MENU instanceof Array) {
                                document.body.classList.remove("disable-menu");
                            } else {
                                document.body.classList.add("disable-menu");
                            }
                            if (this.state.active_project === null) {
                                this.showProjectsDialog();
                            }
                        }}
                        actions={this.getPullActions()}
                        loadingLayout={this.loadingPage()}
                    >
                        <div>
                            {APP_CONFIG.MENU instanceof Array
                                && (
                                    <div className="menu-container">
                                        <div className="align-left">
                                            {this.props.login.auth_type !== "none" && (
                                                <div className="signout-container" onClick={this.signout}>
                                                    <ReactSVG src={icoSignout}/>
                                                    <p>Log ud</p>
                                                </div>)}
                                            {APP_CONFIG.ENABLE_PIN_TO_EDIT && (
                                                <div className="signout-container" onClick={this.toggleScenarioEdit}>
                                                    <ReactSVG src={icoEdit}/>
                                                    <p>
                                                        {(this.props.login.username === "admin" ? "Deaktiver" : "Aktiver") + " redigeringstilstand"}
                                                    </p>
                                                </div>)}
                                        </div>
                                        <div className="align-center">
                                            <Menu
                                                onClick={this.handleMenuClick}
                                                selectedKeys={this.selectedKeys()}
                                                mode="horizontal"
                                            >
                                                {this.getMenuItems()
                                                    .map(entry => (
                                                        <Menu.Item key={entry.key}>
                                                            {entry.name}
                                                        </Menu.Item>
                                                    ))}
                                            </Menu>
                                        </div>
                                        <div className="align-right">
                                            <div className="info-container">
                                                {this.getDeviceName()}
                                                <p className="current-time">
                                                    <ControllerCurrentTime ref={ref => {
                                                        this.controllerCurrentTimeRef = ref;
                                                    }}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>)}
                            <div className="content-container logged-in">
                                {this.state.modal_open
                                    && (
                                        <MMModal
                                            className={this.state.modal_class}
                                            closable={this.state.modal_closable}
                                            loading={this.state.loading}
                                            close={this.state.modal_closing}
                                            width={this.state.modal_width || 600}
                                            height={this.state.modal_height || 200}
                                            onClose={() => this.setState({modal_open: false})}
                                            header={this.state.modal_header}
                                        >
                                            {this.state.modal_children instanceof Function ? this.state.modal_children() : this.state.modal_children}
                                        </MMModal>)}
                                <div>
                                    <Switch>
                                        {this.getRoutes()
                                            .map(this.renderRoute)}
                                        <Route render={() => <default_route.component {...default_route.props}/>}/>
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </PullDataComponent>
                ) : (
                    <div className="content-container">
                        <div>
                            <Login/>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    login: state.login,
    data: state.data
});

export default connect(mapStateToProps)(EmitterProvider(Emitter(App)));
