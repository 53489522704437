import React from "react";
import {connect} from "react-redux";
import {Table} from "antd";
import _ from "lodash";
import "./Universes.less";
import {ReactSVG} from "react-svg";
import MMButton from "../../../elements/buttons/MMButton";
import MMCard from "../../../elements/layout/MMCard";
import {notificationsActions} from "../../../providers/notifications/notificationsActions";
import {dataActions} from "../../../providers/data/dataActions";
import {setTypeChanges} from "../../../providers/setTypeChanges";
import {getElements} from "../../../providers/getElements";
import MMTextInput from "../../../elements/inputs/MMTextInput";
import MMSelect from "../../../elements/inputs/MMSelect";
import isNumeric from "../../../providers/isNumeric";
import {uuidv4} from "../../../providers/data/dataMiddleware";
import {sortObjects} from "../../../providers/sortObjects";
import icoRemove from "../../../assets/ico-remove.svg";
import objectsAreEqual, {isNullable} from "../../../providers/objectsAreEqual";
import icoCopy from "../../../assets/ico-copy.svg";

class Universes extends React.PureComponent {
    columns = [
        {
            title: "Navn",
            dataIndex: "webapp-name",
            render: (text, record) => (
                <MMTextInput
                    width={150}
                    value={text}
                    onChange={(event, value) => this.setTypeChanges("driver", {
                        uuid: record.uuid,
                        "webapp-name": value
                    })}
                />
            )
        },
        {
            title: "Type",
            dataIndex: "type",
            render: (text, record) => <MMSelect
                width={100}
                value={text}
                disabled={text === "tty"}
                options={[
                    {
                        key: "tty",
                        value: "Local DMX",
                        disabled: this.getElements("driver")
                            .find(uni => uni.type === "tty") && text !== "tty" // Only allow a single driver of this type
                    },
                    {
                        key: "artnet",
                        value: "Art-Net"
                    },
                    {
                        key: "enocean",
                        value: "EnOcean",
                        disabled: this.getElements("driver")
                            .find(uni => uni.type === "enocean") && text !== "enocean" // Only allow a single driver of this type
                    },
                    {
                        key: "dummy",
                        value: "Dummy"
                    }
                ]}
                onChange={type => {
                    const changes = {
                        uuid: record.uuid,
                        type
                    };
                    if (type === "enocean") {
                        changes.port = "/dev/ttyS3";
                    } else if (type === "tty") {
                        changes.port = "/dev/ttyS2";
                    }
                    this.setTypeChanges("driver", changes);
                }}
            />
        },
        {
            title: "Port / Subnet",
            dataIndex: "port",
            render: (text, record) => {
                const extra_props = {};
                switch (record.type) {
                    case "artnet":
                        extra_props.min = 0;
                        extra_props.max = 255;
                        extra_props.type = "number";
                        break;
                    case "enocean":
                    case "tty":
                        extra_props.disabled = true;
                        break;
                    case undefined:
                    case "dummy":
                        return null;
                    default:
                        break;
                }

                return <MMTextInput
                    {...extra_props}
                    width={100}
                    value={text}
                    onChange={(event, value) => this.setTypeChanges("driver", {
                        uuid: record.uuid,
                        port: value
                    })}
                />;
            }
        },
        {
            title: "Valgmuligheder",
            dataIndex: "actions",
            render: (text, record) => (
                <div className="actions">
                    {record.type !== "tty" && ([
                        <div
                            key="delete"
                            onClick={() => this.setTypeChanges("driver", {
                                uuid: record.uuid,
                                delete: true
                            })}
                        >
                            <ReactSVG
                                src={icoRemove}
                                beforeInjection={svg => svg.classList.add("icon-remove-svg")}
                                className="icon icon-remove"
                            />
                        </div>,
                        <div key="copy" onClick={() => this.copyUniverse(record)}>
                            <ReactSVG
                                src={icoCopy}
                                beforeInjection={svg => svg.classList.add("icon-copy-svg")}
                                className="icon icon-copy"
                            />
                        </div>
                    ])}
                </div>)
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            changes: {
                light_config: {playlist: {}},
                system_config: {}
            }
        };

        // Bind functions to context
        this.setTypeChanges = setTypeChanges.bind(this);
        this.getElements = getElements.bind(this);
    }

    componentDidMount() {
        const old_universes = this.getValue("universe");
        const universes = this.getElements("driver");
        const converted_universes = [];
        if (old_universes.length > 0) {
            for (const old_universe of old_universes) {
                if (!universes.find(uni => uni.universe === old_universe) && !converted_universes.find(uni => uni.universe === old_universe)) {
                    if (isNumeric(old_universe)) {
                        if (!universes.find(uni => uni.type === "tty") && !converted_universes.find(uni => uni.type === "tty")) {
                            const uuid = uuidv4();
                            converted_universes.push({
                                uuid,
                                universe: old_universe,
                                "webapp-name": "Local DMX",
                                type: "tty",
                                port: "/dev/ttyS2"
                            });
                        }
                    } else if (old_universe.startsWith("artnet-")) {
                        const port = parseInt(old_universe.split("-")[1]);
                        const uuid = uuidv4();
                        converted_universes.push({
                            uuid,
                            universe: old_universe,
                            "webapp-name": "Artnet " + port,
                            type: "artnet",
                            port
                        });
                    } else {
                        console.error("Unknown old universe: " + old_universe);
                    }
                }
            }
            this.setTypeChanges("driver", converted_universes);
            this.setChange("universe", old_universes.map(() => null));
        }
    }

    componentWillUnmount() {
        this.onBlur();
    }

    onFocus = () => {
        window.addEventListener("copy", this.onCopy);
        window.addEventListener("paste", this.onPaste);
    };

    onBlur = () => {
        window.removeEventListener("copy", this.onCopy);
        window.removeEventListener("paste", this.onPaste);
    };

    onCopy = event => {
        try {
            const data = JSON.stringify(this.getSortedUniverses()
                .map(e => {
                    delete e.key;
                    return e;
                }));
            event.clipboardData.setData("text/plain", data);
            event.preventDefault();
        } catch (err) {
            console.error("Failed to copy data:", err);
            this.props.dispatch({type: notificationsActions.COPY_FAILED});
        }
    };

    onPaste = event => {
        const text = (event.clipboardData || window.clipboardData).getData('text');

        if (text.length === 0) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_PASTE});
            return;
        }
        let new_universes = null;
        try {
            new_universes = JSON.parse(text);
        } catch (e) {
            this.props.dispatch({type: notificationsActions.PASTE_INVALID_DATA});
            console.error("Failed to parse paste:", e);
            return;
        }

        if (new_universes.length === 0) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_PASTE});
            return;
        }

        for (const new_universe of new_universes) {
            if (!new_universe.uuid) {
                new_universe.uuid = uuidv4();
            }
        }

        this.setTypeChanges("driver", new_universes);
        event.preventDefault();
    };

    isUniversesInvalid = () => false;

    copyUniverse = universe => {
        const new_universe = {...universe};
        new_universe.uuid = uuidv4();
        new_universe.universe = new_universe.uuid;
        if (!new_universe["webapp-name"]) {
            new_universe["webapp-name"] = "Kopieret univers";
        } else if (isNumeric(new_universe["webapp-name"])) {
            new_universe["webapp-name"] = (parseInt(new_universe["webapp-name"]) + 1);
        } else {
            new_universe["webapp-name"] += " (Kopi)";
        }

        this.setTypeChanges("driver", new_universe);
    };

    submitUniverses = () => {
        const universes = this.getElements("driver");
        for (const universe of universes) {
            if (universes.filter(fixture_entry => fixture_entry["webapp-name"] === universe["webapp-name"]).length > 1) {
                this.props.dispatch({type: notificationsActions.DUPLICATE_UNIVERSE_NAME});
                return;
            }
        }
        this.props.dispatch({
            type: dataActions.SET_LIGHT_CONFIGURATION,
            changes: this.state.changes.light_config,
            callback: () => {
                this.setState(state => {
                    state.changes.light_config = {playlist: {}};
                    return state;
                });
            }
        });

        const changes = {...this.state.changes.system_config};
        // Send system configuration
        if (Object.keys(changes).length > 0) {
            this.props.dispatch({
                type: dataActions.SET_SYSTEM_CONFIGURATION,
                changes
            });
        }
    };

    getValue = (key, index = null) => {
        let array = [];
        if (this.state.changes.system_config[key]) {
            array = this.state.changes.system_config[key];
        } else if (this.props.data.system_config && this.props.data.system_config[key]) {
            array = this.props.data.system_config[key];
        }

        array = array.filter(e => !isNullable(e));

        if (array && index !== null) {
            if (array.length > index && array[index] !== null) return array[index];
            return "";
        }
        return array;
    };

    setChange = (key, value) => {
        this.setState((state) => {
            state.changes.system_config[key] = value;
            if (isNullable(state.changes.system_config[key])
                || state.changes.system_config[key].length === 0
                || (this.props.data.system_config && objectsAreEqual(this.props.data.system_config[key], state.changes.system_config[key], false))
            ) {
                // Remove dropped changes
                delete state.changes.system_config[key];
            }
            return state;
        });
    };

    getSortedUniverses = () => {
        const universes = _.cloneDeep(this.getElements("driver"));
        return sortObjects(universes, ["type", "port", "webapp-name"])
            .sort((a, b) => {
                if (a.type === "tty" || b.type === "tty") {
                    return a.type === "tty" ? -1 : 1;
                }
                return 0;
            });
    };

    getTableData = () => {
        const universes = this.getSortedUniverses();
        for (const universe of universes) {
            universe.key = universe.uuid;
            if (universe["webapp-name"] === undefined) {
                universe["webapp-name"] = universe.universe;
            }
        }

        return universes;
    };

    addUniverse = () => {
        const uuid = uuidv4();
        this.setTypeChanges("driver", {
            uuid,
            universe: uuid,
            "webapp-name": "Nyt univers"
        });
    };

    render() {
        const slaves = this.getValue("slaves");
        const tableData = this.getTableData();
        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            <div className="UniversesPage" style={this.props.style} tabIndex="0" onFocus={this.onFocus} onBlur={this.onBlur}>
                <MMCard width={700} height={640}>
                    <div className="container universes">
                        <p className="header">Universer</p>
                        {slaves.length === 0 ? ([
                            <div className="body" key="body">
                                <Table columns={this.columns} dataSource={tableData} pagination={false}/>
                            </div>,
                            <div className="buttons" key="buttons">
                                <MMButton
                                    className="submit"
                                    disabled={Object.entries(this.state.changes.light_config.playlist).length <= 0 || this.isUniversesInvalid()}
                                    onClick={this.submitUniverses}
                                >
                                    Gem universer
                                </MMButton>
                                <MMButton className="grey" onClick={this.addUniverse}>Tilføj univers</MMButton>
                            </div>]) : (
                            <div className="body">
                                <p className="slave-warning">Denne installation bruger slave enheder, universer kan ikke opsættes</p>
                            </div>)}
                    </div>
                </MMCard>
            </div>
        );
    }
}

const mapStateToProps = state => (
    {
        data: state.data
    }
);

export default connect(mapStateToProps)(Universes);
