export const notificationsActions = {
    CONNECTION_FAILED: "CONNECTION_FAILED",
    MISSING_SCENE_NAME: "MISSING_SCENE_NAME",
    DUPLICATE_SCENE_NAME: "DUPLICATE_SCENE_NAME",
    DUPLICATE_FIXTURE_NAME: "DUPLICATE_FIXTURE_NAME",
    NO_SCENES: "NO_SCENES",
    COPY_TO_SAME_DAY: "COPY_TO_SAME_DAY",
    CONFLICTING_TIME_TRIGGER: "CONFLICTING_TIME_TRIGGER",
    BACKUP_FAILED: "BACKUP_FAILED",
    RESTORE_FAILED: "RESTORE_FAILED",
    RESTORE_COMPLETED: "RESTORE_COMPLETED",
    OVERRIDE_ENABLED: "OVERRIDE_ENABLED",
    AIA_ARMED_SCENES_LOCKED: "AIA_ARMED_SCENES_LOCKED",
    OVERRIDE_ENABLED_SCENES_LOCKED: "OVERRIDE_ENABLED_SCENES_LOCKED",
    SCENE_PRIORITY_LOWER_THAN_ACTIVE: "SCENE_PRIORITY_LOWER_THAN_CURRENT",
    SCENE_MISSING_TRIGGER: "SCENE_MISSING_TRIGGER",
    SCENE_MISSING_INVERSETRIGGER: "SCENE_MISSING_INVERSETRIGGER",
    FIXTURE_ADDRESS_OVERLAP: "FIXTURE_ADDRESS_OVERLAP",
    FIXTURE_INVALID_PROPERTY: "FIXTURE_INVALID_PROPERTY",
    MULTI_EDIT_FIXTURES_ARE_NOT_CONNECTED: "MULTI_EDIT_FIXTURES_ARE_NOT_CONNECTED",
    NOTHING_TO_COPY: "NOTHING_TO_COPY",
    NOTHING_TO_PASTE: "NOTHING_TO_PASTE",
    COPY_FAILED: "COPY_FAILED",
    PASTE_FAILED: "PASTE_FAILED",
    PARALLEL_CUES_CONFLICT: "PARALLEL_CUES_CONFLICT",
    NO_FIXTURES_SELECTED: "NO_FIXTURES_SELECTED",
    PASTE_INVALID_DATA: "PASTE_INVALID_DATA",
    DUPLICATE_UNIVERSE_NAME: "DUPLICATE_UNIVERSE_NAME",
    SCENE_IN_USE: "SCENE_IN_USE",
    TRIGGER_IN_USE: "TRIGGER_IN_USE",
    PALETTE_IN_USE: "PALETTE_IN_USE",
    EDIT_DISALLOWED: "EDIT_DISALLOWED"
};
