import Settings from "../pages/Settings/Settings";
import mm_logo from "../assets/logos/motomuto.svg";
import Fixtures from "../pages/Fixtures/Fixtures";
import {api_v1} from "./common/server_v1_api";
import {color_types} from "./common/color_types";
import NodeRED from "../pages/NodeRED/NodeRED";
import Palettes from "../pages/Palettes/Palettes";
import Scenes from "../pages/Scenes/Scenes";
import Cuelists from "../pages/Cuelists/Cuelists";
import TriggersList from "../pages/Triggers/TriggersList";
import TriggersWrapper from "../pages/Triggers/Wrapper";
import TriggersCalendar from "../pages/Triggers/TriggersCalendar";

export default {
    API_ENDPOINTS: api_v1,
    DEFAULT_CONTROLLER_VERSION: 2,
    DAYS: [
        {titleFull: "Mandag", title: "Man", id: "Mon"},
        {titleFull: "Tirsdag", title: "Tirs", id: "Tue"},
        {titleFull: "Onsdag", title: "Ons", id: "Wed"},
        {titleFull: "Torsdag", title: "Tors", id: "Thu"},
        {titleFull: "Fredag", title: "Fre", id: "Fri"},
        {titleFull: "Lørdag", title: "Lør", id: "Sat"},
        {titleFull: "Søndag", title: "Søn", id: "Sun"}
    ],
    SETTINGS: {},
    TITLE_TIMESTAMPS: [
        "00:00",
        "06:00",
        "12:00",
        "18:00",
        "23:59"
    ],
    COLOR_TYPES: color_types,
    TIMEOUT_TO_LOGIN_SCREEN_MS: 0, // Disable screensaver
    DISABLE_INITIAL_SETUP: process.env.NODE_ENV === "development" && false,
    WATERMARK: [{img: mm_logo, id: "motomuto", style: {bottom: 0, right: 80, left: "unset", top: "unset"}}],
    BRAND_LOGO: {img: mm_logo, id: "motomuto", style: {transform: "scale(2)", bottom: -100}},
    LIVE_EDIT: true,
    CUELIST_ACTIVE_IF_SCENE_IS: false,
    SHOW_ALL_ACTIVE_CUELISTS: true,
    SHOW_PRIORITY_ON_CUELIST: true,
    SHOW_HIDDEN_CUELISTS: true,
    MENU: [
        {key: "cuelists", name: "Cuelists"},
        {key: "scenes", name: "Scener"},
        {key: "fixtures", name: "Lamper"},
        {key: "palettes", name: "Paletter"},
        {key: "calendar", name: "Kalender"},
        {key: "triggers", name: "Triggere"},
        {key: "nodered", name: "Node-RED"},
        {key: "settings", name: "Indstillinger"}
    ],
    ROUTES: [
        {path: "cuelists", component: Cuelists},
        {path: "scenes", component: Scenes},
        {path: "fixtures", component: Fixtures, default: true},
        {path: "palettes", component: Palettes},
        {path: "triggers", component: TriggersList, wrapper: TriggersWrapper},
        {path: "calendar", component: TriggersCalendar, wrapper: TriggersWrapper},
        {path: "nodered", component: NodeRED},
        {path: "settings", component: Settings, props: {configuration: true, universes: true}}
    ]
};
