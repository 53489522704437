import {dataActions} from "./dataActions";
import reducerUpdater from "../reducerUpdater";
import {websocketActions} from "../websocket/websocketActions";

export default function dataReducer(state = {}, action) {
    switch (action.type) {
        case dataActions.SHOW_INITIAL_SETUP:
            return reducerUpdater(state, {showInitialSetup: true});
        case dataActions.SET_SYSTEM_CONFIGURATION:
        case dataActions.GET_SYSTEM_CONFIGURATION:
            if (action.config && action.config.name && action.config.name[0]) {
                // Set browser tab title
                document.title = action.config.name[0];
            }
            return reducerUpdater(state, {system_config: action.config});
        case dataActions.GET_SYSTEM_CONFIGURATION_SLAVES:
            return reducerUpdater(state, {system_config_slaves: action.config});
        case dataActions.SET_LIGHT_CONFIGURATION:
        case dataActions.GET_LIGHT_CONFIGURATION:
            return reducerUpdater(state, {
                light_config: action.config,
                showInitialSetup: false
            });
        case dataActions.GET_VIDEOS:
            return reducerUpdater(state, {videos: action.config});
        case dataActions.CLEAR_LOCAL_DATA:
            return {};
        case dataActions.GET_PROJECTS:
            return reducerUpdater(state, {
                projects_config: action.config,
                controller_config_changed: false
            });
        case dataActions.GET_CONTROLLER_TIME:
            return reducerUpdater(state, {
                time: action.config,
                time_updated_at: Date.now() / 1000
            });
        case dataActions.CALCULATE_SUN_TIMES:
            return reducerUpdater(state, {sun: action.config});
        case dataActions.GET_CONTROLLER_TIMESERVER:
            return reducerUpdater(state, {timeserver: action.config});
        case dataActions.SET_ACTIVE_PROJECT:
            return reducerUpdater(state, {active_project: action.project});
        case websocketActions.WEBSOCKET_CLOSED:
            return reducerUpdater(state, {controller_config_changed: true});
        case websocketActions.WEBSOCKET_OPENED:
            return reducerUpdater(state, {controller_config_changed: false});
        default:
            return state;
    }
}
