import React from "react";
import "./Light.less";
import {ReactSVG} from "react-svg";
import {Col, Row} from "antd";
import {connect} from "react-redux";
import Cuelist, {CuelistState} from "./Cuelist/Cuelist";
import {websocketActions} from "../../providers/websocket/websocketActions";
import {dataActions} from "../../providers/data/dataActions";
import ScenarioEditor from "./ScenarioEditor/ScenarioEditor";
import {APP_CONFIG} from "../../config";
import Watermark from "../../elements/layout/Watermark";
import getActiveScenarios from "../../providers/getActiveScenarios";
import icoEdit from "../../assets/ico-edit.svg";
import icoOverrideEnable from "../../assets/ico-unlocked.svg";
import icoOverrideDisable from "../../assets/ico-locked.svg";
import getActiveCuelists, {getHighestPriorityCuelist} from "../../providers/getActiveCuelists";
import {notificationsActions} from "../../providers/notifications/notificationsActions";
import default_scene_icon from "../../assets/ico-fader.svg";

export class LightComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: null
        };

        if (this.props.data.light_config && this.props.data.light_config.playlist
            && this.props.data.light_config.playlist.timeline instanceof Array
            && this.props.data.light_config.playlist.timeline.length > 0) {
            // Only connect if data exists, otherwise websocket server is closed
            this.props.dispatch({
                type: websocketActions.WEBSOCKET_CONNECT
            });
        }
        // Always register variables, as they will be saved by websocket provider
        this.props.dispatch({
            type: websocketActions.WEBSOCKET_REGISTER_VARIABLE,
            variable: "cuelists"
        });
    }

    noScenes = () => {
        let brand_logo = APP_CONFIG.BRAND_LOGO;
        // Support old brand logo format
        if (!(brand_logo instanceof Object)) {
            brand_logo = {img: brand_logo};
        }
        return (
            <div className="fullscreen-info">
                <div className="login-brand-logo-container" style={brand_logo.style}>
                    {(() => {
                        if (brand_logo.img.includes(".svg")) {
                            return <ReactSVG
                                src={brand_logo.img}
                                alt=""
                                className="login-brand-logo"
                                beforeInjection={svg => svg.classList.add("login-brand-logo-svg", brand_logo.id)}
                            />;
                        }
                        return <img src={brand_logo.img} alt="" className="login-brand-logo"/>;
                    })()}
                </div>
                <p className="fullscreen-info-text">Enheden er ikke konfigureret</p>
            </div>);
    };

    getCuelistByName = (name) => {
        const cuelist = this.props.data.light_config.playlist.timeline.filter(timeline => timeline.name === name);
        return cuelist.length > 0 ? cuelist[0] : null;
    };

    cuelistCanBeActivated = (cuelist) => {
        if (APP_CONFIG.SHOW_ALL_ACTIVE_CUELISTS) {
            return true;
        }
        const highest_priority_cuelist = getHighestPriorityCuelist.bind(this)(cuelist.location);

        if (!highest_priority_cuelist || parseInt(highest_priority_cuelist.priority) <= parseInt(cuelist.priority)) {
            return true;
        }

        if (highest_priority_cuelist && highest_priority_cuelist.type === "aia") {
            this.props.dispatch({type: notificationsActions.AIA_ARMED_SCENES_LOCKED});
        } else if (highest_priority_cuelist && highest_priority_cuelist.type === "override") {
            this.props.dispatch({type: notificationsActions.OVERRIDE_ENABLED_SCENES_LOCKED});
        } else {
            this.props.dispatch({type: notificationsActions.SCENE_PRIORITY_LOWER_THAN_ACTIVE});
        }
        return false;
    };

    onClickCuelist = (cuelist) => {
        if (!this.cuelistCanBeActivated(cuelist)) return;
        let version = APP_CONFIG.DEFAULT_CONTROLLER_VERSION;
        if (this.props.data.active_project !== null && this.props.data.projects_config instanceof Array) {
            const project = this.props.data.projects_config[this.props.data.active_project];
            if (project && project.version !== undefined) {
                version = project.version;
            }
        }
        if (this.props.websocket.cuelists && this.props.websocket.cuelists.includes(cuelist.name)) {
            const scenario_config = (APP_CONFIG.SCENARIOS instanceof Array)
                ? APP_CONFIG.SCENARIOS.filter(scenario => scenario.location === cuelist.location) : [];
            if (scenario_config.length > 0 && !scenario_config[0].allowNoneScenario && !cuelist.name.endsWith("-override")) {
                return; // Don't allow disabling the scenario
            }

            if (version >= 2) {
                this.props.dispatch({
                    type: dataActions.FIRE_CUELIST,
                    cuelist: cuelist.name + ":off"
                });
            } else {
                // Fire trigger if it's a old controller
                let trigger = cuelist.name + "-inverse";
                if (cuelist.inversetrigger.indexOf(trigger) < 0) {
                    if (cuelist.inversetrigger.length > 0) {
                        trigger = cuelist.inversetrigger[0];
                    } else {
                        this.props.dispatch({
                            type: notificationsActions.SCENE_MISSING_INVERSETRIGGER,
                            cuelist: cuelist["webapp-name"] || cuelist.name
                        });
                        return;
                    }
                }
                this.props.dispatch({
                    type: dataActions.FIRE_TRIGGER,
                    trigger
                });
            }
        } else {
            if (cuelist.type === "override" || cuelist.name.endsWith("-override")) {
                this.props.dispatch({type: notificationsActions.OVERRIDE_ENABLED});
            }

            if (version >= 2) {
                this.props.dispatch({
                    type: dataActions.FIRE_CUELIST,
                    cuelist: cuelist.name
                });
            } else {
                // Fire trigger if it's a old controller
                let trigger = cuelist.name;
                if (cuelist.trigger.indexOf(trigger) < 0) {
                    if (cuelist.trigger.length > 0) {
                        trigger = cuelist.trigger[0];
                    } else {
                        this.props.dispatch({
                            type: notificationsActions.SCENE_MISSING_TRIGGER,
                            cuelist: cuelist["webapp-name"] || cuelist.name
                        });
                        return;
                    }
                }
                this.props.dispatch({
                    type: dataActions.FIRE_TRIGGER,
                    trigger
                });
            }
        }
    };

    newScene = (location, x, y) => {
        this.setState({
            modal: (<ScenarioEditor onClose={() => this.setState({modal: null})} location={location} x={x} y={y}/>)
        });
    };

    editCuelist = (cuelist) => {
        this.setState({
            modal: (<ScenarioEditor onClose={() => this.setState({modal: null})} uuid={cuelist.uuid}/>)
        });
    };

    getCuelistState = (cuelist) => {
        const active_cuelists = getActiveCuelists.bind(this)();
        if (active_cuelists.findIndex(c => c.name === cuelist.name + "-override") >= 0) {
            return CuelistState.override;
        }

        const active_cuelist = active_cuelists.find(c => c.name === cuelist.name);
        if (active_cuelist) {
            return active_cuelist.partly_active ? CuelistState.partly_on : CuelistState.on;
        }
        if (APP_CONFIG.CUELIST_ACTIVE_IF_SCENE_IS === false) {
            return CuelistState.off;
        }

        const active_scenarios = getActiveScenarios.bind(this)();
        return cuelist.cue && cuelist.cue.find(cue => active_scenarios.find(scenario => scenario.name === cue.scene))
            ? CuelistState.on : CuelistState.off;
    };

    renderCuelist = (cuelist, color, icon) => {
        let phyBtn = ((APP_CONFIG.PLAYLIST && APP_CONFIG.PLAYLIST.PHYSICAL_BUTTONS)
            || APP_CONFIG.PHYSICAL_BUTTONS
            || []).filter(pbtn => pbtn.x === parseInt(cuelist.x, 10) && pbtn.y === parseInt(cuelist.y, 10) && pbtn.location === cuelist.location);
        phyBtn = phyBtn.length > 0 ? phyBtn[0] : null;

        const cuelist_actions = [];
        if (this.props.login.username === "admin" && !this.props.disable_editing) {
            cuelist_actions.push({
                id: "edit",
                icon: icoEdit,
                onClick: this.editCuelist
            });
        }
        if (this.props.data.light_config.playlist.timeline.findIndex(cuelist_entry => cuelist_entry.name === cuelist.name + "-override") >= 0) {
            cuelist_actions.push({
                id: "override",
                icon: this.props.websocket.cuelists
                && this.props.websocket.cuelists.includes(cuelist.name + "-override") ? icoOverrideDisable : icoOverrideEnable,
                onClick: (timeline) => {
                    // Find override cuelist from cuelist
                    const override_cuelist = this.getCuelistByName(timeline.name + "-override");
                    this.onClickCuelist(override_cuelist);
                }
            });
        }

        return (
            <Col span={6} key={cuelist["webapp-name"] || cuelist.name}>
                <Cuelist
                    cuelist={cuelist}
                    displayName={cuelist["webapp-name"] || cuelist.name}
                    color={color}
                    icon={icon || default_scene_icon}
                    location={cuelist.location}
                    phyBtn={phyBtn ? phyBtn.key : null}
                    state={this.getCuelistState(cuelist)}
                    onClick={this.onClickCuelist}
                    actions={cuelist_actions}
                />
            </Col>
        );
    };

    createCuelists = (scenario_config) => {
        const scenarios = [];

        const timelines_exist = this.props.data.light_config && this.props.data.light_config.playlist
            && this.props.data.light_config.playlist.timeline instanceof Array;

        if (scenario_config) {
            for (let y = 0; y < scenario_config.maxY; y++) {
                for (let x = 0; x < scenario_config.maxX; x++) {
                    let cuelist = timelines_exist && this.props.data.light_config.playlist.timeline
                        .filter(timeline => parseInt(timeline.x, 10) === x
                            && parseInt(timeline.y, 10) === y
                            && timeline.location === scenario_config.location
                            && (timeline["webapp-hidden"] !== "yes" || APP_CONFIG.SHOW_HIDDEN_CUELISTS));
                    if (cuelist && cuelist.length > 0) {
                        cuelist = cuelist[0];

                        const cuelist_color = scenario_config.colors
                            ? scenario_config.colors[scenario_config.maxX * parseInt(cuelist.y) + parseInt(cuelist.x)] : null;
                        const cuelist_icon = scenario_config.iconScene;
                        scenarios.push(this.renderCuelist(cuelist, cuelist_color, cuelist_icon));
                    } else {
                        // No scenario found for coordinates, add empty or gone
                        let phyBtn = ((APP_CONFIG.PLAYLIST && APP_CONFIG.PLAYLIST.PHYSICAL_BUTTONS) || APP_CONFIG.PHYSICAL_BUTTONS || [])
                            .filter(pbtn => pbtn.x === x && pbtn.y === y && pbtn.location === scenario_config.location);
                        phyBtn = phyBtn.length > 0 ? phyBtn[0] : null;

                        scenarios.push((
                            <Col span={6} key={`${scenario_config.location}-${x}-${y}`}>
                                <Cuelist
                                    onClick={() => this.newScene(scenario_config.location, x, y)}
                                    phyBtn={phyBtn ? phyBtn.key : null}
                                    state={this.props.login.username !== "admin" ? CuelistState.gone : CuelistState.empty}
                                />
                            </Col>
                        ));
                    }
                }
            }
        } else {
            if (timelines_exist) {
                for (const cuelist of this.props.data.light_config.playlist.timeline.filter(
                    timeline => timeline["webapp-hidden"] !== "yes" || APP_CONFIG.SHOW_HIDDEN_CUELISTS
                )) {
                    scenarios.push(this.renderCuelist(cuelist));
                }
            }

            if (!this.props.disable_editing) {
                scenarios.push((
                    <Col span={6} key="new">
                        <Cuelist
                            onClick={() => this.newScene(scenario_config && scenario_config.location)}
                            state={CuelistState.empty}
                        />
                    </Col>
                ));
            }
        }
        return scenarios;
    };

    renderLocation = (location) => {
        const cuelists = this.createCuelists(location);
        // Don't create categories, where all scenarios are gone
        if (!cuelists.find(scenario => scenario.props.children.props.state !== CuelistState.gone)) return null;
        return (
            <div className={`category ${location && location.location}`} key={location && location.location}>
                {location
                    ? (
                        <div className="header">
                            <ReactSVG src={location.iconTitle} beforeInjection={svg => svg.classList.add(`icon-title-${location.location}`)}/>
                            <p>{location.title}</p>
                        </div>) : <div className="header-empty"/>}
                <div className="body">
                    <Row>
                        {cuelists}
                    </Row>
                </div>
            </div>
        );
    };

    render() {
        let show_watermark = true;
        if (APP_CONFIG.SCENARIOS instanceof Array) {
            show_watermark = (
                APP_CONFIG.SCENARIOS.map(scenario => scenario.maxY)
                    .reduce((total, sum) => total + sum) * 192 + APP_CONFIG.SCENARIOS.length * 48
            ) < (this.props.browser.height - 20 - 90) || this.props.browser.width > 1400;
        }
        const scenes = APP_CONFIG.SCENARIOS ? APP_CONFIG.SCENARIOS.map(this.renderLocation) : this.renderLocation(null);

        return (
            <div className="LightPage">
                {this.state.modal}
                <div className="locations">
                    {scenes || this.noScenes()}
                </div>
                {show_watermark && <Watermark/>}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: state.data,
    websocket: state.websocket,
    login: state.login,
    browser: state.browser
});

export default connect(mapStateToProps)(LightComponent);
