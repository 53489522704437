import React from "react";
import {connect} from "react-redux";
import classnames from "classnames";
import {PlusOutlined} from "@ant-design/icons";
import "./Palettes.less";
import {setTypeChanges} from "../../providers/setTypeChanges";
import {getElements, getRelativeElement} from "../../providers/getElements";
import colorProvider from "../../providers/colors/color.provider";
import PaletteEditor from "./PaletteEditor/PaletteEditor";
import {notificationsActions} from "../../providers/notifications/notificationsActions";

class Palettes extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            popup_menu: null,
            popup_menu_ref: null,
            popup_menu_x: 0,
            popup_menu_y: 0
        };

        // Bind functions to context
        this.setTypeChanges = setTypeChanges.bind(this);
        this.getElements = getElements.bind(this);
    }

    componentDidMount() {
        window.addEventListener("copy", this.onCopy);
        window.addEventListener("paste", this.onPaste);
    }

    componentWillUnmount() {
        window.removeEventListener("copy", this.onCopy);
        window.removeEventListener("paste", this.onPaste);
    }

    openPaletteEditor = (coords, palette) => {
        let popup_menu;
        const popup_menu_x = coords[0] + 50;
        const popup_menu_y = coords[1] + 50;

        if (palette && palette.uuid) {
            popup_menu = <PaletteEditor
                palette_uuid={palette.uuid}
                onClose={this.closePopupMenu}
                previousPalette={() => this.openPaletteEditor(coords, getRelativeElement(this.getElements("palette"), palette, -1))}
                nextPalette={() => this.openPaletteEditor(coords, getRelativeElement(this.getElements("palette"), palette, 1))}
            />;
        } else { // New fixture
            if (this.state.popup_menu) {
                return; // Menu is open, ignore event
            }

            popup_menu = <PaletteEditor
                new
                onClose={this.closePopupMenu}
            />;
        }

        // Close old menu, before opening a new one
        this.closePopupMenu();
        this.setState({
            popup_menu,
            popup_menu_x,
            popup_menu_y,
            //            active_element: palette
        });
    };

    closePopupMenu = () => {
        this.setState({popup_menu: null});
    };

    addPalette = event => {
        const coords = [event.clientX, event.clientY];
        this.openPaletteEditor(coords);
    };

    editPalette = (event, palette) => {
        const coords = [event.clientX, event.clientY];
        this.openPaletteEditor(coords, palette);
    };

    onCopy = event => {
        try {
            const data = JSON.stringify(this.getElements("palette"));
            event.clipboardData.setData("text/plain", data);
            event.preventDefault();
        } catch (err) {
            console.error("Failed to copy data:", err);
            this.props.dispatch({type: notificationsActions.COPY_FAILED});
        }
    };

    onPaste = event => {
        const text = (event.clipboardData || window.clipboardData).getData('text');

        if (text.length === 0) {
            this.props.dispatch({type: notificationsActions.NOTHING_TO_PASTE});
            return;
        }
        let new_palettes = null;
        try {
            new_palettes = JSON.parse(text);
        } catch (e) {
            this.props.dispatch({type: notificationsActions.PASTE_INVALID_DATA});
            console.error("Failed to parse paste:", e);
            return;
        }

        console.error(new_palettes);
        // event.preventDefault();
    };

    render() {
        return (
            <div className="PalettesPage">
                <div className="palettes">
                    {this.getElements("palette")
                        .map(pal => {
                            const color = colorProvider.getPaletteColor(pal);
                            let background = null;
                            let white = false;
                            if (color) {
                                background = colorProvider.convertToCSSColor(color.type, color.values);
                                white = colorProvider.RGBColorIsWhite(colorProvider.convertToRGBColor(color.type, color.values));
                            }

                            return (
                                <div className="palette" key={pal.uuid} onClick={event => this.editPalette(event, pal)}>
                                    <div
                                        className={classnames("color", {white})}
                                        style={{background}}
                                    />
                                    <p>{pal["webapp-name"] || pal.id}</p>
                                </div>);
                        })}
                    <div className="palette add" onClick={this.addPalette}>
                        <div
                            className="color"
                        />
                        <PlusOutlined/>
                        <p>Tilføj ny</p>
                    </div>
                </div>

                <div
                    className="popup-menu"
                    ref={popup_menu_ref => !this.state.popup_menu_ref && this.setState({popup_menu_ref})}
                    style={{
                        top: this.state.popup_menu_y,
                        left: this.state.popup_menu_x
                    }}
                >
                    {this.state.popup_menu}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: state.data,
    websocket: state.websocket,
    login: state.login,
    browser: state.browser
});

export default connect(mapStateToProps)(Palettes);
