import React from "react";
import {connect} from "react-redux";
import memoize from "memoize-one";
import _ from "lodash";
import "./MMComponent.less";
import {dataActions} from "../../providers/data/dataActions";
import {merge_by_uuid} from "../../providers/data/dataMiddleware";
import MMButton from "../buttons/MMButton";
import objectsAreEqual from "../../providers/objectsAreEqual";

const merge_by_uuid_memoize = memoize(merge_by_uuid);

export class MMComponent extends React.PureComponent {
    static mapStateToProps = state => ({
        data: state.data
    });

    static connect = child_component => connect(MMComponent.mapStateToProps)(child_component);

    constructor(props, componentTag) {
        super(props);
        this.state = {
            changes: {}
        };

        this.componentTag = componentTag;
    }

    componentDidMount() {
        window.addEventListener("copy", this.#_onCopy);
        window.addEventListener("paste", this.#_onPaste);
        document.addEventListener("keydown", this.onKeyDown, false);
    }

    componentDidUpdate(prevProps) {
        const dataUpdated = !objectsAreEqual(prevProps.data, this.props.data);
        if (this.onDataUpdated && dataUpdated) {
            this.onDataUpdated();
        }

        if (this.onChanges && this.hasChanges()) {
            this.onChanges();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("copy", this.onCopy);
        window.removeEventListener("paste", this.onPaste);
        document.removeEventListener("keydown", this.onKeyDown, false);
    }

    // eslint-disable-next-line
    onDataUpdated() {
    }

    onKeyDown = (event) => {
        switch (event.keyCode) {
            case 13: // Enter
                this.saveChanges();
                break;
            case 27: // Exit
                this.discardChanges();
                break;
            default:
                break;
        }
    };

    hasChanges = () => this.state.changes && this.state.changes.light_config
        && this.state.changes.light_config.playlist instanceof Object
        && Object.entries(this.state.changes.light_config.playlist).length > 0;

    getElements = (type = this.componentTag, with_changes = true, with_deleted = false) => {
        let elements = [];
        if (this.props.data && this.props.data.light_config && this.props.data.light_config.playlist
            && this.props.data.light_config.playlist[type]) {
            elements = this.props.data.light_config.playlist[type];
        }

        let elements_changes = null;
        if (with_changes && this.hasChanges() && this.state.changes.light_config.playlist[type]) {
            elements_changes = this.state.changes.light_config.playlist[type];
        }

        return merge_by_uuid_memoize(elements, elements_changes, !with_deleted);
    };

    getElement = (identifier, type = this.componentTag, needle = "uuid") => identifier && this.getElements(type)
        .find(e => e[needle] === identifier);

    setTypeChanges = (changes, type = this.componentTag, callback = null) => new Promise(resolve => {
        if (changes.length === 0) {
            if (callback) {
                callback();
            }
            return resolve();
        }
        this.setState(state => {
            let state_changes = {};
            if (state.changes) {
                // Always modify a copy of the state
                state_changes = _.cloneDeep(state.changes);
            }
            if (!state_changes.light_config) {
                state_changes.light_config = {};
            }
            if (!state_changes.light_config.playlist) {
                state_changes.light_config.playlist = {};
            }
            if (!state_changes.light_config.playlist[type]) {
                state_changes.light_config.playlist[type] = [];
            }
            state_changes.light_config.playlist[type] = merge_by_uuid(state_changes.light_config.playlist[type], changes);
            return {changes: state_changes};
        }, () => {
            if (callback) {
                callback();
            }
            resolve();
        });
    });

    discardChanges = () => {
        this.setState({changes: {}});
    };

    saveChanges = (ignoreCallback = false) => {
        if (this.hasChanges()) {
            // Save changes
            this.props.dispatch({
                type: dataActions.SET_LIGHT_CONFIGURATION,
                changes: this.state.changes.light_config,
                callback: !ignoreCallback ? () => this.discardChanges() : null
            });
        }
    };

    renderSubmit = () => (
        <div className="action-footer" hidden={!this.hasChanges()}>
            <div className="align-right">
                <ul>
                    <li>
                        <MMButton className="grey" onClick={this.discardChanges}>Annullér</MMButton>
                    </li>
                    <li>
                        <MMButton onClick={() => this.saveChanges()}>Gem ændringer</MMButton>
                    </li>
                </ul>
            </div>
        </div>
    );

    #_onCopy = event => {
        if (event.path[0].tagName === "INPUT") {
            return;
        }
        if (this.onCopy) {
            this.onCopy(event);
        }
    };

    #_onPaste = event => {
        if (event.path[0].tagName === "INPUT") {
            return;
        }
        if (this.onPaste) {
            this.onPaste(event);
        }
    };
}
