import {toArray} from "../providers/toArray";
import ElementController from "./ElementController";

export default class TimelineController extends ElementController {
    getTimelinesUsingTrigger = (trigger_id, needles = ["trigger"]) => this.getElements()
        .map((a, index) => ({
            ...a,
            index
        }))
        .filter(b => {
            for (const needle of toArray(needles)) {
                if (b[needle] && b[needle].includes(trigger_id)) {
                    return true;
                }
            }

            return false;
        });
}
