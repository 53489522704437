import React from "react";
import "./MMToggle.less";
import {isNullable} from "../../providers/objectsAreEqual";

export default class MMToggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: !isNullable(this.props.checked) ? props.checked : false
        };
    }

    onChange = (event) => {
        this.setState(state => {
            const checked = !isNullable(this.props.checked) ? !this.props.checked : !state.checked;
            if (this.props.onChange) {
                event.disable = this.props.disable;
                event.enable = this.props.enable;
                this.props.onChange(event, checked);
            }
            return {checked};
        });
    };

    render() {
        return (
            <div className="MMToggle">
                <label htmlFor={this.props.id}>
                    <input
                        type="checkbox"
                        onChange={this.onChange}
                        id={this.props.id}
                        checked={!isNullable(this.props.checked) ? this.props.checked : this.state.checked}
                        disabled={this.props.loading || this.props.disabled}
                    />
                    <span/>
                </label>
            </div>
        );
    }
}
