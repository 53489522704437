import {isNullable} from "./objectsAreEqual";

export function fixture_sorting_algorithm(a, b, sort_by_keys) {
    let sort_by = sort_by_keys;
    if (sort_by_keys instanceof Array) {
        sort_by = sort_by_keys[0];
    }

    if (isNullable(a) || isNullable(b)) {
        return isNullable(a) ? 1 : -1;
    }

    let a_value = parseFloat(a[sort_by]);
    let b_value = parseFloat(b[sort_by]);
    if (isNaN(a_value) && isNaN(b_value)) {
        const a_value2 = typeof a[sort_by] === "string" && parseFloat(a[sort_by].split("-")[1]);
        const b_value2 = typeof b[sort_by] === "string" && parseFloat(b[sort_by].split("-")[1]);
        if (!isNaN(a_value2) && !isNaN(b_value2)) {
            a_value = a_value2;
            b_value = b_value2;
        } else {
            a_value = a[sort_by];
            b_value = b[sort_by];
        }
    } else if (isNaN(a_value) || isNaN(b_value)) {
        return isNaN(a_value) ? 1 : -1; // Make sure all string numbers are first in sorted list
    }

    if (a_value === b_value) {
        if (sort_by_keys instanceof Array && sort_by_keys.length > 1) {
            return fixture_sorting_algorithm(a, b, sort_by_keys.slice(1));
        }
        return 0;
    }
    return a_value > b_value ? 1 : -1;
}

// Sort objects by multiple keys
// Use like this: sortObjects(fixture_list, ["universe", "offset"])
export function sortObjects(objects, sort_by_keys, subKey = null) {
    if (subKey) {
        return objects.sort((a, b) => fixture_sorting_algorithm(a[subKey], b[subKey], sort_by_keys));
    }
    return objects.sort((a, b) => fixture_sorting_algorithm(a, b, sort_by_keys));
}
