import motomuto_config from "./motomuto";
import {api_v2} from "./common/server_v2_api";
import Light from "../pages/Light/Light";
import TriggersCalendar from "../pages/Triggers/TriggersCalendar";
import TriggersWrapper from "../pages/Triggers/Wrapper";

// Moto Muto Remote Access Server configuration

export default {
    ...motomuto_config,
    API_ENDPOINTS: api_v2,
    PRODUCT_NAME: "Remote Access Server (RAS)",
    USES_PROJECTS: true,
    MENU: [
        {key: "scenes", name: "Scener"},
        {key: "calendar", name: "Kalender"}
    ],
    DEFAULT_CONTROLLER_VERSION: 1,
    SHOW_HIDDEN_CUELISTS: false,
    ALLOW_EDITING_LOCKED_CUELISTS: false,
    ROUTES: [
        // {path: "fixtures", component: Fixtures, default: true},
        {path: "scenes", component: Light, props: {disable_editing: true}},
        {path: "calendar", component: TriggersCalendar, wrapper: TriggersWrapper, if_user_has_property: ["calendar"], users: ["admin"]}
    ],
    WEBSOCKET_PORT: ""
};
