import {isNullable} from "../objectsAreEqual";
import {toArray} from "../toArray";

export const callbackMiddleware = (store) => (next) => (action) => {
    if (!isNullable(action.callback)) {
        for (const callback of toArray(action.callback)) {
            callback(action, store);
        }
    }
    return next(action);
};

export default callbackMiddleware;
