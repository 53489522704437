import constants from "../less/motomuto_ras/constants";

export default class ModalController {
    constructor(parent) {
        this.close = this.close.bind(parent);
    }

    close() {
        this.setState({closing: true});

        if (this.props.onClose) {
            setTimeout(this.props.onClose, parseFloat(constants["modal-transition-duration"]));
        }
    }
}
