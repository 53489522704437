import React from "react";
import {connect} from "react-redux";
import _ from "lodash";
import MMCard from "../../../elements/layout/MMCard";
import MMButton from "../../../elements/buttons/MMButton";
import "./SceneEditor.less";
import {setTypeChanges} from "../../../providers/setTypeChanges";
import {getElements} from "../../../providers/getElements";
import {dataActions} from "../../../providers/data/dataActions";
import MMSelect from "../../../elements/inputs/MMSelect";
import MMTextInput from "../../../elements/inputs/MMTextInput";
import {isNullable} from "../../../providers/objectsAreEqual";
import {sortObjects} from "../../../providers/sortObjects";

class SceneEditor extends React.Component {
    constructor(props) {
        super(props);

        // Bind functions to context
        this.setTypeChanges = setTypeChanges.bind(this);
        this.getElements = getElements.bind(this);
        this.state = {};
    }

    componentDidMount() {
        let palette_id = null;
        let intensity = 100;
        let transparency = 0;
        const scene = this.getScene();
        if (scene) {
            const scene_components = scene["scene-component"].filter(e => this.props.fixture_id.includes(e["dmx-fixture"]));
            if (scene_components[0] && scene_components.length === this.props.fixture_id.length) {
                palette_id = scene_components[0].palette;
                if (!isNullable(scene_components[0].intensity)) {
                    intensity = scene_components[0].intensity;
                }
                if (!isNullable(scene_components[0].transparency)) {
                    transparency = scene_components[0].transparency;
                }
            }
            for (const scene_component of scene_components) {
                if (scene_component.palette !== palette_id) {
                    palette_id = null;
                    break;
                }
                if (scene_component.intensity !== intensity) {
                    this.setValueOnSelectedSceneComponents("intensity", 100);
                    intensity = 100;
                    break;
                }
                if (scene_component.transparency !== transparency) {
                    transparency = 0;
                    this.setValueOnSelectedSceneComponents("transparency", transparency);
                    break;
                }
            }
        }
        this.setState({
            palette_id,
            intensity,
            transparency
        });
    }

    getScene = (uuid = this.props.scene_uuid) => this.getElements("scene")
        .find(sce => sce.uuid === uuid);

    submit = () => {
        this.setState({loading: true});
        // Save changes
        this.props.dispatch({
            type: dataActions.SET_LIGHT_CONFIGURATION,
            changes: this.state.changes.light_config,
            callback: () => {
                if (this.props.submit) {
                    this.props.submit();
                }
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            errorCallback: () => {
                this.setState({loading: false});
            }
        });
    };

    setValueOnSelectedSceneComponents = (key, value) => {
        const scene = this.getScene();
        if (!scene) {
            return;
        }

        const scene_components = _.cloneDeep(scene["scene-component"]);

        for (const fixture_id of this.props.fixture_id) {
            const sc = scene_components.find(e => e["dmx-fixture"] === fixture_id);
            if (sc) {
                sc[key] = value;
            } else {
                scene_components.push({
                    "dmx-fixture": fixture_id,
                    [key]: value
                });
            }
        }

        const fixtures = this.getElements("dmx-fixture");
        const scene_components_with_coords = scene_components.map(sc => {
            const fixture = fixtures.find(fix => fix.id === sc["dmx-fixture"]) || {};
            const {
                x,
                y
            } = fixture;
            return ({
                ...sc,
                x,
                y
            });
        });

        const sorted_scene_components = sortObjects(scene_components_with_coords, ["y", "x"])
            .map(sc => {
                delete sc.x;
                delete sc.y;
                return sc;
            });

        this.setTypeChanges("scene", {
            uuid: this.props.scene_uuid,
            "scene-component": sorted_scene_components
        });
    };

    onChangeIntensity = (event, intensity) => {
        this.setState({intensity});
        this.setValueOnSelectedSceneComponents("intensity", intensity);
    };

    onChangeTransparency = (event, transparency) => {
        this.setState({transparency});
        this.setValueOnSelectedSceneComponents("transparency", transparency);
    };

    onChangePalette = value => {
        this.setState({palette_id: value});
        this.setValueOnSelectedSceneComponents("palette", value);
    };

    render() {
        return (
            <div className="SceneEditor" onClick={event => event.stopPropagation()}>
                <MMCard height="fit-content" width={200} padding={5}>
                    <div className="container">
                        <div className="header">
                            <p>Redigér scene</p>
                        </div>
                        <div className="body">
                            <p>Palette</p>
                            <MMSelect
                                options={this.getElements("palette")
                                    .map(pal => ({
                                        key: pal.id,
                                        value: pal["webapp-name"] || pal.id
                                    }))}
                                value={this.state.palette_id}
                                onChange={this.onChangePalette}
                            />
                            <p>Intensitet</p>
                            <div className="text-postfix">
                                <MMTextInput
                                    type="number"
                                    min={0}
                                    max={100}
                                    step={5}
                                    width={30}
                                    value={this.state.intensity}
                                    onChange={this.onChangeIntensity}
                                />
                                <p>%</p>
                            </div>
                            <p>Transparens</p>
                            <div className="text-postfix">
                                <MMTextInput
                                    type="number"
                                    min={0}
                                    max={100}
                                    step={5}
                                    width={30}
                                    value={this.state.transparency}
                                    onChange={this.onChangeTransparency}
                                />
                                <p>%</p>
                            </div>
                        </div>
                        <div className="footer">
                            <MMButton
                                className="link"
                                disabled={this.state.loading || !this.state.changes || !this.state.changes.light_config}
                                onClick={this.submit}
                            >
                                Gem
                            </MMButton>
                            <MMButton
                                className="link"
                                disabled={this.state.loading}
                                onClick={this.props.onClose}
                            >
                                Annullér
                            </MMButton>
                        </div>
                    </div>
                </MMCard>
            </div>);
    }
}

SceneEditor.defaultProps = {
    fixture_id: null
};

const mapStateToProps = state => ({
    data: state.data
});

export default connect(mapStateToProps)(SceneEditor);
