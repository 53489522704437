// Support functions like Array.flat() in MS Edge
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {routerMiddleware, routerReducer} from "react-router-redux";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {Provider} from "react-redux";
import moment from "moment";
// import {createLogger} from 'redux-logger';
import {calculateResponsiveState, createResponsiveStateReducer, responsiveStoreEnhancer} from "redux-responsive";
import {HashRouter, Route} from "react-router-dom";
import {createHashHistory} from "history";
import {notification} from "antd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import App from './App';
import {callbackMiddleware} from "./providers/callback/callbackMiddleware";
import loginReducer from "./providers/login/loginReducer";
import {loginMiddleware} from "./providers/login/loginMiddleware";
import {notificationsMiddleware} from "./providers/notifications/notificationsMiddleware";
import {dataMiddleware} from "./providers/data/dataMiddleware";
import dataReducer from "./providers/data/dataReducer";
import WebsocketMiddlewareInstance from "./providers/websocket/websocketMiddleware";
import websocketReducer from "./providers/websocket/websocketReducer";
import isVirtualKeyboardShown from "./providers/isVirtualKeyboardShown";
import layoutReducer from "./providers/layout/layoutReducer";

// eslint-disable-next-line no-console
console.log("ENV: " + process.env.NODE_ENV);
// eslint-disable-next-line no-console
console.log(`VERSION: ${process.env.REACT_APP_VERSION}:${process.env.REACT_APP_VARIANT}`);
// eslint-disable-next-line no-console
console.log(`SERVER BASE URL: ${process.env.REACT_APP_SERVER_BASE_URL}`);

document.body.classList.add(process.env.REACT_APP_VARIANT);

const loggerMiddleware = () => (next) => (action) => next(action);

// noinspection JSValidateTypes
if (process.env.NODE_ENV === "development") {
    window.moment = moment;
    /*
    loggerMiddleware = createLogger({
        collapsed: true
    });
     */
}

const history = createHashHistory();
const store = createStore(
    combineReducers({
        router: routerReducer,
        login: loginReducer,
        data: dataReducer,
        websocket: websocketReducer,
        browser: createResponsiveStateReducer(null, {
            extraFields: () => ({
                width: window.innerWidth,
                height: window.innerHeight,
                scrollY: window.scrollY
            })
        }),
        // eslint-disable-next-line
        layoutReducer
    }),
    compose(
        responsiveStoreEnhancer,
        applyMiddleware(
            routerMiddleware(history),
            loginMiddleware,
            dataMiddleware,
            (new WebsocketMiddlewareInstance()).middleware,
            notificationsMiddleware,
            callbackMiddleware,
            loggerMiddleware
        )
    )
);
window.addEventListener('resize', () => store.dispatch(calculateResponsiveState(window)));
window.dispatch = store.dispatch; // Expose function to developer console

// Initialize antd notifications, so we can set the margin when virtual keyboard is shown
notification.error({
    style: {
        display: "none"
    }
});

// Check if virtual keyboard is open
let previous_state = false;
const virtualKeyboard = () => {
    const new_state = isVirtualKeyboardShown();
    if (previous_state !== new_state) {
        previous_state = new_state;
        if (new_state) {
            document.body.classList.add("virtual-keyboard");
        } else {
            document.body.classList.remove("virtual-keyboard");
        }
        store.dispatch(calculateResponsiveState(window));
    }
};
virtualKeyboard();
setInterval(virtualKeyboard, 500);

ReactDOM.render(
    <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
            <HashRouter>
                <Route path="/" component={App}/>
            </HashRouter>
        </DndProvider>
    </Provider>,
    document.getElementById('root')
);
